import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountingSettingsService } from './common/services/accounting-settings.service';
import { BasicSettingsNetwork } from '../../core/network/basic-settings.network';
import { AccountsComponent } from './accounts.component';
import { SettingsEmitter } from '../../core/emitters/settings.emitter';
import { HeaderNotificationModule } from '../header-notification/headerNotification.module';
import { SharedModule } from '../../shared/shared.module';
import { AccessRightService } from '../../core/services/access-right.service';
import { AccountingSettingsInterceptorProvider } from './common/interceptors/accounting-settings.interceptor';
import { AccountCreatedEmitter } from '../common/services/account-created.emitter';

const appRoutes: Routes = [
  {
    path: '',
    component: AccountsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes), HeaderNotificationModule, SharedModule],
  declarations: [AccountsComponent],
  providers: [
    AccountingSettingsService,
    BasicSettingsNetwork,
    SettingsEmitter,
    AccessRightService,
    AccountingSettingsInterceptorProvider,
    AccountCreatedEmitter,
  ],
  bootstrap: [],
  exports: [],
})
export class AccountsModule {}
