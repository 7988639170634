<div
  class="columns-section"
  (click)="toggleOpen()"
  [ngClass]="{ 'columns-section--inactive': disabled }"
>
  <troi-icon icon="icon-columns" size="11px"></troi-icon>
  <span class="columns-section__label">{{ 'Booking.labels.columns' | translate }}</span>
</div>

<troi-columns-list
  (openChange)="onOpen($event)"
  *ngIf="open"
  [forceOpen]="forceOpen"
  [columnList]="columnList"
  (columnsSaved)="onColumnsSaved($event)"
></troi-columns-list>
