import * as _ from 'lodash';
import { UserSettings } from '../../core/services/user-settings';
import { StorageService } from '../../core/storage/storage.service';
import { ColumnSelectInterface } from './column-select.interface';

export class ColumnsSelectService extends UserSettings {
  columnStoreMainKey = 'columns';

  fetchedColumns: ColumnSelectInterface[];

  constructor(public storage: StorageService) {
    super(storage);
    this.initLSUserData(this.columnStoreMainKey);
  }

  saveColumns(key: string, columns: ColumnSelectInterface[]) {
    const columnsSaved = this.getAllUsersSettings();
    columnsSaved[this.userId][this.columnStoreMainKey][key] = columns;
    this.saveUserSetting(columnsSaved);
  }

  protected isColumnVisible(columnList: ColumnSelectInterface[], columnName, defaultVisibleState = true): boolean {
    const columnData = _.find(columnList, ['id', columnName]);
    return columnData ? columnData.active : defaultVisibleState;
  }

  protected getColumnList(defaultColumnList: ColumnSelectInterface[], key: string): ColumnSelectInterface[] {
    if (this.fetchedColumns) {
      return this.fetchedColumns;
    }

    let columnsSaved: ColumnSelectInterface[] = this.getSettingForUser(this.columnStoreMainKey)[key];

    if (!columnsSaved) {
      this.saveColumnList(defaultColumnList, key);
      columnsSaved = [...defaultColumnList];
    } else {
      let identicalColumns = true;
      for (let i = 0; i < columnsSaved.length; i++) {
        if (columnsSaved[i].id !== defaultColumnList[i].id) {
          identicalColumns = false;
          break;
        }
      }

      if (!identicalColumns || columnsSaved.length !== defaultColumnList.length) {
        columnsSaved = defaultColumnList.map((column) => {
          const savedColumn = columnsSaved.find((saved) => saved.id === column.id);
          return savedColumn ? savedColumn : column;
        });

        const columnsToRemove = _.differenceBy(columnsSaved, defaultColumnList, 'id');
        columnsSaved = _.pullAllBy(columnsSaved, columnsToRemove, 'id');
      }

      this.saveColumnList(columnsSaved, key);
    }
    this.fetchedColumns = columnsSaved;

    return columnsSaved;
  }

  protected saveColumnList(columns: ColumnSelectInterface[], key: string) {
    this.fetchedColumns = columns;
    this.saveColumns(key, columns);
  }
}
