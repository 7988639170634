import { Component } from '@angular/core';
import { TroiDropdownListModel } from '../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { TranslateService } from '@ngx-translate/core';
import { ActionsEnum } from '../../enum/actions.enum';
import { DropdownOptionSelectedEvent } from '../../../../shared/troi-dropdown-list/interfaces/dropdown-option-selected-event.interface';
import { ModalService } from '../../../../shared/troi-modals/modal.service';
import { BudgetStatusSettingsComponent } from '../modals/budget-status-settings/budget-status-settings.component';
import { ExportListComponent } from '../modals/export-list/export-list.component';
import { ListTopBarService } from './services/list-top-bar.service';

@Component({
  selector: 'list-top-bar',
  templateUrl: './list-top-bar.component.html',
  styleUrls: ['./list-top-bar.component.scss'],
})
export class ListTopBarComponent {
  public isEditMenuOpen = false;

  constructor(
    private translate: TranslateService,
    private modalService: ModalService,
    private listTopBarService: ListTopBarService,
  ) {}

  public get moreActionsOptions(): TroiDropdownListModel[] {
    return [
      {
        active: true,
        icon: 'icon-arrow-up-list',
        label: this.translate.instant('ProjectList.labels.actions.exportList'),
        value: ActionsEnum.EXPORT_LIST,
      },
      {
        active: true,
        icon: 'icon-setting',
        label: this.translate.instant('ProjectList.labels.actions.statusSettings'),
        value: ActionsEnum.STATUS_SETTINGS,
      },
    ];
  }

  public onProjectCreate(): void {
    this.listTopBarService.createNewProjectEmitter.next(true);
  }

  public moreActionOptionClick(event: DropdownOptionSelectedEvent<ActionsEnum>): void {
    switch (event.value) {
      case ActionsEnum.STATUS_SETTINGS: {
        this.modalService.init(BudgetStatusSettingsComponent, {}, {});
        break;
      }
      case ActionsEnum.EXPORT_LIST: {
        this.modalService.init(ExportListComponent, {}, {}, 'auto', '600px');
        break;
      }
    }

    if (event.event) {
      event.event.stopPropagation();
    }
  }

  public onOpeningState(event: boolean): void {
    this.isEditMenuOpen = event;
  }

  public get moreActionsIcon(): string {
    return `icon-arrow-head-${this.isEditMenuOpen ? 'up' : 'down'}`;
  }
}
