import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ClientInterface } from '../../../../core/interfaces/client.interface';
import { BasicFiltersService } from '../../../../core/services/basic-filters.service';
import { Money } from '../../../../shared/troi-money/money';
import {
  AccountDropdownInterface,
  AccountGroupDropdownInterface,
  AccountGroupForSelectInterface,
  AccountTypeInterface,
  TaxRateInterface,
} from '../../../common/interfaces/basic-settings.interface';
import { AccountGroupInterface } from '../../accounting-administration/account-group/interfaces/account-group.interface';
import { AccountGroupModel } from '../../accounting-administration/account-group/models/account-group.model';
import { AccountInterface } from '../../accounting-administration/account-list/interfaces/account.interface';
import { OpeningBalanceInterface } from '../../accounting-administration/account-list/interfaces/opening-balance.interface';
import { AccountModel } from '../../accounting-administration/account-list/models/account.model';
import { OpeningBalanceModel } from '../../accounting-administration/account-list/models/opening-balance.model';
import {
  CostCenterInterface,
  MonthlyCostInterface,
} from '../../accounting-administration/cost-center/interfaces/cost-center.interface';
import { CostCenterModel } from '../../accounting-administration/cost-center/models/cost-center.model';
import { MonthlyCostModel } from '../../accounting-administration/cost-center/models/monthly-cost.model';
import { PaymentMethodInterface } from '../../accounting-administration/payment-methods/interfaces/payment-method.interface';
import { PaymentMethodModel } from '../../accounting-administration/payment-methods/models/payment-method.model';
import { AccountingSettingsService } from '../services/accounting-settings.service';

@Injectable()
export class ModelsFactory {
  constructor(private settings: AccountingSettingsService, private basicFiltersService: BasicFiltersService) {}

  buildAccountGroupModel(groupResponse: AccountGroupInterface): AccountGroupModel {
    return new AccountGroupModel(
      groupResponse.id,
      groupResponse.description,
      this.findClient(groupResponse.client),
      this.findAccountType(groupResponse.type),
      groupResponse.active,
      groupResponse.canBeDeleted,
    );
  }

  buildAccountModel(accountResponse: AccountInterface): AccountModel {
    return new AccountModel(
      accountResponse.id,
      this.findClient(accountResponse.client),
      accountResponse.number,
      accountResponse.name,
      this.findAccountGroupDropdown(accountResponse.group),
      this.findTaxRate(accountResponse.tax),
      this.buildOpeningBalanceModels(accountResponse.openingBalances),
      this.findAccountDropdown(accountResponse.counterAccount),
      accountResponse.cashAccount,
      accountResponse.bankAccount,
      accountResponse.automaticAccount,
      accountResponse.depositAccount,
      accountResponse.datevLock,
      accountResponse.active,
      accountResponse.canBeDeleted,
    );
  }

  buildOpeningBalanceModels(balanceResponse: OpeningBalanceInterface[]): OpeningBalanceModel[] {
    const models: OpeningBalanceModel[] = [];

    _.forEach(balanceResponse, (balance: OpeningBalanceInterface) => {
      models.push(
        new OpeningBalanceModel(
          balance.year,
          new Money(balance.value, this.settings.systemCurrency, this.settings.settings.settings.decimalPlacesNumber),
        ),
      );
    });

    return models;
  }

  buildCostCenterModel(costCenterResponse: CostCenterInterface): CostCenterModel {
    return new CostCenterModel(
      costCenterResponse.id,
      this.findClient(costCenterResponse.client),
      costCenterResponse.year,
      costCenterResponse.number,
      costCenterResponse.description,
      costCenterResponse.active,
      this.buildMonthlyCostsModels(costCenterResponse.costs.fixed_costs),
      this.buildMonthlyCostsModels(costCenterResponse.costs.extraordinary_expenses),
      costCenterResponse.canBeDeleted,
    );
  }

  buildPaymentMethodModel(paymentMethodResponse: PaymentMethodInterface): PaymentMethodModel {
    return new PaymentMethodModel(
      paymentMethodResponse.id,
      paymentMethodResponse.name,
      paymentMethodResponse.mappingValue,
      paymentMethodResponse.active,
      new Date(paymentMethodResponse.deletedAt),
      paymentMethodResponse.client,
      paymentMethodResponse.translations,
      paymentMethodResponse.default,
    );
  }

  private buildMonthlyCostsModels(costs: MonthlyCostInterface[]): MonthlyCostModel[] {
    const costModels: MonthlyCostModel[] = [];

    _.forEach(costs, (cost: MonthlyCostInterface) => {
      costModels.push(
        new MonthlyCostModel(
          cost.name,
          new Money(cost.value, this.settings.systemCurrency, this.settings.settings.settings.decimalPlacesNumber),
        ),
      );
    });

    return costModels;
  }

  public findAccountType(id: number): AccountTypeInterface {
    return _.find(
      this.settings.settings.dropdowns.accountTypes,
      (accountType: AccountTypeInterface) => accountType.id === id,
    );
  }

  public findClient(id: number): ClientInterface {
    return _.find(this.basicFiltersService.clients, (client: ClientInterface) => client.id === id);
  }

  public findTaxRate(id: number): TaxRateInterface {
    return _.find(this.settings.settings.dropdowns.taxRates, (taxRate: TaxRateInterface) => taxRate.id === id);
  }

  public findAccountGroupDropdown(id: string): AccountGroupDropdownInterface {
    return _.find(
      this.settings.settings.dropdowns.accountGroups,
      (group: AccountGroupDropdownInterface) => group.id === id,
    );
  }

  public getAccountDropdownList(): AccountDropdownInterface[] {
    if (this.settings.settings === null) {
      return [];
    }

    let accounts: AccountDropdownInterface[] = [];
    _.forEach(this.settings.settings.dropdowns.accounts, (accountForSelect: AccountGroupForSelectInterface) => {
      accounts.push({
        id: accountForSelect.accountGroup.id,
        name: accountForSelect.accountGroup.description,
        active: accountForSelect.accountGroup.active,
        group: true,
      });
      accounts = [...accounts, ...accountForSelect.accounts];
    });

    return accounts;
  }

  public findAccountDropdown(id: string): AccountDropdownInterface {
    return _.find(this.getAccountDropdownList(), (account: AccountDropdownInterface) => account.id === id);
  }
}
