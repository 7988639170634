import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../../../../core/storage/storage.service';
import { StorageKeys } from '../../../../core/storage/storage.keys';
import { SettingsEmitter } from '../../../../core/emitters/settings.emitter';
import { BaseBookingSettings } from '../../../common/classes/base-booking-settings';
import { TroiDropdownListModel } from '../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { BasicSettingsNetwork } from '../../../../core/network/basic-settings.network';
import {
  BookingSettingsInterface,
  ProjectAssignmentFallbacksInterface,
} from '../interfaces/booking-settings.interface';
import { Routes } from '../../../../core/enums/routes';

@Injectable()
export class BookingSettingsService extends BaseBookingSettings<BookingSettingsInterface> {
  public actualVersion = '7.5';
  public storageKey = StorageKeys.BOOKINGS_SETTINGS;

  constructor(
    protected storageService: StorageService,
    public basicSettingsNetwork: BasicSettingsNetwork,
    protected settingsEmitter: SettingsEmitter,
  ) {
    super(storageService, basicSettingsNetwork, settingsEmitter);
  }

  public getSettingsRoute(): string {
    return Routes.ACCOUNTING_SETTINGS;
  }

  prepareSettings(clientId?: number, force: boolean = false): Observable<BookingSettingsInterface> {
    const settings = this.getSettingsFromLS();
    this.storageService.removeItem(this.storageKey);

    if (
      force ||
      !settings ||
      (!settings && !this.areSettingsForClient(clientId, settings.client)) ||
      !this.isVersionCorrect(settings, this.actualVersion)
    ) {
      return this.downloadSettings(clientId);
    }

    this.saveSettingsToLS(settings);
    this.settings = settings;
    this.setSystemCurrency(this.settings.dropdowns.systemCurrency, this.settings.client);

    return this.prepareSettingCurrency(false);
  }

  saveSettingsToLS(settings: BookingSettingsInterface) {
    super.saveSettingsToLS({ ...settings, version: this.actualVersion });
  }

  public setSystemCurrencyIfNeeded() {
    if (!this.systemCurrency) {
      this.setSystemCurrency(this.settings.dropdowns.systemCurrency, this.settings.client);
    }
  }

  public buildBookingYears(): Array<TroiDropdownListModel> {
    return this.settings ? super.buildBookingYears(this.settings.dropdowns.bookingYears) : [];
  }

  public getFallbackObjects(): ProjectAssignmentFallbacksInterface {
    return this.settings.fallbackObjects.projectAssignment;
  }
}
