import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { BackendResponseInterface } from '../../../../../../core/interfaces/backend.response.interface';
import { ProjectListNetwork } from '../../../../network/project-list.network';
import { ExportListInterface } from '../../../../interfaces/export-list.interface';
import { ExportStatusEnum } from '../../../../enum/export-status.enum';
import { FilesService } from '../../../../../bookings/common/services/files.service';
import { environment } from '../../../../../../../environments/environment';
import { FiltersService } from '../../../../services/filters.service';
import { FiltersFormService } from '../../../../services/filters-form.service';

@Injectable()
export class ExportListService extends FilesService {
  public submitted = false;
  public pending = false;
  public exportState: ExportListInterface;
  public cancelPollingExportStatus: Subject<void> = new Subject<void>();

  constructor(
    private projectListNetwork: ProjectListNetwork,
    private filtersService: FiltersService,
    private filtersFormService: FiltersFormService,
  ) {
    super();
  }

  public initForm(): UntypedFormGroup {
    this.exportState = {
      id: null,
      status: ExportStatusEnum.UNKNOWN,
      progress: 0,
    };

    return new UntypedFormGroup({
      exportType: new UntypedFormControl('', Validators.required),
    });
  }

  public create(exportForm: UntypedFormGroup): Observable<BackendResponseInterface<ExportListInterface>> {
    return this.projectListNetwork.createExport(
      exportForm,
      this.filtersFormService.prepareSearchRequestParams(this.filtersService.actualFilters),
    );
  }

  // TODO: TROR-19666 remove progress param when API is implemented
  public getStatus(progress = 0): Observable<BackendResponseInterface<ExportListInterface>> {
    return this.projectListNetwork.getExport(this.exportState.id, progress);
  }

  public delete(): Observable<BackendResponseInterface<ExportListInterface>> {
    return this.projectListNetwork.deleteExport(this.exportState.id);
  }

  public get directLink(): string {
    return environment.url + this.exportState.directLink;
  }
}
