<div class="approval-actions">
  <approval-process
    *ngIf="approvalsService.canEdit(this.approvalStatus)"
    [placement]="'top'"
    [edit]="true"
    [id]="id"
    (refreshApprovalStatus)="onApprovalStatusChange()"
  >
    <troi-icon
      class="approval-actions__action approval-actions__action--blue"
      [icon]="'icon-can-edit'"
      [ngbTooltip]="'Approval.labels.editApproval' | translate"
      [container]="'body'"
      [tooltipClass]="'approval-actions__tooltip'"
      tooltipClass="approval-tooltip"
    ></troi-icon>
  </approval-process>
  <approval-history
    *ngIf="approvalsService.canHistory(this.approvalStatus)"
    [placement]="'top'"
    [id]="id"
  >
    <troi-icon
      class="approval-actions__action approval-actions__action--blue"
      [icon]="'icon-clock-open'"
      [ngbTooltip]="'Approval.labels.approvalHistory' | translate"
      [container]="'body'"
      [tooltipClass]="'approval-actions__tooltip'"
      tooltipClass="approval-tooltip"
    ></troi-icon>
  </approval-history>

  <troi-icon
    class="approval-actions__action approval-actions__action--red"
    *ngIf="approvalsService.canCancel(this.approvalStatus)"
    [icon]="'icon-minus-with-white-circle'"
    [ngbTooltip]="'Approval.labels.stopApproval' | translate"
    [container]="'body'"
    [tooltipClass]="'approval-actions__tooltip'"
    tooltipClass="approval-tooltip"
    (click)="onCancel()"
  ></troi-icon>
  <troi-icon
    class="approval-actions__action approval-actions__action--red"
    *ngIf="approvalsService.canDecline(this.approvalStatus)"
    [icon]="'icon-close'"
    [ngbTooltip]="'Approval.labels.declineApproval' | translate"
    [container]="'body'"
    [tooltipClass]="'approval-actions__tooltip'"
    tooltipClass="approval-tooltip"
    (click)="onDecline()"
  ></troi-icon>
  <troi-icon
    class="approval-actions__action approval-actions__action--green"
    *ngIf="approvalsService.canApprove(this.approvalStatus)"
    [icon]="'icon-tick'"
    [ngbTooltip]="'Approval.labels.acceptApproval' | translate"
    [container]="'body'"
    [tooltipClass]="'approval-actions__tooltip'"
    tooltipClass="approval-tooltip"
    (click)="onApprove()"
  ></troi-icon>
  <approval-process
    *ngIf="approvalsService.canDelegate(this.approvalStatus)"
    [placement]="'top'"
    [delegate]="true"
    [id]="id"
    (refreshApprovalStatus)="onApprovalStatusChange()"
  >
    <troi-icon
      class="approval-actions__action"
      [icon]="'icon-person-with-empty-checkbox'"
      [ngbTooltip]="'Approval.labels.delegateApproval' | translate"
      [container]="'body'"
      [tooltipClass]="'approval-actions__tooltip'"
      tooltipClass="approval-tooltip"
    ></troi-icon>
  </approval-process>
</div>
