import { Component, Input } from '@angular/core';
import { BaseModalDirective } from '../../../../../shared/troi-base-modal/baseModal.component';
import { ModalService } from '../../../../../shared/troi-modals/modal.service';
import { UserConfirmationEventEnum } from '../../../../../shared/troi-user-confirmation/user-confirmation-event.enum';
import { UserConfirmationPopupInterface } from '../../../../../shared/troi-user-confirmation/user-confirmation-popup.interface';
import { UserConfirmationSubscriber } from '../../../../../shared/troi-user-confirmation/user-confirmation.subscriber';
import { AssigneeInterface } from '../../../interfaces/assignee.interface';

@Component({
  selector: 'app-not-allowed-assignees-task-modal',
  templateUrl: './not-allowed-assignees-modal.component.html',
  styleUrls: ['./not-allowed-assignees-modal.component.scss'],
})
export class NotAllowedAssigneesModalComponent extends BaseModalDirective {
  @Input() assignees: AssigneeInterface[];
  public modalObject: UserConfirmationPopupInterface;

  public constructor(public modalService: ModalService, private subscriber: UserConfirmationSubscriber) {
    super(modalService, false, true);
    this.modalObject = {
      ...this.modalService.object,
      ...this.modalService.componentConfig?.outputs,
    };
  }

  close() {
    this.subscriber.action.next(UserConfirmationEventEnum.CLOSE);
    this.subscriber.actionWithData.next({
      event: UserConfirmationEventEnum.CLOSE,
      data: this.modalObject,
    });
    super.closeSecond();
  }

  execute() {
    this.subscriber.action.next(UserConfirmationEventEnum.EXECUTE);
    this.subscriber.actionWithData.next({
      event: UserConfirmationEventEnum.EXECUTE,
      data: this.modalObject,
    });
    super.closeSecond();
  }
}
