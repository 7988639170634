import { Component, Input } from '@angular/core';
import { TableInterface } from './interfaces/table.interface';

@Component({
  selector: 'troi-table',
  templateUrl: './troi-table.component.html',
  styleUrls: ['./troi-table.component.scss'],
})
export class TroiTableComponent {
  @Input() tableData: TableInterface;
}
