import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'troi-modal-section',
  templateUrl: './troi-modal-section.component.html',
  styleUrls: ['./troi-modal-section.component.scss'],
})
export class TroiModalSectionComponent implements OnInit, OnChanges {
  @Input() public label: string;

  @Input() public forceOpen = false;

  @Input() public sectionId;

  @Input() public showCheckmark = false;

  @Input() public checkmarkChecked = false;

  @Output() public stateChanged = new EventEmitter();

  public open = false;

  ngOnInit() {
    this.open = this.forceOpen;
  }

  public toggleSection(): void {
    this.open = !this.open;
    this.stateChanged.emit({
      id: this.sectionId,
      state: this.open,
    });
  }

  ngOnChanges(changes) {
    if (changes.forceOpen) {
      this.open = changes.forceOpen.currentValue;
    }
  }
}
