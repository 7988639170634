import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { debounce } from 'lodash';

export interface SliderValues {
  lo: number;
  hi: number;
}

@Component({
  selector: 'troi-slider',
  templateUrl: 'troi-slider.component.html',
  styleUrls: ['./troi-slider.component.scss'],
})
export class TroiSliderComponent {
  public _options: Options = {
    floor: 0,
    ceil: 150,
    noSwitching: true,
    showTicks: true,
    showTicksValues: true,
    tickStep: 25,
    tickValueStep: 25,
  };

  @Input() description: string;
  @Input() value: number;
  @Input() highValue: number;
  @Input() public set options(options: Options) {
    this._options = {
      ...this._options,
      ...options,
    };
  }

  public get options(): Options {
    return this._options;
  }

  @Output() sliderValuesChanges = new EventEmitter<SliderValues>();

  constructor() {
    this.emitValues = debounce(this.emitValues, 500);
  }

  public onSliderValuesChange(): void {
    this.emitValues();
  }

  private emitValues(): void {
    this.sliderValuesChanges.emit({ lo: this.value, hi: this.highValue });
  }
}
