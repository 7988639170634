<div
  class="troi-range"
  [ngClass]="{
    'troi-range--disabled': disabled,
    'default-alignment': defaultAlignment,
    'assign-employee-alignment': assignEmployeeAlignment,
    'hide-ranges': hideRanges
  }"
>
  <input
    #datePickerInput
    type="text"
    date-format="{{ settings.format }}"
    class="troi-range__input"
    troi-daterangepicker
    [(ngModel)]="selected"
    [showCustomRangeLabel]="!singleDatePicker && !hideRanges"
    [alwaysShowCalendars]="true"
    [locale]="settings"
    [ranges]="singleDatePicker || hideRanges ? [] : ranges"
    [autoApply]="singleDatePicker"
    [linkedCalendars]="true"
    [showDropdowns]="showDropdowns"
    [align]="'right'"
    [singleDatePicker]="singleDatePicker"
    (ngModelChange)="dateChanged($event)"
    [showClearButton]="true"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [ngClass]="{ 'troi-range--invalid': (fieldInvalid && validationEnabled) || !validDate }"
    (dateFocusOut)="onDateChange($event)"
    [placeholder]="placeholder"
    [readOnly]="readOnly"
  />
</div>
<div class="troi-range__error" *ngIf="validationEnabled || !validDate">
  <span *ngIf="fieldInvalid && validDate" title="{{ requiredFieldErrorMessage | translate }}">{{
    requiredFieldErrorMessage | translate
  }}</span>
  <span *ngIf="!validDate" title="{{ 'Common.error.incorrectDate' | translate }}">{{
    'Common.error.incorrectDate' | translate
  }}</span>
</div>
