import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PersonInterface } from './interfaces/person.interface';

const AVATAR_IMAGE_PATH_PREFIX = 'site/';

@Component({
  selector: 'troi-person',
  templateUrl: './troi-person.component.html',
  styleUrls: ['./troi-person.component.scss'],
})
export class TroiPersonComponent implements OnInit, OnChanges {
  @Input() person: PersonInterface | string;
  @Input() multiplePersons: PersonInterface[] | string;
  @Input() multiPersons = false;
  @Input() header: string = null;
  @Input() large: boolean;
  @Input() medium: boolean;
  @Input() small: boolean;

  public persons: PersonInterface[] | string;

  constructor() {}

  ngOnInit() {
    this.assignMultiplePersons();
  }

  /* multiple persons must be assigned since ngb-popover doesnt work
    when rendering through an array entered by Input() */
  public assignMultiplePersons() {
    this.persons = this.multiplePersons;
  }

  public get isText(): boolean {
    if (this.multiPersons) return typeof this.persons === 'string';
    else return typeof this.person === 'string';
  }

  public getAvatarSrc(person: PersonInterface): string {
    return this.isText || !person['image'] ? `` : this.getAvatarPath(person.image);
  }

  private getAvatarPath(personImage: string): string {
    if (personImage.includes(AVATAR_IMAGE_PATH_PREFIX)) {
      return personImage;
    }
    return `${AVATAR_IMAGE_PATH_PREFIX}${personImage}`;
  }

  public getInitials(person: PersonInterface): any {
    return person.firstName[0]?.toUpperCase() + person.lastName[0]?.toUpperCase();
  }

  public getNgbPopoverMessage(person: PersonInterface) {
    return person.lastName + ', ' + person.firstName;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.multiplePersons &&
      changes.multiplePersons.previousValue &&
      changes.multiplePersons.currentValue.length !== changes.multiplePersons.previousValue.length
    ) {
      this.persons = this.multiplePersons;
    }
  }
}
