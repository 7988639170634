import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { HeaderNotificationModule } from '../header-notification/headerNotification.module';
import { SharedModule } from '../../shared/shared.module';
import { BasicSettingsNetwork } from '../../core/network/basic-settings.network';
import { SettingsEmitter } from '../../core/emitters/settings.emitter';
import { BookingsComponent } from './bookings.component';
import { IframeModalComponent } from './common/iframe-modal/iframe-modal.component';
import { BookingSettingsService } from './common/services/booking-settings.service';
import { AccessRightService } from '../../core/services/access-right.service';
import { BookingSettingsInterceptorProvider } from './common/interceptors/booking-settings.interceptor';
import { BookingFormComponent } from './common/modals/booking-form/booking-form.component';
import { TermsComponent } from './common/modals/booking-form/terms/terms.component';
import { ListComponent } from './common/modals/booking-form/terms/list/list.component';
import { MathOperationsService } from '../../core/services/math-operations.service';
import { MathOperationsNetwork } from '../../core/network/math-operations.network';
import { AddProjectComponent } from './common/modals/booking-form/add-project/add-project.component';
import { AddProjectOptionSettingService } from './common/services/add-project-option-setting.service';
import { AddProjectByKnumberComponent } from './common/modals/booking-form/add-project/add-project-by-knumber/add-project-by-knumber.component';
import { AddProjectByKnumberListComponent } from './common/modals/booking-form/add-project/add-project-by-knumber/list/add-project-by-knumber-list.component';
import { SearchProjectByCustomerService } from './common/services/search-project-by-customer.service';
import { BookingFormNetwork } from './common/networks/booking-form.network';
import { AddProjectByKnumberService } from './common/services/add-project-by-knumber.service';
import { CpDataComponent } from './common/modals/booking-form/cp-data/cp-data.component';
import { PaymentMethodsService } from './common/services/payment-methods.service';
import { ApprovalStatusesService } from './common/services/approval-statuses.service';
import { PaProjectComponent } from './common/modals/booking-form/multiple-add-project/pa-project/pa-project.component';
import { PaSubprojectComponent } from './common/modals/booking-form/multiple-add-project/pa-project/pa-subproject/pa-subproject.component';
import { PaCalculationComponent } from './common/modals/booking-form/multiple-add-project/pa-project/pa-subproject/pa-calculation/pa-calculation.component';
import { ProjectAssignmentDataService } from './common/services/project-assignment-data.service';
import { MultipleAddProjectComponent } from './common/modals/booking-form/multiple-add-project/multiple-add-project.component';
import { BookOnBlockedComponent } from './common/modals/booking-form/add-project/book-on-blocked/book-on-blocked.component';
import { CpService } from './common/services/cp.service';
import { RefreshFormEmitter } from './common/services/refresh-form.emitter';
import { HeaderComponent } from './common/modals/booking-form/accounting/list/header/header.component';
import { AccountingListComponent } from './common/modals/booking-form/accounting/list/accounting-list.component';
import { TotalComponent } from './common/modals/booking-form/accounting/list/total/total.component';
import { CalculationPositionsRowComponent } from './common/modals/booking-form/accounting/list/calculation-positions-row/calculation-positions-row.component';
import { ExportComponent } from './common/modals/booking-form/export/export.component';
import { AccountCreatedEmitter } from '../common/services/account-created.emitter';
import { AccountingSettingsService } from '../accounts/common/services/accounting-settings.service';
import { AccountsService } from '../accounts/accounting-administration/account-list/services/accounts.service';
import { FiltersService } from '../accounts/accounting-administration/account-list/services/filters.service';
import { AddEditService } from '../accounts/accounting-administration/account-list/services/addEdit.service';
import { CreateAccountBaseModule } from '../accounts/common/modules/create-account-base.module';
import { FilesComponent } from './common/modals/booking-form/files/files.component';
import { AttachFilesDropdownComponent } from './common/modals/booking-form/files/attach-files-dropdown/attach-files-dropdown.component';
import { AttachFilesDropdownListComponent } from './common/modals/booking-form/files/attach-files-dropdown/attach-files-dropdown-list/attach-files-dropdown-list.component';
import { UploadedFilesService } from './common/services/uploaded-files.service';
import { UploadedFilesNetwork } from './common/networks/uploaded-files.network';
import { AssignFilesToBrNetwork } from './common/networks/assign-files-to-br.network';
import { AssignFilesToBrService } from './common/services/assign-files-to-br.service';
import { BookingAccessService } from './common/services/booking-access.service';
import { CancelBookingReceiptComponent } from './protocol/modals/cancel-booking-receipt/cancel-booking-receipt.component';
import { AddProjectSubscriber } from './common/modals/booking-form/add-project/add-project.subscriber';
import { SearchProjectByProjectNameService } from './common/services/search-project-by-project-name.service';
import { ProjectSearchComponent } from './common/modals/booking-form/add-project/project-search/project-search.component';
import { ProjectResultListComponent } from './common/modals/booking-form/add-project/project-search/project-result-list/project-result-list.component';
import { ProjectSublistResultListComponent } from './common/modals/booking-form/add-project/project-search/project-result-list/project-sublist-result-list/project-sublist-result-list.component';
import { AddAutoProjectComponent } from './common/modals/booking-form/add-project/add-auto-project/add-auto-project.component';
import { AddProjectByOrderNumberComponent } from './common/modals/booking-form/add-project/add-project-by-order-number/add-project-by-order-number.component';
import { AddProjectByOrderNumberListComponent } from './common/modals/booking-form/add-project/add-project-by-order-number/list/add-project-by-order-number-list.component';
import { ApprovalStateService } from './common/services/approval-state.service';
import { CommonModule } from '@angular/common';
import { ApprovalsModule } from '../approvals/approvals.module';

const appRoutes: Routes = [
  {
    path: '',
    component: BookingsComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes),
    HeaderNotificationModule,
    SharedModule,
    CreateAccountBaseModule,
    NgxExtendedPdfViewerModule,
    CommonModule,
    ApprovalsModule,
  ],
  declarations: [
    BookingsComponent,
    IframeModalComponent,
    BookingsComponent,
    BookingFormComponent,
    TermsComponent,
    ListComponent,
    AddProjectComponent,
    ProjectSearchComponent,
    ProjectResultListComponent,
    ProjectSublistResultListComponent,
    AddProjectByKnumberComponent,
    AddProjectByKnumberListComponent,
    CpDataComponent,
    PaProjectComponent,
    PaSubprojectComponent,
    PaCalculationComponent,
    MultipleAddProjectComponent,
    BookOnBlockedComponent,
    HeaderComponent,
    AccountingListComponent,
    CalculationPositionsRowComponent,
    ExportComponent,
    TotalComponent,
    FilesComponent,
    AttachFilesDropdownComponent,
    AttachFilesDropdownListComponent,
    CancelBookingReceiptComponent,
    AddAutoProjectComponent,
    AddProjectByOrderNumberComponent,
    AddProjectByOrderNumberListComponent,
  ],
  providers: [
    BookingSettingsService,
    BasicSettingsNetwork,
    SettingsEmitter,
    AccessRightService,
    BookingSettingsInterceptorProvider,
    MathOperationsService,
    MathOperationsNetwork,
    AddProjectOptionSettingService,
    SearchProjectByCustomerService,
    SearchProjectByProjectNameService,
    BookingFormNetwork,
    AddProjectByKnumberService,
    PaymentMethodsService,
    ApprovalStatusesService,
    ProjectAssignmentDataService,
    CpService,
    RefreshFormEmitter,
    AccountCreatedEmitter,
    AccountingSettingsService,
    AccountsService,
    FiltersService,
    AddEditService,
    AccountingSettingsService,
    UploadedFilesService,
    UploadedFilesNetwork,
    AssignFilesToBrNetwork,
    AssignFilesToBrService,
    BookingAccessService,
    AddProjectSubscriber,
    ApprovalStateService,
  ],
  bootstrap: [],
  exports: [],
})
export class BookingsModule {}
