<div class="list-top-bar">
  <troi-btn
    *ngIf="isProjectContext"
    class="list-top-bar__button list-top-bar__import"
    (click)="onImportClick()"
  >
    <troi-icon icon="icon-import" size="14px" [bolder]="true"></troi-icon>
    <span>{{ importBtnValue | translate }}</span>
  </troi-btn>

  <troi-btn class="list-top-bar__button" (click)="onNewClick()">
    <troi-icon icon="icon-plus" size="14px" [bolder]="true"></troi-icon>
    <span>{{ firstBtnValue | translate }}</span>
  </troi-btn>
  <troi-btn
    *ngIf="secondBtnValue"
    class="list-top-bar__button list-top-bar__export"
    (click)="onExportClick()"
  >
    <troi-icon icon="icon-arrow-up-list" size="14px" [bolder]="true"></troi-icon>
    <span>{{ secondBtnValue | translate }}</span>
  </troi-btn>
</div>
