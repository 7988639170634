<div class="budget-status-settings">
  <troi-modal-header>
    <span>{{ 'ProjectList.labels.modals.budgetStatusSettings.title' | translate }}</span>
  </troi-modal-header>
  <troi-modal-content class="budget-status-settings__content">
    <troi-slider
      [value]="budgetThresholdsService.lowWarningPoint"
      [highValue]="budgetThresholdsService.highWarningPoint"
      [options]="sliderOptions"
      description="ProjectList.labels.modals.budgetStatusSettings.description"
      (sliderValuesChanges)="updateThresholdValues($event)"
    ></troi-slider>
  </troi-modal-content>
  <troi-modal-footer class="budget-status-settings__footer">
    <troi-btn class="footer__button" (click)="close()" [outline]="true" [submit]="false">{{
      'Common.labels.cancel' | translate
    }}</troi-btn>
    <troi-btn class="footer__button footer__button--save" (click)="save()">{{
      'Common.labels.save' | translate
    }}</troi-btn>
  </troi-modal-footer>
</div>
