import { Component, Input } from '@angular/core';

@Component({
  selector: 'troi-modal-footer',
  templateUrl: './troi-modal-footer.component.html',
  styleUrls: ['./troi-modal-footer.component.scss'],
})
export class TroiModalFooterComponent {
  @Input() fullWidth = false;
}
