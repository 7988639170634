import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ApprovalsNetwork } from '../../networks/approvals.network';
import { ApprovalsService } from '../../services/approvals.service';

@Component({
  selector: 'approval-process',
  templateUrl: './approval-process.component.html',
  styleUrls: ['./approval-process.component.scss'],
})
export class ApprovalProcessComponent implements AfterViewInit {
  @Input() placement = 'left bottom';
  @Input() alignTop = false;
  @Input() delegate = false;
  @Input() edit = false;
  @Input() approvalState: string;
  @Input() id: string;

  @Output() openStateChanged = new EventEmitter<boolean>();
  @Output() refreshApprovalStatus = new EventEmitter<boolean>();
  approvalStatus;
  selectedEmployee = [];
  createForm = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public approvalsNetwork: ApprovalsNetwork,
    public approvalsService: ApprovalsService,
  ) {}

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  private fetchData(): void {
    this.approvalsNetwork.status(this.id).subscribe((result) => {
      this.approvalStatus = result;
      if (!this.approvalStatus.employees) {
        return;
      }
      if (this.delegate) {
        this.approvalStatus.employeesForDelegation.forEach(
          (employee) =>
            (employee.employees = employee.employees.map((option: Record<string, unknown>) => ({
              label: option.name,
              id: option.id,
              disabled: option.nonSelectable,
              nonSelectable: option.nonSelectable,
            }))),
        );
      } else {
        this.approvalStatus.employees.forEach(
          (employee) =>
            (employee.employees = employee.employees.map((option) => ({
              label: option.name,
              id: option.id,
              disabled: option.nonSelectable,
              nonSelectable: option.nonSelectable,
            }))),
        );
      }
    });
  }

  get employees() {
    if (this.delegate) {
      this.approvalStatus.employeesForDelegation.forEach((employee) =>
        employee.employees.forEach((option: Record<string, unknown>) => {
          option.disabled = option.nonSelectable || this.isSelected(option);
        }),
      );
      return this.approvalStatus.employeesForDelegation;
    } else {
      this.approvalStatus.employees.forEach((employee) =>
        employee.employees.forEach((option) => {
          option.disabled = option.nonSelectable || this.isSelected(option);
        }),
      );
      return this.approvalStatus.employees;
    }
  }

  public isSelected(employee) {
    return this.selectedEmployee.some((selectedEmployee) => selectedEmployee.employeeId === employee.id);
  }

  public onSelectEmployee(employee, level) {
    let employeeId;
    if (employee) {
      employeeId = employee.id;
    }
    this.selectedEmployee = this.selectedEmployee.filter((option) => option.level !== level);
    this.selectedEmployee.push({ level, employeeId });
  }
  public onDelegateApproval(popover) {
    if (!this.selectedEmployee) {
      return;
    }
    this.approvalsNetwork
      .delegate(this.id, {
        delegated_to: this.selectedEmployee[0].employeeId,
      })
      .subscribe((result) => {
        this.closeOverlay(popover);
        this.refreshApprovalStatus.emit(true);
      });
  }
  public onEditApproval(popover) {
    if (!this.selectedEmployee) {
      return;
    }
    this.approvalsNetwork.edit(this.id, this.attachDescriptionToPayload(this.selectedEmployee)).subscribe((result) => {
      this.closeOverlay(popover);
      this.refreshApprovalStatus.emit(true);
    });
  }
  public onStartApproval(popover) {
    if (!this.selectedEmployee && !this.createForm.get('direct').value) {
      return;
    }
    if (this.createForm.get('direct').value) {
      this.approvalsNetwork.direct(this.id, this.attachDescriptionToPayload([])).subscribe((result) => {
        this.closeOverlay(popover);
        this.refreshApprovalStatus.emit(true);
      });
    } else {
      this.approvalsNetwork
        .start(this.id, this.attachDescriptionToPayload(this.selectedEmployee))
        .subscribe((result) => {
          this.closeOverlay(popover);
          this.refreshApprovalStatus.emit(true);
        });
    }
  }
  public toggleOverlay(popover): void {
    if (popover.isOpen()) {
      this.closeOverlay(popover);
    } else {
      this.fetchData();
      this.openOverlay(popover);
    }
  }

  initForm(): void {
    this.createForm = new UntypedFormGroup({
      direct: new UntypedFormControl(false),
      endDate: new UntypedFormControl(moment().format('YYYY-MM-DD'), [Validators.required]),
      employee: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
    });
  }
  openOverlay(popover): void {
    if (popover.isOpen()) {
      return;
    }
    this.initForm();
    popover.open();
    this.openStateChanged.emit(true);

    this.changeDetectorRef.detectChanges();
  }

  closeOverlay(popover): void {
    if (!popover.isOpen()) {
      return;
    }

    popover.close();
    this.openStateChanged.emit(false);
  }

  get isStart(): boolean {
    return !this.delegate && !this.edit && this.approvalsService.canStart(this.approvalStatus);
  }

  get isDelegate(): boolean {
    return this.delegate && !this.edit && this.approvalsService.canDelegate(this.approvalStatus);
  }

  get isEdit(): boolean {
    return !this.delegate && this.edit && this.approvalsService.canEdit(this.approvalStatus);
  }

  private attachDescriptionToPayload(payload: Record<string, unknown>[]): Record<string, unknown>[] {
    if (this.approvalStatus?.settings?.showDescriptionFieldInApprovalProcess) {
      payload[0].description = this.createForm.get('description').value;
    }
    return payload;
  }
}
