import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[repeat]',
})
export class RepeatDirective {
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @Input() set repeat(times: number) {
    for (let i = 0; i < times; i += 1) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
