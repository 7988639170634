<div
  class="subtask"
  ngbPopover="{{ 'Tasks.labels.deletionDenied' | translate }}"
  placement="bottom"
  #deletePopover="ngbPopover"
  triggers="manual"
  [ngClass]="{
    checked: subtask.status.statusGroup.id === 5
  }"
  (mouseenter)="toggleIcons()"
  (mouseleave)="toggleIcons()"
>
  <div class="subtask__title">
    <troi-checkbox
      [ngClass]="{
        'grey-border': subtask.status.statusGroup.id === 5
      }"
      [value]="subtask.status.statusGroup.id === 5"
      (checkboxChange)="checkboxChange($event)"
      [asLabel]="false"
    ></troi-checkbox>
    <span
      [ngClass]="{
        crossout: subtask.status.statusGroup.id === 5,
        'cut-title': showIcons
      }"
      >{{ subtask.title }}</span
    >
  </div>

  <div class="subtask__creation" [ngClass]="{ 'hide-name': allAssignees.length === 1 }">
    <div *ngIf="showIcons || dropdownOpen" class="subtask__icon-container">
      <troi-icon
        [size]="'16px'"
        [icon]="'icon-can-edit'"
        (click)="onEditSubtaskClick()"
      ></troi-icon>

      <troi-icon
        *ngIf="!task.project?.projectStatus?.blockedForBooking && isBookingEnabled(subtask)"
        [size]="'16px'"
        [icon]="'icon-timer'"
        (click)="onStopwatchClick(subtask)"
      ></troi-icon>
      <troi-dropdown-list
        textAlignment="left"
        [options]="dropdownOptions"
        (selectedEmitter)="onActionSelect($event)"
        (openChange)="onDropdownActionOpeningState($event)"
        [disable]="subtask.status.statusGroup.id === 5"
      >
        <troi-icon [size]="'16px'" [icon]="'icon-circles-full'"></troi-icon>
      </troi-dropdown-list>
    </div>
    <div *ngIf="subtask.endDate" class="subtask__creation--calendar">
      <troi-icon [icon]="'icon-list-points'"></troi-icon>
      <span>{{ getSubtasksEnddate() }}</span>
    </div>
    <troi-person [multiplePersons]="allAssignees" [medium]="true" [multiPersons]="true">
    </troi-person>
  </div>
</div>
