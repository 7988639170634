import { Component, Input } from '@angular/core';

@Component({
  selector: 'troi-modal-row',
  templateUrl: './troi-modal-row.component.html',
  styleUrls: ['./troi-modal-row.component.scss'],
})
export class TroiModalRowComponent {
  @Input() disabled = false;
  @Input() noRwd = false;
  @Input() withLabel = true;
}
