import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FileStatusEnum } from './file-status.enum';

@Component({
  selector: 'troi-upload-icon',
  templateUrl: './troi-upload-icon.component.html',
  styleUrls: ['./troi-upload-icon.component.scss'],
})
export class TroiUploadIconComponent implements OnInit {
  @Input() public status: string;

  @Input() public number: number;

  @Input() public progress = 100;

  FileStatusEnum = FileStatusEnum;

  ngOnInit(): void {
    if (_.isUndefined(this.progress)) {
      this.progress = 100;
    }
  }

  actualProgress(): string {
    const progress = this.progress > 100 || this.progress < 0 ? 100 : this.progress;

    return `progress-${progress}`;
  }
}
