import { TimerangeView } from '../../modules/tasks/modules/timelineview/enum/view';
import { FilterElementInterface } from '../troi-filter-with-modal/filter-element.interface';
import { Currency } from '../troi-money/currency';

export class FiltersInterface {
  search: string;

  dropdownFirst: number;

  dropdownSecond: number | string | number[] | string[];

  dropdownThird?: number | string | number[] | string[];

  dropdownFourth?: number | string | number[] | string[];

  isWorkLoadInPercent?: boolean;

  calendarScaleUnit?: TimerangeView;

  filters: FilterElementInterface[];

  currentPage: number;

  pageSize: number;

  sortingDir?: string;

  sortBy?: string;

  dropdownOptional?: FilterElementInterface;

  displayRangeFrom?: number;

  displayRangeTo?: number;
}

export class CurrencyMoneyInterface {
  currency: Currency;

  decimalPlaces: number;
}
