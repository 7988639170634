<div class="tabs">
  <div class="tabs__items">
    <div *ngFor="let tab of tabs" class="tabs__shadow-container" [title]="tab.name | translate">
      <div
        class="tabs__tab pointer"
        *ngIf="tab.routerLink; else noRouterLink"
        [routerLink]="tab.routerLink"
        (click)="changeTab(tab)"
        [ngClass]="{ 'tabs__tab--selected': tab.active }"
      >
        <div class="tabs__tab-name">
          <span>{{ tab.name | translate }}</span>
          <span *ngIf="tab.data && tab.data(tab.id)">{{ tab.data(tab.id) }}</span>
        </div>
      </div>

      <ng-template #noRouterLink>
        <div
          class="tabs__tab pointer"
          (click)="changeTab(tab)"
          [ngClass]="{ 'tabs__tab--selected': tab.active }"
        >
          <div class="tabs__tab-name">
            <span>{{ tab.name | translate }}</span>
            <span *ngIf="tab.data && tab.data(tab.id)">{{ tab.data(tab.id) }}</span>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
