<div class="troi-radio-icons">
  <div class="troi-radio-icons__icon">
    <input
      type="radio"
      name="one"
      [id]="actionName"
      [value]="actionName"
      (click)="radioModel = actionName"
      [checked]="actionName === radioModel"
      [(ngModel)]="radioModel"
      required="{{ required }}"
      (change)="propagateChange(actionName)"
    />

    <label [for]="actionName">
      <troi-icon
        [icon]="iconName"
        [class]="className"
        [ngbTooltip]="tooltip"
        [container]="'body'"
        [tooltipClass]="'approval-actions__tooltip'"
      >
      </troi-icon>
    </label>
  </div>
</div>
