export enum FilterTypeEnum {
  SWITCH = 'SWITCH',
  DROPDOWN = 'DROPDOWN',
  DROPDOWN_LAZY = 'DROPDOWN_LAZY',
  INPUT = 'INPUT',
  RANGE = 'RANGE',
  RANGE_DATE = 'RANGE_DATE',
  RANGE_STRING = 'RANGE_STRING',
  CHECKBOX = 'CHECKBOX',
  GROUP = 'GROUP',
}
