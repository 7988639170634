import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TagInterface } from '../../../../../../../interfaces/tag.interface';
import { TagService } from '../../../../network/tag.service';
import { Subscription } from 'rxjs';
import { TagResponseInterface } from '../../../../../../../interfaces/responses.interface';
import { TaskModel } from '../../../../../../../models/task.model';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'troi-tag-manager',
  templateUrl: './tag-manager.component.html',
  styleUrls: ['./tag-manager.component.scss'],
})
export class TagManagerComponent implements OnDestroy {
  @ViewChild('saveTaskPopover') saveTaskPopover: NgbPopover;

  @Input() task: TaskModel;
  private subscriptions: Subscription = new Subscription();

  public optionsLoaded = true;
  public tagSearch = '';
  public showDropdown = false;
  public showColorPalette = false;

  public showHighlightBorder = false;

  @Input() assignedTags: TagInterface[] = [];
  @Output() tagsChanged: EventEmitter<TagInterface[]> = new EventEmitter<[]>();

  constructor(public tagService: TagService) {
    this.optionsLoaded = false;
    this.subscriptions.add(
      this.tagService.getTagOptions().subscribe((options: TagResponseInterface) => {
        this.tagService.tagOptions = options.data as TagInterface[];
        this.optionsLoaded = true;
      }),
    );
  }

  public filterTags(): TagInterface[] {
    return this.tagService.tagOptions.filter((x: TagInterface) =>
      x.title.toLowerCase().includes(this.tagSearch.toLowerCase()),
    );
  }

  public toggleOptions(): void {
    this.tagSearch = '';
    this.showDropdown = !this.showDropdown;
  }

  public onTagSelected(tag: TagInterface): void {
    if (!this.task.created) {
      this.saveTaskPopover.open();
      return;
    }
    if (this.assignedTags.find((x: TagInterface) => x.id === tag.id) === undefined) {
      this.assignedTags.push(tag);
      this.tagsChanged.emit(this.assignedTags);
    }
    this.tagSearch = '';
    this.showDropdown = false;
  }

  public onShowColorPalette(): void {
    this.showDropdown = false;
    this.showColorPalette = true;
  }

  public onCloseColorPalette(): void {
    this.showDropdown = true;
    this.showColorPalette = false;
  }

  public onCreateTag(selectedColor: string): void {
    const newTag: TagInterface = {
      title: this.tagSearch,
      colorcode: selectedColor,
    };
    if (!newTag.title.length) return;

    if (this.task.created)
      this.subscriptions.add(
        this.tagService.createTag(newTag).subscribe((res: TagResponseInterface) => {
          this.tagService.tagOptions.push(res.data as TagInterface);
          this.onTagSelected(res.data as TagInterface);
          this.showColorPalette = false;
        }),
      );
    else this.saveTaskPopover.open();
  }

  public onTagDeleted(tag: TagInterface): void {
    this.assignedTags = this.assignedTags.filter((x: TagInterface) => x.id !== tag.id);
    this.tagsChanged.emit(this.assignedTags);
  }

  public toggleHighlightBorder() {
    this.showHighlightBorder = !this.showHighlightBorder;
  }

  public tagAlreadyExists(): TagInterface {
    return this.tagService.tagOptions.find((tag: TagInterface) => tag.title === this.tagSearch);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
