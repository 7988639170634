<span class="modal-section">
  <span
    class="modal-section__label"
    [ngClass]="{ 'modal-section__label--open': open }"
    (click)="toggleSection()"
  >
    <div class="modal-section__label-content">
      <troi-icon *ngIf="open" class="modal-section__icon" icon="icon-bold-arrow-down"></troi-icon>
      <troi-icon *ngIf="!open" class="modal-section__icon" icon="icon-bold-arrow-right"></troi-icon>
      <span>
        {{ label }}
      </span>
      <troi-checkmark [state]="checkmarkChecked" *ngIf="showCheckmark"></troi-checkmark>
    </div>
  </span>
  <span class="modal-section__content" *ngIf="open">
    <ng-content> </ng-content>
  </span>
</span>
