import { FilterSetInterface } from '../interfaces/filter-set.interface';
import { FilterSetModel } from '../models/filter-set.model';
import { FiltersInterface } from '../../troi-data-listing-filters/filters.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class FilterSetsFactory {
  public buildFilterSetModel(filterSet: FilterSetInterface): FilterSetModel {
    return new FilterSetModel(
      filterSet.id,
      filterSet.name,
      filterSet.value,
      filterSet.type,
      filterSet.isDefault,
      new FiltersInterface(),
    );
  }

  public buildFilterSetModels(filtersSets: FilterSetInterface[]): FilterSetModel[] {
    return filtersSets.map(this.buildFilterSetModel);
  }
}
