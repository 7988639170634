<div class="tnd-week-calendar" [ngClass]="{ 'work-time-calendar': showWorktime}">
  <!-- <div class="tnd-week-calendar__month-label">
      {{ currentDateString }}
      <span class="tnd-week-calendar__month-label__cw">
        {{ 'Desktop.Widgets.Calendar.CalendarWeek' | translate }}
        {{ currentDate | date : 'ww' }}
      </span>
    </div> -->
  <div class="d-flex tnd-week-calendar__weekview" [ngClass]="{'tnd-week-calendar__weekview--vertical': mode === 'vertical' }">
    <troi-icon-button
      class="tnd-week-calendar__nav"
      [icon]="mode === 'vertical' ? 'icon-arrow-head-up' : 'icon-arrow-head-left'"
      (click)="prevMonth()"
    ></troi-icon-button>
    <div
      class="day-cell"
      *ngFor="let day of weekDays"
      (click)="dayClicked(day)"
      [ngClass]="{ selected: day.date.isSame(dateSelected, 'day') }"
    >
      <span>{{ day.weekday }}</span>
      <span>{{ day.label }}</span>

      <div class="day-cell-times-bar" *ngIf="showWorktime">
        <div
          *ngIf="day.worktime && !day.worktime.overflow"
          class="progress-bar"
          [ngStyle]="{ height: day.worktime ? day.worktime.percent : 0 + '%' }"
        ></div>
        <div
          *ngIf="day.worktime && day.worktime.overflow"
          class="progress-bar-overflow"
          [ngStyle]="{ height: day.worktime ? day.worktime.percent : 0 + '%' }"
        ></div>
      </div>

      <span *ngIf="day.worktime" class="day-cell-time">{{
        day.worktime.quantity | number : '1.1'
      }}</span>
    </div>

    <troi-icon-button
      class="tnd-week-calendar__nav"
      [icon]="mode === 'vertical' ? 'icon-arrow-head-down' : 'icon-arrow-head-right'"
      (click)="nextMonth()"
    ></troi-icon-button>
  </div>

  <div class="d-flex align-items-center my-3" *ngIf="showWorktime">
    <div class="d-flex flex-column tnd-flex-1 tnd-week-balance-overview">
      <span>{{ 'Desktop.Widgets.Worktimes.WeekTotal' | translate }}</span>
      <div>
        {{ weekLogged | number : '1.1' }}/{{ weekTotal | number : '1.1' }}&nbsp;{{
          'Desktop.Widgets.Worktimes.HoursAbb' | translate
        }}
      </div>
    </div>
    <div class="tnd-week-balance">
      {{ weekLogged - weekTotal | number : '1.1' }}&nbsp;{{
        'Desktop.Widgets.Worktimes.HoursAbb' | translate
      }}
    </div>
  </div>
</div>
