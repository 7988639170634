import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { map } from 'rxjs/operators';

@Injectable()
export class SearchCalcPositionService {
  private readonly apiEndpointSearch: string = '/components/desktop/project-timerecording/calculation-position/search';
  private readonly apiEndpointFavorite: string = '/components/desktop/project-timerecording/favorite';
  private readonly apiEndpointUnfavorite: string = '/components/desktop/project-timerecording/unfavorite';
  private readonly apiEndpointSearchClient: string = '/components/desktop/search/client';
  private readonly apiEndpointSearchCustomer: string = '/components/desktop/search/customer';
  private readonly apiEndpointSearchProject: string = '/components/desktop/search/project';

  private httpOptions = {};

  public constructor(private http: HttpClient) {}

  searchProjectTimeRecords(
    search: string,
    clientId: string = '',
    customerId: string = '',
    projectId: string = '',
  ): Observable<any> {
    const params: Array<string> = ['search=' + encodeURIComponent(search)];

    if (clientId.length) {
      params.push('clientId=' + encodeURIComponent(clientId));
    }
    if (customerId.length) {
      params.push('customerId=' + encodeURIComponent(customerId));
    }
    if (projectId.length) {
      params.push('projectId=' + encodeURIComponent(projectId));
    }

    const url: string = this.apiEndpointSearch + '?' + params.join('&');
    return this.http.get(url);
  }

  favoriteProjectTimeRecord(objectType: string, objectId: string): Observable<any> {
    const form: UntypedFormGroup = this.createFavForm(objectType, objectId);

    return this.http.post(this.apiEndpointFavorite, form.value, this.httpOptions);
  }

  unfavoriteProjectTimeRecord(objectType: string, objectId: string): Observable<any> {
    const form: UntypedFormGroup = this.createFavForm(objectType, objectId);

    return this.http.post(this.apiEndpointUnfavorite, form.value, this.httpOptions);
  }

  searchClients(term: string): Observable<any> {
    const search: string = term.trim().length > 0 ? term.trim() : '*';

    return this.http.get(this.apiEndpointSearchClient + '?search=' + search).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  searchCustomers(term: string, clientId: string = ''): Observable<any> {
    const search: string = term.trim().length > 0 ? term.trim() : '*';
    const params: Array<string> = ['search=' + encodeURIComponent(search)];

    if (clientId.length) {
      params.push('clientId=' + encodeURIComponent(clientId));
    }

    return this.http.get(this.apiEndpointSearchCustomer + '?' + params.join('&')).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  searchProjects(term: string, clientId: string = '', customerId: string = ''): Observable<any> {
    const search: string = term.trim().length > 0 ? term.trim() : '*';
    const params: Array<string> = ['search=' + encodeURIComponent(search)];

    if (clientId.length) {
      params.push('clientId=' + encodeURIComponent(clientId));
    }
    if (customerId.length) {
      params.push('customerId=' + encodeURIComponent(customerId));
    }

    return this.http.get(this.apiEndpointSearchProject + '?' + params.join('&')).pipe(
      map((response: any) => {
        response.forEach((item) => {
          item.name += ` (${item.number})`;
        });
        return response;
      }),
    );
  }

  private createFavForm(objectType: string, objectId: string): UntypedFormGroup {
    const form: UntypedFormGroup = new UntypedFormGroup({
      objectType: new UntypedFormControl(''),
      objectId: new UntypedFormControl(''),
    });

    form.controls['objectType'].setValue(objectType);
    form.controls['objectId'].setValue(objectId);

    return form;
  }
}
