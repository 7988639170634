import * as moment from 'moment';

export default class Schedule {
  constructor() {}

  runCallbackAtTime(callBackTime, callback) {
    const now = moment();
    const diff = callBackTime.diff(now);

    window.setTimeout(callback, diff);
  }
}
