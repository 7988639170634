<div (click)="toggleOpen()" class="filter-section">
  <troi-icon icon="icon-filter" size="11px"></troi-icon>
  <span class="filter-section__label">{{ 'Booking.labels.filter' | translate }}</span>
</div>
<troi-my-projects-filter-modal
  *ngIf="open"
  (openChange)="onOpen($event)"
  [forceOpen]="forceOpen"
  (filtersApplied)="emitFiltersApplied($event)"
  (filtersReset)="emitFiltersReset()"
  [newDesign]="newDesign"
  [applyFiltersDataResendFetch]="applyFiltersDataResend"
></troi-my-projects-filter-modal>
