import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'troiTime' })
export class TimePipe implements PipeTransform {
  transform(value: number): string {
    return `${this.getHours(value)}:${this.getMinutes(value)}`;
  }
  private getHours(value: number): string {
    return String(Math.trunc(value));
  }
  private getMinutes(value: number): string {
    return String(Math.round((value % 1) * 60)).padStart(2, '0');
  }
}
