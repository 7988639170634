<div
  class="troi-range"
  [ngClass]="{
    'troi-range--disabled': disabled
  }"
>
  <span class="troi-range__label troi-range__label--first"
    >{{ 'Booking.labels.from' | translate }}:</span
  >
  <troi-input-field
    *ngIf="isCurrencyInput && !isNull(_rangeFrom)"
    row-value
    money-format
    (change)="updateRange($event.target.value, 0)"
    [value]="_rangeFrom.formattedValue"
    [money]="_rangeFrom"
    [currency]="currency"
    [numbersOnly]="true"
    [enabled]="!disabled"
  ></troi-input-field>
  <troi-input-field
    *ngIf="!isCurrencyInput || isNull(_rangeFrom)"
    row-value
    (change)="updateRange($event.target.value, 0)"
    [value]="_rangeFrom"
    [numbersOnly]="true"
    [enabled]="!disabled"
  ></troi-input-field>
  <span class="troi-range__label">{{ 'Booking.labels.to' | translate }}:</span>
  <troi-input-field
    *ngIf="!isNull(_rangeTo) && isCurrencyInput"
    row-value
    money-format
    (change)="updateRange($event.target.value, 1)"
    [money]="_rangeTo"
    [currency]="currency"
    [numbersOnly]="true"
    [value]="_rangeTo.formattedValue"
    [enabled]="!disabled"
  ></troi-input-field>
  <troi-input-field
    *ngIf="isNull(_rangeTo) || !isCurrencyInput"
    row-value
    (change)="updateRange($event.target.value, 1)"
    [numbersOnly]="true"
    [value]="_rangeTo"
    [enabled]="!disabled"
  ></troi-input-field>
</div>
