import { Component, EventEmitter, OnInit, Input, Output, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { DeskSettingsService } from '../../modules/desk/services/desk-settings.service';
import { TimeRecordingService } from '../../modules/time-recording/time-recording.service';
import { TranslateService } from '@ngx-translate/core';
import { TroiDropdownListModel } from '../troi-dropdown-list/models/troi-dropdown-list.model';

@Component({
  selector: 'troi-project-tooltip',
  templateUrl: './troi-project-tooltip.component.html',
  styleUrls: ['./troi-project-tooltip.component.scss'],
})
export class TroiProjectTooltipComponent implements OnInit {
  @Input() placement = 'top bottom';
  @Input() projectItem;
  @Input() type = '';
  @Output() openStateChanged = new EventEmitter<boolean>();
  @Output() loggedWork = new EventEmitter<string>();
  cutShowText = '-';
  showText = '-';
  public titleShow = true;
  public showPopup = false;

  constructor(public deskSettingsService: DeskSettingsService, public timeService: TimeRecordingService) {}

  ngOnInit(): void {
    let originalText = '';

    if (this.type === 'billingInternalComment') {
      originalText = this.projectItem.billing.billingInternalComment;
      this.showText = this.projectItem.billing.billingInternalComment;
    }

    if (this.type === 'billingExternalComment') {
      originalText = this.projectItem.billing.billingExternalComment;
      this.showText = this.projectItem.billing.billingExternalComment;
    }

    this.cutShowText = originalText.slice(0, 50);
    if (originalText.length > 50) {
      this.showPopup = true;
      this.cutShowText += '...';
    }
  }

  public toggleOverlay(popover): void {
    if (popover.isOpen()) {
      this.closeOverlay(popover);
    } else {
      this.openOverlay(popover);
    }
  }

  openOverlay(popover): void {
    if (!this.showPopup) {
      return;
    }

    if (popover.isOpen()) {
      return;
    }
    this.titleShow = false;
    popover.open();
    this.openStateChanged.emit(true);
  }

  closeOverlay(popover): void {
    this.titleShow = true;
    if (!popover.isOpen()) {
      return;
    }
    popover.close();
    this.resetAndReload();
  }

  onCancel(popover): void {
    this.closeOverlay(popover);
  }

  onHidden(): void {
    this.resetAndReload();
  }

  resetAndReload() {}
}
