<!-- <troi-icon
  icon="icon-plus"
  [size]="'18px'"
  class="toggle-add-dialog"
  [ngbPopover]="popContent"
  [popoverTitle]="popTitle"
  triggers="manual"
  #recordProjectTime="ngbPopover"
  popoverClass="troi-popover record-project-time"
  (click)="toggleOverlay(recordProjectTime)"
  [placement]="placement"
  autoClose="outside"
></troi-icon> -->

<span
  class="toggle-add-dialog"
  [title]="titleShow && showPopup ? ('Timerecording.click_here_to_read_more' | translate) : ''"
  [ngbPopover]="popContent"
  triggers="manual"
  #recordProjectTime="ngbPopover"
  popoverClass="troi-popover record-project-time"
  (click)="toggleOverlay(recordProjectTime)"
  [placement]="placement"
  autoClose="outside"
  >{{ cutShowText }}</span
>

<!-- <ng-template #popTitle>
  <div class="popover-title"></div>
</ng-template> -->
<ng-template #popContent>
  <div class="popover-content">
    <span>{{ showText }}</span>
  </div>
</ng-template>
