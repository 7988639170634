import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { TabInterface } from '../../shared/troi-data-listing-tabs/tab.interface';

export enum TabIdsEnum {
  ACCOUNT_LIST = 'ACCOUNT_LIST',
  ACCOUNT_GROUPS = 'ACCOUNT_GROUPS',
  COST_CENTER = 'COST_CENTER',
  UPLOADED_FILES = 'UPLOADED_FILES',
  RECORDED = 'RECORDED',
  PROTOCOL = 'PROTOCOL',
  ASSIGNED = 'ASSIGNED',
  APPROVED = 'APPROVED',
  IMPORT_EXPORT = 'IMPORT_EXPORT',
  HOURS = 'H',
  MATERIAL = 'M',
  INCOMING_INVOICES = 'I',
}

export interface SelectTabInterface {
  id: TabIdsEnum;
  active: boolean;
  data?: (tab: TabIdsEnum | string) => any;
}

@Injectable()
export class TabsService {
  private availableTabs: TabInterface[] = [
    {
      id: TabIdsEnum.ACCOUNT_GROUPS,
      name: 'Booking.labels.accountGroups',
      active: false,
      parentWindowUrl: 'bookings_account_group',
      routerLink: '/accounting-administration/groups',
    },
    {
      id: TabIdsEnum.ACCOUNT_LIST,
      name: 'Booking.labels.accountSelection',
      active: false,
      parentWindowUrl: 'bookings_account',
      routerLink: '/accounting-administration',
    },
    {
      id: TabIdsEnum.COST_CENTER,
      name: 'Booking.labels.costCenter',
      active: false,
      parentWindowUrl: 'cost_center',
      routerLink: '/accounting-administration/costcenters',
    },
    {
      id: TabIdsEnum.UPLOADED_FILES,
      name: 'Booking.labels.uploadedFiles',
      active: false,
      parentWindowUrl: 'bookings_uploaded_files',
      routerLink: '/bookings/uploaded-files',
    },
    {
      id: TabIdsEnum.RECORDED,
      name: 'Booking.recorded.label',
      active: false,
      parentWindowUrl: 'bookings_recorded',
      routerLink: '/bookings/recorded',
    },
    {
      id: TabIdsEnum.PROTOCOL,
      name: 'Booking.protocol.label',
      active: false,
      parentWindowUrl: 'bookings_protocol',
      routerLink: '/bookings/protocol',
    },
    {
      id: TabIdsEnum.IMPORT_EXPORT,
      name: 'Booking.importExport.label',
      active: false,
      parentWindowUrl: 'bookings_import_export',
      routerLink: '/bookings/import-export',
    },
    {
      id: TabIdsEnum.HOURS,
      name: 'Billings.tabType.hours',
      active: false,
    },
    {
      id: TabIdsEnum.MATERIAL,
      name: 'Billings.tabType.material',
      active: false,
    },
    {
      id: TabIdsEnum.INCOMING_INVOICES,
      name: 'Billings.tabType.incomingInvoices',
      active: false,
    },
  ];

  public generateTabs(selection: SelectTabInterface[]): TabInterface[] {
    const tabs = [];
    _.forEach(selection, (tab: SelectTabInterface) => {
      const fundTab = _.find(this.availableTabs, ['id', tab.id]);

      if (fundTab) {
        fundTab.active = tab.active;
        tabs.push(
          tab.data
            ? {
                ...fundTab,
                data: tab.data,
              }
            : fundTab,
        );
      }
    });

    return tabs;
  }
}
