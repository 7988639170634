import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { Money } from '../troi-money/money';
import { Currency } from '../troi-money/currency';

@Component({
  selector: 'troi-range',
  templateUrl: './troi-range.component.html',
  styleUrls: ['./troi-range.component.scss'],
})
export class TroiRangeComponent implements OnInit {
  @Input() public rangeFrom: any;

  @Input() public rangeTo: any;

  @Input() public disabled = false;

  @Input() public currency: Currency;

  @Input() public decimalPlaces: number;

  @Output() public rangeChanged = new EventEmitter();

  _rangeFrom: Money;

  _rangeTo: Money;

  isCurrencyInput = false;

  ngOnInit(): void {
    this._rangeFrom = this.rangeFrom;
    this._rangeTo = this.rangeTo;

    this.isCurrencyInput = !!this.currency;
  }

  public isNull = (value: any): boolean => {
    return _.isNull(value);
  };

  public updateRange(value, index: number) {
    let _value = value;
    if (_value === '' && index === 0) {
      _value = '0';
    }
    if (index === 0) {
      this._rangeFrom = this.isCurrencyInput ? this.convertToMoney(_value) : _value;
    } else if (index === 1) {
      if (_value) {
        this._rangeTo = this.isCurrencyInput ? this.convertToMoney(_value) : _value;
      }
    }
    const range = [this._rangeFrom, this._rangeTo];
    this.rangeChanged.emit(range);
  }

  convertToMoney(value): Money {
    return new Money(
      parseFloat(value.replace(this.currency.format.decimalMark, '.')),
      this.currency,
      this.decimalPlaces,
    );
  }
}
