import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BookingFormNetwork } from '../networks/booking-form.network';
import { BackendResponseInterface } from '../../../../core/interfaces/backend.response.interface';
import { BookingCP } from '../interfaces/booking-receipt-full.interface';
import { CpModel } from '../models/cp.model';

@Injectable()
export class CpService {
  constructor(private network: BookingFormNetwork) {}

  createCP(cp: CpModel): Observable<BackendResponseInterface<BookingCP>> {
    return this.network.createCP(
      cp.client,
      cp.customer,
      cp.project,
      cp.subproject,
      cp.nameToCreate,
      cp.unit,
      cp.internalId,
    );
  }
}
