import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AssigneeInterface } from '../../../../../interfaces/assignee.interface';
import { TaskModel } from '../../../../../models/task.model';
import { TasksHelperService } from '../../../../../services/helper.service';
import * as moment from 'moment';
import { AssignmentInterface } from '../../../../../interfaces/assignment.interface';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SubtaskInterface } from '../../../../../interfaces/subtask.interface';
import { Subscription } from 'rxjs';
import { SubtaskService } from '../../../../../network/subtask.service';
import { TasksSettingsService } from '../../../../../services/tasks-settings.service';
import { AssignedEmployeeComponent } from './components/assigned-employee/assigned-employee.component';

@Component({
  selector: 'troi-task-modal-resource-planning',
  templateUrl: './resource-planning.component.html',
  styleUrls: ['./resource-planning.component.scss'],
})
export class ResourcePlanningComponent implements OnInit, OnDestroy {
  @ViewChildren(AssignedEmployeeComponent) assignedEmployeeComponents: QueryList<AssignedEmployeeComponent>;
  @ViewChild('addDatesPopover') addDatesPopover: NgbPopover;
  @Input() task: TaskModel;

  public fromDate = null;
  public toDate = null;
  public assignHours = false;
  public showSubtaskAssignees = false;

  public startDateError = false;
  public endDateError = false;

  public selectedDates: any;

  public minDate: any;
  public maxDate: any;
  public emptyEndDate = false;

  private subscriptions: Subscription = new Subscription();

  constructor(
    public helperService: TasksHelperService,
    private subtaskService: SubtaskService,
    public settingsService: TasksSettingsService,
  ) {}

  ngOnInit() {
    if (this.task.startDate && this.task.endDate) {
      const fromDate = new Date(this.task.startDate).getTime();
      const toDate = new Date(this.task.endDate).getTime();
      if (fromDate === 0 && toDate === 0) return;
      this.fromDate = fromDate;
      this.toDate = toDate;
    }

    this.determineAssignHoursState();
    this.checkIfHoursAreAssigned();
  }

  public checkIfHoursAreAssigned() {
    if (this.task.assignees && this.task.assignees.some((assignee) => assignee.minutesTotal)) this.openHourAssignment();
  }

  public subtaskHaveAssignees(): boolean {
    return this.task.subtasks.some((subtask) => {
      return subtask.assignees?.length > 0;
    });
  }

  private determineAssignHoursState() {
    let state = false;
    if (this.task.assignees) {
      this.task.assignees.forEach((a: AssigneeInterface) => {
        if (a.assignments.find((as: AssignmentInterface) => as.minutes > 0)) {
          state = true;
        }
      });
    }
    this.helperService.setAssignedHoursState(state);
  }

  public openHourAssignment(): void {
    this.helperService.setAssignedHoursState(true);
    this.assignHours = true;
    if (!this.assignedEmployeeComponents) return;
    this.assignedEmployeeComponents.toArray().forEach((childComponent: AssignedEmployeeComponent) => {
      if (childComponent.assignee.assignments.length === 0) {
        childComponent.addTimerange();
      }
    });
  }

  public onDontAssignHoursClick(): void {
    if (!this.hoursAssigned()) {
      this.helperService.setAssignedHoursState(false);
      this.assignHours = false;
      this.assignedEmployeeComponents.toArray().forEach((childComponent: AssignedEmployeeComponent) => {
        childComponent.deleteAllAssignments();
      });
    }
  }

  public onShowSubtaskAssignees(): void {
    this.showSubtaskAssignees = !this.showSubtaskAssignees;
  }

  public hoursAssigned(): boolean {
    return this.task.assignees.some((assignee) => assignee.minutesTotal > 0);
  }

  public removeAssigneeFromTask(assignee: AssigneeInterface, subtask?: SubtaskInterface): void {
    if (subtask) {
      const subtaskIndex = this.task.subtasks.findIndex((x) => x.id === subtask.id);

      this.task.subtasks[subtaskIndex].assignees = this.task.subtasks[subtaskIndex].assignees.filter(
        (x) => x.id !== assignee.id,
      );
      this.subscriptions.add(this.subtaskService.deleteSubtaskAssignee(subtask, assignee).subscribe());
      this.helperService.onAssignedEmployeeChange(true);
    } else {
      this.task.assignees = this.task.assignees.filter((x) => x.id !== assignee.id);
      this.helperService.onUnassignEmployee.next(assignee.id);
    }
  }

  public getMinDate() {
    if (this.minDate) return this.minDate;
  }

  public getMaxDate() {
    if (this.maxDate) return this.maxDate;
  }

  public setMinDate(): void {
    setTimeout(() => (this.minDate = moment(this.task.startDate)));
  }

  public setMaxDate(): void {
    setTimeout(() => (this.maxDate = moment(this.task.endDate)));
  }

  public startDateChanged(dates: any): void {
    if (dates.date[0]) {
      this.task.startDate = new Date(dates.date[0]);
      this.setMinDate();
    }
  }

  public endDateChanged(dates: any): void {
    if (dates.date[0]) {
      this.task.endDate = new Date(dates.date[0]);
      this.setMaxDate();
    }
  }

  public updateRuntimeRange(dates: any): void {
    if (!dates.date[0] && !dates.date[1]) return;
    this.task.startDate = new Date(dates.date[0]);
    this.task.endDate = new Date(dates.date[1]);
  }

  public resetStartDate() {
    this.task.startDate = null;
    this.resetEndDate();
  }

  public resetEndDate() {
    this.task.endDate = this.task.startDate;
    if (this.task.startDate !== null) this.toDate = new Date(this.task.startDate).getTime();
    else {
      this.emptyEndDate = true;
      this.toDate = null;
    }
    this.setMaxDate();
  }

  public showAddTimerangePopover() {
    this.addDatesPopover.open();
  }

  ngOnDestroy() {
    this.helperService.setAssignedHoursState(false);
  }
}
