import { Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModuleInterceptor } from '../../../core/enums/module-interceptor';
import { ProjectListSettingsService } from '../services/project-list-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageNotificationService } from '../../../core/notifications/storageNotification.service';
import { BackendResponseInterface } from '../../../core/interfaces/backend.response.interface';

@Injectable()
export class ProjectListSettingsInterceptor implements HttpInterceptor {
  private blocked = false;

  constructor(
    private projectListSettingsService: ProjectListSettingsService,
    private translateService: TranslateService,
    private notificationService: StorageNotificationService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.overrideRequest(req, next);
  }

  private overrideRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && req.headers.get(ModuleInterceptor.PROJECT_LIST)) {
          const headerLastModified = parseInt(event.headers.get('settings-last-modified'));
          const headerDataLastModified = parseInt(event.headers.get('data-last-modified'));
          let settings =
            this.projectListSettingsService.settings ?? this.projectListSettingsService.getSettingsFromLS();

          if (settings && !this.blocked) {
            if (!settings.lastDownload) {
              settings.lastDownload = headerLastModified;
              this.projectListSettingsService.saveSettingsToLS(settings);
            }
            if (!settings.dataLastDownload) {
              settings.dataLastDownload = headerDataLastModified;
              this.projectListSettingsService.saveSettingsToLS(settings);
            }
            if (settings.lastDownload && headerLastModified !== settings.lastDownload) {
              if (settings.dataLastDownload && headerDataLastModified !== settings.dataLastDownload) {
                settings.lastDownload = headerLastModified;
                settings.dataLastDownload = headerDataLastModified;
                this.projectListSettingsService.saveSettingsToLS(settings);
              } else {
                const actualFromLS = this.projectListSettingsService.getSettingsFromLS();

                this.translateService.get('Common.error.settingsRefresh').subscribe((message) => {
                  this.notificationService.showInfo(message);
                });
                this.blocked = true;

                if (actualFromLS && settings.client !== actualFromLS.client) {
                  settings = actualFromLS;
                }

                this.projectListSettingsService.basicSettingsNetwork
                  .downloadSettings(settings.client, 'false')
                  .subscribe((result: BackendResponseInterface<any>) => {
                    this.projectListSettingsService.settings = result.data;
                    this.projectListSettingsService.settings.lastDownload = headerLastModified;
                    this.projectListSettingsService.settings.dataLastDownload = headerDataLastModified;
                    this.projectListSettingsService.saveSettingsToLS(this.projectListSettingsService.settings);
                    this.blocked = false;
                    window.location.reload();
                  });
              }
            }
          }

          return event;
        }

        return event;
      }),
    );
  }
}

export const ProjectListSettingsInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ProjectListSettingsInterceptor,
  multi: true,
};
