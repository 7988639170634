<div class="resources-planning">
  <div
    class="resources-planning__daterange-picker"
    ngbPopover="{{ 'Tasks.labels.createTaskModal.addTimerange' | translate }}"
    placement="bottom"
    #addDatesPopover="ngbPopover"
    popoverClass="timeentries-tooltip-popover"
    triggers="manual"
  >
    <troi-range-date
      [rangeFrom]="fromDate"
      [rangeTo]="toDate"
      [minDate]="getMinDate()"
      [maxDate]="getMaxDate()"
      [hideRanges]="true"
      (rangeChanged)="updateRuntimeRange($event)"
      [settings]="{ format: settingsService.settings?.settings.dateFormat }"
      [placeholder]="'Tasks.labels.dateFormat' | translate"
      [emitIfEmpty]="false"
    ></troi-range-date>
  </div>

  <troi-task-modal-select-assignee [task]="task"></troi-task-modal-select-assignee>
  <div class="resources-planning__assigned-employees">
    <div class="resources-planning__assigned-employees--scrollable">
      <div *ngIf="task.assignees && task.assignees.length > 0">
        <div>
          <div class="resources-planning__assigned-employees--subtitle">
            <p>{{ 'Tasks.labels.createTaskModal.assigneeSelection.toEmployees' | translate }}</p>
            <p
              *ngIf="!assignHours"
              (click)="openHourAssignment()"
              class="resources-planning__assigned-employees--assign-hours"
            >
              + {{ 'Tasks.labels.createTaskModal.assigneeSelection.hourAssignment' | translate }}
            </p>
            <p
              [style.opacity]="this.hoursAssigned() ? '0.5' : ''"
              *ngIf="assignHours"
              (click)="onDontAssignHoursClick()"
              class="resources-planning__assigned-employees--assign-hours"
            >
              - {{ 'Tasks.labels.createTaskModal.assigneeSelection.noHourAssignment' | translate }}
            </p>
          </div>
        </div>
        <div class="resources-planning__assigned-employee-container">
          <troi-task-modal-assigned-employee
            *ngFor="let assignee of task.assignees"
            [assignee]="assignee"
            (assigneeToRemove)="removeAssigneeFromTask($event)"
            [task]="task"
            [isEmpty]="true"
            (showPopover)="showAddTimerangePopover()"
            [showProgressCircle]="task.startDate && task.endDate"
          ></troi-task-modal-assigned-employee>
        </div>
      </div>
      <div class="resources-planning__assigned-employees--subtasks" *ngIf="task.subtasks">
        <p
          *ngIf="!showSubtaskAssignees"
          (click)="onShowSubtaskAssignees()"
          class="resources-planning__assigned-employees--show-sub-assignees"
        >
          <troi-icon [medium]="true" icon="icon-bold-arrow-down"></troi-icon>
          {{ 'Tasks.labels.createTaskModal.subtasks.showSubAssignees' | translate }}
        </p>
        <p
          *ngIf="showSubtaskAssignees"
          (click)="onShowSubtaskAssignees()"
          class="resources-planning__assigned-employees--show-sub-assignees"
        >
          <troi-icon [medium]="true" icon="icon-bold-arrow-up"></troi-icon>
          {{ 'Tasks.labels.createTaskModal.subtasks.dontShowSubAssignees' | translate }}
        </p>
        <ng-container *ngIf="showSubtaskAssignees">
          <div
            class="resources-planning__assigned-employees--subtasks--subtask"
            *ngFor="let subtask of task.subtasks"
          >
            <div *ngIf="subtask.assignees.length > 0">
              <span class="paragraph-title"
                >{{ 'Tasks.labels.createTaskModal.subtasks.subtask' | translate }}:
                {{ subtask.title }}</span
              >
              <troi-task-modal-assigned-employee
                *ngFor="let assignee of subtask.assignees"
                [assignee]="assignee"
                (assigneeToRemove)="removeAssigneeFromTask($event, subtask)"
                [task]="subtask"
                [parentTask]="task"
                [isSubtask]="true"
                [isEmpty]="true"
                (showPopover)="showAddTimerangePopover()"
                [showProgressCircle]="task.startDate && task.endDate"
              ></troi-task-modal-assigned-employee>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
