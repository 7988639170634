<div class="breadcrumbs">
  <div class="breadcrumbs__container">
    <div class="breadcrumbs__item" *ngFor="let crumb of breadcrumbs; let last = last">
      {{ crumb.name | translate }}
      <troi-icon *ngIf="!last" class="breadcrumbs__icon" icon="icon-arrow-head-right"></troi-icon>
    </div>
  </div>
    <div class="iDemoInfo" *ngIf="demoSettings?.isDemo">
        <p>
            <span *ngIf="demoSettings.daysTillExpiration < 0"> {{ 'demo.expired' | translate }}</span>
            <span *ngIf="demoSettings.daysTillExpiration === 0" [innerHTML]="'demo.today_expiration' | translate:{ value: demoSettings.daysTillExpiration }"></span>
            <span *ngIf="demoSettings.daysTillExpiration > 0" [innerHTML]="'demo.future_expiration' | translate:{ value: demoSettings.daysTillExpiration }"></span>
            <span *ngIf="!demoSettings.canExpire">{{ 'demo.no_expiration' | translate }}</span>.<br>
            <span>{{ 'demo.if_questions' | translate }} </span><a href="mailto:{{ demoSettings.email }}">{{ demoSettings.email }}</a>.
        </p>
    </div>
  <div class="breadcrumbs__logo-wrapper" *ngIf="!placeholder && loaded">
    <img class="breadcrumbs__logo" src="{{ logoUrl }}" />
  </div>
  <div class="breadcrumbs__placeholder placeholder" *ngIf="placeholder && loaded">
    <div class="placeholder__wrapper">
      <img src="{{ placeholderUrl }}" />
      <div class="placeholder__label">
        <div class="placeholder__label-text1">{{ 'Header.placeholder.text1' | translate }}</div>
        <div class="placeholder__label-text2">{{ 'Header.placeholder.text2' | translate }}</div>
      </div>
    </div>
  </div>
</div>
