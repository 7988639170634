import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DropdownsService } from '../../modules/common/services/dropdowns.service';
import { DropdownsNetwork } from '../../modules/common/networks/dropdowns.network';
import { SharedModule } from '../shared.module';

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule],
  declarations: [],
  bootstrap: [],
  providers: [DropdownsService, DropdownsNetwork],
  exports: [],
})
export class DropdownSelectLazyModule {}
