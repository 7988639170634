<div *ngIf="loading" class="align-items-center" style="position: relative">
  <troi-loading-spinner></troi-loading-spinner>
</div>
<canvas [id]="chartCanvasId" class="mt-2"></canvas>

<!-- Overview Month -->
<div
  class="d-flex justify-content-between flex-row mb-5 mt-3"
  *ngIf="chartCanvasId === 'overViewMonthBarChart'"
>
  <ng-container *ngFor="let data of monthWeekData">
    <div class="showData">
      <div class="d-flex">
        <p class="mb-0">{{ 'Timerecording.Working Time(Week)' | translate }}</p>
        <p class="total mb-0 px-2">
          {{ timeService.setFormat(data.workingTimeweekTotal.toFixed(2).replace('.', ',')) }} h
        </p>
      </div>
      <div class="d-flex">
        <p>{{ 'Timerecording.Project Time(Week)' | translate }}</p>
        <p class="total px-3">
          {{ timeService.setFormat(data.projectTimeweekTotal.toFixed(2).replace('.', ',')) }} h
        </p>
      </div>
    </div>
  </ng-container>
</div>

<!-- Working Time Month -->
<div
  class="d-flex justify-content-between flex-row mb-5 mt-3"
  *ngIf="chartCanvasId === 'workingTimeMonthBarChart'"
>
  <ng-container *ngFor="let data of monthWeekData">
    <div class="showData d-flex">
      <div>
        <span class="p-2">{{ 'Timerecording.Working Time(Week)' | translate }}</span
        ><br />
        <p class="p-2">
          <span [style.color]="data.colorWt">{{
            timeService.setFormat(data.workingTimeweekTotal.toFixed(2).replace('.', ','))
          }}</span>
          <span
            >{{
              '/' + timeService.setFormat(data.workingTimeTotalDebit.toFixed(2).replace('.', ','))
            }}
            h</span
          >
        </p>
      </div>
      <div class="mt-3">
        <span class="p-2 totalBox"
          >{{
            timeService.getFormattedDifference(
              data.workingTimeTotalDebit,
              data.workingTimeweekTotal
            )
          }}
          h</span
        >
      </div>
    </div>
  </ng-container>
</div>

<!-- Project Time Month -->
<div
  class="d-flex justify-content-between flex-row mb-5 mt-3"
  *ngIf="chartCanvasId === 'projectTimeMonthBarChart'"
>
  <ng-container *ngFor="let data of monthWeekData">
    <div class="showData">
      <div>
        <span
          >{{ 'Timerecording.CW' | translate }} {{ data.weekNumber }}
          {{ 'Timerecording.ProjectTime' | translate }}</span
        >
        <span class="px-4 total"
          >{{
            timeService.setFormat(data.projectTimeweekTotal.toFixed(2).replace('.', ','))
          }}
          h</span
        >
      </div>
    </div>
  </ng-container>
</div>

<!-- Working Time Week -->
<div
  class="showData d-flex justify-content-between flex-row mb-5 mt-3"
  *ngIf="chartCanvasId === 'workingTimeWeekBarChart'"
>
  <ng-container *ngFor="let data of weekWeekData">
    <div>
      <span class="p-2">{{ 'Timerecording.Working Time(Week)' | translate }}</span
      ><br />
      <p class="p-2">
        <span [style.color]="data.colorWt">{{
          timeService.setFormat((data.workingTimeweekTotal + data.workingTimeTotalAbsenceQuantity).toFixed(2).replace('.', ','))
        }}</span>
        <span
          >{{
            '/' + timeService.setFormat(data.workingTimeTotalDebit.toFixed(2).replace('.', ','))
          }}
          h</span
        >
      </p>
    </div>
    <div class="mt-3">
      <span class="p-2 totalBox"
        >{{
          timeService.getFormattedDifference(data.workingTimeTotalDebit, data.workingTimeweekTotal + data.workingTimeTotalAbsenceQuantity)
        }}
        h</span
      >
    </div>
  </ng-container>
</div>

<!-- Progress bar -->
<div
  *ngIf="chartCanvasId === 'overViewWeekBarChart' || chartCanvasId === 'projectTimeWeekBarChart'"
>
  <div class="container mx-1">
    <div class="row mt-4">
      <div class="col-4">
        <small class="progressText">{{ 'Timerecording.Billable_Projects' | translate }}</small>
      </div>
      <div class="col-4 p-0 mt-2">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            style="width: {{ billableprogressbar.percantage }}%"
            aria-valuenow="{{ billableprogressbar.percantage }}"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      <div class="col-3">
        <small class="progressText"
          >{{
            billableprogressbar.percantage > 100 ? 100 : billableprogressbar.percantage
          }}
          %</small
        >
      </div>
      <div class="col-1">
        <small class="progressText" [style.color]="'#90989C'" [style.white-space]="'nowrap'"
          >{{
            timeService.setFormat(billableprogressbar.totalhours.toFixed(2).replace('.', ','))
          }}
          h</small
        >
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-4">
        <small class="progressText">{{ 'Timerecording.Non_Billable_Projects' | translate }}</small>
      </div>
      <div class="col-4 p-0 mt-2">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            style="width: {{ nonbillableprogressbar.percantage }}%"
            aria-valuenow="{{ nonbillableprogressbar.percantage }}"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      <div class="col-3">
        <small class="progressText"
          >{{
            nonbillableprogressbar.percantage > 100 ? 100 : nonbillableprogressbar.percantage
          }}
          %</small
        >
      </div>
      <div class="col-1">
        <p class="progressText" [style.color]="'#90989C'" [style.white-space]="'nowrap'">
          {{
            timeService.setFormat(nonbillableprogressbar.totalhours.toFixed(2).replace('.', ','))
          }}
          h
        </p>
      </div>
    </div>
  </div>
</div>
