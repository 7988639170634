<div
  class="troi-person"
  [class.troi-person--large]="large"
  [class.troi-person--medium]="medium"
  [class.troi-person--small]="small"
  *ngIf="person && !multiplePersons"
>
  <span *ngIf="isText; else objectPerson">{{ person }}</span>
  <ng-template #objectPerson>
    <!-- <img class="troi-person__avatar mr-2" [src]="getAvatarSrc(person)" alt="person" /> -->

    <div
      class="person-image mr-2"
      *ngIf="person"
      [ngClass]="{ 'person-image--no-avatar': !person.image }"
      [ngStyle]="{ 'background-image': 'url(' + getAvatarSrc(person) + ')' }"
    >
      <div class="person-image__initials" *ngIf="!person.image">
        {{ getInitials(person) }}
      </div>
    </div>

    <div *ngIf="header; else withoutHeader" class="troi-person__name">
      <span>{{ header }}</span>
      <p>{{ person.firstName }}&nbsp;{{ person.lastName }}</p>
    </div>
    <ng-template #withoutHeader
      ><span class="person-name" title="{{ person.firstName }}&nbsp;{{ person.lastName }}"
        >{{ person.firstName }}&nbsp;{{ person.lastName }}</span
      ></ng-template
    >
  </ng-template>
</div>

<div
  class="troi person troi-multiple-persons"
  [class.troi-person--large]="large"
  [class.troi-person--medium]="medium"
  [class.troi-person--small]="small"
  *ngIf="multiplePersons"
>
  <span *ngIf="isText; else objectPerson">{{ persons | translate }}</span>

  <ng-template #objectPerson>
    <div class="avatars-container">
      <ng-container *ngFor="let person of persons; let i = index">
        <div
          class="person-image troi-multiple-persons__avatar"
          [ngStyle]="{ 'background-image': 'url(' + getAvatarSrc(person) + ')' }"
          [style]="i === 0 ? 'z-index: 3' : 'z-index:2'"
          *ngIf="i < 2"
          #singlePopover="ngbPopover"
          [ngbPopover]="getNgbPopoverMessage(person)"
          [ngClass]="{ 'troi-multiple-persons--no-avatar': !person.image }"
          (mouseleave)="singlePopover.close()"
        >
          <div
            (mouseenter)="singlePopover.open()"
            class="troi-multiple-persons__initials"
            *ngIf="!getAvatarSrc(person)"
          >
            {{ getInitials(person) }}
          </div>
        </div>

        <div
          *ngIf="i === 2"
          class="troi-multiple-persons__placeholder"
          [ngbPopover]="otherAssigneesPopover"
          (mouseleave)="multiplePoppver.close()"
          #multiplePoppver="ngbPopover"
          placement="right bottom"
        >
          <p (mouseenter)="multiplePoppver.open()" class="troi-multiple-persons__count m-0">
            +{{ multiplePersons.length - 2 }}
          </p>
        </div>
        <ng-template #otherAssigneesPopover>
          <div class="popover__assignee" *ngFor="let person of persons">
            <troi-person [person]="person" [large]="true"></troi-person>
          </div>
        </ng-template>
      </ng-container>
    </div>

    <span class="ml-1 single-person" *ngIf="multiplePersons.length === 1">
      {{ multiplePersons[0].firstName }},&nbsp;{{ multiplePersons[0].lastName }}
    </span>

    <ng-template *ngIf="multiplePersons.length > 1" #multipleAssignees>
      <span class="ml-1"> Multiple&nbsp;({{ multiplePersons.length }}) </span>
    </ng-template>
  </ng-template>
</div>
