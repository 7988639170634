<div class="troi-filter-sets">
  <troi-dropdown-select
    class="troi-filter-sets__select"
    [noBorder]="true"
    size="12px"
    width="250px"
    labelIcon="icon-folded-list"
    (selected)="filterSetSelected.emit($event)"
    [(ngModel)]="selectedFilterSetId"
    [options]="filterSetsDropdownOptions"
    [actions]="filterSetsDropdownActions"
  >
  </troi-dropdown-select>
</div>
