import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FiltersInterface } from '../../../../shared/troi-data-listing-filters/filters.interface';
import { ForecastTeamInterface } from '../../interfaces/forecast.interface';
import { TasksService } from '../../network/tasks.service';
import { TaskFiltersService } from '../../services/filters.service';
import { TasksHelperService } from '../../services/helper.service';

@Component({
  selector: 'troi-ganttchartview',
  templateUrl: './ganttchartview.component.html',
  styleUrls: ['./ganttchartview.component.scss'],
})
export class GanttchartviewComponent implements OnDestroy {
  private _subscriptions: Subscription = new Subscription();

  public isProjectContext = false;
  public resourceTasks: ForecastTeamInterface[] = [];
  public filters: FiltersInterface = {
    search: '',
    dropdownOptional: null,
    dropdownFirst: null,
    dropdownSecond: null,
    dropdownThird: null,
    filters: null,
    currentPage: 1,
    pageSize: 0,
  };
  constructor(
    private _helperService: TasksHelperService,
    public tasksService: TasksService,
    public filterService: TaskFiltersService,
  ) {
    this.getProjectContext();
    this.resourceTasks = this.tasksService.resourceTasks;
  }

  private getProjectContext(): void {
    this._subscriptions.add(
      this._helperService.isProjectContext.subscribe((value: boolean): void => {
        if (value) {
          this.isProjectContext = true;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
