export enum Routes {
  SETTINGS = '/components/projectlist/settings',
  EXPORT = '/components/projectlist/export',

  TREE_LIST = '/components/projectlist/list',
  TREE_LIST_CHILDREN_LAZY = '/components/projectlist/children',

  TREE_LIST_MOVE_ITEM = '/components/projectlist/drop',

  PROJECT_FOLDER = '/components/projectlist/folder',

  ITEM_PROJECT_NUMBER_SETTINGS = '/components/projectlist/settings-project-number-parts',
  ITEM_CREATE = '/components/projectlist/item',
  ITEM_COPY = '/components/projectlist/item/copy',
  ITEM_INLINE_SAVE = '/components/projectlist/item/inline',
  ITEM_NEW_PROJECT_PLAN = '/components/projectlist/item/new-project-plan',

  INCOMING_INVOICE_LIST = '/components/projectlist/incoming-invoice-list',
  INVOICE_ASSIGNED_NUMBER_LIST = '/components/projectlist/invoice-assigned-number-list',

  DROPDOWN_DATA_SELECTED = '/components/projectlist/dropdown-selected',
  DROPDOWN_PROJECT_FOLDER = '/components/projectlist/dropdown/project-folder',

  FILTER_SETS = '/components/projectlist/filter-sets',

  FILTERS_CUSTOMER = '/components/pricelist/filtersdropdown/customers',

  PROJECT_BASE_DATA = '/site/index.php?page=project_base_data&project=',

  INCOMING_INVOICE_EXPORT = '/components/booking/booking-receipt/export',
}
