<div class="file" (mouseenter)="showIcons = true" (mouseleave)="showIcons = false">
  <div [ngClass]="{ 'file__no-image': !thumbnailUrl }" *ngIf="!showIcons" class="file__thumbnail">
    <span
      class="file__thumbnail__file-extension"
      *ngIf="!attachment.thumbnailPath && attachment.filename"
      >{{ getFileExtension() }}</span
    >
    <troi-icon
      *ngIf="!thumbnailUrl"
      class="file__thumbnail__icon"
      [icon]="'icon-empty-file'"
    ></troi-icon>
    <img *ngIf="thumbnailUrl" [src]="thumbnailUrl" alt="Thumbnail" />
  </div>
  <div *ngIf="showIcons" class="file__popover">
    <div *ngIf="!isCreateComment" class="file__popover__icon-col">
      <troi-icon
        class="file__popover__icon"
        [icon]="'icon-save'"
        (click)="onFileDownload()"
      ></troi-icon>
    </div>
    <div class="file__popover__icon-col">
      <troi-icon
        class="file__popover__icon"
        [icon]="'icon-trash-can'"
        (click)="onFileDelete()"
      ></troi-icon>
    </div>
  </div>
</div>
