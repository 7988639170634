import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import * as _ from 'lodash';
import { TroiDropDownCloseService } from '../../troi-dropdown-list/services/troi-dropdown-close.service';
import { TroiDropdownListModel } from '../../troi-dropdown-list/models/troi-dropdown-list.model';
import { SearchCalcPositionService } from '../../../modules/desk/widgets/popover/search-calc-position/search-calc-position.service';

@Component({
  selector: 'troi-my-projects-filter-modal',
  templateUrl: './troi-my-projects-filter-modal.component.html',
  styleUrls: ['./troi-my-projects-filter-modal.component.scss'],
})
export class TroiMyProjectsFilterModalComponent implements OnChanges {
  private element: ElementRef;
  public openState = false;
  @Input() applyFiltersDataResendFetch: Record<string, any> = {};

  @Input() public forceOpen = false;
  @Input() newDesign: boolean;

  public _filters = [
    {
      clientId: '',
      customerId: '',
      projectId: '',
    },
  ];

  @Output() openChange = new EventEmitter<boolean>();
  @Output() filtersReset = new EventEmitter<boolean>();
  @Output() filtersApplied = new EventEmitter<any>();

  public resultClients: Array<TroiDropdownListModel> = [];
  public resultCustomers: Array<TroiDropdownListModel> = [];
  public resultProjects: Array<TroiDropdownListModel> = [];

  public loadedClients = true;
  public loadedCustomers = true;
  public loadedProjects = true;

  private selectedClient = {};
  private selectedCustomer = {};
  private selectedProject = {};

  initialClient: Array<any> = [];
  initialCustomers: Array<any> = [];
  initialProject: Array<any> = [];

  constructor(
    element: ElementRef,
    private troiDropDownCloseService: TroiDropDownCloseService,
    private searchcalcpositionservice: SearchCalcPositionService,
  ) {
    this.element = element;
    this.troiDropDownCloseService.getEmitter().subscribe((event) => {
      const clickedElement = _.find(
        event.path || event.composedPath(),
        (path) => path === this.element.nativeElement || path === this.element.nativeElement.parentElement,
      );
      if (!clickedElement) {
        this.hideModal();
      }
      event.stopPropagation();
    });
    // this.onPopupOpen();
  }

  hideModal() {
    this.openState = false;
    this.openChange.emit(this.openState);
  }

  ngOnChanges(changes) {
    if (changes.forceOpen) {
      this.openState = this.forceOpen;
    }
    if (changes.applyFiltersDataResendFetch) {
      this.onPopupOpen();
    }
  }

  public emitFiltersApplied(): void {
    this.filtersApplied.emit(this._filters);
    this.hideModal();
  }

  emitFiltersReset() {
    this.filtersReset.emit(true);
    this.hideModal();
  }

  /* client */
  onClientSearchStringChanged(term: string): void {
    this.loadedClients = false;
    this.searchcalcpositionservice.searchClients(term).subscribe((result: any) => {
      this.resultClients = result;
      this.loadedClients = true;
    });
  }

  onClientSelected(client) {
    if (!client) {
      client = {};
    }
    this.selectedClient = client;
    this._filters[0]['clientId'] = client.id;
    this.onCustomerSearchStringChanged('*');
  }

  /* customer */
  onCustomerSearchStringChanged(term: string): void {
    const client: string = this.selectedClient
      ? this.selectedClient.hasOwnProperty('id')
        ? this.selectedClient['id']
        : ''
      : '';

    this.loadedCustomers = false;
    this.searchcalcpositionservice.searchCustomers(term, client).subscribe((result: any) => {
      this.resultCustomers = result;
      this.loadedCustomers = true;
    });
  }

  onCustomerSelected(customer) {
    if (!customer) {
      customer = {};
    }
    this.selectedCustomer = customer;
    this._filters[0]['customerId'] = customer.id;
    this.onProjectSearchStringChanged('*');
  }

  /* project */
  onProjectSearchStringChanged(term: string): void {
    const client: string = this.selectedClient
      ? this.selectedClient.hasOwnProperty('id')
        ? this.selectedClient['id']
        : ''
      : '';
    const customer: string = this.selectedCustomer
      ? this.selectedCustomer.hasOwnProperty('id')
        ? this.selectedCustomer['id']
        : ''
      : '';

    this.loadedProjects = false;
    this.searchcalcpositionservice.searchProjects(term, client, customer).subscribe((result: any) => {
      this.resultProjects = result;
      this.loadedProjects = true;
    });
  }

  onProjectSelected(project) {
    if (!project) {
      project = {};
    }
    this.selectedProject = project;
    this._filters[0]['projectId'] = project.id;
  }

  onPopupOpen() {
    /* client */
    this.loadedClients = false;
    this.searchcalcpositionservice.searchClients('*').subscribe((result: any) => {
      this.resultClients = result;
      this.loadedClients = true;
      if (this.applyFiltersDataResendFetch && this.applyFiltersDataResendFetch['clientId'] !== undefined) {
        this.initialClient = [
          this.resultClients.find((client) => client.id === this.applyFiltersDataResendFetch['clientId']),
        ];
      }
    });

    /* customer */
    const clientStr: string =
      this.applyFiltersDataResendFetch && this.applyFiltersDataResendFetch['clientId'] !== undefined
        ? this.applyFiltersDataResendFetch['clientId']
        : '';

    this.loadedCustomers = false;
    this.searchcalcpositionservice.searchCustomers('*', clientStr).subscribe((result: any) => {
      this.resultCustomers = result;
      this.loadedCustomers = true;
      if (this.applyFiltersDataResendFetch && this.applyFiltersDataResendFetch['customerId'] !== undefined) {
        this.initialCustomers = [
          this.resultCustomers.find((client) => client.id === this.applyFiltersDataResendFetch['customerId']),
        ];
      }
    });

    /* project */
    const customerStr: string =
      this.applyFiltersDataResendFetch && this.applyFiltersDataResendFetch['customerId'] !== undefined
        ? this.applyFiltersDataResendFetch['customerId']
        : '';

    this.loadedProjects = false;
    this.searchcalcpositionservice.searchProjects('*', clientStr, customerStr).subscribe((result: any) => {
      this.resultProjects = result;
      this.loadedProjects = true;
      if (this.applyFiltersDataResendFetch && this.applyFiltersDataResendFetch['projectId'] !== undefined) {
        this.initialProject = [
          this.resultProjects.find((client) => client.id === this.applyFiltersDataResendFetch['projectId']),
        ];
      }
    });
  }
}
