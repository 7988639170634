import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddEditComponent } from '../../accounting-administration/account-list/modals/add-edit/add-edit.component';
import { AccountsService } from '../../accounting-administration/account-list/services/accounts.service';
import { FiltersService } from '../../accounting-administration/account-list/services/filters.service';
import { AddEditService } from '../../accounting-administration/account-list/services/addEdit.service';
import { SharedModule } from '../../../../shared/shared.module';
import { HeaderNotificationModule } from '../../../header-notification/headerNotification.module';
import { ModelsFactory } from '../factories/models.factory';
import { StorageNotificationService } from '../../../../core/notifications/storageNotification.service';
import { AccountsNetwork } from '../../accounting-administration/account-list/networks/accounts.network';
import { FiltersFormService } from '../../accounting-administration/account-list/services/filters-form.service';
import { TabsService } from '../../../../core/services/tabs.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    HeaderNotificationModule,
    NgbTooltipModule,
  ],
  declarations: [AddEditComponent],
  providers: [
    AccountsService,
    AccountsNetwork,
    FiltersFormService,
    FiltersService,
    AddEditService,
    ModelsFactory,
    StorageNotificationService,
    TabsService,
  ],
  bootstrap: [],
  exports: [AddEditComponent],
})
export class CreateAccountBaseModule {}
