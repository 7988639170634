import { Component, Input } from '@angular/core';
import { CalcPositionService } from '../../../../../../network/calc-pos.service';
import { TaskInterface } from '../../../../../../interfaces/task.interface';
import { CalculationPositionInterface } from '../../../../../../../tasks/interfaces/calculationPosition.interface';
import { TasksHelperService } from '../../../../../../services/helper.service';
import * as _ from 'lodash';

@Component({
  selector: 'troi-task-calc-pos',
  templateUrl: './calc-pos.component.html',
  styleUrls: ['./calc-pos.component.scss'],
})
export class CalculationPositionComponent {
  @Input() calcPosition: CalculationPositionInterface;
  @Input() selectedCalcualtionPosition: CalculationPositionInterface;
  @Input() task: TaskInterface;

  constructor(public calcPositionService: CalcPositionService, public helperService: TasksHelperService) {}

  public onSelectPositionClick(calcPosition: CalculationPositionInterface): void {
    _.assign(this.selectedCalcualtionPosition, calcPosition);
    this.calcPositionService.setSelectedCalcPosition(this.selectedCalcualtionPosition);
  }
}
