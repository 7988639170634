import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { BasicFiltersNetwork } from '../network/basic-filters.network';
import { ClientInterface } from '../interfaces/client.interface';
import { TroiDropdownListModel } from '../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { BackendResponseInterface } from '../interfaces/backend.response.interface';

@Injectable()
export class BasicFiltersService {
  clients: ClientInterface[] = [];

  clientsLoaded = new Subject<ClientInterface[]>();

  constructor(private network: BasicFiltersNetwork) {}

  fetchClients(withoutCustomers: boolean) {
    this.network.getClients(withoutCustomers).subscribe((result: BackendResponseInterface<ClientInterface[]>) => {
      this.clients = result.data;
      this.clientsLoaded.next(result.data);
    });
  }

  generateClientsDropdown(): Array<TroiDropdownListModel> {
    const list = [];
    _.forEach(this.clients, (element: ClientInterface) => {
      list.push({
        label: element.name,
        value: element.id,
        active: true,
      });
    });

    return list;
  }

  defaultClientId(): number {
    const client = _.find(this.clients, ['default', true]);
    return client ? client.id : _.first(this.clients).id;
  }
}
