import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Routes } from '../enum/routes';
import { MenuResponseInterface, MenuUpdateInterface } from '../interface/menu.interface';

@Injectable()
export class MenuNetwork {
  public constructor(public http: HttpClient) {}

  public fetchMenu(): Observable<MenuResponseInterface> {
    return this.http.get<MenuResponseInterface>(environment.url + Routes.MENU);
  }

  public updateQuickLinks(body): Observable<MenuUpdateInterface> {
    return this.http.post<MenuUpdateInterface>(environment.url + Routes.SHORTCUTS, body);
  }
}
