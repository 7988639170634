import { TimeParserObject } from '../interfaces/time-parser.interface';

export class TimeParserModel {
  public parse(time: string | number): TimeParserObject {
    if (typeof time === 'number') {
      time = time.toString();
    }

    if (typeof time !== 'string') {
      return {
        ...this.getEmptyTimeParserObject(),
        error: 'Time must be a string or a number',
      };
    }

    if (time.includes('-')) {
      const [, startTimeHours, startTimeMinutes, endTimeHours, endTimeMinutes] = time
        .match(/^(\d{1,2}):?(\d{2})?-?(\d{1,2}):?(\d{2})?$/)
        .map((timePart) => (timePart ? parseInt(timePart) : 0));

      const startTime = startTimeHours + startTimeMinutes / 60;
      const endTime = endTimeHours + endTimeMinutes / 60;

      if (startTime > endTime) {
        return {
          ...this.getEmptyTimeParserObject(),
          error: 'Start time cannot be greater than end time',
        };
      }

      return {
        startTime: this.parseNumberToTime(startTime),
        endTime: this.parseNumberToTime(endTime),
        timeDiff: endTime - startTime,
        timeDiffAsTime: this.parseNumberToTime(endTime - startTime),
        isTimeRange: true,
        formattedTime: `${this.parseNumberToTime(startTime)} - ${this.parseNumberToTime(endTime)}`,
        error: null,
      };
    }

    const cleanTimeNumber = this.parseTimeToNumber(time);
    return {
      startTime: this.parseNumberToTime(cleanTimeNumber),
      endTime: null,
      timeDiff: cleanTimeNumber,
      timeDiffAsTime: this.parseNumberToTime(cleanTimeNumber),
      isTimeRange: false,
      formattedTime: this.parseNumberToTime(cleanTimeNumber),
      error: null,
    };
  }

  private getEmptyTimeParserObject(): TimeParserObject {
    return {
      startTime: '',
      endTime: null,
      timeDiff: 0,
      timeDiffAsTime: '',
      isTimeRange: false,
      formattedTime: '',
      error: null,
    };
  }

  private parseTimeToNumber(time: string): number {
    const timeClean = time.replace(/ +/g, '').replace(/,/g, '.');

    const hmFormat = timeClean.match(/(\d+(\.\d+)?)h?(\d+(\.\d+)?)?m?/);
    if (hmFormat && (timeClean.includes('h') || timeClean.includes('m'))) {
      if (!hmFormat[0].includes('h')) return Number(hmFormat[1]) / 60;
      const hours = hmFormat[1];
      const minutes = hmFormat[3] ? hmFormat[3] : 0;
      return Number(hours) + Number(minutes) / 60;
    }

    // This should probably not happen, fallback to old entry format
    const colonFormat = timeClean.match(/^(\d{1,2}):(\d{1,2})$/);
    if (colonFormat) {
      const hours = colonFormat[1];
      const minutes = colonFormat[2];
      return Number(hours) + Number(minutes) / 60;
    }

    return parseFloat(timeClean);
  }

  private parseNumberToTime(time: number): string {
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);
    return `${this.prependZero(hours)}:${this.prependZero(minutes)}`;
  }

  private prependZero(time: string | number): string {
    if (typeof time === 'number') time = time.toString();
    return time.length === 1 ? `0${time}` : time;
  }
}
