import { Component } from '@angular/core';
import { BasicFiltersService } from '../../core/services/basic-filters.service';
import { BookingSettingsService } from './common/services/booking-settings.service';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
})
export class BookingsComponent {
  constructor(public settingsService: BookingSettingsService, public basicFiltersService: BasicFiltersService) {}
}
