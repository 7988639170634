import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'troi-textarea',
  templateUrl: './troi-textarea.component.html',
  styleUrls: ['./troi-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TroiTextareaComponent),
      multi: true,
    },
  ],
})
export class TroiTextareaComponent implements ControlValueAccessor {
  @ViewChild(DefaultValueAccessor, { static: true }) private valueAccessor: DefaultValueAccessor;

  @Input()
  public get height(): number {
    return this._height;
  }
  public set height(value: number) {
    this._height = coerceNumberProperty(value);
  }
  private _height = 40;

  @Input()
  public get fontSize(): number {
    return this._fontSize;
  }
  public set fontSize(value: number) {
    this._fontSize = coerceNumberProperty(value);
  }
  private _fontSize = 10;

  @Input()
  public placeholder = '';

  @ViewChild('textarea', { static: false, read: ElementRef }) textareaElement: ElementRef<HTMLTextAreaElement>;

  writeValue(obj: any) {
    this.valueAccessor.writeValue(obj);
  }

  registerOnChange(fn: any) {
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any) {
    this.valueAccessor.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.valueAccessor.setDisabledState(isDisabled);
  }

  public focus() {
    this.textareaElement.nativeElement.focus();
  }
}
