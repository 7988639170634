import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { LanguagesService } from '../../core/services/languages.service';
import { LanguageTranslationsBackend } from '../../core/interfaces/languageTranslations.backend';
import { DragNDropElementInterface } from '../troi-dragndrop/interfaces/dragNDropElement.interface';
import { ItemModel } from '../../modules/price-list/models/item.model';
import { PriceListItemInterface } from '../../modules/price-list/interfaces/priceListItem.interface';
import { Money } from '../troi-money/money';

@Injectable()
export class ObjectChangesService {
  public constructor(private languageService: LanguagesService) {}

  public isTranslatableFieldChanged(
    nameActual: LanguageTranslationsBackend,
    nameOriginal: LanguageTranslationsBackend,
  ): boolean {
    if (!nameOriginal) {
      return false;
    }

    const originalName = this.languageService.getLanguageValue(nameOriginal);
    const actualName = this.languageService.getLanguageValue(nameActual);

    if ((_.isNull(originalName) && actualName === '') || (originalName === '' && _.isNull(actualName))) {
      return false;
    }

    return actualName !== originalName;
  }

  public isUnitChanged = (priceListItem: PriceListItemInterface & ItemModel & DragNDropElementInterface): boolean => {
    return (
      priceListItem.scope.isOverridden &&
      priceListItem.itemChanges.unit.value &&
      priceListItem.fields.unit.id !== priceListItem.itemChanges.unit.value.id
    );
  };

  public isItemNumberChanged = (
    priceListItem: PriceListItemInterface & ItemModel & DragNDropElementInterface,
  ): boolean => {
    return (
      priceListItem.scope.isOverridden && priceListItem.fields.itemNumber !== priceListItem.itemChanges.itemNumber.value
    );
  };

  public isPricesChanged = (priceActual: Money, priceOriginal: Money): boolean => {
    if (!priceActual && !priceOriginal) {
      return false;
    }

    if ((priceActual && !priceOriginal) || (!priceActual && priceOriginal)) {
      return true;
    }

    return priceActual.numberValue !== priceOriginal.numberValue;
  };

  public isFeeChanged = (priceListItem: PriceListItemInterface & ItemModel & DragNDropElementInterface): boolean => {
    return (
      priceListItem.scope.isOverridden &&
      priceListItem.itemChanges.fee.value &&
      priceListItem.fields.fee.id !== priceListItem.itemChanges.fee.value.id
    );
  };

  public isAccountChanged = (
    priceListItem: PriceListItemInterface & ItemModel & DragNDropElementInterface,
  ): boolean => {
    if (!priceListItem.itemChanges || !priceListItem.scope.isOverridden) {
      return false;
    }

    if (_.isNull(priceListItem.itemChanges.account.value) || _.isNull(priceListItem.fields.account)) {
      return !(_.isNull(priceListItem.itemChanges.account.value) && _.isNull(priceListItem.fields.account));
    }

    return (
      priceListItem.scope.isOverridden && priceListItem.itemChanges.account.value.id !== priceListItem.fields.account.id
    );
  };
}
