import { Injectable } from '@angular/core';
import { ProjectListNetwork } from '../../../../network/project-list.network';
import { ProjectListSettingsService } from '../../../../services/project-list-settings.service';
import { IncomingInvoiceModel } from '../../../../models/incoming-invoice.model';
import { LanguagesService } from '../../../../../../core/services/languages.service';
import { Money } from '../../../../../../shared/troi-money/money';

@Injectable()
export class IncomingInvoiceListService {
  public isLoading = true;
  public items: IncomingInvoiceModel[];
  public settings: null;
  public pageTotal: string;
  public pageTotalQuantity: number;
  public projectContextId: number;
  public total: Money;
  public pending: string;
  public approved: string;
  public declined: string;
  public totalCount: number;
  public totalPerApproval: {
    approved: Money;
    pending: Money;
    declined: Money;
    started: Money;
  };
  public totalQuantity: number;
  public allSelected = false;
  public selectedElements: string[] = [];
  public allItemsIds: string[] = [];
  public selectedElementsObjects: Record<string, string>[] = [];

  constructor(
    private projectListNetwork: ProjectListNetwork,
    private projectListSettingsService: ProjectListSettingsService,
    private languageService: LanguagesService,
  ) {}

  public isSelected(id: string): boolean {
    return this.selectedElements.includes(id);
  }

  onSelect(elem: Record<string, string>, event) {
    if (event === true) {
      this.selectedElements.push(elem.id);
      this.allSelected = this.selectedElements.length === this.allItemsIds.length;
    } else {
      this.selectedElements = this.selectedElements.filter((id: string) => id !== elem.id);
      this.allSelected = event;
    }
  }

  public toggleSelectAll(event) {
    this.selectedElements = event ? this.allItemsIds : [];
    this.allSelected = event;
  }

  public loadList(
    projectId: string | number,
    showOnlyMyPending: boolean,
    pageSize: number,
    currentPage: number,
    allIds = true,
  ): void {
    this.isLoading = true;

    // TODO: TROR-19711 add params or remove at all when are not required
    this.projectListNetwork
      .fetchIncomingInvoices(projectId, {
        lang: this.languageService.getDataLanguage(),
        showOnlyMyPending,
        pageSize,
        currentPage,
        allIds,
      })
      .subscribe((result) => {
        // @ts-ignore
        this.settings = result.settings;
        // @ts-ignore
        this.items = [...result.data];
        this.allItemsIds = [...result.allIds?.map((id) => String(id))];
        this.isLoading = false;
        this.pageTotal = result.pageTotal;
        this.pageTotalQuantity = result.pageTotalQuantity;
        this.projectContextId = result.projectContextId;
        this.total = new Money(
          result.total,
          this.projectListSettingsService.settings.activeCurrency,
          // @ts-ignore
          result.settings.monetarySettings.decimalPlaces,
        );
        this.totalCount = result.totalCount;
        this.totalPerApproval = {
          approved: new Money(
            result.totalPerApproval.approved,
            this.projectListSettingsService.settings.activeCurrency,
            // @ts-ignore
            result.settings.monetarySettings.decimalPlaces,
          ),
          pending: new Money(
            result.totalPerApproval.pending,
            this.projectListSettingsService.settings.activeCurrency,
            // @ts-ignore
            result.settings.monetarySettings.decimalPlaces,
          ),
          declined: new Money(
            result.totalPerApproval.declined,
            this.projectListSettingsService.settings.activeCurrency,
            // @ts-ignore
            result.settings.monetarySettings.decimalPlaces,
          ),

          started: new Money(
            result.totalPerApproval.started,
            this.projectListSettingsService.settings.activeCurrency,
            // @ts-ignore
            result.settings.monetarySettings.decimalPlaces,
          ),
        };
        this.totalQuantity = result.totalQuantity;
      });
  }
}
