<troi-dropdown-select
  [isLoading]="optionsLoading"
  [noBorder]="noBorder"
  [size]="'11px'"
  [lazyLoading]="true"
  [search]="enableSearch()"
  placeholder="{{ placeholder | translate }}"
  class="troi-dropdown-select--full"
  (searchEvent)="eventTriggered($event)"
  [searchPrefix]="searchPrefix"
  [searchInputType]="searchInputType"
  [totalOptions]="totalOptions"
  [options]="options"
  [initValue]="initValue"
  [clearButton]="clearButton"
  [defaultEmptyValue]="defaultEmptyValue"
  [doubleHeight]="true"
  [fieldInvalid]="fieldInvalid"
  [validationEnabled]="validationEnabled"
  [enabled]="enabled"
  [predefinedOptions]="predefinedOptions"
  [initLazyOptionOnInit]="initLazyOptionOnInit"
  [returnSelectedObject]="returnSelectedObject"
  [validationMessage]="validationMessage"
  (selected)="selected.emit($event)"
  [colorizeBackgroundProperty]="colorizeBackgroundProperty"
  [width]="width"
  [multipleSelect]="multipleSelect"
  [selectAllOption]="selectAllOption"
  [selectAllOptionLabel]="selectAllOptionLabel"
>
</troi-dropdown-select>
