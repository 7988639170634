<div class="tnd-search-select">
  <!-- <troi-icon [icon]="icon" *ngIf="icon.length > 0" class="tnd-search-select__icon-type"></troi-icon> -->

  <!-- <troi-icon [icon]="icon" *ngIf="icon.length > 0" class="tnd-search-select__icon-type"></troi-icon> -->
  <div style="width: 5%; display: flex; justify-content: center; align-items: center">
    <troi-icon class="searchIcon" icon="icon-loop-with-arrow-down"></troi-icon>
  </div>

  <div style="width: 90%">
    <input
      [ngStyle]="{ 'background-color': transparentBackground ? 'transparent' : '' }"
      type="text"
      (keyup)="fetchData()"
      (blur)="onBlur()"
      [(ngModel)]="searchString"
      (click)="toggle()"
      [disabled]="disabled"
      class="tnd-search-select__input"
      placeholder="{{ placeholder }}"
    />
  </div>
  <div style="width: 5%">
    <troi-icon
      *ngIf="!open && !disabled"
      icon="icon-bold-arrow-down"
      class="tnd-search-select__icon-state"
    ></troi-icon>
    <troi-icon
      *ngIf="open && !disabled"
      icon="icon-bold-arrow-up"
      class="tnd-search-select__icon-state"
    ></troi-icon>
    <troi-icon
      [ngStyle]="{ display: hideCloseIcon ? 'none' : '' }"
      *ngIf="disabled"
      icon="icon-close"
      class="tnd-search-select__icon-state"
      (click)="reset()"
    ></troi-icon>
  </div>
  <ul class="tnd-search-select__result-list tnd-scrollbar" *ngIf="open && searchString.length > 0">
    <troi-loading-spinner *ngIf="loading"></troi-loading-spinner>
    <div
      class="sideBarOpen"
      *ngIf="!resultSelected && searchResult && searchResult.length > 0 && open"
    >
      <!-- <li
        (click)="setValue(result)"
        *ngFor="let result of searchResult"
        class="tnd-search-select__list-item"
      >
        {{ labelFieldName1.length ? result[labelFieldName1] : '' }}&nbsp;
        {{ labelFieldName2.length ? result[labelFieldName2] : '' }}
      </li> -->
      <li
        (click)="result.id !== -1 && setValue(result)"
        *ngFor="let result of searchResult"
        class="tnd-search-select__list-item"
        [innerHTML]="labelFieldName1.length ? result[labelFieldName1] : ''"
        [ngClass]="{ 'not-clickable': result.id === -1 }"
      ></li>
    </div>
    <div *ngIf="searchResult && searchResult.length === 0 && open && !loading">
      <span class="tnd-search-select__no-result font-italic">
        {{ 'Desktop.General.Messages.noResults' | translate }}
      </span>
    </div>
  </ul>
  <ul *ngIf="multiSelect" class="tnd-search-select__chip-list">
    <li *ngFor="let result of multiSelectValues; index as i" class="tnd-search-select__chip">
      {{ labelFieldName1.length ? result[labelFieldName1] : '' }}&nbsp;
      {{ labelFieldName2.length ? result[labelFieldName2] : '' }}
      <troi-icon
        ngClass="tnd-search-select delete-chip"
        icon="icon-close"
        (click)="remove(i)"
      ></troi-icon>
    </li>
  </ul>
</div>
