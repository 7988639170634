import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LanguagesService } from '../../../../core/services/languages.service';
import { MenuInterface } from '../../interface/menu.interface';
import { MenuService } from '../../services/menu.service';
import { TimeRecordingService } from '../../../time-recording/time-recording.service';

@Component({
  selector: 'menu-quick-link',
  templateUrl: './menu-quick-link.component.html',
  styleUrls: ['./menu-quick-link.component.scss'],
})
export class MenuQuickLinkComponent {
  constructor(
    public menuService: MenuService,
    public languagesService: LanguagesService,
    public timeService: TimeRecordingService,
  ) {}

  @Input() item: MenuInterface;
  @Input() active: MenuInterface;
  @Input() skeleton = false;
  @Output() dragStarted = new EventEmitter<Record<string, boolean | MenuInterface>>();

  get isActive(): boolean {
    return this.active?.id === this.item?.id;
  }

  public onDragStart(event) {
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('application/json', JSON.stringify(this.item));
    this.dragStarted.emit({ state: true, item: this.item });
  }

  public onDragEnd(event) {
    this.dragStarted.emit({ state: false, item: this.item });
  }

  checkAnyUnsavedItem() {
    if (localStorage.getItem('unsavedChanges') === 'true') {
      const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (confirmLeave) {
        this.timeService.setUnsavedChanges(false);
        this.timeService.clearRedBorders();
        localStorage.setItem('unsavedChanges', JSON.stringify(false));
        return true;
      } else {
        this.timeService.highlightUnsavedItems();
        return false;
      }
      return confirmLeave;
    }
    localStorage.setItem('unsavedChanges', JSON.stringify(false));
    return true;
  }
}
