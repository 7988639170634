<div class="delete-confirmation">
  <troi-modal-header [noCloseButton]="true">
    <span>{{ 'FilterSets.modals.delete.header' | translate }}</span>
  </troi-modal-header>
  <troi-modal-content>
    <p class="delete-confirmation__message">
      {{ 'FilterSets.modals.delete.description' | translate }}
    </p>
  </troi-modal-content>
  <troi-modal-footer>
    <troi-btn class="delete-confirmation__button" [outline]="true" (click)="close()">
      {{ 'Common.labels.cancel' | translate }}
    </troi-btn>
    <troi-btn class="delete-confirmation__button" (click)="execute()">
      {{ 'Common.labels.delete' | translate }}
    </troi-btn>
  </troi-modal-footer>
</div>
