import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { map } from 'rxjs/operators';

@Injectable()
export class SearchProjectService {
  private readonly apiEndpointSearchClient: string = '/components/desktop/search/client';
  private readonly apiEndpointSearchCustomer: string = '/components/desktop/search/customer';
  private readonly apiEndpointSearchProject: string = '/components/desktop/search/project';
  private readonly apiEndpointFavorite: string = '/components/desktop/project-timerecording/favorite';
  private readonly apiEndpointUnfavorite: string = '/components/desktop/project-timerecording/unfavorite';

  private httpOptions = {};

  public constructor(private http: HttpClient) {}

  searchProjects(search: string, clientId: string = '', customerId: string = ''): Observable<any> {
    const params: Array<string> = [];

    if (search.length) {
      params.push('search=' + encodeURIComponent(search));
    }
    if (clientId.length) {
      params.push('clientId=' + encodeURIComponent(clientId));
    }
    if (customerId.length) {
      params.push('customerId=' + encodeURIComponent(customerId));
    }

    const url: string = this.apiEndpointSearchProject + (params.length ? '?' + params.join('&') : '');

    return this.http.get(url);
  }

  searchClients(term: string): Observable<any> {
    const search: string = term.trim().length > 0 ? term.trim() : '*';

    return this.http.get(this.apiEndpointSearchClient + '?search=' + search).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  searchCustomers(term: string, clientId: string = ''): Observable<any> {
    const search: string = term.trim().length > 0 ? term.trim() : '*';

    return this.http
      .get(
        this.apiEndpointSearchCustomer +
          '?search=' +
          encodeURIComponent(search) +
          (clientId.length ? '&clientId=' + encodeURIComponent(clientId) : ''),
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  favoriteProject(objectType: string, objectId: string): Observable<any> {
    const form: UntypedFormGroup = this.createFavForm(objectType, objectId);

    return this.http.post(this.apiEndpointFavorite, form.value, this.httpOptions);
  }

  unfavoriteProject(objectType: string, objectId: string): Observable<any> {
    const form: UntypedFormGroup = this.createFavForm(objectType, objectId);

    return this.http.post(this.apiEndpointUnfavorite, form.value, this.httpOptions);
  }

  private createFavForm(objectType: string, objectId: string): UntypedFormGroup {
    const form: UntypedFormGroup = new UntypedFormGroup({
      objectType: new UntypedFormControl(''),
      objectId: new UntypedFormControl(''),
    });

    form.controls['objectType'].setValue(objectType);
    form.controls['objectId'].setValue(objectId);

    return form;
  }
}
