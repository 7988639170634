import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { TimerangeView } from '../../modules/tasks/modules/timelineview/enum/view';
import { TasksHelperService } from '../../modules/tasks/services/helper.service';

@Component({
  selector: 'app-timelineview-view-switch',
  templateUrl: './view-switch.component.html',
  styleUrls: ['./view-switch.component.scss'],
})
export class TimelineviewViewSwitchComponent implements OnInit {
  @Input() isResourceView = false;
  public TimerangeView = TimerangeView;
  public view;

  constructor(private helperService: TasksHelperService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.view = this.isResourceView ? TimerangeView.DAY : TimerangeView.WEEK;
      this.helperService.timeRangeViewChanged(this.view);
    });
  }

  onChangeView(view: TimerangeView) {
    this.view = view;
    this.helperService.timeRangeViewChanged(view);
  }
}
