<div class="subproject__title-container">
  <troi-icon
    class="subproject__title-container--arrow-icon"
    (click)="toggleSubproject()"
    [icon]="!collapseSubproject ? 'icon-bold-arrow-down' : 'icon-bold-arrow-right'"
  ></troi-icon>

  <troi-checkbox
    [value]="allCalcPosChosen()"
    (checkboxChange)="addAllCPs($event)"
    [asLabel]="false"
  ></troi-checkbox>
  <span class="subproject__subproject-name">{{ subproject.name }}</span>
</div>

<div class="subproject__cp-container" *ngIf="!collapseSubproject">
  <div
    class="subproject__calc-position"
    *ngFor="let calcPos of subproject.cps"
    [ngClass]="{ 'is-disabled': getCalcDisabled(calcPos) }"
  >
    <div class="subproject__title-container">
      <troi-checkbox
        [value]="!getCalcDisabled(calcPos) && cpsToImport.includes(calcPos)"
        [disabled]="getCalcDisabled(calcPos)"
        (checkboxChange)="addCP($event, calcPos)"
        [asLabel]="false"
      ></troi-checkbox>
      <span class="subproject__cp-id">K-{{ calcPos.id }}</span>

      <span class="subproject__cp-name">{{ calcPos.serviceName }}</span>
    </div>

    <span>{{ helperService.formatQuantity(calcPos.quantity, calcPos.unit?.unitRate, calcPos.unit?.unitType) }} h</span>
  </div>
</div>
