export enum ActionsEnum {
  NEW_PROJECT = 'newProject',
  NEW_PROJECT_FOLDER = 'newProjectFolder',
  EDIT_PROJECT_FOLDER = 'editProjectFolder',
  DELETE_PROJECT_FOLDER = 'deleteProjectFolder',
  DELETE_PROJECT = 'deleteProject',
  NEW_PROJECT_PLAN = 'newProjectPlan',
  INVOICE_ASSIGN_NRS = 'invoiceAssignNrs',
  INCOMING_INVOICE_LIST = 'incomingInvoiceList',
  STATUS_SETTINGS = 'statusSettings',
  EXPORT_LIST = 'exportList',
}
