import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as _ from 'lodash';
import { forEach, union } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { DropdownActionInterface } from '../troi-dropdown-list/interfaces/dropdown-action.interface';
import { TroiDropdownListModel } from '../troi-dropdown-list/models/troi-dropdown-list.model';
import { TroiDropdownListComponent } from '../troi-dropdown-list/troi-dropdown-list.component';

const noop = () => {};

export const TroiDropdownSelectControlValueAccessor: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TroiDropdownSelectComponent),
  multi: true,
};

@Component({
  selector: 'troi-dropdown-select',
  templateUrl: './troi-dropdown-select.component.html',
  styleUrls: ['./troi-dropdown-select.component.scss'],
  providers: [TroiDropdownSelectControlValueAccessor],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TroiDropdownSelectComponent implements ControlValueAccessor, OnChanges, OnInit, AfterViewInit, OnDestroy {
  @ViewChild(TroiDropdownListComponent, { static: true }) public optionList: TroiDropdownListComponent;

  @Input()
  public set options(newOptions: TroiDropdownListModel[]) {
    this._options = newOptions;

    if ((this.multipleSelect || this.treeMode) && this.initValue) {
      this.initMultipleSelectOptions(newOptions);
    }
  }

  public get options(): TroiDropdownListModel[] {
    return this._options;
  }

  @Input() public actions: DropdownActionInterface[];

  @Input() public predefinedOptions: Array<TroiDropdownListModel> = [];

  @Input() public static = false;

  @Input() public noBorder = false;

  @Input() public fullWidth = false;

  @Input() public size: string;

  @Input() public textAlignment = '';

  @Input() public forceOpen = false;

  @Input() public top = false;

  @Input() public enabled = true;

  @Input() public clearButton = false;

  @Input() public noMinWith = false;

  @Input() public maxWidth = '100%';

  @Input() public width = '100%';

  @Input() public toRight = false;

  @Input() public placeholder = '';

  @Input() public labelIcon: string;

  @Input() public initValue;

  @Input() public search = false;

  @Input() public searchPrefix: string;

  @Input() public searchInputType = 'text';

  @Input() public disable = false;

  @Input() public fieldInvalid = false;

  @Input() public validationEnabled = false;

  @Input() public multipleSelect = false;

  @Input() public treeMode = false;

  @Input() public selectAllOption = false;

  @Input() public selectAllOptionLabel = '';

  @Input() public lazyLoading = false;

  @Input() public totalOptions = null;

  @Input() public defaultEmptyValue = null;

  @Input() public returnSelectedObject = false;

  @Input() public isLoading = false;

  @Input() public doubleHeight = false;

  @Input() public initLazyOptionOnInit = false;

  @Input() public onlyOneGroupSelect = false;

  @Input() public validationMessage = 'Form.Error.Required';

  @Input() public reloadPreloadedOptions;

  @Input() public resetMultipleSelect: Subject<boolean>;

  @Input() public colorizeBackgroundProperty = '';

  @Input() public allautoselected = false;

  @Input() public bypassOptionsReset = false;

  @Output() selected = new EventEmitter<Record<string, unknown> | string>();

  @Output() searchEvent = new EventEmitter<Record<string, unknown>>();

  @Output() loadOptions = new EventEmitter<number>();

  @Input() public showArrow = false;
  @Input() public leftArrowTitle: string;
  @Input() public rightArrowTitle: string;

  @Output() leftArrowClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() rightArrowClicked: EventEmitter<void> = new EventEmitter<void>();

  private _options: TroiDropdownListModel[];

  private element: ElementRef;

  private innerValue: any = '';

  private innerArray: any = [];

  public open: boolean;

  private onTouchedCallback: () => void = noop;

  private onChangeCallback: (_: any) => void = noop;

  private selectedOption: TroiDropdownListModel;

  private areAllMultipleOptionsSelected = false;

  private customLabel = '';

  private resetMultipleSelectSubscription: Subscription;

  constructor(element: ElementRef, public cdRef: ChangeDetectorRef) {
    this.element = element;
  }

  ngOnInit(): void {
    if (this.allautoselected) {
      this.innerArray = [];
      this.toggleMultipleSelectValuesAsSelected(this._options);
    }

    this.preloadOptionsForMultipleSelect(this.predefinedOptions);

    if (this.multipleSelect || this.treeMode) {
      this.subscribeToResetMultipleSelect();
    }
  }

  private subscribeToResetMultipleSelect(): void {
    this.resetMultipleSelectSubscription = this.resetMultipleSelect?.subscribe((shouldReload: boolean) => {
      this.innerArray = shouldReload ? [] : this.innerArray;
    });
  }

  private toggleMultipleSelectValuesAsSelected(options: TroiDropdownListModel[]) {
    forEach(options, (option) => {
      const actualOption = this.returnSelectedObject ? option : option.value;

      if (!this.innerArray.includes(actualOption)) {
        this.toggleValue(actualOption);
      }
    });

    this.label = this.selectAllOptionLabel || 'Common.labels.all';
    this.areAllMultipleOptionsSelected = true;
  }

  private initMultipleSelectOptions(options: TroiDropdownListModel[]): void {
    this.innerArray = [];

    if (this.initValue === '*') {
      this.toggleMultipleSelectValuesAsSelected(options);
    } else {
      forEach(this.initValue, (option) => {
        if (!this.innerArray.includes(option) && !option.group) {
          this.toggleValue(option);
        }
      });

      this.areAllMultipleOptionsSelected = false;
    }
  }

  private preloadOptionsForMultipleSelect(options): void {
    this.label = '';

    if (this.multipleSelect) {
      if (
        this.selectAllOption &&
        !this.treeMode &&
        !this.options.some((option: TroiDropdownListModel) => option.value === '*')
      ) {
        this.options.unshift({
          label: this.selectAllOptionLabel || 'Common.labels.all',
          value: '*',
          active: true,
        });
      }

      if (options) {
        _.forEach(options, (innerValue) => {
          const optionElement = {
            label: innerValue.label,
            value: innerValue.value,
            active: true,
            fullObject: innerValue.fullObject || innerValue,
            group: innerValue.group,
            multipleChoice: innerValue.multipleChoice,
            multipleChoiceGroup: innerValue.multipleChoiceGroup,
            disabled: innerValue.disabled,
            colorizeBackground:
              this.colorizeBackgroundProperty === '' ? false : innerValue[this.colorizeBackgroundProperty],
          };

          this.options.push(optionElement);
        });
      }

      this.options = _.uniqBy(this.options, 'value');
    }
  }

  get value(): any {
    return this.multipleSelect || this.treeMode ? this.innerArray : this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  onOpen(state) {
    this.open = state;

    if (this.open && this.selectedOption && this.search) {
      if (!this.isSelectedItemExistsInOptions()) {
        this.options.unshift(this.selectedOption);
      }
      this.options = _.uniqBy(this.options, 'value');
    }

    this.cdRef.detectChanges();
  }

  private isSelectedItemExistsInOptions(): boolean {
    return (
      this.selectedOption && _.findIndex(this.options, (option) => option.value === this.selectedOption.value) > -1
    );
  }

  public onSelect(event): void {
    this.element.nativeElement.focus();

    if (this.multipleSelect) {
      if (event.value === '*' || (this.returnSelectedObject && event.value.value === '*')) {
        this.handleSelectAll(event);
      } else {
        this.handleSelectItemForMultipleSelect(event.value);
      }
    } else if (this.treeMode && this.returnSelectedObject) {
      if (event.value.multipleChoice || event.value.multipleChoiceGroup) {
        this.handleSelectItemForMultipleSelect(event.value);
      } else if (event.value.parent) {
        this.toggleValue(event.value);

        this.selected.emit(this.innerArray);
      }
    } else if (this.value !== event.value || (this.returnSelectedObject && event.value.value !== this.value)) {
      this.value = this.returnSelectedObject ? event.value.value : event.value;
      this.selected.emit(event.value);
      this.selectedOption = this.getSelectedOption(this.value);
    }

    this.forceOpen = false;
  }

  private handleSelectAll(event): void {
    const value = this.returnSelectedObject ? event.value.value : event.value;
    const saveValue = () => {
      this.innerArray = [...this.innerArray];
      this.value = this.innerArray;
    };

    this.areAllMultipleOptionsSelected = !this.areAllMultipleOptionsSelected;

    if (this.areAllMultipleOptionsSelected) {
      this.toggleMultipleSelectValuesAsSelected(this.options);
      saveValue();
      this.selected.emit(value);
    } else {
      this.label = '';
      forEach(this.options, (option) => {
        const actualOption = this.returnSelectedObject ? option : option.value;

        if (this.innerArray.includes(actualOption)) {
          this.toggleValue(actualOption);
        }
      });

      saveValue();
      this.selected.emit(this.innerArray);
    }
  }

  private handleSelectItemForMultipleSelect(value): void {
    this.label = '';

    if (this.anotherGroupSelected(value.value) && this.onlyOneGroupSelect && value.group) {
      return;
    }
    this.toggleValue(value);
    const selectedOptionsWithoutAllOptionAndChildren = this.innerArray.filter(this.isNotSelectAllOptionAndNotChild);

    if (
      this.selectAllOption &&
      !this.treeMode &&
      selectedOptionsWithoutAllOptionAndChildren.length ===
        this.options.filter(this.isNotSelectAllOptionAndNotChild).length
    ) {
      this.checkSelectAllOption();
    } else {
      this.uncheckSelectAllOption();
    }
  }

  private checkSelectAllOption(): void {
    const selectAllOption = this.returnSelectedObject
      ? this.options.find((option: TroiDropdownListModel) => option.value === '*')
      : '*';

    if (!this.innerArray.includes(selectAllOption)) {
      this.toggleValue(selectAllOption);
    }

    this.areAllMultipleOptionsSelected = true;
    this.label = this.selectAllOptionLabel || 'Common.labels.all';
    this.value = this.innerArray;
    this.selected.emit('*');
  }

  private uncheckSelectAllOption() {
    this.innerArray = this.innerArray.filter(this.isNotSelectAllOption);
    this.areAllMultipleOptionsSelected = false;
    this.value = this.innerArray;
    this.selected.emit(this.innerArray);
  }

  private isNotSelectAllOptionAndNotChild(option: TroiDropdownListModel | string) {
    return typeof option === 'string' ? option !== '*' : option.value !== '*' && !option.parent;
  }

  private isNotSelectAllOption(option: TroiDropdownListModel | string) {
    return typeof option === 'string' ? option !== '*' : option.value !== '*';
  }

  anotherGroupSelected(wantSelectId): boolean {
    let groupSelected = false;
    _.forEach(this.innerArray, (innerValue) => {
      if (innerValue.group && innerValue.value !== wantSelectId) {
        groupSelected = true;
      }
    });

    return groupSelected;
  }

  private unselectRadioGroup(value: any): void {
    const parentOption = this.options.find((option: TroiDropdownListModel) => option.value === value.parent);
    const parentRadioValues = parentOption
      ? parentOption.groupValues.map((option: TroiDropdownListModel) => option.value)
      : [];
    this.innerArray = this.innerArray.filter((innerVal) => !parentRadioValues.includes(innerVal.value));
  }

  private toggleValue(value: any, toggleOnlyItself = false): void {
    if (this.returnSelectedObject && (this.multipleSelect || this.treeMode)) {
      if (this.treeMode && !value.multipleChoiceGroup && value.parent) {
        this.unselectRadioGroup(value);
      }

      let found = false;
      const data = [];

      _.forEach(this.innerArray, (innerValue) => {
        if (innerValue.value === value.value) {
          found = true;
        } else {
          data.push(innerValue);
        }
      });

      if (found) {
        this.innerArray = data;
      } else {
        this.innerArray.push(value);
      }

      if (!toggleOnlyItself && value.parent) {
        this.toggleParent(value, !found);
      }

      if (!toggleOnlyItself && value.group && value.groupValues) {
        _.forEach(value.groupValues, (innerSubValue) => {
          const includesSubValue = this.innerArray.find(
            (option: TroiDropdownListModel) => option.value === innerSubValue.value,
          );

          if ((!found && !includesSubValue) || (found && includesSubValue)) {
            this.toggleValue(innerSubValue);
          }
        });
      }

      return;
    }

    const index = this.innerArray.indexOf(value);

    if (index < 0) {
      this.innerArray.push(value);
    } else {
      this.innerArray.splice(index, 1);
    }
  }

  private toggleParent(value: TroiDropdownListModel, hasChildBeenChecked: boolean): void {
    const parent = this.options.find((option: TroiDropdownListModel) => option.value === value.parent);
    if (parent) {
      const selectedChildren = this.innerArray.filter(
        (option: TroiDropdownListModel) => option.parent === parent.value,
      );
      const selectedParent = this.innerArray.find((option: TroiDropdownListModel) => option.value === parent.value);

      if (
        (selectedParent && !hasChildBeenChecked) ||
        (!selectedParent && hasChildBeenChecked && selectedChildren.length === parent.groupValues.length)
      ) {
        this.toggleValue(parent, true);
      }
    }
  }

  public set label(customLabel: string) {
    this.customLabel = customLabel;
    this.cdRef.detectChanges();
  }

  public get label(): string {
    if (this.customLabel) {
      return this.customLabel;
    }

    if ((this.multipleSelect || this.treeMode) && this.options) {
      return this.getMultipleSelectLabels(this.options).join(', ');
    }

    if (this.search && this.selectedOption) {
      return this.selectedOption.label;
    }

    if (this.options && this.options.filter((option: TroiDropdownListModel) => option?.value === this.innerValue)[0]) {
      return this.options?.filter((option: TroiDropdownListModel) => option.value === this.innerValue)[0].label;
    }

    return '';
  }

  public get labelInfo(): string {
    if (this.search && this.selectedOption) {
      return this.selectedOption.labelInfo;
    }

    if (this.options && this.options.filter((option: TroiDropdownListModel) => option.value === this.innerValue)[0]) {
      return this.options.filter((option: TroiDropdownListModel) => option.value === this.innerValue)[0].labelInfo;
    }

    return '';
  }

  private getMultipleSelectLabels = (options) => {
    let presents = [];

    _.forEach(options, (option) => {
      _.forEach(this.innerArray, (innerValue) => {
        if (this.returnSelectedObject && option.groupValues?.length > 0) {
          presents = union(presents, this.getMultipleSelectLabels(option.groupValues));
        } else {
          if (
            (this.returnSelectedObject && option.value === innerValue.value) ||
            (!this.returnSelectedObject && option.value === innerValue)
          ) {
            presents.push(option.label);
          }
        }
      });
    });

    return presents;
  };
  findItemByValue(value, tree) {
    for (const node of tree) {
      if (String(node.value) === String(value)) return node;
      if (node.groupValues && node.groupValues.length) {
        const child = this.findItemByValue(value, node.groupValues);
        if (child) return child;
      }
    }
  }

  selectInitValues(value): void {
    if (value && typeof value === 'string' && this.treeMode) {
      const ids = value.split(';');
      const objects = [];
      ids.forEach((id) => objects.push(this.findItemByValue(id, this.options)));
      objects.forEach((object) => {
        this.handleSelectItemForMultipleSelect(object);
      });
    }
  }

  getSelectedOption(id): TroiDropdownListModel {
    const option = _.filter(this.options, (singleOption: TroiDropdownListModel) => singleOption.value === id);

    return option ? option[0] : null;
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngOnChanges(changes) {
    if (changes.initValue && !_.isUndefined(changes.initValue.currentValue)) {
      this.value = changes.initValue.currentValue;
      this.selectedOption = this.getSelectedOption(this.innerValue);
      this.selectInitValues(this.innerValue);
    }
    if (changes.forceOpen) {
      this.open = this.forceOpen;
    }
    if (changes.reloadPreloadedOptions && changes.reloadPreloadedOptions.currentValue) {
      this.preloadOptionsForMultipleSelect(this.predefinedOptions);
    }

    if (this.allautoselected) {
      this.innerArray = [];
      this.toggleMultipleSelectValuesAsSelected(this._options);
    }
  }

  clearValue(event) {
    event.stopPropagation();
    if (this.multipleSelect || this.treeMode) {
      this.innerArray = [];
      this.label = '';
      this.areAllMultipleOptionsSelected = false;
    }
    this.value = this.defaultEmptyValue;
    this.optionList.hideList();
    this.selectedOption = this.value;
    this.selected.emit(this.value);
  }

  onLoadOptions(event) {
    this.loadOptions.emit(event);
  }

  onSearchOptions(event) {
    this.searchEvent.emit(event);
  }

  showClearButton(): boolean {
    if (!this.clearButton) {
      return false;
    }

    return (
      ((this.multipleSelect || this.treeMode) && this.value.length) ||
      (!this.multipleSelect && !this.treeMode && this.value !== this.defaultEmptyValue)
    );
  }

  @HostListener('keydown.space', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (this.search) {
      return;
    }
    this.forceOpen = !this.forceOpen;
    event.preventDefault();
    event.stopPropagation();
  }

  public ngOnDestroy(): void {
    this.resetMultipleSelectSubscription?.unsubscribe();
  }

  onLeftArrowClicked($event) {
    this.optionList.doSomethingAfterLeftArrowClick($event);
  }

  onRightArrowClicked($event) {
    this.optionList.doSomethingAfterRightArrowClick($event);
  }

  onKeyDown(event: KeyboardEvent) {
    if (!this.showArrow) {
      return;
    }

    switch (event.key) {
      case 'ArrowLeft':
        this.optionList.doSomethingAfterLeftArrowClick(event);
        break;
      case 'ArrowRight':
        this.optionList.doSomethingAfterRightArrowClick(event);
        break;
      default:
        break;
    }
  }

  isCursorInTextField = false;

  @HostListener('document:focusin', ['$event'])
  onFocusin(event: FocusEvent): void {
    this.isCursorInTextField = true;
  }

  @HostListener('document:focusout', ['$event'])
  onFocusout(event: FocusEvent): void {
    this.isCursorInTextField = false;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyEvent(event: KeyboardEvent) {
    if (this.isCursorInTextField) {
      return;
    }
    if (!this.showArrow) {
      return;
    }

    switch (event.key) {
      case 'ArrowLeft':
        this.optionList.doSomethingAfterLeftArrowClick(event);
        break;
      case 'ArrowRight':
        this.optionList.doSomethingAfterRightArrowClick(event);
        break;
    }
  }

  ngAfterViewInit() {
    this.element.nativeElement.focus();
  }
}
