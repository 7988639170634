import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BackendResponseInterface } from '../../../core/interfaces/backend.response.interface';
import { IUser } from '../../../core/user/IUser';

@Injectable()
export class SecurityCenterService {
  public constructor(private http: HttpClient) {}

  public getSecurityCenterData() {
    return this.http.get('/components/settings/security-center/currentData', {
      responseType: 'json',
    });
  }

  public saveOTPToken(token: string, secret: string) {
    return this.http.post<any>('/components/settings/security-center/saveOTP', { token, secret });
  }

  public deactivateOTPToken() {
    return this.http.post<any>('/components/settings/security-center/deactivateOTP', {});
  }

  public changePassword(currentPassword: string, newPassword1: string, newPassword2: string) {
    return this.http.post<any>('/components/settings/security-center/changePassword', {
      currentPassword,
      newPassword1,
      newPassword2,
    });
  }

  public refreshV2Token() {
    return this.http.post<any>('/components/settings/security-center/refreshV2Token', {});
  }

  public refreshV3Token() {
    return this.http.post<any>('/components/settings/security-center/refreshV3Token', {});
  }

  public generateWebauthnRequest() {
    return this.http.post<any>('/components/settings/security-center/generateWebauthnRequest', {});
  }

  public registerWebauthn(publicKey, name) {
    const headers = { 'content-type': 'application/json' };
    publicKey.name = name;
    const body = JSON.stringify(publicKey);
    return this.http.post<any>('/components/settings/security-center/registerWebauthn', body, {
      headers,
    });
  }

  public deleteWebauthnDevice(id: number) {
    return this.http.post<any>('/components/settings/security-center/deleteWebauthnDevice', { id });
  }
}
