<div
  class="calc-pos"
  [ngClass]="{
    'calc-pos__selected': calcPosition.id === selectedCalcualtionPosition.id
  }"
  (click)="onSelectPositionClick(calcPosition)"
>
  <div class="calc-pos__position">
    <div class="checkbox">
      <div
        [ngClass]="{
          checkbox__inner: calcPosition.id === selectedCalcualtionPosition.id
        }"
      ></div>
    </div>

    <span class="calc-pos__id">K-{{ calcPosition.id }}</span>
    <span title="{{ calcPosition.serviceHeadline }}" class="calc-pos__name">{{
      calcPosition.serviceHeadline
    }}</span>
  </div>
  <span *ngIf="helperService.isUnitTypeTime(calcPosition.unit?.unitType)">{{ helperService.formatQuantity(calcPosition.quantity, calcPosition.unit?.unitRate, calcPosition.unit?.unitType)}} h</span>
</div>
