<troi-modal-header>
  <span>{{ workLogDate | date : 'dd.MM.yyyy' }}</span>
</troi-modal-header>
<troi-modal-content style="overflow-y: inherit">
  <div>
    <tr-log-workingtime [LogDate]="workLogDate" [showAbsence]="true"></tr-log-workingtime>
  </div>
  <div class="modal-table">
    <tr-log-projecttime [LogDate]="workLogDate"></tr-log-projecttime>
  </div>
</troi-modal-content>
<troi-modal-footer> </troi-modal-footer>
