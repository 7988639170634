import { FolderFieldsModel } from './folder-fields.model';
import { TreeListItemModel } from '../../../../../../../shared/troi-tree-list/models/tree-list-item.model';
import { ProjectTypeEnum } from '../../../enums/project-type.enum';
import { LanguageTranslationsBackend } from '../../../../../../../core/interfaces/languageTranslations.backend';

export class FolderModel extends TreeListItemModel<ProjectTypeEnum> {
  private _shortcut: LanguageTranslationsBackend;

  constructor(
    public _id: string,
    public _order: number,
    public properties: FolderFieldsModel,
    public folderHasChildren: boolean,
    public customer: string | number,
  ) {
    super(_id, properties.name, _order);
    this._type = ProjectTypeEnum.FOLDER;
    this.shortcut = properties.shortcut;
    this._nodes = [];
    this.hasChildren = folderHasChildren;
  }

  get shortcut(): LanguageTranslationsBackend {
    return this._shortcut;
  }

  set shortcut(value: LanguageTranslationsBackend) {
    this._shortcut = value;
  }

  public isFolder = (): boolean => {
    return true;
  };

  public isCustomer = (): boolean => {
    return !this.customer;
  };

  get areChildrenLoaded(): boolean {
    return !this.folderHasChildren || (this.folderHasChildren && this.nodes.length > 0);
  }
}
