import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { TroiDropdownListModel } from '../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';

@Injectable()
export class ApprovalStateService {
  public methods = [
    {
      id: 'n',
      translationKey: 'Approval.filters.state.notStarted',
    },
    {
      id: 'p',
      translationKey: 'Approval.filters.state.pending',
    },
    {
      id: 'a',
      translationKey: 'Approval.filters.state.approved',
    },
    {
      id: 'd',
      translationKey: 'Approval.filters.state.declined',
    },
    {
      id: 'c',
      translationKey: 'Approval.filters.state.cancelled',
    },
  ];

  constructor(private translateService: TranslateService) {}

  buildApprovalStates(): Array<TroiDropdownListModel> {
    const data: Array<TroiDropdownListModel> = [];

    this.methods.forEach((single) => {
      data.push({
        label: this.translateService.instant(single.translationKey),
        value: single.id,
        active: true,
      });
    });

    // data.sort((a, b) => {
    //   return a.label.localeCompare(b.label);
    // });

    data.unshift({
      label: this.translateService.instant('Booking.labels.pleaseSelect'),
      value: '',
      active: true,
    });

    return data;
  }
}
