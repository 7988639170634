import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Routes } from '../enums/routes';
import { BackendResponseInterface } from '../../../core/interfaces/backend.response.interface';
import { SettingsResponseInterface } from '../interfaces/settings-response.interface';

@Injectable()
export class DeskNetwork {
  public constructor(public http: HttpClient) {}

  public downloadSettings(): Observable<BackendResponseInterface<SettingsResponseInterface>> {
    return this.http.get<BackendResponseInterface<SettingsResponseInterface>>(environment.url + Routes.SETTINGS);
  }
}
