import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { ModuleInterceptor } from '../../../../../../core/enums/module-interceptor';
import { Routes } from '../../../../enum/routes';
import { AssignmentResponseInterface } from '../../../../interfaces/responses.interface';
import { AssignmentInterface } from '../../../../interfaces/assignment.interface';

@Injectable()
export class AssignmentService {
  private headers: HttpHeaders;

  public constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');
  }

  // For now: no getter required as assignments get loaded with tasks

  public createAssignment(
    newAssignment: AssignmentInterface,
    isSubtask?: boolean,
  ): Observable<AssignmentResponseInterface> {
    if (isSubtask) {
      return this.http.post<AssignmentResponseInterface>(
        environment.url + Routes.SUBTASKS + '/assignment',
        newAssignment,
        {
          headers: this.headers,
        },
      );
    } else {
      return this.http.post<AssignmentResponseInterface>(environment.url + Routes.ASSIGNMENT, newAssignment, {
        headers: this.headers,
      });
    }
  }

  public updateAssignment(
    assignment: AssignmentInterface,
    isSubtask?: boolean,
  ): Observable<AssignmentResponseInterface> {
    if (isSubtask) {
      return this.http.put<AssignmentResponseInterface>(
        environment.url + Routes.SUBTASKS + `/assignment/${assignment.id}`,
        assignment,
        {
          headers: this.headers,
        },
      );
    } else {
      return this.http.put<AssignmentResponseInterface>(
        environment.url + Routes.ASSIGNMENT + `/${assignment.id}`,
        assignment,
        {
          headers: this.headers,
        },
      );
    }
  }

  public deleteAssignment(
    assignment: AssignmentInterface,
    isSubtask?: boolean,
  ): Observable<AssignmentResponseInterface> {
    if (isSubtask) {
      return this.http.delete<AssignmentResponseInterface>(
        environment.url + Routes.SUBTASKS + `/assignment/${assignment.id}`,
        {
          headers: this.headers,
        },
      );
    } else {
      return this.http.delete<AssignmentResponseInterface>(environment.url + Routes.ASSIGNMENT + `/${assignment.id}`, {
        headers: this.headers,
      });
    }
  }
}
