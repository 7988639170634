<div
  #ApprovalProcess="ngbPopover"
  [ngbPopover]="popContent"
  [popoverTitle]="popTitle"
  container="body"
  popoverClass="troi-popover approval-process left"
  triggers="manual"
  autoClose="outside"
  [placement]="placement"
>
  <div class="approval-process__trigger" (click)="toggleOverlay(ApprovalProcess)">
    <ng-content></ng-content>
  </div>
</div>
<ng-template #popTitle>
  <div class="d-flex">
    <h3 *ngIf="isStart" class="approval-process__title">
      {{ 'Approval.labels.startApproval' | translate }}
    </h3>
    <h3 *ngIf="isDelegate" class="approval-process__title">
      {{ 'Approval.labels.delegateApproval' | translate }}
    </h3>
    <h3 *ngIf="isEdit" class="approval-process__title">
      {{ 'Approval.labels.editApproval' | translate }}
    </h3>
  </div>
</ng-template>
<ng-template #popContent>
  <div>
    <div class="tnd-error-msg" *ngIf="approvalStatus?.actionErrorMessage">
      {{ approvalStatus.actionErrorMessage }}
    </div>
    <form [formGroup]="createForm" *ngIf="approvalStatus">
      <div
        class="approval-process__row approval-process__direct"
        *ngIf="isStart && approvalStatus?.employees?.length === 1"
      >
        <span>{{ 'Approval.labels.directApproval' | translate }}</span>
        <troi-switch formControlName="direct" [small]="true"></troi-switch>
      </div>
      <div *ngIf="!isDelegate && !createForm.get('direct').value">
        <div *ngFor="let employee of employees" class="approval-process__row">
          <div *ngIf="approvalStatus.employees.length > 1" class="approval-process__level">
            {{ employee.level_name }}
          </div>
          <tnd-select-box
            [options]="employee.employees"
            [initialId]="employee.selectedEmployeeId"
            placeholder="{{ 'Approval.labels.employee' | translate }}"
            (valueSelected)="onSelectEmployee($event, employee.level)"
            icon="icon-empty-person"
            class="approval-process__select"
          ></tnd-select-box>
        </div>
      </div>
      <div *ngIf="isDelegate">
        <div *ngFor="let employee of employees" class="approval-process__row">
          <tnd-select-box
            [options]="employee.employees"
            [initialId]="employee.selectedEmployeeId"
            placeholder="{{ 'Approval.labels.employee' | translate }}"
            (valueSelected)="onSelectEmployee($event, employee.level)"
            icon="icon-empty-person"
            class="approval-process__select"
          ></tnd-select-box>
        </div>
      </div>
      <div *ngIf="approvalStatus?.settings?.showDescriptionFieldInApprovalProcess">
        <troi-textfield
          formControlName="description"
          [placeholder]="'PriceList.labels.description' | translate"
          [icon]="'icon-empty-bubble'"
          [iconSide]="'left'"
          [multiline]="true"
        ></troi-textfield>
      </div>
      <div class="d-flex popover-footer">
        <troi-btn
          class="approval-process__cancel"
          (click)="toggleOverlay(ApprovalProcess)"
          [outline]="true"
          >{{ 'Approval.labels.cancel' | translate }}</troi-btn
        >
        <troi-btn
          class="approval-process__accept"
          *ngIf="isStart"
          (click)="onStartApproval(ApprovalProcess)"
          >{{ 'Approval.labels.startApproval' | translate }}</troi-btn
        >
        <troi-btn
          class="approval-process__accept"
          *ngIf="isDelegate"
          (click)="onDelegateApproval(ApprovalProcess)"
          >{{ 'Approval.labels.delegateApproval' | translate }}</troi-btn
        >
        <troi-btn
          class="approval-process__accept"
          *ngIf="isEdit"
          (click)="onEditApproval(ApprovalProcess)"
          >{{ 'Approval.labels.saveChanges' | translate }}</troi-btn
        >
      </div>
    </form>
  </div>
</ng-template>
