import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'troi-range-string',
  templateUrl: './troi-range-string.component.html',
  styleUrls: ['./troi-range-string.component.scss'],
})
export class TroiRangeStringComponent {
  @Input() disabled = false;

  @Input() public rangeFrom: string;

  @Input() public rangeTo: string;

  @Input() public year: string;

  @Output() public rangeChanged = new EventEmitter();

  ranges: any;

  yearSign() {
    if (!this.year) {
      return '';
    }
    return this.year.length ? (parseInt(this.year) % 2000).toString() : '';
  }

  changed(value, index) {
    if (index === 1) {
      this.rangeTo = value === '' ? null : value;
    } else {
      this.rangeFrom = value === '' ? null : value;
    }
    this.rangeChanged.emit([this.rangeFrom, this.rangeTo]);
  }
}
