import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { TaskInterface } from '../../../../interfaces/task.interface';

@Injectable()
export class TaskFormService {
  public submitted = false;
  public pending = false;

  constructor() {}

  public initForm(task: TaskInterface, formControls?: { [key: string]: AbstractControl }): FormGroup {
    const baseFormControls = formControls || {
      title: new FormControl(task.title, Validators.required),
    };

    return new FormGroup({
      ...baseFormControls,
    });
  }
}
