import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '../troi-modals/modal.service';

@Component({
  selector: 'troi-modal-header',
  templateUrl: './troi-modal-header.component.html',
  styleUrls: ['./troi-modal-header.component.scss'],
})
export class TroiModalHeaderComponent {
  @Input() public noCloseButton = false;

  @Input() public askBeforeClose = false;

  @Input() public isSecond = false;

  @Output() public modalWantClose = new EventEmitter();

  constructor(public modalService: ModalService) {}

  public close() {
    if (this.askBeforeClose) {
      this.modalWantClose.emit(true);
    } else {
      this.modalService.destroy(this.isSecond);
    }
  }
}
