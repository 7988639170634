import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Placement } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgEventBus } from 'ng-event-bus';
import { Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StorageNotificationService } from '../../../app/core/notifications/storageNotification.service';
import { ISpreadResponse } from '../../../app/modules/time-recording/interfaces/spread.interface';
import { StorageKeys } from '../../core/storage/storage.keys';
import { StorageService } from '../../core/storage/storage.service';
import { DeskSettingsService } from '../../modules/desk/services/desk-settings.service';
import { RecordProjectTimeService } from '../../modules/desk/widgets/popover/record-project-time/record-project-time.service';
import { IBoilerPlatesFormatted } from '../../modules/time-recording/interfaces/boilerPlates.interface';
import { TimeRecordingService } from '../../modules/time-recording/time-recording.service';
import { TroiDropdownListModel } from '../troi-dropdown-list/models/troi-dropdown-list.model';
import { RangeDateChangedInterface } from '../troi-range-date/troi-range-date.component';
@Component({
  selector: 'troi-record-project-time-table',
  templateUrl: './troi-record-project-time-table.component.html',
  styleUrls: ['./troi-record-project-time-table.component.scss'],
})
export class TroiRecordProjectTimeTableComponent implements OnInit, AfterViewInit {
  public isEditable = false;
  public createForm = new UntypedFormGroup({
    calculationPosition: new UntypedFormControl(''),
    date: new UntypedFormControl('', [Validators.required]),
    workTime: new UntypedFormControl('', [Validators.required]),
    actualHours: new UntypedFormControl(''),
    estimation: new UntypedFormControl(''),
    internalComment: new UntypedFormControl(''),
    externalComment: new UntypedFormControl(''),
    billable: new UntypedFormControl(''),
    alreadyAdded: new UntypedFormControl(false),
    billingId: new UntypedFormControl(''),
  });

  public errorMessages = {
    workTime: 'Desktop.Widgets.Projecttimes.Error.RequiredFields.WorkTime',
    internalComment: 'Internal comment is required',
    externalComment: 'External comment is required',
  };
  isTimeRecordingExternalCommentRequired = false;
  isTimeRecordingInternalCommentRequired = false;
  isEnableTimerecordingAdditionalFields = false;
  isRestExpenseEstimationActive = false;
  isEnabledBoilerPlatesInComments = false;
  ExternalCommentErrorShow = false;
  InternalCommentErrorShow = false;
  showError = false;
  showErrorMessage: string;
  url: any;

  public boilerPlates!: IBoilerPlatesFormatted;

  public saving = false;
  public loading = false;
  public loadedProjects = true;

  public fromDate = 0;
  public toDate = 0;
  public spreadFromDate = 0;
  public spreadToDate = 0;
  public spreadRangeValid = false;
  public backwardDays!: number;
  public totalbillingdata: any[];
  currentPageData: any[];
  itemsPerPage = 1;
  currentPage = 1;
  apiPageNumber = 0;
  public serchProjectData: Array<TroiDropdownListModel> = [];

  public minDate!: Moment;
  public maxDate!: Moment;

  @Input() placement: Placement[] = ['top', 'bottom', 'auto'];
  @Input() cpId = '';
  @Input() isBillable = false;
  @Input() dateFor;
  @Input() projectItem;
  @Input() billingQuantity;
  @Input() color;
  @Input() trackedMoreHours = false;
  @Input() userId;
  @Input() bypassEditTimeCheck = false;
  @Input() apeBrand!: string;
  @Input() public weekStart!: string;
  @Input() public weekEnd!: string;

  @Output() openStateChanged = new EventEmitter<boolean>();
  @Output() loggedWork = new EventEmitter<string>();

  searchInput = new Subject<string>();
  searchProject = '';
  showReplaceProject = false;
  public disableBillable = false;

  constructor(
    private storageService: StorageService,
    private service: RecordProjectTimeService,
    private changeDetectorRef: ChangeDetectorRef,
    private eventBus: NgEventBus,
    public deskSettingsService: DeskSettingsService,
    public timeService: TimeRecordingService,
    private ngZone: NgZone,
    private notificationService: StorageNotificationService,
    private translate: TranslateService,
  ) {
    this.searchInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(() => {
      if (this.searchProject.length > 2 || this.searchProject.length === 0) {
        this.emitFiltersChanged();
      }
    });

    this.fromDate = Date.now();
    this.toDate = Date.now();

    this.updateSettings();
  }

  public async updateSettings(): Promise<void> {
    await new Promise<void>((resolve) => {
      this.deskSettingsService.getSettingsCache(true).subscribe((settings) => {
        this.backwardDays = this.deskSettingsService.settings?.settings?.backwardBillingsCollectionDays;

        if (this.backwardDays !== -1) {
          const minDate = new Date();
          minDate.setDate(minDate.getDate() - this.backwardDays);
          this.minDate = moment(minDate);

          const maxDate = new Date();
          maxDate.setDate(maxDate.getDate());
          this.maxDate = moment(maxDate);
        }

        this.disableBillable = this.timeService.isHideBillableCheckboxInTimeRecording();
        this.updateSpreadDates();
        resolve();
      });
    });
  }

  private updateSpreadDates(): void {
    const today = new Date();
    let monday = new Date();
    let sunday = new Date();

    if (this.weekStart && this.weekEnd) {
      monday = new Date(this.weekStart);
      sunday = new Date(this.weekEnd);
    } else {
      monday.setDate(today.getDate() - today.getDay() + 1);
      sunday.setDate(today.getDate() - today.getDay() + 7);
    }

    if (this.backwardDays === -1) {
      this.spreadFromDate = monday.getTime();
      this.spreadToDate = sunday.getTime();
    } else {
      const fromDate = new Date();
      fromDate.setDate(today.getDate() - this.backwardDays);

      if (fromDate.getTime() < monday.getTime()) {
        this.spreadFromDate = monday.getTime();
      } else {
        this.spreadFromDate = fromDate.getTime();
      }

      if (today.getTime() > sunday.getTime()) {
        this.spreadToDate = sunday.getTime();
      } else {
        this.spreadToDate = today.getTime();
      }
    }

    this.validateSpreadRange();
  }

  public validateSpreadRange(): void {
    const diff = Math.abs(this.spreadToDate - this.spreadFromDate);
    const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
    this.spreadRangeValid = diffDays <= 30;
  }

  ngOnInit(): void {
    this.totalbillingcount(1);
  }

  public ngAfterViewInit(): void {
    this.initForm();
    this.setFormValidation();
  }

  private initForm(): void {
    this.createForm.reset();
    const cp = this.createForm.controls['calculationPosition'];
    const billable = this.createForm.controls['billable'];
    const workTime = this.createForm.controls['workTime'];

    if (cp instanceof UntypedFormControl) {
      cp.setValue(this.cpId);
    }

    if (billable instanceof UntypedFormControl) {
      billable.setValue(this.isBillable);
    }

    this.totalbillingcount(1);
  }

  totalbillingcount(page: number) {
    this.totalbillingdata = this.projectItem.billingsData.billings.filter(
      (billing) => billing.billingDate === this.dateFor,
    );

    this.totalbillingdata.forEach((item, index) => {
      item.alreadyAdded = true;
    });

    this.currentPageData = this.totalbillingdata.slice(0, this.itemsPerPage);
    this.changePage(page);
  }

  changePage(pageNumber: number) {
    const startIndex = (pageNumber - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.currentPageData = this.totalbillingdata.slice(startIndex, endIndex);
    this.currentPage = pageNumber;

    if (this.currentPageData.length > 0) {
      const firstItem = this.currentPageData[0];
      this.updateFormControlsFromPageData(firstItem);
    }
  }

  updateFormControlsFromPageData(pageData: any) {
    this.createForm.controls['internalComment'].setValue(pageData.billingInternalComment);
    this.createForm.controls['externalComment'].setValue(pageData.billingExternalComment);
    this.createForm.controls['workTime'].setValue(this.timeService.formatTime(pageData.billingQuantity));

    if (this.isEnableTimerecordingAdditionalFields) {
      this.createForm.controls['actualHours'].setValue(this.timeService.formatTime(pageData.billingActualHours));
    }

    if (this.isRestExpenseEstimationActive) {
      this.createForm.controls['estimation'].setValue(this.timeService.formatTime(pageData.billingEstimation));
    }

    this.createForm.controls['alreadyAdded'].setValue(true);
    this.createForm.controls['billingId'].setValue(pageData.billingId);
    this.createForm.controls['billable'].setValue(pageData.billingIsBillable);
    this.isBillable = pageData.billingIsBillable;
  }

  addnewtrackedhours() {
    this.createForm.controls['internalComment'].setValue('');
    this.createForm.controls['externalComment'].setValue('');
    this.createForm.controls['workTime'].setValue('');

    if (this.isEnableTimerecordingAdditionalFields) {
      this.createForm.controls['actualHours'].setValue('');
    }

    if (this.isRestExpenseEstimationActive) {
      this.createForm.controls['estimation'].setValue('');
    }

    this.createForm.controls['alreadyAdded'].setValue(false);
    this.createForm.controls['billingId'].setValue('');
    this.createForm.controls['billable'].setValue(false);

    this.currentPage = 0;

    this.showError = false;
    this.showErrorMessage = '';
  }

  getPaginationArray() {
    const pageCount = Math.ceil(this.totalbillingdata.length / this.itemsPerPage);
    return Array(pageCount)
      .fill(0)
      .map((_, index) => index + 1);
  }

  public isRequiredField(field: string) {
    const formField = this.createForm.get(field);
    if (!formField.validator) {
      return false;
    }
    const validator = formField.validator({} as AbstractControl);
    return validator && validator.required;
  }

  private setFormValidation(): void {
    const timerecordingSettings = this.storageService.getItem(StorageKeys.TIMERECORDING_SETTINGS);

    if (timerecordingSettings) {
      const settingsToCheck = [
        'isTimeRecordingExternalCommentRequired',
        'isTimeRecordingInternalCommentRequired',
        'isEnableTimerecordingAdditionalFields',
        'isRestExpenseEstimationActive',
        'isEnabledBoilerPlatesInComments',
      ];

      settingsToCheck.forEach((setting) => {
        if (timerecordingSettings.settings[setting]) {
          this[setting] = true;
        }
      });

      if (this.isEnabledBoilerPlatesInComments) {
        this.fillBoilerPlates();
      }
    }
  }

  private setFieldAsRequired(fieldName): void {
    const field = this.createForm.get(fieldName);
    field.setValidators([Validators.required]);
    field.updateValueAndValidity();
  }

  public isErrorField(field: string) {
    const formField = this.createForm.get(field);
    return formField.dirty && !formField.valid;
  }

  public getErrorForField(field: string): string {
    const controls = this.createForm.controls;
    if (controls[field].invalid && controls[field].dirty) {
      return this.errorMessages[field];
    }
    return '';
  }

  public toggleOverlay(popover): void {
    this.showError = false;
    this.showErrorMessage = '';
    this.isEditable = this.timeService.checkIsEditable(this.dateFor, 'P');
    this.showReplaceProject = false;

    if (!this.isEditable && !this.bypassEditTimeCheck) {
      return;
    }

    this.ExternalCommentErrorShow = false;
    this.InternalCommentErrorShow = false;

    if (popover.isOpen()) {
      this.closeOverlay(popover);
    } else {
      this.openOverlay(popover);
    }
  }

  openOverlay(popover): void {
    if (popover.isOpen()) {
      return;
    }
    this.initForm();
    this.saving = false;

    popover.open();

    this.ExternalCommentErrorShow = false;
    this.InternalCommentErrorShow = false;

    if (this.dateFor) {
      this.fromDate = new Date(this.dateFor).valueOf();
      this.toDate = new Date(this.dateFor).valueOf();
    }
    this.openStateChanged.emit(true);
  }

  closeOverlay(popover): void {
    if (!popover.isOpen() || this.saving) {
      return;
    }
    popover.close();
    this.resetAndReload();
  }

  dateChanged(pickedDate: any): void {
    if ('undefined' === typeof pickedDate.date) {
      return;
    }
    const date: number = pickedDate.date;
    const dateFromatted: string = format(new Date(date[0]), 'yyyy-MM-dd');
    this.createForm.controls['date'].setValue(dateFromatted);
  }

  onCancel(popover): void {
    this.closeOverlay(popover);
  }

  onHidden(): void {
    this.resetAndReload();
  }

  resetAndReload() {
    this.createForm.reset();
    // this.timeService.reloading.next(true);
    this.showError = false;
    this.showErrorMessage = '';
    this.ExternalCommentErrorShow = false;
    this.InternalCommentErrorShow = false;
    this.openStateChanged.emit(false);
  }

  onSubmit(popover): void {
    if (!this.canSaveForm()) {
      return;
    }

    this.markFormAsDirty();

    if (!this.isFormValid()) {
      return;
    }

    this.saving = true;

    if (this.createForm.value.billingId !== '' && this.createForm.value.billingId !== null) {
      const billingId = this.createForm.value.billingId;

      delete this.createForm.value.alreadyAdded;
      delete this.createForm.value.billingId;

      this.timeService
        .logWorkTime(this.createForm, '', billingId, 'PUT', this.userId, this.apeBrand)
        .subscribe((response) => {
          this.afterSave(response.success, popover, response.errorMessage);
        });
    } else {
      delete this.createForm.value.alreadyAdded;
      delete this.createForm.value.billingId;

      this.timeService.logWorkTime(this.createForm, '', '', 'POST', this.userId).subscribe((response) => {
        this.afterSave(response.success, popover, response.errorMessage);
      });
    }
    return;
  }

  private canSaveForm(): boolean {
    if (!this.timeService.checkIsEditable(this.createForm.value.date, 'P') && !this.bypassEditTimeCheck) {
      this.showError = true;
      this.notificationService.showError(this.translate.instant('Timerecording.you_can_not_add_log_for_this_date'));
      return false;
    }

    if (!this.userId) {
      return false;
    }

    if (this.saving) {
      return false;
    }

    return true;
  }

  private markFormAsDirty(): void {
    this.createForm.get('calculationPosition').markAsDirty();
    this.createForm.get('date').markAsDirty();
    this.createForm.get('workTime').markAsDirty();

    if (this.isEnableTimerecordingAdditionalFields) {
      this.createForm.get('actualHours').markAsDirty();
    }

    if (this.isRestExpenseEstimationActive) {
      this.createForm.get('estimation').markAsDirty();
    }

    this.createForm.get('internalComment').markAsDirty();
    this.createForm.get('externalComment').markAsDirty();
    this.createForm.get('billable').markAsDirty();
    this.createForm.get('billable').setValue(this.isBillable);
  }

  private isFormValid(): boolean {
    const externalRequired = this.externalCommentRequired();
    const internalRequired = this.internalCommentRequired();

    if (!externalRequired || !internalRequired) {
      return false;
    }

    if (!this.createForm.valid) {
      return false;
    }

    return true;
  }

  private afterSave(success: boolean | string, popover: any, errorMessage?: string, skipNotification = false): void {
    if (!skipNotification) {
      if (success) {
        this.notificationService.showSuccess(this.translate.instant('Common.labels.dataSuccessfullySaved'));
      } else {
        this.notificationService.showError(errorMessage || this.translate.instant('Common.error.general'));
      }
    }
    this.saving = false;
    this.initForm();
    this.loggedWork.emit(success as string);
    this.eventBus.cast('app:project-time:logged');
    this.timeService.reloading.next(true);
    this.showError = false;
    this.showErrorMessage = '';
    this.closeOverlay(popover);
  }

  externalCommentRequired(): boolean {
    if (this.isTimeRecordingExternalCommentRequired) {
      const externalCommentControl = this.createForm.get('externalComment');
      if (!externalCommentControl.value || externalCommentControl.value.trim() === '') {
        this.ExternalCommentErrorShow = true;
        return false;
      } else {
        this.ExternalCommentErrorShow = false;
        return true;
      }
    } else {
      return true;
    }
  }

  internalCommentRequired(): boolean {
    if (this.isTimeRecordingInternalCommentRequired) {
      const internalCommentControl = this.createForm.get('internalComment');
      if (!internalCommentControl.value || internalCommentControl.value.trim() === '') {
        this.InternalCommentErrorShow = true;
        return false;
      } else {
        this.InternalCommentErrorShow = false;
        return true;
      }
    } else {
      return true;
    }
  }

  deleteLog(pageIndex: number): void {
    const confirmDelete = confirm(this.translate.instant('Timerecording.Are_you_sure_you_want_to_delete_this_project'));
    if (!confirmDelete) {
      return;
    }
    const Item = this.totalbillingdata[pageIndex - 1];
    if (Item) {
      this.url = this.timeService.deleteProjectTimeLogData(this.userId, Item.billingId);
      if (this.url) {
        this.url.subscribe(
          (response: any) => {
            this.notificationService.showSuccess(this.translate.instant('Common.labels.dataSuccessfullyDeleted'));
            this.timeService.reloading.next(true);
            this.showError = false;
            this.showErrorMessage = '';
          },
          (error: any) => {
            this.showError = true;
            this.notificationService.showError(this.translate.instant('Common.error.general'));
          },
        );
      }
    }
  }

  emitFiltersChanged() {
    this.serchProjectData = [];
    this.apiPageNumber = 0;
    if (this.searchProject.length < 3) {
      this.searchProject = '';
      this.serchProjectData = [];
    } else {
      this.getSearchProjects();
    }
  }

  /* project */
  onProjectSearchStringChanged(term: string): void {
    this.searchProject = term;
    this.serchProjectData = [];
    this.apiPageNumber = 0;
    if (this.searchProject.length < 3) {
      this.searchProject = '';
      this.serchProjectData = [];
    } else {
      this.getSearchProjects();
    }
  }

  getSearchProjects() {
    const params = {
      simpleSearch: this.searchProject,
      page: this.apiPageNumber,
    };

    this.loadedProjects = false;
    this.loading = true;
    this.timeService.getMyProjects(params).subscribe(
      (response: any) => {
        this.loading = false;
        const result = [];
        for (const projectName of response.entries) {
          result.push({
            id: projectName.cp.cpId,
            name: projectName.cp.cpPathHeader + ' / ' + projectName.cp.cpPathTail,
          });
        }
        this.serchProjectData = this.serchProjectData.concat(result);
        this.loadedProjects = true;
      },
      (error) => {
        this.loading = false;
      },
    );
  }

  onProjectSelected(project) {
    if (!project) {
      project = {};
    }
    if (project.id && project.id > 0) {
      const confirmDelete = confirm(this.translate.instant('Timerecording.are_you_sure_want_to_repace_this_project'));
      if (!confirmDelete) {
        return;
      }
      this.ReplaceProjectAndDeleteOld(project.id);
    }
  }

  ReplaceProjectAndDeleteOld(newId) {
    const replaceBillingId = this.createForm.value.billingId;
    this.createForm.value.calculationPosition = newId;
    delete this.createForm.value.alreadyAdded;
    delete this.createForm.value.billingId;
    /* New Data Save */
    this.loading = true;
    this.timeService
      .logWorkTime(this.createForm, '', replaceBillingId, 'PUT', this.userId)
      .subscribe((responseCheck) => {
        this.loading = false;
        if (responseCheck.success) {
          this.timeService.reloading.next(true);
        } else {
          alert(this.translate.instant('Common.error.general'));
        }
      });
  }

  showReplaceProjectFunctionality() {
    this.showReplaceProject = true;
  }

  hideReplaceProjectFunctionality() {
    this.showReplaceProject = false;
  }

  public updateIsBillable(isBillable: boolean): void {
    this.isBillable = isBillable;
    this.createForm.controls['billable'].setValue(isBillable);
  }

  public async fillBoilerPlates(): Promise<void> {
    try {
      const response = await new Promise<IBoilerPlatesFormatted>((resolve) => {
        this.timeService.getBoilerPlates().subscribe((data) => {
          resolve(data);
        });
      });
      if (response) {
        this.boilerPlates = response;
      } else {
        this.notificationService.showError(this.translate.instant('Common.error.general'));
      }
    } catch (error) {
      this.notificationService.showError(this.translate.instant('Common.error.general'));
    }
  }

  public spreadDateChanged(pickedDate: RangeDateChangedInterface): void {
    if (pickedDate.isValid && pickedDate.date.length === 2 && pickedDate.date[0] && pickedDate.date[1]) {
      this.spreadFromDate = pickedDate.date[0];
      this.spreadToDate = pickedDate.date[1];
    }

    this.validateSpreadRange();
  }

  public saveAndSpread(popover: any): void {
    if (!this.canSaveForm()) {
      return;
    }

    this.markFormAsDirty();

    if (!this.isFormValid()) {
      return;
    }

    this.saving = true;

    this.executeSpread(popover);
  }

  private executeSpread(popover: any) {
    const billingId = this.createForm.value.billingId ?? '';

    delete this.createForm.value.alreadyAdded;
    delete this.createForm.value.billingId;

    this.timeService
      .logWorkTime(this.createForm, '', billingId, billingId === '' ? 'POST' : 'PUT', this.userId, this.apeBrand)
      .pipe(
        catchError((error) => {
          this.notificationService.showError(this.translate.instant('Common.error.general'));
          this.afterSave(false, popover, this.translate.instant('Common.error.general'), true);
          return [];
        }),
      )
      .subscribe((response) => {
        if (!response.success) {
          if (response.errorMessage.includes('_')) {
            this.notificationService.showError(
              this.translate.instant(`Timerecording.log.error.${response.errorMessage}`),
            );

            return this.afterSave(response.success, popover, null, true);
          }

          return this.afterSave(response.success, popover, response.errorMessage, false);
        }

        this.timeService
          .spreadBilling(
            billingId === '' ? response.billing.billingId : billingId,
            this.spreadFromDate,
            this.spreadToDate,
            this.userId,
          )
          .pipe(
            catchError((error) => {
              this.notificationService.showError(this.translate.instant('Common.error.general'));
              this.afterSave(false, popover, null, true);
              return [];
            }),
          )
          .subscribe((spreadResponse: ISpreadResponse) => {
            if (!spreadResponse.success && spreadResponse.errorMessage) {
              this.notificationService.showError(
                this.translate.instant(`Timerecording.spread.error.${spreadResponse.errorMessage}`),
              );
              return this.afterSave(spreadResponse.success, popover, null, true);
            }

            this.notificationService.showSuccess(
              this.translate.instant('Timerecording.spread.success', {
                days_created: spreadResponse.created.length,
              }),
            );

            this.afterSave(spreadResponse.success, popover, null, true);
          });
      });
  }
}
