import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'troi-btn',
  templateUrl: './troi-btn.component.html',
  styleUrls: ['./troi-btn.component.scss'],
})
export class TroiBtnComponent {
  @Input() public disabled = false;

  @Input() public submit = true;

  @Input() public outline = false;

  @Input() public green = false;

  @Input() public red = false;

  @Input()
  public set small(value: boolean) {
    this._small = coerceBooleanProperty(value);
  }
  public get small(): boolean {
    return this._small;
  }
  private _small = false;

  @Input()
  public set noBackground(value: boolean) {
    this._noBackground = coerceBooleanProperty(value);
  }
  public get noBackground(): boolean {
    return this._noBackground;
  }
  private _noBackground = false;
}
