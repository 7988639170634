import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommentInterface } from '../../../../../../interfaces/comment.interface';
import { CommentsService } from '../../../../../../network/comments.service';
import { UserService } from '../../../../../../../../core/user/user.service';
import { AttachmentInterface } from '../../../../../../interfaces/attachment.interface';
import { TaskInterface } from '../../../../../../interfaces/task.interface';
import { PersonInterface } from '../../../../../../../../shared/troi-person/interfaces/person.interface';

@Component({
  selector: 'troi-task-modal-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit, OnDestroy {
  @Input() comment: CommentInterface;
  @Input() isAnswer = false;
  @Input() task: TaskInterface;
  @Output() reply = new EventEmitter<CommentInterface>();
  @Output() commentsChanged = new EventEmitter<CommentInterface>();
  private subscriptions: Subscription = new Subscription();
  public showTrashIcon: boolean;

  public creator: PersonInterface;

  constructor(private commentService: CommentsService, private userService: UserService) {}

  ngOnInit() {
    this.convertCreatorToPerson();
  }

  public convertCreatorToPerson() {
    this.creator = {
      firstName: (this.comment.createdBy as PersonInterface).firstName,
      lastName: (this.comment.createdBy as PersonInterface).lastName,
      image: (this.comment.createdBy as PersonInterface).image,
    } as PersonInterface;
  }

  public onDeleteCommentClick(commentId: string) {
    this.subscriptions.add(
      this.commentService.deleteComment(commentId, this.isAnswer).subscribe(() => {
        this.commentsChanged.emit();
      }),
    );
  }

  public onLikeClick() {
    const userId = this.userService.getUser().id;
    if (!this.comment.likeDetails) this.comment.likeDetails = [];

    if (this.userHasLiked()) {
      const userLike = this.comment.likeDetails.find((like) => like.liker.id === userId);
      this.commentService.deleteCommentLike(userLike.id, this.isAnswer).subscribe((res) => {
        this.commentsChanged.emit();
      });
    } else {
      this.commentService.createCommentLike(this.comment, this.isAnswer).subscribe((res) => {
        this.commentsChanged.emit();
      });
    }
  }

  public userHasLiked() {
    const userId = this.userService.getUser().id;
    return this.comment.likeDetails && this.comment.likeDetails.some((like) => like.liker.id === userId);
  }

  public toggleTrashIcon() {
    const currentUser = this.userService.getUser();
    if (currentUser.id === this.comment.createdBy['id']) this.showTrashIcon = !this.showTrashIcon;
  }

  public formatDate(dateString: Date): string {
    const datum = new Date(dateString);

    const tag = datum.getDate();
    const monat = datum.getMonth() + 1;
    const jahr = datum.getFullYear();
    const stunde = datum.getHours();
    const minute = datum.getMinutes();

    const formatierterString = `${tag.toString().padStart(2, '0')}.${monat
      .toString()
      .padStart(2, '0')}.${jahr} um ${stunde.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} Uhr`;

    return formatierterString;
  }

  public onFileDelete(attachment: AttachmentInterface) {
    this.comment.attachments = this.comment.attachments.filter((x: AttachmentInterface) => x.id !== attachment.id);
  }

  public onReplyClick() {
    this.reply.emit(this.comment);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
