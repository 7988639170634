import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { StorageKeys } from '../../../core/storage/storage.keys';
import * as _ from 'lodash';
import { StorageService } from '../../../core/storage/storage.service';
import { DeskNetwork } from '../network/desk.network';
import { map, shareReplay } from 'rxjs/operators';
import { BackendResponseInterface } from '../../../core/interfaces/backend.response.interface';
import { SettingsResponseInterface } from '../interfaces/settings-response.interface';
import { SettingsModel } from '../models/settings.model';
import { TroiNumberConfigInterface } from '../../../core/pipes/number.pipe';

@Injectable()
export class DeskSettingsService {
  public settings: SettingsModel;

  private maxCacheAge = 5000;
  private settingsCache$!: Observable<SettingsModel>;
  private lastSettingsCache = 0;

  public constructor(private storageService: StorageService, private deskNetwork: DeskNetwork) {}

  public get numberConfig(): TroiNumberConfigInterface {
    return {
      digitsInfo: `1.0-${this.settings.settings.decimalPlaces}`,
      decimalMark: this.settings.settings.decimalMark,
      thousandsMark: this.settings.settings.thousandsMark,
    };
  }

  public getSettingsCache(bypassStorage: boolean = false): Observable<SettingsModel> {
    if (!this.settingsCache$ || Date.now() - this.lastSettingsCache > this.maxCacheAge) {
      this.settingsCache$ = this.getSettings(bypassStorage).pipe(shareReplay(1));
      this.lastSettingsCache = Date.now();
    }

    return this.settingsCache$;
  }

  public getSettings(force: boolean = false): Observable<any> {
    let settings = this.storageService.getItem(StorageKeys.DESK_SETTINGS) as SettingsModel;
    this.storageService.removeItem(StorageKeys.DESK_SETTINGS);
    if (force || _.isNull(settings)) {
      return this.downloadSettings();
    }

    settings = this.mapToSettingsModel(settings);
    this.saveSettingToLS(settings);
    this.settings = settings;

    return new Observable((observer: Subscriber<SettingsModel>) => {
      observer.next(settings);
    });
  }

  public saveSettingToLS(settings: any): void {
    this.storageService.setItem(StorageKeys.DESK_SETTINGS, settings);
  }

  private downloadSettings(): Observable<any> {
    return this.deskNetwork.downloadSettings().pipe(
      map((response: BackendResponseInterface<SettingsResponseInterface>) => {
        const model = this.createSettings(response.data);

        this.settings = model;
        this.storageService.setItem(StorageKeys.DESK_SETTINGS, response.data);

        return model;
      }),
    );
  }

  private createSettings(settingsData: SettingsResponseInterface): SettingsModel {
    return new SettingsModel(settingsData.settings);
  }

  private mapToSettingsModel = (modelFromLocalStorage: SettingsModel): SettingsModel => {
    return new SettingsModel(modelFromLocalStorage.settings);
  };
}
