import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { TroiDropdownListModel } from '../../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { BaseModalDirective } from '../../../../../shared/troi-base-modal/baseModal.component';
import { ModalService } from '../../../../../shared/troi-modals/modal.service';
import { LanguagesService } from '../../../../../core/services/languages.service';
import * as _ from 'lodash';
import { TimeRecordingService } from '../../../time-recording.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, firstValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { StorageNotificationService } from '../../../../../../app/core/notifications/storageNotification.service';
import { SearchProjectService } from '../../../../desk/widgets/popover/search-project/search-project.service';
import { BookingSettingsService } from '../../../../bookings/common/services/booking-settings.service';

@Component({
  selector: 'tr-assign-project-model',
  templateUrl: './tr-assign-project-model.component.html',
  styleUrls: ['./tr-assign-project-model.component.scss'],
})
export class TrAssignProjectModelComponent extends BaseModalDirective implements OnInit {
  url: any;
  public loading = false;
  public modalObject: any;
  public initValue: number;
  public userId: number;
  public filters: any = {
    search: '',
    client: '',
  };

  public activeTab: any = {
    assign: true,
    unassign: false,
  };
  selectAll = false;
  searchInput = new Subject<string>();
  assignedProjectData: any[] = [];
  unassignedProjectData: any[] = [];
  originalassignedProjectData: any[] = [];
  originalunassignedProjectData: any[] = [];
  checkedProjects: any[] = [];
  private clients: {
    id: number;
    name: string;
  }[] = [];

  constructor(
    public modalService: ModalService,
    public languagesService: LanguagesService,
    public timeService: TimeRecordingService,
    private translate: TranslateService,
    private notificationService: StorageNotificationService,
    private searchProjectService: SearchProjectService,
    private bookingSettingsService: BookingSettingsService,
  ) {
    super(modalService);
    this.modalObject = this.modalService.object;

    this.searchInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(() => {
      if (this.filters.search.length > 2 || this.filters.search.length === 0) {
        this.searchChanged();
      }
    });
  }

  ngOnInit(): void {
    this.userId = this.modalObject.employeeId;
    this.activeTab.assign = true;
    this.activeTab.unassign = false;
    this.getClients();
  }

  private async getClients(): Promise<void> {
    this.clients = await firstValueFrom(
      this.searchProjectService
        .searchClients('')
        .pipe(map((clients) => clients.map((client) => ({ id: Number(client.id), name: client.name })))),
    );
    if (this.clients.length > 0) {
      this.filters.client = (await firstValueFrom(this.getMainClient()))?.client;
    }
    this.getProjectData();
  }

  getProjectData() {
    if (!this.userId) {
      return;
    }
    this.assignedProjectData = [];
    this.unassignedProjectData = [];
    this.loading = true;
    this.timeService.getAssignedAndUnassigned(this.userId, this.filters.client).subscribe(async (response: any) => {
      this.loading = false;
      if (response.assigned) {
        this.assignedProjectData = response.unassigned;
        this.originalassignedProjectData = [...this.assignedProjectData];
      }
      if (response.unassigned) {
        this.unassignedProjectData = response.assigned;
        this.originalunassignedProjectData = [...this.unassignedProjectData];
      }
    });

    this.getFilterData();
  }

  getFilterData() {
    const searchTerm = this.filters.search.toLowerCase();
    if (this.activeTab.assign) {
      this.assignedProjectData = this.originalassignedProjectData.filter((item) => {
        const name = item.project.name.toLowerCase();
        const number = item.project.number.toLowerCase();
        const customer = item.project.customer.name.toString().toLowerCase();
        return name.includes(searchTerm) || number.includes(searchTerm) || customer.includes(searchTerm);
      });
    }

    if (this.activeTab.unassign) {
      this.unassignedProjectData = this.originalunassignedProjectData.filter((item) => {
        const name = item.project.name.toLowerCase();
        const number = item.project.number.toLowerCase();
        const customer = item.project.customer.name.toString().toLowerCase();
        return name.includes(searchTerm) || number.includes(searchTerm) || customer.includes(searchTerm);
      });
    }
  }

  generateClientDropdownOptions(): Array<TroiDropdownListModel> {
    if (!this.clients.length) {
      return [];
    }
    this.initValue = this.filters.client;
    const list = [];
    _.forEach(this.clients, (client) => {
      list.push({
        label: client.name,
        value: client.id,
        active: true,
      });
    });
    return list;
  }

  getMainClient() {
    return this.bookingSettingsService.prepareSettings(undefined, false);
  }

  selectClient(option) {
    if (option) {
      this.filters.client = option;
      this.getProjectData();
    }
  }

  searchChanged() {
    if (this.filters.search.length < 3) {
      this.filters.search = '';
      this.assignedProjectData = this.originalassignedProjectData;
      this.unassignedProjectData = this.originalunassignedProjectData;
    } else {
      this.getFilterData();
    }
  }

  tabChanged(tab): void {
    this.selectAll = false;
    this.filters.search = '';
    this.checkedProjects = [];
    if (tab === 'assign') {
      this.activeTab.assign = true;
      this.activeTab.unassign = false;
    }
    if (tab === 'unassign') {
      this.activeTab.assign = false;
      this.activeTab.unassign = true;
    }
    this.getProjectData();
  }

  updateCheckedProjects(project: any) {
    if (project.project.checked) {
      this.checkedProjects.push(project.project.id);
    } else {
      this.checkedProjects = this.checkedProjects.filter((pId) => pId !== project.project.id);
    }
  }

  addAndremoveAssignProject() {
    if (this.checkedProjects.length <= 0) {
      return false;
    }
    const newJson = {
      projectIds: this.checkedProjects,
    };
    /* Add Assignments */
    if (this.activeTab.assign) {
      this.url = this.timeService.addAndremoveAssignProjectAPI(this.userId, 'assign', newJson);
    }
    /* Remove Assignments */
    if (this.activeTab.unassign) {
      this.url = this.timeService.addAndremoveAssignProjectAPI(this.userId, 'unassign', newJson);
    }
    if (this.url) {
      this.url.subscribe(
        (response: any) => {
          if (response.success) {
            this.getProjectData();

            /* Add Assignments */
            if (this.activeTab.assign) {
              this.notificationService.showSuccess(this.translate.instant('Timerecording.AddAssignmentsSuccessfully'));
            }
            /* Remove Assignments */
            if (this.activeTab.unassign) {
              this.notificationService.showSuccess(
                this.translate.instant('Timerecording.RemoveAssignmentsSuccessfully'),
              );
            }
          }
        },
        (error: any) => {},
      );
    }
  }

  toggleCheckbox(project: any): void {
    this.selectAll = false;
    project.project.checked = !project.project.checked;
    this.updateCheckedProjects(project);
  }

  selectAllProjects() {
    /* Add Assignments */
    if (this.activeTab.assign) {
      if (this.selectAll) {
        this.checkedProjects = this.assignedProjectData.map((aProject) => aProject.project.id);
        this.assignedProjectData.forEach((item, index) => {
          item.project.checked = true;
        });
      } else {
        this.checkedProjects = [];
        this.assignedProjectData.forEach((item, index) => {
          item.project.checked = false;
        });
      }
    }

    /* Remove Assignments */
    if (this.activeTab.unassign) {
      if (this.selectAll) {
        this.checkedProjects = this.unassignedProjectData.map((unaProject) => unaProject.project.id);
        this.unassignedProjectData.forEach((item, index) => {
          item.project.checked = true;
        });
      } else {
        this.checkedProjects = [];
        this.unassignedProjectData.forEach((item, index) => {
          item.project.checked = false;
        });
      }
    }
  }
}
