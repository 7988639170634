import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { ModuleInterceptor } from '../../../../../../core/enums/module-interceptor';
import { Routes } from '../../../../enum/routes';

import { PhrasesResponseInterface } from '../../../../interfaces/responses.interface';
import { PhrasesInterface } from '../../../../interfaces/phrases.interface';

@Injectable()
export class PhrasesService {
  public phrasesSubscription: Subscription;
  public phrasesOptions: PhrasesInterface[] = [];
  public phrasesOptionsLoaded = new BehaviorSubject(false);

  private headers: HttpHeaders;

  public constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');

    this.phrasesSubscription = this.getPhrasesOptions().subscribe((res: PhrasesResponseInterface) => {
      this.phrasesOptions = res.data;
      this.phrasesOptionsLoaded.next(true);
    });
  }

  private getPhrasesOptions(): Observable<PhrasesResponseInterface> {
    return this.http.get<PhrasesResponseInterface>(environment.url + Routes.PHRASES, {
      headers: this.headers,
    });
  }
}
