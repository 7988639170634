import { Injectable } from '@angular/core';
import { InvoiceAssignNumberInterface } from '../interfaces/invoice-assign-number.interface';
import { InvoiceAssignNumberModel } from '../models/invoice-assign-number.model';
import { TreeListElementStatus } from '../../common/enums/tree-list-element-status';

@Injectable()
export class InvoiceAssignNumberFactory {
  private buildInvoiceAssignNumber = (invoiceAssignNumber: InvoiceAssignNumberInterface) =>
    new InvoiceAssignNumberModel(
      invoiceAssignNumber.id,
      invoiceAssignNumber.name,
      invoiceAssignNumber.assignmentNumber,
      invoiceAssignNumber.parent || null,
      invoiceAssignNumber.treeStatus,
    );

  public buildInvoiceAssignNumbers(invoiceAssignNumbers: InvoiceAssignNumberInterface[], showAll: boolean) {
    const parentTreeStatus = showAll ? TreeListElementStatus.EXPANDED : TreeListElementStatus.COLLAPSED;
    const flattenedInvoiceAssignNumbers = invoiceAssignNumbers.reduce(
      (acc: InvoiceAssignNumberInterface[], invoiceAssignNumber: InvoiceAssignNumberInterface) => {
        acc.push({ ...invoiceAssignNumber, parent: null, treeStatus: parentTreeStatus });
        return [
          ...acc,
          ...invoiceAssignNumber.calculations.map((cal: InvoiceAssignNumberInterface) => ({
            ...cal,
            treeStatus: TreeListElementStatus.DISABLED,
            parent: invoiceAssignNumber.id,
          })),
        ];
      },
      [],
    );

    return flattenedInvoiceAssignNumbers.map(this.buildInvoiceAssignNumber);
  }
}
