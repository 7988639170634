<div class="delete-recorded">
  <troi-modal-header [noCloseButton]="true">
    <span>{{ 'Approval.labels.stopApproval' | translate }}</span>
  </troi-modal-header>
  <troi-modal-content style="overflow-y: inherit">
    <p class="delete-recorded__message">
      {{ 'Approval.labels.cannotBeUndone' | translate }}
    </p>
    <form [formGroup]="createForm">
      <troi-textfield formControlName="reason"></troi-textfield>
    </form>
  </troi-modal-content>
  <troi-modal-footer>
    <troi-btn class="delete-recorded__button" [outline]="true" (click)="close()">
      {{ 'Approval.labels.cancel' | translate }}
    </troi-btn>
    <troi-btn class="delete-recorded__button" (click)="cancelApproval()">
      {{ 'Approval.labels.stopApproval' | translate }}
    </troi-btn>
  </troi-modal-footer>
</div>
