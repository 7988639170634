<div class="color-palette">
  <div
    *ngFor="let color of shownColors; let i = index"
    class="color-palette__option"
    [ngStyle]="{ 'background-color': shownColors[i] }"
    (click)="onColorSelect(shownColors[i])"
  >
    <div class="color-option-placeholder"></div>
  </div>
  <div class="color-palette__option close" (click)="onTagCreationClose()">
    <troi-icon icon="icon-close"></troi-icon>
  </div>
</div>
