import { Component, Input } from '@angular/core';
import { TaskFiltersService } from '../../services/filters.service';

@Component({
  selector: 'troi-view-switch',
  templateUrl: './view-switch.component.html',
  styleUrls: ['./view-switch.component.scss'],
})
export class ViewSwitchComponent {
  @Input() projectContext: boolean;
  constructor(private filterService: TaskFiltersService) {}

  public navigate(view: string) {
    if (this.filterService.isProjectContext) this.filterService.projecttaskview = view;
    const filters = this.filterService.putFiltersInUrl();
    const params = filters.split('?')[1];
    const newUrl = this.filterService.isProjectContext
      ? `/site/index.php?page=project_task_list&${params}`
      : `/site/index.php?page=projecttask_${view}view&${params}`;
    window.parent.window.location.href = newUrl;
  }
}
