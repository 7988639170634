import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { TaskInterface } from '../../../../../interfaces/task.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TasksService } from '../../../../../network/tasks.service';

import { SubtaskService } from '../../../../../network/subtask.service';
import { Subscription } from 'rxjs';
import { SubtaskInterface } from '../../../../../interfaces/subtask.interface';
import { TasksHelperService } from '../../../../../services/helper.service';
import { TasksResponseInterface } from '../../../../../interfaces/responses.interface';
import { TaskModel } from '../../../../../models/task.model';

@Component({
  selector: 'troi-task-modal-subtasks',
  templateUrl: './subtasks-content.component.html',
  styleUrls: ['./subtasks-content.component.scss'],
})
export class SubtasksContentComponent implements OnInit, OnDestroy {
  @Input() task: TaskModel;

  @Output() showEditSubtaskContent = new EventEmitter<boolean>();

  public createSubtaskForm = new FormGroup({
    title: new FormControl('', Validators.required),
  });

  public enableInput = false;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private taskService: TasksService,
    private helperService: TasksHelperService,
    private subtaskService: SubtaskService,
  ) {}

  ngOnInit() {
    this.getSubtasks();
    this.deleteSubtaskSubscription();
  }

  private getSubtasks() {
    this.subscriptions.add(
      this.subtaskService.getSubtasks(this.task.id).subscribe((res: TasksResponseInterface) => {
        if (res.data.length > 0) this.task.subtasks = res.data[0].subtasks;
      }),
    );
  }

  private deleteSubtaskSubscription() {
    this.subscriptions.add(
      this.helperService.deleteSubtask.subscribe((subtask: SubtaskInterface) => {
        if (subtask) {
          this.task.subtasks = this.task.subtasks.filter((x) => x.id !== subtask.id);
        }
      }),
    );
  }

  public onCreateSubtaskClick(): void {
    this.enableInput = true;
  }

  public createSubtask(): void {
    if (this.createSubtaskForm.status === 'INVALID') console.warn('invalid');
    else {
      if (!this.task.subtasks) this.task.subtasks = [];
      this.subtaskService.createSubtask(this.createSubtaskForm.value.title, this.task).subscribe((res) => {
        this.task.subtasks.push(res.data[0]);
        this.task.subtaskAll++;
        this.taskService.updateTask(this.task);
      });
      this.createSubtaskForm.reset();
      this.enableInput = false;
    }
  }

  public openEditSubtaskModal(value: boolean): void {
    this.showEditSubtaskContent.emit(value);
  }

  public calculateProgressValue(): number {
    if (!this.task.subtasks || this.task.subtasks.length === 0) return 0;
    else return (this.task.subtaskFinished / this.task.subtaskAll) * 100;
  }

  ngOnDestroy(): void {
    this.showEditSubtaskContent.emit(false);
    this.subscriptions.unsubscribe();
  }
}
