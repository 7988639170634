import { InjectionToken } from '@angular/core';
import moment from 'moment';

export const LOCALE_CONFIG = new InjectionToken<LocaleConfig>('daterangepicker.config');

/**
 * Interface representing the configuration for locale settings
 * used in the date range picker component.
 */
export interface LocaleConfig {
  /**
   * Text direction for the date range picker.
   * 'ltr' stands for left-to-right.
   */
  direction?: string;

  /**
   * Separator string used between the start and end dates.
   */
  separator?: string;

  /**
   * Label for the week column in the calendar.
   */
  weekLabel?: string;

  /**
   * Label for the apply button.
   */
  applyLabel?: string;

  /**
   * Label for the cancel button.
   */
  cancelLabel?: string;

  /**
   * Label for the clear button.
   */
  clearLabel?: string;

  /**
   * Label for the custom range option.
   */
  customRangeLabel?: string;

  /**
   * Array of abbreviated day names, e.g., ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].
   */
  daysOfWeek?: string[];

  /**
   * Array of abbreviated month names, e.g., ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].
   */
  monthNames?: string[];

  /**
   * Index of the first day of the week (0 for Sunday, 1 for Monday, etc.).
   */
  firstDay?: number;

  /**
   * Format string for displaying dates.
   */
  format?: string;

  /**
   * Format string for displaying dates in the input field.
   */
  displayFormat?: string;

  /**
   * Locale settings for the date range picker.
   */
  locale?: any;
}

/**
 * DefaultLocaleConfig provides the default configuration for locale settings
 * used in the date range picker component.
 */
export const DefaultLocaleConfig: LocaleConfig = {
  direction: 'ltr',
  separator: ' - ',
  weekLabel: 'KW',
  applyLabel: 'Apply',
  cancelLabel: 'Cancel',
  clearLabel: 'Clear',
  customRangeLabel: 'Custom range',
  daysOfWeek: moment.weekdaysMin(),
  monthNames: moment.monthsShort(),
  firstDay: moment.localeData().firstDayOfWeek(),
};
