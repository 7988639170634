import { Component, Input } from '@angular/core';

@Component({
  selector: 'troi-loading-spinner',
  templateUrl: './troi-loading-spinner.component.html',
  styleUrls: ['./troi-loading-spinner.component.scss'],
})
export class TroiLoadingSpinnerComponent {
  @Input() width = '100%';

  @Input() height = '95%';
}
