<div class="projectApproval" *ngIf="billingIsApprovalRequired">
  <!-- pending -->
  <div class="d-flex flex-row" *ngIf="billingApprovalStatus === 1">
    <div class="p-2"><troi-icon icon="icon-circle-not-closed"></troi-icon></div>
  </div>
  <!-- approve -->
  <div class="d-flex flex-row" *ngIf="billingApprovalStatus === 2">
    <div class="p-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="18"
        height="18"
        viewBox="0 0 24 24"
      >
        <path
          d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z"
        ></path>
      </svg>
    </div>
  </div>
  <!-- reject -->
  <div class="d-flex flex-row" *ngIf="billingApprovalStatus === 3">
    <div class="p-2"><troi-icon icon="icon-close" class="icon-close"></troi-icon></div>
  </div>
</div>
