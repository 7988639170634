import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BookingsCommonNetwork } from './bookings-common.network';
import { BookingReceiptFullInterface } from '../interfaces/booking-receipt-full.interface';
import { BackendResponseInterface } from '../../../../core/interfaces/backend.response.interface';
import { environment } from '../../../../../environments/environment';
import { Routes } from '../enums/routes';

@Injectable()
export class AssignFilesToBrNetwork extends BookingsCommonNetwork {
  public constructor(public http: HttpClient) {
    super(http);
  }

  assign(
    booking: string,
    group: string,
    files: string[] = [],
  ): Observable<BackendResponseInterface<BookingReceiptFullInterface>> {
    return this.http.post<BackendResponseInterface<BookingReceiptFullInterface>>(
      environment.url + Routes.BOOKING_ASSIGN_FILES,
      {
        bookingReceipt: booking,
        group: group || null,
        files,
      },
    );
  }
}
