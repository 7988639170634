import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { StorageService } from '../../../../core/storage/storage.service';
import { StorageKeys } from '../../../../core/storage/storage.keys';
import { BasicSettingsNetwork } from '../../../../core/network/basic-settings.network';
import { TroiDropdownListModel } from '../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { LanguagesService } from '../../../../core/services/languages.service';
import { SettingsEmitter } from '../../../../core/emitters/settings.emitter';
import { BaseBookingSettings } from '../../../common/classes/base-booking-settings';
import { AccountingSettingsInterface } from '../interfaces/accounting-settings.interface';
import {
  AccountGroupDropdownInterface,
  AccountTypeInterface,
  TaxRateInterface,
} from '../../../common/interfaces/basic-settings.interface';
import { Routes } from '../../../../core/enums/routes';

@Injectable()
export class AccountingSettingsService extends BaseBookingSettings<AccountingSettingsInterface> {
  public storageKey = StorageKeys.ACCOUNTING_SETTINGS;

  constructor(
    protected storageService: StorageService,
    public basicSettingsNetwork: BasicSettingsNetwork,
    private languagesService: LanguagesService,
    protected settingsEmitter: SettingsEmitter,
  ) {
    super(storageService, basicSettingsNetwork, settingsEmitter);
  }

  public getSettingsRoute(): string {
    return Routes.ACCOUNTING_SETTINGS;
  }

  public buildAccountTypeList(): Array<TroiDropdownListModel> {
    const list = [];
    if (!this.settings) {
      return [];
    }

    _.forEach(this.settings.dropdowns.accountTypes, (element: AccountTypeInterface) => {
      list.push({
        label: this.languagesService.getLanguageValue(element.name),
        value: element.id,
        active: true,
      });
    });
    return list;
  }

  public buildAccountGroupList(): Array<TroiDropdownListModel> {
    const list = [];
    if (!this.settings) {
      return [];
    }
    _.forEach(this.settings.dropdowns.accountGroups, (element: AccountGroupDropdownInterface) => {
      list.push({
        label: element.description,
        value: element.id,
        active: true,
      });
    });

    return list;
  }

  public buildTaxKeyList(): Array<TroiDropdownListModel> {
    const list = [];
    if (!this.settings) {
      return [];
    }

    _.forEach(this.settings.dropdowns.taxRates, (element: TaxRateInterface) => {
      list.push({
        label: this.languagesService.getLanguageValue(element.name),
        value: element.id,
        active: true,
      });
    });
    return list;
  }

  public buildBookingYears(): Array<TroiDropdownListModel> {
    return this.settings ? super.buildBookingYears(this.settings.dropdowns.bookingYears) : [];
  }
}
