import { Injectable } from '@angular/core';
import { TranslationMode } from '../../../core/enums/translationMode';
import { LanguagesService } from '../../../core/services/languages.service';

@Injectable()
export class MenuService {
  constructor(private languagesService: LanguagesService) {}

  public target(item): string {
    if (item?.link && item?.linkTarget) {
      return item.linkTarget;
    }
    return '_top';
  }

  public getLanguageValue(string) {
    if (!string) {
      return '';
    }
    return this.languagesService.getLanguageValue(string, TranslationMode.NAVIGATION);
  }
}
