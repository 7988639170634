import { Component, EventEmitter, Output } from '@angular/core';
import { ModalService } from '../../../../shared/troi-modals/modal.service';
import { StorageNotificationService } from '../../../../core/notifications/storageNotification.service';
import { BaseModalDirective } from '../../../../shared/troi-base-modal/baseModal.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'decline-approval',
  templateUrl: './decline-approval.component.html',
  styleUrls: ['./decline-approval.component.scss'],
  providers: [],
})
export class DeclineApprovalComponent extends BaseModalDirective {
  public createForm = new UntypedFormGroup({
    reason: new UntypedFormControl('', [Validators.required]),
  });
  @Output() parent;
  public modalObject: any;
  public reason = '';
  public canBeDeleted = true;

  public submitted = false;

  public constructor(public modalService: ModalService, private notificationService: StorageNotificationService) {
    super(modalService, false, true);
    this.modalObject = this.modalService.object;
  }

  public declineApproval() {
    const reason = this.createForm.get('reason').value;
    this.parent.declineApproval(reason);
    this.closeSecond();
  }

  public cannotBeCancelled(): boolean {
    return !this.canBeDeleted;
  }
}
