export const timePresentation = (counter: number, noSeconds: boolean = false) => {
  const hours: number = Math.floor(counter / 3600);
  const minutes: number = Math.floor((counter - hours * 3600) / 60);
  const seconds: number = counter % 60;

  const hoursPadding: string = 10 > hours ? '0' : '';
  const minutesPadding: string = 10 > minutes ? '0' : '';
  const secondsPadding: string = 10 > seconds ? '0' : '';

  if (!noSeconds) {
    return `${hoursPadding}${hours}:${minutesPadding}${minutes}:${secondsPadding}${seconds}`;
  }

  if (0 < seconds) {
    const minutesPlus = minutes + 1;
    return `${hoursPadding}${hours}:${minutesPadding}${minutesPlus}`;
  }

  return `${hoursPadding}${hours}:${minutesPadding}${minutes}`;
};
