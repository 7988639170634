import { ClientInterface } from '../../../../../core/interfaces/client.interface';
import { AccountTypeInterface } from '../../../../common/interfaces/basic-settings.interface';

export class AccountGroupModel {
  constructor(
    public id: string,
    public description: string,
    public client: ClientInterface,
    public type: AccountTypeInterface,
    public active: boolean,
    public canBeDeleted?: boolean,
  ) {}
}
