import { Subject, Observable } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

import { StorageService } from './storage.service';
import { StorageKeys } from './storage.keys';

@Injectable()
export class StorageListener implements OnDestroy {
  private onSubject = new Subject<any>();

  constructor(private storageService: StorageService) {
    this.start();
  }

  ngOnDestroy() {
    this.stop();
  }

  get storageChanges(): Observable<any> {
    return this.onSubject.asObservable();
  }

  public getStorage() {
    const s = [];
    for (let i = 0; i < localStorage.length; i++) {
      s.push({
        key: localStorage.key(i),
        value: this.storageService.getItem(localStorage.key(i)),
      });
    }
    return s;
  }

  public store(key: string, data: any): void {
    this.storageService.setItem(key, data);
    this.onSubject.next({ key, value: data });
  }

  public clear(key) {
    this.storageService.removeItem(key);
  }

  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      let v;
      try {
        v = JSON.parse(event.newValue);
      } catch (e) {
        v = event.newValue;
      }
      this.onSubject.next({ key: event.key, value: v });
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
    this.onSubject.complete();
  }
}
