<span class="troi-inline-edit" [ngClass]="{ toRight: toRight }">
  <span class="troi-inline-edit__value" *ngIf="!editMode">
    {{ value }}
  </span>
  <span
    class="troi-inline-edit__edit"
    [ngClass]="{ 'troi-inline-edit__edit--width-auto': widthAuto }"
    *ngIf="editMode"
    focus-directive
    cssSelector="input, select"
    [withSelection]="true"
    [clickOutsideEvents]="'click,touchstart'"
    (clickOutside)="onClickOutside()"
  >
    <ng-content> </ng-content>
  </span>
</span>
