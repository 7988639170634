import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MathOperationsNetwork } from '../network/math-operations.network';
import { BackendResponseInterface } from '../interfaces/backend.response.interface';
import { CountUnassignedAmountInterface, MathOperationInterface } from '../interfaces/math-operation.interface';
import { NetGrossResponseInterface } from '../interfaces/net-gross-response.interface';

@Injectable()
export class MathOperationsService {
  constructor(private network: MathOperationsNetwork) {}

  multiply(left: string, right: string): Observable<BackendResponseInterface<MathOperationInterface>> {
    return this.network.multiply(left, right);
  }

  sum(elements: string[]): Observable<BackendResponseInterface<MathOperationInterface>> {
    return this.network.sum(elements);
  }

  calculateNetGrossValues(
    value: string,
    isNet: boolean,
    taxId: number,
  ): Observable<BackendResponseInterface<NetGrossResponseInterface>> {
    return this.network.calculateNetGrossValues(value, isNet, taxId);
  }

  countUnassignedAmount(
    isNet: boolean,
    tax: number,
    left: string,
    right,
  ): Observable<BackendResponseInterface<CountUnassignedAmountInterface>> {
    return this.network.countUnassignedAmount(isNet, tax, left, right);
  }
}
