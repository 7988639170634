import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubprojectDataInterface } from '../../../../interfaces/subproject.interface';
import { CalculationPositionInterface } from '../../../../interfaces/calculationPosition.interface';
import { TasksHelperService } from '../../../../services/helper.service';

@Component({
  selector: 'troi-import-calc-pos-subproject',
  templateUrl: './subproject.component.html',
  styleUrls: ['./subproject.component.scss'],
})
export class SubprojectComponent {
  @Input() subproject: SubprojectDataInterface;
  @Input() cpsToImport: CalculationPositionInterface[];
  @Input() occupiedCPs: CalculationPositionInterface[] = [];
  @Output() checkboxChanged = new EventEmitter<{
    value: boolean;
    calcPos: CalculationPositionInterface;
  }>();

  constructor(public helperService: TasksHelperService) {}
  public collapseSubproject = false;

  public addCP(value: boolean, calcPos: CalculationPositionInterface) {
    if (!this.getCalcDisabled(calcPos)) {
      this.checkboxChanged.emit({ value, calcPos });
    }
  }

  public addAllCPs(value: boolean) {
    this.subproject.cps.forEach((cp) => {
      if (!this.getCalcDisabled(cp)) {
        this.addCP(value, cp);
      }
    });
  }

  public toggleSubproject() {
    this.collapseSubproject = !this.collapseSubproject;
  }

  public allCalcPosChosen() {
    return this.subproject.cps.every((pos) => this.getCalcDisabled(pos) || this.cpsToImport.includes(pos));
  }

  public getCalcDisabled(calcPos: CalculationPositionInterface): boolean {
    return this.occupiedCPs.some((occupiedCP) => occupiedCP.id === calcPos.id);
  }
}
