import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterElementInterface } from './filter-element.interface';
import { CurrencyMoneyInterface } from '../troi-data-listing-filters/filters.interface';

@Component({
  selector: 'troi-filter-with-modal',
  templateUrl: './troi-filter-with-modal.component.html',
  styleUrls: ['./troi-filter-with-modal.component.scss'],
})
export class TroiFilterWithModalComponent {
  open = false;
  forceOpen = false;
  private _dateFormat: string;

  @Input() filterData: FilterElementInterface[];
  @Input() currencyMoneyData: CurrencyMoneyInterface;
  @Input() year: number;
  @Input() limitDate = true;
  @Input() rangesInPast = true;

  @Input() set dateFormat(dateFormat: string) {
    this._dateFormat = dateFormat || 'DD.MM.YYYY';
  }

  get dateFormat(): string {
    return this._dateFormat;
  }

  @Input() lang: string;
  @Input() client: number;
  @Input() newDesign = false;

  @Output() filtersApplied = new EventEmitter<FilterElementInterface[]>();
  @Output() filtersReset = new EventEmitter<boolean>();

  onOpen(state) {
    this.open = state;
  }

  toggleOpen() {
    this.open = !this.open;
    this.forceOpen = this.open;
  }

  emitFiltersApplied(filters: FilterElementInterface[]) {
    this.filtersApplied.emit(filters);
  }

  emitFiltersReset() {
    this.filtersReset.emit(true);
  }
}
