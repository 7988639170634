import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TimerangeView } from '../../modules/tasks/modules/timelineview/enum/view';
import { ResourcesService } from '../../modules/tasks/network/resources.service';
import { TasksHelperService } from '../../modules/tasks/services/helper.service';
import { ColumnSelectInterface } from '../troi-columns-selector/column-select.interface';
import { TroiDropdownListModel } from '../troi-dropdown-list/models/troi-dropdown-list.model';
import { TroiDropdownSelectConfigInterface } from '../troi-dropdown-select/interfaces/troi-dropdown-select-config.interface';
import { FilterSetModel } from '../troi-filter-sets/models/filter-set.model';
import { FilterElementInterface } from '../troi-filter-with-modal/filter-element.interface';
import { CurrencyMoneyInterface, FiltersInterface } from './filters.interface';

@Component({
  selector: 'troi-data-listing-filters',
  templateUrl: './troi-data-listing-filters.component.html',
  styleUrls: ['./troi-data-listing-filters.component.scss'],
})
export class TroiDataListingFiltersComponent implements OnChanges, AfterViewChecked, OnDestroy {
  private _filterSets = [];
  private _secondDropdown: TroiDropdownSelectConfigInterface;
  private _thirdDropdown: TroiDropdownSelectConfigInterface;
  private _fourthDropdown: TroiDropdownSelectConfigInterface;
  private subscriptions: Subscription = new Subscription();
  public showDropdown = false;
  public selectedDateView = TimerangeView.DAY;
  public selectedUtilization = true;

  @Input() selectedFilterSetId: string;
  @Input() isResourceContext = false;
  @Input() isTimelineContext = false;
  @Input() rangesInPast = true;

  @Input() filters: FiltersInterface = {
    search: '',
    dropdownOptional: null,
    dropdownFirst: null,
    dropdownSecond: null,
    isWorkLoadInPercent: true,
    calendarScaleUnit: TimerangeView.DAY,
    filters: null,
    currentPage: 1,
    pageSize: 0,
  };

  @Input()
  public set skipFilterSets(skipFilterSets: boolean | string) {
    this._skipFilterSets = coerceBooleanProperty(skipFilterSets);
  }
  public get skipFilterSets(): boolean {
    return this._skipFilterSets;
  }
  private _skipFilterSets = false;

  @Input() set filterSets(filterSets: FilterSetModel[]) {
    this._filterSets = [...filterSets];
  }

  get filterSets(): FilterSetModel[] {
    return this._filterSets;
  }

  @Input() areFiltersEdited = false;
  @Input() showColumnSelection = true;
  @Input() showResetButton = true;
  @Input() reloadOptionalDropdownOptions = false;
  @Input() optionalDropdown: FilterElementInterface = null;
  @Input() firstDropdown: Array<TroiDropdownListModel> = [];
  @Input() set secondDropdown(options: TroiDropdownSelectConfigInterface | TroiDropdownListModel[]) {
    this._secondDropdown =
      options && !options.hasOwnProperty('values')
        ? TroiDataListingFiltersComponent.initDropdownWithConfig(options as TroiDropdownListModel[])
        : (options as TroiDropdownSelectConfigInterface);
  }
  @Input() reloadSecondDropdownOptions = 0;
  @Input() resetSecondDropdown: Subject<boolean>;
  @Input() set thirdDropdown(options: TroiDropdownSelectConfigInterface | TroiDropdownListModel[]) {
    this._thirdDropdown =
      options && !options.hasOwnProperty('values')
        ? TroiDataListingFiltersComponent.initDropdownWithConfig(options as TroiDropdownListModel[])
        : (options as TroiDropdownSelectConfigInterface);
  }
  @Input() set fourthDropdown(options: TroiDropdownSelectConfigInterface | TroiDropdownListModel[]) {
    this._fourthDropdown =
      options && !options.hasOwnProperty('values')
        ? TroiDataListingFiltersComponent.initDropdownWithConfig(options as TroiDropdownListModel[])
        : (options as TroiDropdownSelectConfigInterface);
  }

  @Input() reloadThirdDropdownOptions = 0;
  @Input() resetThirdDropdown: Subject<boolean>;

  @Input() reloadFourthDropdownOptions = 0;
  @Input() resetFourthDropdown: Subject<boolean>;

  @Input() reloadFilterChips: Subject<FilterElementInterface[]>;

  @Input() columnList: ColumnSelectInterface[] = [];
  @Input() currencyMoneyData: CurrencyMoneyInterface;
  @Input() listLoaded: boolean;
  @Input() year: number;
  @Input() client: number;
  @Input() lang = 'de';
  @Input() dateFormat;
  @Input() limitDate = true;
  @Input() header: string;
  @Input() showTitle = true;
  @Input() showSettings = true;
  @Input() enableDaterangePicker = false;
  @Input() enableClientDropDown = false;

  @Output() reloadOptionalDropdownOptionsChange = new EventEmitter();
  @Output() filtersChanged = new EventEmitter<FiltersInterface>();
  @Output() firstDropdownChanged = new EventEmitter<FiltersInterface>();
  @Output() secondDropdownChanged = new EventEmitter<FiltersInterface>();
  @Output() thirdDropdownChanged = new EventEmitter<FiltersInterface>();
  @Output() fourthDropdownChanged = new EventEmitter<FiltersInterface>();
  @Output() optionalDropdownChanged = new EventEmitter<FiltersInterface>();
  @Output() columnsChanged = new EventEmitter<ColumnSelectInterface[]>();
  @Output() filtersReset = new EventEmitter<boolean>();
  @Output() filterSetSelected = new EventEmitter<string | number>();
  @Output() filterSetDeleted = new EventEmitter<FilterSetModel>();
  @Output() filterSetAsDefault = new EventEmitter<FilterSetModel>();
  @Output() filterSetOpenSave = new EventEmitter<void>();

  @ViewChild('filterChips') filterChips: ElementRef;

  get secondDropdownWithConfig(): TroiDropdownSelectConfigInterface {
    return this._secondDropdown;
  }

  get thirdDropdownWithConfig(): TroiDropdownSelectConfigInterface {
    return this._thirdDropdown;
  }

  get fourthDropdownWithConfig(): TroiDropdownSelectConfigInterface {
    return this._fourthDropdown;
  }

  searchInput = new Subject<string>();
  public dateNow = new Date();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private resourcesService: ResourcesService,
    private helperService: TasksHelperService,
  ) {
    this.searchInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(() => {
      if (this.filters.search.length > 2 || this.filters.search.length === 0) {
        this.emitFiltersChanged();
      }
    });
    this.subscriptions.add(this.helperService.timeRangeView.subscribe((view) => this.selectViewChanged(view)));
  }

  public onSelectUtilizationClick(selectedUtil: boolean): void {
    this.selectedUtilization = selectedUtil;
    this.helperService.setSelectedUtilization(selectedUtil);
    this.filters.isWorkLoadInPercent = selectedUtil;
  }

  public selectViewChanged(selectedView: TimerangeView): void {
    this.selectedDateView = selectedView;
    this.helperService.setSelectedDateView(selectedView);
    this.filters.calendarScaleUnit = selectedView;
    this.emitFiltersChanged();
  }

  public toggleViewSettings(): void {
    this.showDropdown = !this.showDropdown;
  }
  rangeChanged(event) {
    const timeRangeFilter = this.filters.filters.find((f) => {
      return f.formName === 'timerange';
    });

    if (timeRangeFilter) {
      timeRangeFilter.value = event.date;
      this.emitFiltersChanged();
    }
  }

  private static initDropdownWithConfig(options: TroiDropdownListModel[]): TroiDropdownSelectConfigInterface {
    return {
      values: options,
      search: false,
      multiple: false,
      disable: false,
      selectAllOption: false,
    };
  }

  public ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public get filterChipsWidth(): string {
    return this.filterChips ? `${this.filterChips.nativeElement.getBoundingClientRect().width}px` : 'initial';
  }

  public sortAlphabetic(options: TroiDropdownListModel[], sortNames: boolean) {
    if (!options) {
      return [];
    }
    if (sortNames)
      return options.sort((a, b) => {
        if (a.fullObject && b.fullObject)
          return (a.fullObject.lastName as string).localeCompare(b.fullObject.lastName as string);
      });
    else return options.sort((a, b) => a.label.localeCompare(b.label));
  }

  columnDisabled(): boolean {
    return this.columnList?.length === 0;
  }

  searchActive(): boolean {
    return this.filters.search?.length > 0;
  }

  emitFiltersChanged() {
    if (this.filters.search === null || this.filters.search.length < 3) {
      this.filters.search = '';
    }
    this.filtersChanged.emit(this.filters);
  }

  emitFiltersReset() {
    this.filters.filters[1].value = this.filters.filters[1].defaultValue;
    this.filtersReset.emit(true);
  }

  public emitFilterSetSelected(filterSetId: string | number): void {
    this.filterSetSelected.emit(filterSetId);
  }

  public emitFilterSetDeleted(filterSet: FilterSetModel): void {
    this.filterSetDeleted.emit(filterSet);
  }

  public emitFilterSetAsDefault(filterSet: FilterSetModel): void {
    this.filterSetAsDefault.emit(filterSet);
  }

  emitFirstDropdownChanged() {
    this.resetOptionalDropdownValue();
    this.firstDropdownChanged.emit(this.filters);
  }

  emitSecondDropdownChanged() {
    if (
      this.secondDropdownWithConfig.selectAllOption &&
      Array.isArray(this.filters.dropdownSecond) &&
      [...this.filters.dropdownSecond].includes('*')
    ) {
      this.filters.dropdownSecond = '*';
    }

    this.resetOptionalDropdownValue();
    this.secondDropdownChanged.emit(this.filters);
  }

  public emitThirdDropdownChanged(): void {
    if (
      this.thirdDropdownWithConfig.selectAllOption &&
      Array.isArray(this.filters.dropdownThird) &&
      [...this.filters.dropdownThird].includes('*')
    ) {
      this.filters.dropdownThird = '*';
    }

    this.resetOptionalDropdownValue();
    this.thirdDropdownChanged.emit(this.filters);
  }

  public emitFourthDropdownChanged(): void {
    if (
      this.fourthDropdownWithConfig.selectAllOption &&
      Array.isArray(this.filters.dropdownFourth) &&
      [...this.filters.dropdownFourth].includes('*')
    ) {
      this.filters.dropdownFourth = '*';
    }

    this.resetOptionalDropdownValue();
    this.fourthDropdownChanged.emit(this.filters);
  }

  resetOptionalDropdownValue() {
    if (this.optionalDropdown) {
      this.filters.dropdownOptional.value = this.filters.dropdownOptional.defaultValue;
      this.filters.dropdownOptional.preloadedOptions = [...this.filters.dropdownOptional.staticPreloadedOptions];
    }
  }

  emitOptionalDropdownChanged(event) {
    this.optionalDropdown.value = event;
    this.filters.dropdownOptional.value = event;
    this.optionalDropdownChanged.emit(this.filters);
  }

  updateOptionalDropdown(event) {
    this.optionalDropdown.dropdownData = event;
    this.reloadOptionalDropdownOptionsChange.emit(false);
  }

  filtersApplied(filtersChanged: FilterElementInterface[]) {
    this.filters.filters = filtersChanged;
    this.emitFiltersChanged();
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.year || change.client) {
      this.reloadOptionalDropdownOptionsChange.emit(true);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onColumnChanged(columns: ColumnSelectInterface[]) {
    this.columnsChanged.emit(columns);
  }

  shouldReloadOptionalDropdownOptions(): boolean {
    return this.listLoaded && this.reloadOptionalDropdownOptions;
  }
}
