import { Component, Input } from '@angular/core';
import { UserConfirmationSubscriber } from './user-confirmation.subscriber';
import { ModalService } from '../troi-modals/modal.service';
import { UserConfirmationPopupInterface } from './user-confirmation-popup.interface';
import { BaseModalDirective } from '../troi-base-modal/baseModal.component';
import { UserConfirmationEventEnum } from './user-confirmation-event.enum';

@Component({
  selector: 'troi-user-confirmation',
  templateUrl: './troi-user-confirmation.component.html',
  styleUrls: ['./troi-user-confirmation.component.scss'],
})
export class TroiUserConfirmationComponent extends BaseModalDirective {
  @Input() translations;

  public modalObject: UserConfirmationPopupInterface;

  public constructor(public modalService: ModalService, private subscriber: UserConfirmationSubscriber) {
    super(modalService, false, true);
    this.modalObject = {
      ...this.modalService.object,
      ...this.modalService.componentConfig?.outputs,
    };
  }

  close() {
    this.subscriber.action.next(UserConfirmationEventEnum.CLOSE);
    this.subscriber.actionWithData.next({
      event: UserConfirmationEventEnum.CLOSE,
      data: this.modalObject,
    });
    super.closeSecond();
  }

  execute() {
    this.subscriber.action.next(UserConfirmationEventEnum.EXECUTE);
    this.subscriber.actionWithData.next({
      event: UserConfirmationEventEnum.EXECUTE,
      data: this.modalObject,
    });
    super.closeSecond();
  }
}
