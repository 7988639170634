import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { UserService } from '../../../core/user/user.service';
import { BackendResponseInterface } from '../../../core/interfaces/backend.response.interface';
import { IUser } from '../../../core/user/IUser';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthenticationService {
  public constructor(
    private http: HttpClient,
    private userService: UserService,
    private translateService: TranslateService,
  ) {}

  public login(username: string, password: string) {
    return this.http.post<any>('/site/login.php', { username, password }).pipe(
      map((userData: BackendResponseInterface<IUser>) => {
        if (userData && userData.data.id) {
          userData.data.isLogged = true;
          this.userService.saveUser(userData.data);
          this.translateService.setDefaultLang(userData.data.lang);
        }
      }),
    );
  }

  public login2FA(username: string, password: string, token: string, method: string) {
    return this.http.post<any>('/site/login.php', { username, password, token, method }).pipe(
      map((userData: BackendResponseInterface<IUser>) => {
        if (userData && userData.data.id) {
          userData.data.isLogged = true;
          this.userService.saveUser(userData.data);
          this.translateService.setDefaultLang(userData.data.lang);
        }
      }),
    );
  }

  public getBannerData() {
    return this.http.get(environment.loginImageUrl, { responseType: 'json' });
  }

  public backendLogout() {
    this.http.post<BackendResponseInterface<any>>('/logout', {});
  }

  public logout(force: boolean = false) {
    if (!this.userService.getRememberMe() || force) {
      this.userService.removeUser();
    }
    this.userService.setLoggedIn(false);
  }

  public authenticatorCheck() {
    return this.http.get('/site/login.php?page=login&action=authenticator', {});
  }

  public select2FA(username: string, password: string, method: string) {
    return this.http.post<any>('/site/login.php', { username, password, method });
  }

  public getWebauthnCredentialsArgs(username: string, password: string) {
    return this.http.post<any>('/components/security/webauthn/getArgs', { username, password });
  }
}
