import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssignFilesToBrNetwork } from '../networks/assign-files-to-br.network';
import { BackendResponseInterface } from '../../../../core/interfaces/backend.response.interface';
import { BookingReceiptFullInterface } from '../interfaces/booking-receipt-full.interface';
import { BrFilesAssignmentInterface } from '../interfaces/br-files-assignment.interface';

@Injectable()
export class AssignFilesToBrService {
  constructor(private network: AssignFilesToBrNetwork) {}

  assignFilesToBR(
    booking: string,
    files: BrFilesAssignmentInterface,
  ): Observable<BackendResponseInterface<BookingReceiptFullInterface>> {
    return this.network.assign(booking, files.group, files.files);
  }
}
