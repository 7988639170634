import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { TroiDropdownListModel } from '../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';

@Injectable()
export class ApprovalStatusesService {
  public statuses = [
    {
      id: '',
      translationKey: 'Common.labels.notStarted',
    },
    {
      id: 'PENDING',
      translationKey: 'Common.labels.pending',
    },
    {
      id: 'DECLINED',
      translationKey: 'Common.labels.declined',
    },
    {
      id: 'APPROVED',
      translationKey: 'Common.labels.approved',
    },
    {
      id: 'CANCELLED',
      translationKey: 'Common.labels.cancelled',
    },
  ];

  constructor(private translateService: TranslateService) {}

  buildApprovalStatuses(): Array<TroiDropdownListModel> {
    const data: Array<TroiDropdownListModel> = [];

    _.forEach(this.statuses, (single) => {
      data.push({
        label: this.translateService.instant(single.translationKey),
        value: single.id,
        active: true,
      });
    });

    data.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    data.unshift({
      label: this.translateService.instant('Booking.labels.pleaseSelect'),
      value: '',
      active: true,
    });

    return data;
  }
}
