import { Subscription } from 'rxjs';
import { Chart, ChartDataset } from 'chart.js';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TimeRecordingService } from '../../modules/time-recording/time-recording.service';
import { WorkTimeResponseInterface } from '../../modules/time-recording/interfaces/work-time-response.interface';
import { WorkTimeSummaryInterface } from '../../modules/time-recording/interfaces/work-time-summary-response.interface';
import { TranslateService } from '@ngx-translate/core';
import { startOfDay, format, getISOWeek } from 'date-fns';
import { StorageKeys } from '../../core/storage/storage.keys';
import { StorageService } from '../../core/storage/storage.service';
import { ThemeService } from '../../modules/time-recording/theme.service';

@Component({
  selector: 'troi-donut-chart',
  templateUrl: './troi-donut-chart.component.html',
  styleUrls: ['./troi-donut-chart.component.scss'],
})
export class TroiDonutChartComponent implements OnInit, OnDestroy {
  @Input() chartId: string;
  @Input() width: string;
  @Input() height: string;
  @Input() passcurrentDate?: string;
  @Input() employeeId?: number;
  @Output() showAbsencescheck: EventEmitter<any> = new EventEmitter();
  private reloadingSubscription: Subscription;
  private restoreParamsSubscription: Subscription;
  private weekFilterSubscription: Subscription;
  public loading = false;
  viewChart: Chart<'doughnut', (number | null)[], unknown>;
  summaryData: WorkTimeSummaryInterface;
  chartData: WorkTimeResponseInterface[];
  filterDateDonutChart: any = new Date();
  url: any;
  userId: number;
  public workingTimeUndertimeWarningThresholdPercentage = 0;
  public workingTimeOvertimeWarningThresholdPercentage = 0;

  weeknumber = getISOWeek(new Date());
  timerecordingSettings: any;

  constructor(
    private storageService: StorageService,
    private themeService: ThemeService,
    private translate: TranslateService,
    private http: HttpClient,
    public timeService: TimeRecordingService,
  ) {
    this.restoreParamsSubscription = this.timeService.restoreParamsDonutChart.subscribe((response) => {
      if (response && response[1] !== undefined) {
        this.filterDateDonutChart = format(new Date(response[1]), 'yyyy-MM-dd');
      }
      this.getAllCharts(response[0]);
    });

    /* reloading after add new */
    this.reloadingSubscription = this.timeService.reloading.subscribe((value) => {
      this.getAllCharts('default');
    });

    /* weekfilter change */
    this.weekFilterSubscription = this.timeService.weekFilter.subscribe((value) => {
      if (value) {
        this.userId = value.employeeId;
        this.weeknumber = value.currentWeeknumber;
        this.getAllCharts('default');
      }
    });

    /* TimerecordingSettings  */
    this.timerecordingSettings = this.storageService.getItem(StorageKeys.TIMERECORDING_SETTINGS);
    if (this.timerecordingSettings) {
      if (this.timerecordingSettings.settings.warningThresholds.workingTimeUndertimeWarningThreshold) {
        this.workingTimeUndertimeWarningThresholdPercentage =
          this.timerecordingSettings.settings.warningThresholds.workingTimeUndertimeWarningThreshold;
      }
      if (this.timerecordingSettings.settings.warningThresholds.workingTimeOvertimeWarningThreshold) {
        this.workingTimeOvertimeWarningThresholdPercentage =
          this.timerecordingSettings.settings.warningThresholds.workingTimeOvertimeWarningThreshold;
      }
    }
  }

  ngOnInit(): void {
    this.weeknumber = getISOWeek(new Date());
    this.filterDateDonutChart = this.passcurrentDate
      ? startOfDay(new Date(this.passcurrentDate))
      : startOfDay(new Date());

    this.userId = this.employeeId ?? this.storageService.getItem(StorageKeys.USER)?.id;
    this.getAllCharts('default');
  }

  ngOnDestroy() {
    if (
      this.restoreParamsSubscription ||
      this.reloadingSubscription.unsubscribe() ||
      this.weekFilterSubscription.unsubscribe()
    ) {
      this.restoreParamsSubscription.unsubscribe();
      this.reloadingSubscription.unsubscribe();
      this.weekFilterSubscription.unsubscribe();
    }
  }

  getAllCharts(data?: string) {
    const lang = this.storageService.getItem(StorageKeys.LANG);
    const weekNumber = document.getElementById('weekNumber');
    if (weekNumber) {
      weekNumber.textContent = `${this.translate.instant('Timerecording.CW')} ${this.weeknumber}`;
    }

    if (!this.userId) {
      return;
    }

    const selectedDate = this.filterDateDonutChart ? new Date(this.filterDateDonutChart) : new Date();
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const monthLong = selectedDate.toLocaleString('en-US', { month: 'long' });
    const day = String(selectedDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    if (
      this.chartId === 'overViewDayDonutChart' ||
      this.chartId === 'workingTimeDayDonutChart' ||
      this.chartId === 'projectTimeDayDonutChart'
    ) {
      this.url = this.timeService.getDayData(formattedDate, this.userId);
    } else if (
      this.chartId === 'overViewWeekDonutChart' ||
      this.chartId === 'workingTimeWeekDonutChart' ||
      this.chartId === 'projectTimeWeekDonutChart'
    ) {
      this.url = this.timeService.getWeekData(1, this.userId, this.weeknumber);
    }

    if (!this.url) {
      return;
    }
    this.loading = true;
    this.url.subscribe((response: any) => {
      this.loading = false;
      if (response.length) {
        /* summary */
        this.summaryData = response[0].workingTimeSummary;
        /* Data */
        this.chartData = response[0].workingTimeDays;
        this.createChartView(this.chartId);
        const showDateElement = document.getElementById(this.chartId + 'Date');

        if (showDateElement) {
          showDateElement.textContent =
            lang === 'de'
              ? `${day}. ${this.translate.instant('Timerecording.month.' + monthLong)}`
              : `${day}, ${this.translate.instant('Timerecording.month.' + monthLong)}`;
        }
      }
    });
  }

  createChartView(canvasId: string) {
    const viewCtx = document.getElementById(canvasId) as HTMLCanvasElement;
    const chartExist = Chart.getChart(canvasId);

    if (chartExist !== undefined) chartExist.destroy();

    const finalWorkingTimepercentage = Math.max(Math.min(this.summaryData.workingTime.percent, 100), 0);
    const finalProjectTimepercentage = Math.max(Math.min(this.summaryData.projectTime.percent, 100), 0);

    /* ++++++++++ Color Section ++++++++++++++ */
    const backgroundColor = this.themeService.getColor();

    const workingbackgroundColorsText = [finalWorkingTimepercentage].map((percent, index) => {
      if (percent === 0) {
        return this.themeService.getDarkColor_overview(backgroundColor);
      } else if (percent <= this.workingTimeUndertimeWarningThresholdPercentage) {
        return 'rgba(245, 140, 1, 1)'; /* orange */
      } else if (percent >= this.workingTimeOvertimeWarningThresholdPercentage) {
        return this.themeService.getFullDarkColor_overview(backgroundColor);
      }
      return this.themeService.getDarkColor_overview(backgroundColor);
    });

    const projectbackgroundColorsText = [finalProjectTimepercentage].map((percent, index) => {
      return backgroundColor; /* default */
    });

    /* Utilization Calculation */
    let TotalUTZValue = 0;
    if (this.timerecordingSettings) {
      if (this.timerecordingSettings.settings.workingTimeUtilizationType === 0) {
        TotalUTZValue =
          this.timeService.getNonNegativeValue(this.summaryData.projectTime.quantity) /
          this.timeService.getNonNegativeValue(this.summaryData.workingTime.debit);
      }
      if (this.timerecordingSettings.settings.workingTimeUtilizationType === 1) {
        TotalUTZValue =
          this.timeService.getNonNegativeValue(this.summaryData.projectTime.quantity) /
          this.timeService.getNonNegativeValue(this.summaryData.workingTime.quantity);
      }
    }

    if (isNaN(TotalUTZValue)) {
      TotalUTZValue = 0;
    }

    /* ++++++++++ Set Total Hour Label ++++++++++++++ */
    const textElements = {
      WT: {
        value: Math.max(this.summaryData.workingTime.quantity, 0),
        color: workingbackgroundColorsText,
        symbol: 'h',
      },
      PT: {
        value: Math.max(this.summaryData.projectTime.quantity, 0),
        color: projectbackgroundColorsText,
        symbol: 'h',
      },
      TB: { value: Math.max(this.summaryData.workingTime.breakQuantity, 0), color: '#C2262E', symbol: 'h' },
      PTH: {
        value: Math.max(this.summaryData.projectTime.billable, 0),
        color: projectbackgroundColorsText,
        symbol: 'h',
      },
      PNB: { value: Math.max(this.summaryData.projectTime.notBillable, 0), color: '#C2262E', symbol: 'h' },
      InsideLabel: { value: Math.max(this.summaryData.projectTime.billable, 0), color: null, symbol: 'h' },
      WTW: { value: Math.max(this.summaryData.workingTime.quantity, 0), color: null, symbol: 'h' },
      THW: { value: Math.max(this.summaryData.projectTime.quantity, 0), color: null, symbol: 'h' },
      TH: {
        value: Math.max(this.summaryData.projectTime.billable, 0),
        color: projectbackgroundColorsText,
        symbol: 'h',
      },
      NB: { value: Math.max(this.summaryData.projectTime.notBillable, 0), color: '#C2262E', symbol: 'h' },

      MBH: { value: Math.max(this.summaryData.projectTime.billable, 0), color: '#82AD26', symbol: 'h' },
      NBH: { value: Math.max(this.summaryData.projectTime.notBillable, 0), color: '#C2262E', symbol: 'h' },
      UTP: {
        value: TotalUTZValue,
        color: 'null',
        symbol: '%',
      },

      ABS: { value: Math.max(this.summaryData.workingTime.absenceQuantity, 0), color: '#C2262E', symbol: 'h' },
    };

    /* check for show absence quantity or not  */
    if (this.summaryData.workingTime.absenceQuantity > 0) {
      this.showAbsencescheck.emit(true);
    } else {
      this.showAbsencescheck.emit(false);
    }

    for (const key in textElements) {
      if (textElements.hasOwnProperty(key)) {
        this.timeService.updateTextElement(
          canvasId,
          key,
          textElements[key].value,
          textElements[key].color,
          textElements[key].symbol,
        );
      }
    }

    /* ++++++++++ Create dataset section ++++++++++++++ */
    let PTDATA = [];
    let WTDATA = [];
    let PBC = [];
    let WBC = [];

    /*overview => Day */
    if (this.chartId === 'overViewDayDonutChart') {
      PTDATA = [finalProjectTimepercentage, 100 - finalProjectTimepercentage];
      PBC = [projectbackgroundColorsText.toString(), 'rgba(54, 162, 235, 0.1)'];

      WTDATA = [finalWorkingTimepercentage, 100 - finalWorkingTimepercentage];
      WBC = [workingbackgroundColorsText.toString(), 'rgba(54, 162, 235, 0.1)'];
    } else if (this.chartId === 'workingTimeDayDonutChart') {
      /*working time => Day */
      PTDATA = [0, 100];
      PBC = [projectbackgroundColorsText.toString(), 'rgba(54, 162, 235, 0.1)'];

      WTDATA = [finalWorkingTimepercentage, 100 - finalWorkingTimepercentage];
      WBC = [workingbackgroundColorsText.toString(), 'rgba(54, 162, 235, 0.1)'];
    } else if (this.chartId === 'projectTimeDayDonutChart') {
      /*Project Time  => Day */
      WTDATA = [0, 100];
      WBC = [workingbackgroundColorsText.toString(), 'rgba(54, 162, 235, 0.1)'];

      PTDATA = [
        Math.max(this.summaryData.projectTime.notBillableToDebitPercent, 0),
        Math.max(this.summaryData.projectTime.billableToDebitPercent, 0),
        Math.max(100 - this.summaryData.projectTime.percent, 0),
      ];

      PBC = ['#C2262E', projectbackgroundColorsText.toString(), 'rgba(54, 162, 235, 0.1)'];
    } else if (this.chartId === 'overViewWeekDonutChart') {
      /* Overview => Week */
      PTDATA = [finalProjectTimepercentage, 100 - finalProjectTimepercentage];
      PBC = [projectbackgroundColorsText.toString(), 'rgba(54, 162, 235, 0.1)'];

      WTDATA = [finalWorkingTimepercentage, 100 - finalWorkingTimepercentage];
      WBC = [workingbackgroundColorsText.toString(), 'rgba(54, 162, 235, 0.1)'];
    } else if (this.chartId === 'workingTimeWeekDonutChart') {
      /* WorkingTime => Week */
      PTDATA = [0, 100];
      PBC = [projectbackgroundColorsText.toString(), 'rgba(54, 162, 235, 0.1)'];

      WTDATA = [finalWorkingTimepercentage, 100 - finalWorkingTimepercentage];
      WBC = [workingbackgroundColorsText.toString(), 'rgba(54, 162, 235, 0.1)'];
    } else if (this.chartId === 'projectTimeWeekDonutChart') {
      /* ProjectTime => Week */
      WTDATA = [0, 100];
      WBC = [workingbackgroundColorsText.toString(), 'rgba(54, 162, 235, 0.1)'];

      PTDATA = [
        Math.max(this.summaryData.projectTime.notBillableToDebitPercent, 0),
        Math.max(this.summaryData.projectTime.billableToDebitPercent, 0),
        Math.max(100 - this.summaryData.projectTime.percent, 0),
      ];
      PBC = ['#C2262E', projectbackgroundColorsText.toString(), 'rgba(54, 162, 235, 0.1)'];
    }

    /* Data Set */
    const datasets: (ChartDataset<'doughnut', number[]> & { cutout: string })[] = [
      {
        label: 'Project Time',
        data: PTDATA,
        backgroundColor: PBC,
        cutout: '98%',
        borderWidth: 0,
      },
      {
        label: 'Working Time',
        data: WTDATA,
        backgroundColor: WBC,
        cutout: '70%',
        borderWidth: 0,
        weight: 0.08,
      },
    ];

    /* ++++++++++ Generate chart section ++++++++++++++ */

    const textWTDiv = document.getElementById(canvasId + 'WTDiv');
    const textPTDiv = document.getElementById(canvasId + 'PTDiv');
    const textPTHDiv = document.getElementById(canvasId + 'PTHDiv');
    const textPNBDiv = document.getElementById(canvasId + 'PNBDiv');

    /*overview => Day => working time (And) Working Time => Day => Net working time */
    const insidetotalWorkLabel = document.getElementById('insidetotalWorkLabel');
    if (insidetotalWorkLabel) {
      const totalHours = Math.floor(
        this.chartData.reduce((sum, item) => sum + Math.max(item.workingTime.quantity, 0), 0),
      );
      const totalMinutes =
        Math.round(this.chartData.reduce((sum, item) => sum + Math.max(item.workingTime.quantity, 0), 0) * 60) % 60;

      insidetotalWorkLabel.textContent = this.translate.instant(
        'Timerecording.You worked {{totalHours}} h and {{totalMinutes}}min.',
        {
          totalHours,
          totalMinutes,
        },
      );
    }

    this.viewChart = new Chart<'doughnut', number[], unknown>(viewCtx, {
      type: 'doughnut',
      data: {
        datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            align: 'start',
            labels: {
              usePointStyle: true,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        onHover: (event, activeElements) => {
          const updateTextStyle = (
            element: HTMLElement,
            smallFontSize: string,
            pFontSize: string,
            fontWeight: string,
            color: string,
          ) => {
            element.querySelector('small').style.cssText = `font-size: ${smallFontSize}; font-weight: ${fontWeight};`;
            element.querySelector(
              'p',
            ).style.cssText = `font-size: ${pFontSize}; font-weight: ${fontWeight}; color: ${color}`;
          };

          if (textWTDiv) {
            updateTextStyle(textWTDiv, '11px', '25px', 'normal', workingbackgroundColorsText.toString());
          }
          if (textPTDiv) {
            updateTextStyle(textPTDiv, '11px', '25px', 'normal', projectbackgroundColorsText.toString());
          }
          if (textPTHDiv) {
            updateTextStyle(textPTHDiv, '11px', '25px', 'normal', projectbackgroundColorsText.toString());
          }
          if (textPNBDiv) {
            updateTextStyle(textPNBDiv, '11px', '25px', 'normal', '#C2262E');
          }

          if (activeElements && activeElements.length) {
            const chartElement = activeElements[0];
            const datasetLabel = this.viewChart.data.datasets[chartElement.datasetIndex].label || '';

            if (datasetLabel === 'Working Time' && textWTDiv) {
              updateTextStyle(textWTDiv, '13px', '33px', 'bold', workingbackgroundColorsText.toString());
            }
            if (datasetLabel === 'Project Time' && textPTDiv) {
              updateTextStyle(textPTDiv, '13px', '33px', 'bold', projectbackgroundColorsText.toString());
            }
            if (datasetLabel === 'Project Time' && textPTHDiv && chartElement.index === 1) {
              updateTextStyle(textPTHDiv, '13px', '33px', 'bold', projectbackgroundColorsText.toString());
            }
            if (datasetLabel === 'Project Time' && textPNBDiv && chartElement.index === 0) {
              updateTextStyle(textPNBDiv, '13px', '33px', 'bold', '#C2262E');
            }
          }
        },
      },
    });
  }
}
