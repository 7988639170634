import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SecurityCenterService } from './security-center.service';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageNotificationService } from '../../../core/notifications/storageNotification.service';
import { WebAuthNService } from '../../../core/services/webauthn.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'security-center',
  templateUrl: './security-center.component.html',
  styleUrls: ['./security-center.component.scss'],
})
export class SecurityCenterComponent implements OnInit {
  @ViewChild('employeeFormElement') loginFormElement: ElementRef;

  public changePasswordData: Record<string, string> = {
    currentPassword: '',
    newPassword1: '',
    newPassword2: '',
  };

  public apiV2Data: Record<string, string> = {
    token: '',
  };

  public apiV3Data: Record<string, string> = {
    publicToken: '',
    secretToken: '',
  };

  public otpData: Record<string, string> = {
    token: '',
    secret: '',
  };

  public qrCodePath;

  public changePasswordForm;

  public apiV2Form;

  public apiV3Form;

  public otpForm;

  public userLog;

  public webauthnDeviceName;

  public webauthnDeviceForm;

  public webauthnDevices;

  public internalAuthenticator: true;

  public isWebAuthNEnabled: false;

  public isTwoFactorAuthEnabled: false;

  public isTwoFactorAuthSetUp: false;

  public constructor(
    private securityCenterService: SecurityCenterService,
    private _sanitizer: DomSanitizer,
    private notificationService: StorageNotificationService,
    private webauthnService: WebAuthNService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.initForm();
    this.loadCurrentData();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {}

  initForm() {
    this.changePasswordForm = new FormGroup({
      currentPassword: new FormControl(this.changePasswordData.currentPassword, Validators.required),
      newPassword1: new FormControl(this.changePasswordData.newPassword1, Validators.required),
      newPassword2: new FormControl(this.changePasswordData.newPassword2, Validators.required),
    });

    this.apiV2Form = new FormGroup({
      token: new FormControl(this.apiV2Data.token),
    });

    this.apiV3Form = new FormGroup({
      publicToken: new FormControl(this.apiV3Data.publicToken),
      secretToken: new FormControl(this.apiV3Data.secretToken),
    });

    this.otpForm = new FormGroup({
      token: new FormControl(this.otpData.token),
    });

    this.webauthnDeviceForm = new FormGroup({
      name: new FormControl(this.webauthnDeviceName),
    });
  }

  loadCurrentData() {
    this.securityCenterService.getSecurityCenterData().subscribe((response: any) => {
      if (response.data.otp.base64) {
        this.qrCodePath = this._sanitizer.bypassSecurityTrustResourceUrl(
          'data:image/jpg;base64,' + response.data.otp.base64,
        );
        this.otpData.secret = response.data.otp.secret;
      }

      if (response.data.userlog) {
        this.userLog = response.data.userlog;
      }

      if (response.data.webauthnDevices) {
        this.webauthnDevices = response.data.webauthnDevices;
      }

      if (response.data.internalAuthenticator) {
        this.internalAuthenticator = response.data.internalAuthenticator;
      }

      if (response.data.isWebAuthNEnabled) {
        this.isWebAuthNEnabled = response.data.isWebAuthNEnabled;
      }

      if (response.data.isTwoFactorAuthSetUp !== undefined) {
        this.isTwoFactorAuthSetUp = response.data.isTwoFactorAuthSetUp;
      }

      if (response.data.isTwoFactorAuthEnabled !== undefined) {
        this.isTwoFactorAuthEnabled = response.data.isTwoFactorAuthEnabled;
      }

      if (response.data.currentApiToken.apiV2.token !== undefined) {
        this.apiV2Form.controls['token'].setValue(response.data.currentApiToken.apiV2.token);
      }

      if (response.data.currentApiToken.apiV2.token !== undefined) {
        this.apiV3Form.controls['publicToken'].setValue(response.data.currentApiToken.apiV3.publicToken);
      }

      if (response.data.currentApiToken.apiV2.token !== undefined) {
        this.apiV3Form.controls['secretToken'].setValue(response.data.currentApiToken.apiV3.secretToken);
      }
    });
  }

  saveOTPToken() {
    this.securityCenterService
      .saveOTPToken(this.otpForm.value.token, this.otpData.secret)
      .subscribe((response: any) => {
        if (response.success === true) {
          this.notificationService.showSuccess('Two-Factor Authentication was activated!');
        }

        this.loadCurrentData();
      });
  }

  deactivateOTPToken() {
    this.securityCenterService.deactivateOTPToken().subscribe((response: any) => {
      if (response.success === true) {
        this.notificationService.showSuccess('Two-Factor Authentication was deactivated!');
      }

      this.loadCurrentData();
    });
  }

  copySecretToClipboard() {
    navigator.clipboard.writeText(this.otpData.secret);

    this.notificationService.showSuccess('Secret key copied to clipboard');
  }

  changePassword() {
    if (this.changePasswordForm.value.newPassword1 !== this.changePasswordForm.value.newPassword2) {
      this.notificationService.showError(
        this.translate.instant('SecurityCenter.PasswordChange.ValidationMessages.PasswordsDontMatch'),
      );
      return;
    }

    this.securityCenterService
      .changePassword(
        this.changePasswordForm.value.currentPassword,
        this.changePasswordForm.value.newPassword1,
        this.changePasswordForm.value.newPassword2,
      )
      .subscribe((response: any) => {
        if (response.success === true) {
          this.notificationService.showSuccess(this.translate.instant('SecurityCenter.PasswordChange.PasswordChanged'));
          this.changePasswordForm.reset();
          return;
        }
      });
  }

  refreshV2Token() {
    this.securityCenterService.refreshV2Token().subscribe((response: any) => {
      if (response.type === 'string') {
        this.notificationService.showSuccess('API v2 Token refreshed.');
        this.apiV2Data.token = response.data;
        this.apiV2Form.controls['token'].setValue(this.apiV2Data.token);
        return;
      }
    });
  }

  refreshV3Token() {
    this.securityCenterService.refreshV3Token().subscribe((response: any) => {
      if (response.type === 'collection') {
        this.notificationService.showSuccess('API v3 Token refreshed.');
        this.apiV3Data.publicToken = response.data.publicId;
        this.apiV3Form.controls['publicToken'].setValue(this.apiV3Data.publicToken);
        this.apiV3Data.secretToken = response.data.secret;
        this.apiV3Form.controls['secretToken'].setValue(this.apiV3Data.secretToken);
        return;
      }
    });
  }

  async generateWebauthnRequest() {
    this.securityCenterService.generateWebauthnRequest().subscribe((response: any) => {
      this.webauthnService.createCreds(response.data.publicKey).then((result) => {
        this.securityCenterService
          .registerWebauthn(result, this.webauthnDeviceForm.value.name)
          .subscribe((response1: any) => {
            this.loadCurrentData();
          });
      });
      return;
    });
  }

  deleteWebauthnDevice(id: number) {
    this.securityCenterService.deleteWebauthnDevice(id).subscribe((response: any) => {
      this.loadCurrentData();
    });
  }
}
