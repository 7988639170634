import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';
import { PhrasesInterface } from '../../../../../interfaces/phrases.interface';
import { TaskInterface } from '../../../../../interfaces/task.interface';
import { PhrasesService } from '../../network/phrases.service';
import { TagInterface } from '../../../../../interfaces/tag.interface';
import { TasksHelperService } from '../../../../../services/helper.service';

@Component({
  selector: 'troi-task-modal-general',
  templateUrl: './general-content.component.html',
  styleUrls: ['./general-content.component.scss'],
})
export class GeneralContentComponent implements OnInit, OnDestroy {
  @Input() curTask: TaskInterface;
  @Output() curTaskChange = new EventEmitter<TaskInterface>();

  public assignedHoursOfAllTasks = 0;
  public loadConsumptionPopover = false;

  htmlText = '';
  hasFocus = false;

  phrasesLoadedSub: Subscription;
  phrasesOptions: PhrasesInterface[] = [];

  constructor(private phrasesService: PhrasesService, public helperService: TasksHelperService) {}

  ngOnInit() {
    this.getTextModules();
  }

  getAssignedHoursOfAllTasks(hours) {
    this.assignedHoursOfAllTasks = hours.toFixed(2);
  }

  calculateAssignedHours(): string {
    return this.curTask.hoursBudget ? this.curTask.hoursBudget.toFixed(2) : '0.00';
  }

  public get isUnitTypeTime(): boolean {
    return this.curTask.calculationPosition?.unit?.unitType === 'T';
  }

  // Checks wether value of task hours is less than assigned hours.
  // Sets input eqaual to sum of assigned hours if true.
  public onAssignHoursInput(inputElement: HTMLInputElement): void {
    const inputValue = +inputElement.value;
    const sumOfAssignedHours =
      this.curTask.assignees?.reduce(
        (accumulator, currentValue) => accumulator + (currentValue.minutesTotal ? currentValue.minutesTotal : 0),
        0,
      ) / 60;

    if (inputValue < sumOfAssignedHours) {
      inputElement.value = sumOfAssignedHours.toString();
      this.curTask.hoursBudget = sumOfAssignedHours;
    } else this.curTask.hoursBudget = inputValue;
  }

  private getTextModules(): void {
    this.phrasesLoadedSub = this.phrasesService.phrasesOptionsLoaded.subscribe((isLoaded: boolean) => {
      if (isLoaded) this.formatPhrases();
    });
  }

  private formatPhrases(): void {
    this.phrasesOptions = this.phrasesService.phrasesOptions.map((phrase) => {
      return {
        ...phrase,
        label: phrase.textIdent,
        active: true,
      };
    });
  }

  public onTextModuleSelect(phrase: string): void {
    this.curTask.description = this.curTask.description || '';
    this.curTask.description += ' ' + phrase;
    this.curTask.description = this.helperService.addHTTPSToURL(this.curTask.description);
  }

  public onTagsChanged(tags: TagInterface[]) {
    this.curTask.tags = tags;
  }

  public onShowConsumptionPopoverClick() {
    this.loadConsumptionPopover = true;
  }

  ngOnDestroy(): void {
    if (this.phrasesLoadedSub) this.phrasesLoadedSub.unsubscribe();
    if (this.phrasesService.phrasesSubscription) this.phrasesService.phrasesSubscription.unsubscribe();
  }
}
