import { FooterSettingsInterface } from '../../../core/interfaces/footer-settings.interface';
import { Currency } from '../../../shared/troi-money/currency';
import { SettingsInterface } from '../interfaces/settings.interface';
import { BaseSettingsDropdownModel } from '../../../core/models/settings/dropdowns/base-settings-dropdown.model';

export class SettingsModel implements FooterSettingsInterface {
  private _availableLanguagesForNavigator: Array<string>;
  private _availableLanguagesForData: Array<string>;
  private _lastDownload: number;
  private _dataLastDownload: number;

  constructor(
    public client: number,
    public systemCurrency: Currency,
    public activeCurrency: Currency,
    public currencies: Currency[],
    public projectStatuses: BaseSettingsDropdownModel[],
    public exportTypes: BaseSettingsDropdownModel[],
    public calculationTypes: BaseSettingsDropdownModel[],
    public settings: SettingsInterface,
  ) {
    this.availableLanguagesForNavigator = settings.availableLanguagesForNavigator;
    this.availableLanguagesForData = settings.availableLanguagesForData;
  }

  get availableLanguagesForNavigator(): Array<string> {
    return this._availableLanguagesForNavigator;
  }

  set availableLanguagesForNavigator(value: Array<string>) {
    this._availableLanguagesForNavigator = value;
  }

  get availableLanguagesForData(): Array<string> {
    return this._availableLanguagesForData;
  }

  set availableLanguagesForData(value: Array<string>) {
    this._availableLanguagesForData = value;
  }

  get lastDownload(): number {
    return this._lastDownload;
  }

  set lastDownload(value: number) {
    this._lastDownload = value;
  }

  get dataLastDownload(): number {
    return this._dataLastDownload;
  }

  set dataLastDownload(value: number) {
    this._dataLastDownload = value;
  }
}
