import { Component, EventEmitter, Output, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { TimeRecordingService } from '../../modules/time-recording/time-recording.service';
@Component({
  selector: 'troi-week-calendar',
  templateUrl: './troi-week-calendar.component.html',
  styleUrls: ['./troi-week-calendar.component.scss'],
})
export class TroiWeekCalendarComponent implements OnChanges {
  currentDate: any;
  weekDays: any[];
  dateSelected: any;
  currentDateString: string;
  weekTotal = 0;
  weekLogged = 0;

  @Input() passcurrentDate?: string;
  @Input() showWorktime = false;
  @Input() weekWorkTimes: any = null;
  @Input() mode: 'horizontal' | 'vertical' = 'horizontal';
  @Output() dayChanged = new EventEmitter<Date>();
  @Output() weekChanged = new EventEmitter<Date>();

  constructor(
    public translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    public timeService: TimeRecordingService,
  ) {
    moment.updateLocale(this.translate.store.defaultLang, {
      week: {
        dow: 1,
        doy: 4,
      },
    });
    this.initDates();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.weekWorkTimes) {
      this.updateWeekDays();
      this.weekTotal = this.weekWorkTimes ? this.weekWorkTimes.weeklyHours : 0;
    }
    if ('passcurrentDate' in changes) {
      this.initDates();
    }
  }

  initDates() {
    if (this.passcurrentDate) {
      this.currentDate = moment(this.passcurrentDate);
      this.dateSelected = moment(this.passcurrentDate);
    } else {
      this.currentDate = moment();
      this.dateSelected = moment();
    }
    this.currentDateString = this.currentDate.format('MMMM YYYY');
    this.calcWeekDays();
  }

  calcWeekDays() {
    this.weekDays = [];
    const weekStart = this.currentDate.clone().startOf('week');
    const weekEnd = this.currentDate.clone().endOf('week');

    for (let i = 0; i <= 6; i++) {
      const dayDate = moment(weekStart).add(i, 'days');

      this.weekDays.push({
        label: dayDate.format('DD'),
        weekday: dayDate.format('dd'),
        date: dayDate,
        worktime: null,
      });
    }
  }

  updateWeekDays() {
    this.weekLogged = 0;
    this.weekDays.forEach((day) => {
      let worktime = null;
      if (this.weekWorkTimes) {
        worktime = this.weekWorkTimes.week[day.date.format('YYYY-MM-DD')];

        const balance = parseFloat(worktime.working_time_balance.replace(',', '.'));
        const quantity = parseFloat(worktime.working_time_quantity.replace(',', '.'));
        const total = quantity - balance;
        const percent = (100 / total) * quantity;

        worktime.overflow = percent > 100 ? true : false;
        worktime.percent = percent > 100 ? percent - 100 : percent;
        worktime.quantity = quantity;
        this.weekLogged += quantity;
      }
      day.worktime = worktime;
    });
  }

  prevMonth() {
    this.currentDate = moment(this.currentDate).subtract(7, 'days');
    this.currentDateString = this.currentDate.format('MMMM YYYY');
    this.calcWeekDays();
    this.weekChanged.emit(this.currentDate);
  }

  nextMonth() {
    this.currentDate = moment(this.currentDate).add(7, 'days');
    this.currentDateString = this.currentDate.format('MMMM YYYY');
    this.calcWeekDays();
    this.weekChanged.emit(this.currentDate);
  }

  dayClicked(day) {
    if (this.timeService.getUnsavedChanges()) {
      const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (confirmLeave) {
        this.timeService.setUnsavedChanges(false);
        this.timeService.clearRedBorders();
        this.dateSelected = day.date;
        this.dayChanged.emit(day.date);
      } else {
        this.timeService.highlightUnsavedItems();
      }
      return confirmLeave;
    } else {
      this.dateSelected = day.date;
      this.dayChanged.emit(day.date);
    }
  }
}
