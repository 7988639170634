import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { ModuleInterceptor } from '../../../../../../core/enums/module-interceptor';
import { Routes } from '../../../../enum/routes';
import { TagInterface } from '../../../../interfaces/tag.interface';
import { TagResponseInterface } from '../../../../interfaces/responses.interface';

@Injectable()
export class TagService {
  public tagOptions: TagInterface[] = [];

  private headers: HttpHeaders;

  public constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');
  }

  public getTagOptions(): Observable<TagResponseInterface> {
    return this.http.get<TagResponseInterface>(environment.url + Routes.TAGS, {
      headers: this.headers,
    });
  }

  public createTag(newTag: TagInterface): Observable<TagResponseInterface> {
    return this.http.post<TagResponseInterface>(environment.url + Routes.TAGS, newTag, {
      headers: this.headers,
    });
  }
}
