import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'troi-my-projects-filter',
  templateUrl: './troi-my-projects-filter.component.html',
  styleUrls: ['./troi-my-projects-filter.component.scss'],
})
export class TroiMyProjectsFilterComponent {
  open = false;
  forceOpen = false;
  @Input() applyFiltersDataResend: Record<string, any> = {};

  @Input() newDesign = false;
  @Output() filtersApplied = new EventEmitter<any>();
  @Output() filtersReset = new EventEmitter<boolean>();

  onOpen(state) {
    this.open = state;
  }

  toggleOpen() {
    this.open = !this.open;
    this.forceOpen = this.open;
  }

  emitFiltersApplied(filters) {
    this.filtersApplied.emit(filters);
  }

  emitFiltersReset() {
    this.filtersReset.emit(true);
  }
}
