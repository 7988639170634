import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TroiFilterSetsService } from './services/troi-filter-sets.service';
import { TroiDropdownListModel } from '../troi-dropdown-list/models/troi-dropdown-list.model';
import { FilterSetModel } from './models/filter-set.model';
import { FilterSetTypeEnum } from './enums/filter-set-type.enum';
import { DropdownActionInterface } from '../troi-dropdown-list/interfaces/dropdown-action.interface';

@Component({
  selector: 'troi-filter-sets',
  templateUrl: './troi-filter-sets.component.html',
  styleUrls: ['./troi-filter-sets.component.scss'],
})
export class TroiFilterSetsComponent {
  private _filterSets = [];
  private _areFiltersEdited = false;
  private _filterSetDropdownData: TroiDropdownListModel[] = [];

  @Input() selectedFilterSetId: string;

  @Input() set filterSets(filterSets: FilterSetModel[]) {
    this._filterSets = [...filterSets];

    this.filterSetsDropdownOptions = filterSets.map((filterSet: FilterSetModel) => ({
      active: true,
      label: filterSet.name,
      labelInfo:
        this.selectedFilterSetId === filterSet.id && this.areFiltersEdited
          ? 'FilterSets.statuses.edited'
          : this.getFilterSet(filterSet.id)?.isDefault
          ? 'FilterSets.statuses.default'
          : '',
      value: filterSet.id,
      icon: this.filterSetIcon(filterSet),
      disabled: false,
    }));
  }

  get filterSets(): FilterSetModel[] {
    return this._filterSets;
  }

  @Input() set areFiltersEdited(areEdited: boolean) {
    this.filterSetsDropdownOptions = this.filterSetsDropdownOptions.map((option: TroiDropdownListModel) => {
      return {
        ...option,
        labelInfo:
          this.selectedFilterSetId === option.value && areEdited
            ? 'FilterSets.statuses.edited'
            : this.getFilterSet(option.value)?.isDefault
            ? 'FilterSets.statuses.default'
            : '',
      };
    });

    this._areFiltersEdited = areEdited;
  }

  get areFiltersEdited(): boolean {
    return this._areFiltersEdited;
  }

  @Output() filterSetSelected = new EventEmitter<string | number>();
  @Output() filterSetDeleted = new EventEmitter<FilterSetModel>();
  @Output() filterSetAsDefault = new EventEmitter<FilterSetModel>();

  constructor(public filterSetsService: TroiFilterSetsService) {}

  public getFilterSet(filterSetId: string | number): FilterSetModel {
    return this.filterSets.find((filterSet: FilterSetModel) => filterSet.id === filterSetId);
  }

  public filterSetIcon(filterSet: FilterSetModel): string {
    switch (filterSet.type) {
      case FilterSetTypeEnum.ALL: {
        return 'icon-responsive-menu-black';
      }
      case FilterSetTypeEnum.FAVOURITES: {
        return 'icon-star';
      }
    }

    return 'icon-save';
  }

  public set filterSetsDropdownOptions(newDrop: TroiDropdownListModel[]) {
    this._filterSetDropdownData = [...newDrop];
  }

  public get filterSetsDropdownOptions(): TroiDropdownListModel[] {
    return this._filterSetDropdownData;
  }

  public get filterSetsDropdownActions(): DropdownActionInterface[] {
    return [
      {
        id: 1,
        icon: 'icon-trash-can',
        tooltip: 'FilterSets.modals.delete.tooltip',
        active: (filterSetId: string) => this.getFilterSet(filterSetId).type === FilterSetTypeEnum.OWN,
        actionSelected: (filterSetId: string | number) => {
          this.filterSetDeleted.emit(this.getFilterSet(filterSetId));
        },
      },
      {
        id: 2,
        icon: 'icon-eye-with-list',
        active: (filterSetId: string) => this.getFilterSet(filterSetId).type !== FilterSetTypeEnum.ALL,
        tooltip: 'FilterSets.modals.setAsDefault.tooltip',
        actionSelected: (filterSetId: string | number) => {
          this.filterSetAsDefault.emit(this.getFilterSet(filterSetId));
        },
      },
    ];
  }
}
