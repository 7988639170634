import { Currency } from '../../../shared/troi-money/currency';
import { SettingsModel } from '../models/settings.model';
import { ProjectListSettingsService } from '../services/project-list-settings.service';
import { Money } from '../../../shared/troi-money/money';

export abstract class ProjectListFactory {
  protected constructor(protected projectListSettingsService: ProjectListSettingsService) {}

  protected parsePrice(price: string, currency: Currency, settings: SettingsModel): Money {
    return new Money(price, currency, settings.settings.decimalPlacesNumber);
  }

  protected get selectedCurrency(): Currency {
    if (this.projectListSettingsService.settings.activeCurrency) {
      return this.projectListSettingsService.settings.activeCurrency;
    }
    if (this.projectListSettingsService.settings.systemCurrency) {
      return this.projectListSettingsService.settings.systemCurrency;
    }
    if (this.projectListSettingsService.settings.currencies) {
      return this.projectListSettingsService.settings.currencies[0];
    }
    return null;
  }

  protected findCurrency(currencyId: number, settings: SettingsModel): Currency {
    if (settings.currencies.length < 1) {
      return null;
    }

    const currency = settings.currencies.find((curr: Currency) => curr.id === currencyId);
    return currency || settings.currencies[0];
  }
}
