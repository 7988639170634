<div class="list-top-bar">
  <troi-btn class="list-top-bar__button" (click)="onProjectCreate()">
    <troi-icon icon="icon-plus" size="14px" [bolder]="true"></troi-icon>
    <span>{{ 'ProjectList.labels.actions.newProject' | translate }}</span>
  </troi-btn>
  <troi-dropdown-list
    class="list-top-bar__more-actions"
    width="175px"
    (selectedEmitter)="moreActionOptionClick($event)"
    (openChange)="onOpeningState($event)"
    [options]="moreActionsOptions"
  >
    <div class="more-actions__item" [ngClass]="{ 'more-actions__item--active': isEditMenuOpen }">
      <span class="item__label">{{ 'ProjectList.labels.actions.moreActions' | translate }}</span>
      <troi-icon [icon]="moreActionsIcon" size="15px" [bolder]="true"></troi-icon>
    </div>
  </troi-dropdown-list>
</div>
