import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, newTab: boolean = true): any {
    if (!value) {
      return value;
    }

    const target = newTab ? '_blank' : '_self';
    const linkPattern = /((?:https?:\/\/|www\.)\S+)/g;

    const transformedValue = value.replace(
      linkPattern,
      `<a href="$1" target="${target}" style="color: #3DB0E8; text-decoration: none;">$1</a>`,
    );

    return this.sanitizer.bypassSecurityTrustHtml(transformedValue);
  }
}
