import { ProjectFactory } from './project.factory';
import { Injectable } from '@angular/core';
import { FolderModel } from '../components/folder/models/folder.model';
import { ProjectTypeEnum } from '../enums/project-type.enum';
import { TreeListItemResponseInterface } from '../../../../../shared/troi-tree-list/interfaces/tree-list-item-response.interface';
import { ProjectPropertiesInterface } from '../interfaces/project-properties.interface';
import { TreeListItemModel } from '../../../../../shared/troi-tree-list/models/tree-list-item.model';
import { SettingsModel } from '../../../models/settings.model';
import { FolderFieldsModel } from '../components/folder/models/folder-fields.model';
import { ProjectModel } from '../models/project.model';

@Injectable()
export class TreeViewListFactory {
  constructor(private projectFactory: ProjectFactory) {}

  private static isFolder(
    element: TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>,
  ): boolean {
    return element.type === ProjectTypeEnum.FOLDER;
  }

  public static createEmptyFolder(parent: FolderModel): FolderModel {
    const newFolder = new FolderModel(
      '0',
      0,
      new FolderFieldsModel({ en: '', de: '', fr: '' }, true, false, { en: '', de: '', fr: '' }),
      false,
      parent.isCustomer() ? parent.id : parent.customer,
    );
    newFolder.translatedName = '';
    newFolder.parent = parent;
    newFolder.hasChildren = false;
    newFolder.expanded = false;

    return newFolder;
  }

  parseElement(
    element: TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>,
    forceExpanded = false,
    settings: SettingsModel,
    parent?: FolderModel,
  ): TreeListItemModel<ProjectTypeEnum> {
    if (TreeViewListFactory.isFolder(element)) {
      const folder = new FolderModel(
        element.id.id,
        element.order,
        element.fields,
        element.hasChildren,
        element.id.customer,
      );
      folder._parent = parent;
      folder._expanded = forceExpanded;
      folder.nodes = element.children.map(
        (
          node: TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>,
        ): TreeListItemModel<ProjectTypeEnum> => this.parseElement(node, forceExpanded, settings, folder),
      );

      return folder;
    }

    const item = this.projectFactory.createProject(element, settings);
    item._parent = parent;

    return item;
  }
}
