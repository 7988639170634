import { Component, Input } from '@angular/core';

@Component({
  selector: 'troi-original-value-tooltip',
  templateUrl: './original-value-tooltip.component.html',
  styleUrls: ['./original-value-tooltip.component.scss'],
})
export class OriginalValueTooltipComponent {
  @Input() public value: string;

  @Input() public align = 'left top';

  public alignLeft(): boolean {
    return this.align.indexOf('left') > -1;
  }

  public alignRight(): boolean {
    return this.align.indexOf('right') > -1;
  }

  public alignTop(): boolean {
    return this.align.indexOf('top') > -1;
  }

  public alignBottom(): boolean {
    return this.align.indexOf('bottom') > -1;
  }
}
