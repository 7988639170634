<div class="title-row">
  <div class="title-row__col1">
    <troi-icon class="attachment-icon" [icon]="'icon-paper-clip'"></troi-icon>
    <span class="paragraph-title">{{
      'Tasks.labels.createTaskModal.attachments' | translate
    }}</span>
  </div>
  <div class="title-row__col2">
    <span class="paragraph-title add-attachment" (click)="dropzone.showFileSelector()"
      >+ {{ 'Tasks.labels.attachment.add' | translate }}</span
    >
  </div>
</div>
<div *ngIf="!isLoading" class="file-container">
  <div
    *ngFor="let attachment of attachmentOptions; let i = index"
    title="{{ attachment.filename }}"
  >
    <troi-attachment
      [attachment]="attachment"
      [attachmentType]="'projecttask'"
      (deleteFile)="onFileDelete($event)"
    ></troi-attachment>
  </div>
</div>

<!-- For now only necessary to open file browser - dropdown is maybe added in the future (display:none for now) -->
<div class="attachment-dropdown-zone">
  <ngx-dropzone
    [accept]="'image/png,image/jpeg,image/jpg, application/pdf'"
    [maxFileSize]="20000000"
    (filesAdded)="onFileUpload($event, dropzone)"
    #dropzone
  ></ngx-dropzone>
</div>
