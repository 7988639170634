import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { HeaderNotificationComponent } from './headerNotification.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [CommonModule, NgbModule, SharedModule],
  declarations: [HeaderNotificationComponent],
  bootstrap: [],
  exports: [HeaderNotificationComponent],
})
export class HeaderNotificationModule {}
