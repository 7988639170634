import { environment } from '../../../../../environments/environment';

export class FilesService {
  public downloadFile(path: string): void {
    const href = environment.url + path;
    const tmpLink = document.createElement('a');
    const fileName = path.substr(path.lastIndexOf('/') + 1);

    tmpLink.href = href;
    tmpLink.download = fileName;
    tmpLink.target = '_blank';
    document.body.appendChild(tmpLink);
    tmpLink.click();
  }
}
