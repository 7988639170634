import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { StorageNotificationService } from '../../../../../app/core/notifications/storageNotification.service';
import { StorageKeys } from '../../../../core/storage/storage.keys';
import { StorageService } from '../../../../core/storage/storage.service';
import { ModalService } from '../../../../shared/troi-modals/modal.service';
import { TroiTextareaComponent } from '../../../../shared/troi-textarea/troi-textarea.component';
import { TroiTimeInputComponent } from '../../../../shared/troi-time-input/troi-time-input.component';
import { IBoilerPlatesFormatted } from '../../interfaces/boilerPlates.interface';
import { TimeRecordingService } from '../../time-recording.service';
import { TrAssignProjectModelComponent } from '../modal/tr-assign-project-model/tr-assign-project-model.component';

@Component({
  selector: 'tr-log-projecttime',
  templateUrl: './tr-log-projecttime.component.html',
  styleUrls: ['./tr-log-projecttime.component.scss'],
})
export class TrLogProjecttimeComponent implements OnInit, OnDestroy, OnChanges {
  private reloadingSubscription: Subscription;
  private addnewProjectSubscription: Subscription;
  private getDataInProgress = false;

  public loading = false;
  public isEditable = false;
  public itemDelete = false;
  public itemSave = false;

  @Input() employeeId?: number;
  @Input() LogDate: string;
  @Input() progressBar = false;

  dateFor: string;
  url: any;
  cloneProjectData: Record<string, any> = {};
  LogData: any[] = [];
  tableData: any[] = [];
  totalProjectTime: number;
  totalActualHours: number;
  totalRestEstimation: number;
  userId: number;
  loginUserId: number;
  showError = false;
  showErrorMessage: string;
  cookiesData: any[] = [];
  originalLogData: any = [];

  isTimeRecordingExternalCommentRequired = false;
  isTimeRecordingInternalCommentRequired = false;
  isEnableTimerecordingAdditionalFields = false;
  isRestExpenseEstimationActive = false;
  isEnabledBoilerPlatesInComments = false;
  isEnableInlineMode = false;
  public isProjectsChanged = false;

  public fromDate = 0;
  public toDate = 0;
  public backwardDays;

  public showAssignToProjectButton = false;
  public isDisplayDebitActual = false;

  public boilerPlates!: IBoilerPlatesFormatted;

  @Input() placement = 'top bottom';
  public popup = {
    text: null,
  };

  @Input()
  public get hideSelfAssign(): boolean {
    return this._hideSelfAssign;
  }
  public set hideSelfAssign(value: boolean) {
    this._hideSelfAssign = coerceBooleanProperty(value);
  }
  private _hideSelfAssign = false;

  @ViewChildren('internalComment') internalComment: QueryList<TroiTextareaComponent>;
  @ViewChildren('externalComment') externalComment: QueryList<TroiTextareaComponent>;
  @ViewChildren('quantityTime') quantityTime: QueryList<TroiTimeInputComponent>;
  @ViewChildren('actualHoursTime') actualHoursTime: QueryList<TroiTimeInputComponent>;
  @ViewChildren('estimationTime') estimationTime: QueryList<TroiTimeInputComponent>;
  @ViewChildren('isBillable') isBillable: QueryList<ElementRef<HTMLInputElement>>;

  constructor(
    private storageService: StorageService,
    public timeService: TimeRecordingService,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private notificationService: StorageNotificationService,
    public modalService: ModalService,
  ) {
    /* reloading after add new */
    this.reloadingSubscription = this.timeService.reloading.subscribe((value) => {
      if (!this.getDataInProgress) {
        this.getDataProjectTime();
      }
    });

    /* add new blank data */
    this.addnewProjectSubscription = this.timeService.AddnewProject.subscribe((value) => {
      if (value) {
        if (!this.getDataInProgress) {
          this.getDataProjectTime();
        }
      }
    });

    this.fromDate = Date.now();
    this.toDate = Date.now();

    const timerecordingSettings = this.storageService.getItem(StorageKeys.TIMERECORDING_SETTINGS);
    if (timerecordingSettings?.settings?.showAssignToProjectButton) {
      this.showAssignToProjectButton = true;
    }
  }

  ngOnInit(): void {
    this.loginUserId = this.timeService.loginUser();

    if (this.employeeId) {
      this.userId = this.employeeId;
    } else {
      const user = this.storageService.getItem(StorageKeys.USER);
      if (user) {
        this.userId = user.id;
      }
    }
    this.getDataProjectTime();

    /* check validation for internal and external comment */
    this.timeService.getTRSettings().subscribe(() => {
      const timerecordingSettings: { [key: string]: any } = this.storageService.getItem(
        StorageKeys.TIMERECORDING_SETTINGS,
      );
      if (timerecordingSettings) {
        const settingsToCheck = [
          'isTimeRecordingExternalCommentRequired',
          'isTimeRecordingInternalCommentRequired',
          'isEnableTimerecordingAdditionalFields',
          'isRestExpenseEstimationActive',
          'isEnabledBoilerPlatesInComments',
          'isDisplayDebitActual',
        ];

        settingsToCheck.forEach((setting) => {
          // use has own property to check if the setting exists
          if (timerecordingSettings.settings.hasOwnProperty(setting)) {
            this[setting] = timerecordingSettings.settings[setting];
          }
        });

        if (this.isEnabledBoilerPlatesInComments) {
          this.fillBoilerPlates();
        }

        this.cdr.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.LogDate) {
      this.getDataProjectTime();
    }
  }

  ngOnDestroy() {
    this.addnewProjectSubscription.unsubscribe();
    this.userId = null;
    this.LogDate = null;
  }

  async getDataProjectTime() {
    this.timeService.getTRSettings().subscribe((response) => {
      this.isEditable = this.timeService.checkIsEditable(this.LogDate, 'P', response);
    });
    this.cloneProjectData = this.storageService.getItem(StorageKeys.CLONE_PROJECT_DATA) || {};

    this.dateFor = this.LogDate;
    if (this.getDataInProgress) {
      return;
    }

    this.getDataInProgress = true;
    this.LogData = [];
    this.cookiesData = [];

    if (!this.userId) {
      this.getDataInProgress = false;
      return;
    }
    this.loading = true;
    this.url = this.timeService.getProjectTimeLogData(this.userId, this.LogDate, 'day');
    if (this.url) {
      this.url.subscribe(async (response: any) => {
        this.loading = false;
        this.LogData = response;
        await this.addalreadyAddedProjects()
          .then((updatedLogData) => {
            this.setDataTable();
            this.cdr.detectChanges();
            this.getDataInProgress = false;
          })
          .catch((error) => {
            this.getDataInProgress = false;
          });
      });
    }
  }

  setDataTable() {
    this.originalLogData = cloneDeep([...this.LogData]);

    this.itemDelete = false;
    this.itemSave = false;

    let total = 0;
    let totalActualHours = 0;
    let totalRestEstimation = 0;
    const estimationTrackers = [];
    this.LogData.forEach((item, index) => {
      total += item.billing.billingQuantity;
      item.cp.editMode = item.cp.cpNewAdded ? false : false;
      item.cp.internalCommentError = false;
      item.cp.externalCommentError = false;
      item.cp.timeError = false;
      item.billing.billingQuantityTime = item.billing.billingQuantity
        ? this.timeService.getNonNegativeValue(item.billing.billingQuantity)
        : '';

      if (this.isEnableTimerecordingAdditionalFields) {
        totalActualHours += item.billing.billingActualHours ?? 0;

        item.billing.billingActualHoursTime = item.billing.billingActualHours
          ? this.timeService.getNonNegativeValue(item.billing.billingActualHours)
          : '';
      }

      if (this.isRestExpenseEstimationActive) {
        if (!estimationTrackers.includes(item.cp.cpId)) {
          totalRestEstimation += item.billing.billingEstimation ? Number(item.billing.billingEstimation) : 0;
          estimationTrackers.push(item.cp.cpId);
        }

        item.billing.billingEstimationTime = item.billing.billingEstimation
          ? this.timeService.getNonNegativeValue(item.billing.billingEstimation)
          : '';
      }

      item.cp.percentDisplay = 100 < item.cp.cpPercentUsed ? 100 : item.cp.cpPercentUsed;
      item.cp.percentDisplay = 0 > item.cp.cpPercentUsed ? 0 : item.cp.cpPercentUsed;

      if (0 === item.cp.cpPlannedUnits && 0 < item.cp.cpUsedUnits) {
        item.cp.percentDisplay = 100;
      }

      /* Check this project cloneing data */
      if (this.cloneProjectData['isCloneProject']) {
        if (item.cp.cpNewAdded && item.cp.cpId === this.cloneProjectData['sidebarLastcpId'] && !this.itemSave) {
          item.cp.editMode = false;
          item.billing.billingId = this.cloneProjectData['billingId'];
          item.billing.billingExternalComment = this.cloneProjectData['billingExternalComment'];
          item.billing.billingInternalComment = this.cloneProjectData['billingInternalComment'];
          item.billing.billingIsBillable = this.cloneProjectData['billingIsBillable'];
          item.billing.billingQuantityTime = this.cloneProjectData['billingQuantityTime'];

          if (this.isEnableTimerecordingAdditionalFields) {
            item.billing.billingActualHours = this.cloneProjectData['billingActualHours'];
          }

          if (this.isRestExpenseEstimationActive) {
            item.billing.billingEstimation = this.cloneProjectData['billingEstimation'];
          }

          this.fromDate = new Date(this.cloneProjectData['billingDate']).valueOf();
          this.toDate = new Date(this.cloneProjectData['billingDate']).valueOf();
          this.dateFor = this.cloneProjectData['billingDate'];
          item.cp.cpNewAdded = false;
          this.setDataForCreateAndUpdate(item);
          this.itemSave = true;
          this.removeIdFromCookies(item.cp.cpId);
        }
      }
    });

    this.totalProjectTime = total;
    this.totalActualHours = totalActualHours;
    this.totalRestEstimation = totalRestEstimation;

    this.LogData.sort((a, b) => {
      const headerA = a.cp.cpPathHeader.toLowerCase();
      const headerB = b.cp.cpPathHeader.toLowerCase();

      if (headerA < headerB) {
        return -1;
      } else if (headerA > headerB) {
        return 1;
      } else {
        const tailA = a.cp.cpPathTail.toLowerCase();
        const tailB = b.cp.cpPathTail.toLowerCase();

        if (tailA < tailB) {
          return -1;
        } else if (tailA > tailB) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  }

  toggleEditMode(indexId: number) {
    this.timeService.cloneProjectDataSet();

    this.showError = false;
    this.showErrorMessage = '';

    this.fromDate = new Date(this.LogDate).valueOf();
    this.toDate = new Date(this.LogDate).valueOf();
    this.dateFor = this.LogDate;

    this.timeService.hideSideBar();

    this.LogData.forEach((item) => {
      item.cp.editMode = false;
    });
    const object = this.LogData[indexId];
    if (object) {
      // object.cp.editMode = true;
      const objectCopy = cloneDeep(object);
      objectCopy.cp.editMode = true;
      this.LogData[indexId] = objectCopy;
    }
  }

  toggleMyProjectsVisibility(cloneBtnClicked = false): void {
    if (!cloneBtnClicked) {
      this.timeService.cloneProjectDataSet();
    }
    this.timeService.toggleMyProjectsVisibility(this.userId, this.LogDate);
  }

  getValueInputField(item: any, field: string, index: number) {
    this.timeService.popupsetUnsavedChanges(true);

    if (this.LogData[index] && field === 'checkbox') {
      this.LogData[index].billing.billingIsBillable = !this.LogData[index].billing.billingIsBillable;
    }

    if (field === 'checkbox') {
      return;
    }

    if (
      field === 'checkbox' &&
      (item.billing.billingQuantityTime === null ||
        item.billing.billingQuantityTime === '' ||
        item.billing.billingQuantityTime === '00:00')
    ) {
      this.LogData[index].cp.timeError = true;
    }

    if (
      item.billing.billingQuantityTime === null ||
      item.billing.billingQuantityTime === '' ||
      item.billing.billingQuantityTime === '00:00'
    ) {
      this.LogData[index].cp.timeError = true;

      if (field === 'checkbox') {
        this.toggleEditMode(index);
      }

      return;
    } else if (item.billing.billingInternalComment === null || item.billing.billingInternalComment.trim() === '') {
      if (this.isTimeRecordingInternalCommentRequired) {
        this.LogData[index].cp.internalCommentError = true;

        if (field === 'checkbox') {
          this.toggleEditMode(index);
        }

        return;
      }
    } else if (item.billing.billingExternalComment === null || item.billing.billingExternalComment.trim() === '') {
      if (this.isTimeRecordingExternalCommentRequired) {
        this.LogData[index].cp.externalCommentError = true;

        if (field === 'checkbox') {
          this.toggleEditMode(index);
        }

        return;
      }
    } else {
      if (
        typeof item.billing.billingQuantityTime !== 'number' &&
        !this.timeService.isValidTimeFormat(item.billing.billingQuantityTime)
      ) {
        this.showError = true;

        this.notificationService.showError(this.translate.instant('Timerecording.invalid_time_format'));
        return;
      } else {
        this.showError = false;
        this.showErrorMessage = '';
      }
    }

    this.LogData[index].cp.timeError = false;
    this.LogData[index].cp.internalCommentError = false;
    this.LogData[index].cp.externalCommentError = false;
    this.setDataForCreateAndUpdate(item);
  }

  dateChanged(pickedDate: any): void {
    if ('undefined' === typeof pickedDate.date) {
      return;
    }
    const date: number = pickedDate.date;
    const dateFromatted: string = format(new Date(date[0]), 'yyyy-MM-dd');
    this.dateFor = dateFromatted;
  }

  /* Update Time */
  setDataForCreateAndUpdate(item) {
    this.timeService.cloneProjectDataSet();

    if (!this.timeService.checkIsEditable(this.dateFor, 'P')) {
      this.notificationService.showError(this.translate.instant('Timerecording.you_can_not_add_log_for_this_date'));
      return;
    }

    if (!this.userId) {
      return;
    }

    const newJson = this.prepareNewJson(item);

    this.loading = true;
    if (item.cp.cpNewAdded) {
      this.url = this.timeService.addProjectTimeLogData(this.userId, item.billing.billingId, newJson);
    } else {
      this.url = this.timeService.updateProjectTimeLogData(this.userId, item.billing.billingId, newJson);
    }

    if (this.url) {
      this.url.subscribe(
        (response: any) => {
          if (response.success) {
            this.notificationService.showSuccess(this.translate.instant('Common.labels.dataSuccessfullySaved'));
          } else {
            this.notificationService.showError(response.errorMessage || this.translate.instant('Common.error.general'));
          }

          this.loading = false;

          this.showError = false;
          this.showErrorMessage = '';

          if (item.cp.cpNewAdded) {
            this.removeIdFromCookies(item.cp.cpId);
          }

          if (!this.getDataInProgress) {
            this.getDataProjectTime();
          }

          this.timeService.reloading.next(true);
        },
        (error: any) => {
          this.loading = false;
          this.showError = true;
          if (error.error) {
            for (const key in error.error) {
              if (error.error.hasOwnProperty(key)) {
                const errorMessage = error.error[key][0];
                this.notificationService.showError(this.translate.instant(`Error for ${key}: ${errorMessage}`));
              }
            }
          } else {
            this.notificationService.showError(this.translate.instant('Timerecording.invalid_time_format'));
          }
        },
      );
    }
  }

  private prepareNewJson(item: any) {
    const newJson = {
      calculationPosition: item.cp.cpId,
      date: this.dateFor,
      workTime: item.billing.billingQuantityTime,
      internalComment: item.billing.billingInternalComment,
      externalComment: item.billing.billingExternalComment,
      billable: item.billing.billingIsBillable,
      projectTask: null,
      projectSubTask: null,
      employeeId: this.userId,
    };

    if (this.isEnableTimerecordingAdditionalFields) {
      newJson['actualHours'] = item.billing.billingActualHoursTime || item.billing.billingActualHours || '';
    }

    if (this.isRestExpenseEstimationActive) {
      newJson['estimation'] = item.billing.billingEstimationTime || item.billing.billingEstimation || '';
    }

    return newJson;
  }

  /* Delete Log */
  deleteLog(project, buttonClick = false) {
    if (buttonClick) {
      const confirmDelete = confirm(
        this.translate.instant('Timerecording.Are_you_sure_you_want_to_delete_this_project'),
      );
      if (!confirmDelete) {
        return;
      }
    }

    this.timeService.popupsetUnsavedChanges(true);

    this.timeService.cloneProjectDataSet();

    const billingId = project.billing.billingId;
    if (billingId === 0 || billingId === null) {
      this.removeIdFromCookies(project.cp.cpId);
      if (!this.getDataInProgress) {
        this.notificationService.showSuccess(this.translate.instant('Common.labels.dataSuccessfullyDeleted'));
        this.getDataProjectTime();
      }
    } else {
      this.url = this.timeService.deleteProjectTimeLogData(this.userId, billingId);
      if (this.url) {
        this.url.subscribe(
          (response: any) => {
            this.removeIdFromCookies(project.cp.cpId);
            if (!this.getDataInProgress) {
              this.notificationService.showSuccess(this.translate.instant('Common.labels.dataSuccessfullyDeleted'));
              this.getDataProjectTime();
            }

            this.showError = false;
            this.showErrorMessage = '';
            this.timeService.reloading.next(true);
          },
          (error: any) => {
            this.showError = true;
            this.notificationService.showError(this.translate.instant('Common.error.general'));
            // this.showErrorMessage = 'Something went wrong please try again.';
          },
        );
      }
    }
  }

  removeIdFromCookies(removeId: number): boolean {
    const rootUserId = this.loginUserId;
    const addProjectUserid = this.userId;
    const addProjectDate = this.LogDate;

    if (!rootUserId || !addProjectUserid || !addProjectDate) {
      return false;
    }

    const keyId = addProjectUserid + '_' + addProjectDate;

    const itemsStr = this.cookieService.get('item');
    const existingItems = itemsStr ? JSON.parse(itemsStr) : {};

    if (existingItems[rootUserId] && existingItems[rootUserId][keyId]) {
      const userItems = existingItems[rootUserId][keyId];
      const index = userItems.indexOf(removeId);

      if (index !== -1) {
        userItems.splice(index, 1);
        const expirationDate = new Date();
        expirationDate.setHours(23, 59, 59, 999);
        this.cookieService.set('item', JSON.stringify(existingItems), expirationDate);
      }
    }
    return;
  }

  async addalreadyAddedProjects() {
    try {
      const rootUserId = this.loginUserId;
      const addProjectUserid = this.userId;
      const addProjectDate = this.LogDate;

      if (!rootUserId || !addProjectUserid || !addProjectDate) {
        return this.LogData;
      }

      const keyId = addProjectUserid + '_' + addProjectDate;

      const itemsStr = this.cookieService.get('item');
      const existingItemsGet = itemsStr ? JSON.parse(itemsStr) : [];

      let existingItems =
        existingItemsGet[rootUserId] && existingItemsGet[rootUserId][keyId] ? existingItemsGet[rootUserId][keyId] : [];

      existingItems = existingItems.reverse();

      const cpIds = existingItems.map((item) => item);
      const cpIdsString = cpIds.join(',');

      if (cpIdsString !== null && cpIdsString !== '') {
        this.loading = true;
        const response = await this.timeService.getProjectTimeLogDataFromCpid(cpIdsString).toPromise();
        const resultArray = [];
        if (response) {
          this.loading = false;
          const cpIdsArray = cpIdsString.split(',').map(Number);

          cpIdsArray.forEach((cpId) => {
            const matchingObject = response.entries.find((item) => item.cp.cpId === cpId);
            if (matchingObject) {
              resultArray.push(matchingObject);
            }
          });
          // this.cookiesData = response.entries;
          this.cookiesData = resultArray;
          const newCookiesData = cloneDeep(this.cookiesData);
          this.LogData = [...this.LogData, ...newCookiesData].reverse();
          this.cdr.detectChanges();
        }
        return this.LogData;
      } else {
        return this.LogData;
      }
    } catch (error) {
      throw error;
    }
  }

  cloneData(data) {
    const randomString = data.cp.cpId + '+' + (typeof data.cp.cpNewAdded !== 'undefined' ? data.cp.cpNewAdded : false);

    const cloneData = {
      isCloneProject: true,
      sidebarLastcpId: 0,
      randomString,
      cpId: data.cp.cpId,
      billingId: data.billing.billingId,
      billingIsBillable: data.billing.billingIsBillable,
      billingQuantity: data.billing.billingQuantity,
      billingQuantityTime: data.billing.billingQuantityTime,
      billingExternalComment: data.billing.billingExternalComment,
      billingInternalComment: data.billing.billingInternalComment,
      billingDate: this.dateFor,
      type: 'Day',
      isCookieItem: typeof data.cp.cpNewAdded !== 'undefined' ? data.cp.cpNewAdded : false,
    };

    if (this.isEnableTimerecordingAdditionalFields) {
      cloneData['billingActualHours'] = data.billing.billingActualHours;
    }

    if (this.isRestExpenseEstimationActive) {
      cloneData['billingEstimation'] = data.billing.billingEstimation;
    }

    this.storageService.setItem(StorageKeys.CLONE_PROJECT_DATA, cloneData);

    /* Open sidebar */
    this.toggleMyProjectsVisibility(true);
  }

  public toggleOverlay(popover: any, data: any): void {
    if (data != null) {
      this.popup = {
        text: data || '-',
      };
    }
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }

  saveData(project) {
    this.timeService.popupsetUnsavedChanges(true);
    let timeError = false;
    let internalCommentError = false;
    let externalCommentError = false;

    if (
      project.billing.billingQuantityTime === null ||
      project.billing.billingQuantityTime === '' ||
      project.billing.billingQuantityTime === '00:00'
    ) {
      project.cp.timeError = true;
      timeError = true;
    } else {
      project.cp.timeError = false;
    }

    if (project.billing.billingInternalComment === null || project.billing.billingInternalComment.trim() === '') {
      if (this.isTimeRecordingInternalCommentRequired) {
        project.cp.internalCommentError = true;
        internalCommentError = true;
      }
    } else {
      project.cp.internalCommentError = false;
    }

    if (project.billing.billingExternalComment === null || project.billing.billingExternalComment.trim() === '') {
      if (this.isTimeRecordingExternalCommentRequired) {
        project.cp.externalCommentError = true;
        externalCommentError = true;
      }
    } else {
      project.cp.externalCommentError = false;
    }

    const anyErrors = timeError || internalCommentError || externalCommentError;
    if (!anyErrors) {
      this.setDataForCreateAndUpdate(project);
    }
  }

  public onEnterUp(event, project, field, index): void {
    event.preventDefault();
    if (project.billing.billingQuantityTime) {
      this.getValueInputField(project, field, index);
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape':
        if (this.isEnableInlineMode) {
          if (this.isProjectsChanged) {
            const alert = window.confirm(this.translate.instant('Timerecording.discard_inline_edit'));
            if (!alert) {
              return;
            }
          }

          this.resetData();
        }

        this.LogData = this.originalLogData;
        this.setDataTable();
        break;
    }
  }

  public assignProject() {
    this.modalService.object = {
      employeeId: this.userId,
    };
    this.modalService.init(TrAssignProjectModelComponent, {}, {}, '1300px', '1072px');
  }

  public async fillBoilerPlates(): Promise<void> {
    try {
      const response = await new Promise<IBoilerPlatesFormatted>((resolve) => {
        this.timeService.getBoilerPlates().subscribe((data) => {
          resolve(data);
        });
      });
      if (response) {
        this.boilerPlates = response;
      } else {
        this.notificationService.showError(this.translate.instant('Common.error.general'));
      }
    } catch (error) {
      this.notificationService.showError(this.translate.instant('Common.error.general'));
    }
  }

  public async toggleInlineEditMode(
    index?: number,
    type?: 'internalComment' | 'externalComment' | 'quantityTime' | 'actualHoursTime' | 'estimationTime' | 'isBillable',
  ): Promise<void> {
    if (this.isProjectsChanged && this.isEnableInlineMode) {
      const alert = window.confirm(this.translate.instant('Timerecording.discard_inline_edit'));
      if (!alert) {
        return;
      }

      this.resetData();
      return;
    }

    // here we disable the block edit if any are still in edit mode
    this.LogData.forEach((item) => {
      if (item.cp.editMode) {
        item.cp.editMode = false;
      }
    });

    this.isEnableInlineMode = !this.isEnableInlineMode;

    if (!this.isEnableInlineMode || index === undefined || !type) {
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 25));
    const inputElements = this[type].toArray();
    if (inputElements.length > 0) {
      if ((inputElements[index] as ElementRef<HTMLInputElement>).nativeElement) {
        (inputElements[index] as ElementRef<HTMLInputElement>).nativeElement.focus();
      } else {
        (inputElements[index] as TroiTextareaComponent | TroiTimeInputComponent).focus();
      }
    }
  }

  public markAsChanged(project: any) {
    project.isChanged = true;
    this.isProjectsChanged = true;
    this.timeService.setUnsavedChanges(true);
  }

  public saveAllProjects() {
    const urls$: Observable<any>[] = [];
    let timeError = false;
    let internalCommentError = false;
    let externalCommentError = false;

    this.LogData.forEach((project) => {
      if (project.isChanged) {
        const newJson = this.prepareNewJson(project);

        if (
          project.billing.billingQuantityTime === null ||
          project.billing.billingQuantityTime === '' ||
          project.billing.billingQuantityTime === '00:00'
        ) {
          project.cp.timeError = true;
          timeError = true;
        } else {
          project.cp.timeError = false;
        }

        if (project.billing.billingInternalComment === null || project.billing.billingInternalComment.trim() === '') {
          if (this.isTimeRecordingInternalCommentRequired) {
            project.cp.internalCommentError = true;
            internalCommentError = true;
          }
        } else {
          project.cp.internalCommentError = false;
        }

        if (project.billing.billingExternalComment === null || project.billing.billingExternalComment.trim() === '') {
          if (this.isTimeRecordingExternalCommentRequired) {
            project.cp.externalCommentError = true;
            externalCommentError = true;
          }
        } else {
          project.cp.externalCommentError = false;
        }

        if (timeError || internalCommentError || externalCommentError) {
          return;
        }

        if (project.cp.cpNewAdded) {
          urls$.push(this.timeService.addProjectTimeLogData(this.userId, project.billing.billingId, newJson));

          this.removeIdFromCookies(project.cp.cpId);
        } else {
          urls$.push(this.timeService.updateProjectTimeLogData(this.userId, project.billing.billingId, newJson));
        }

        project.isChanged = false;
      }
    });

    const anyErrors = timeError || internalCommentError || externalCommentError;

    if (urls$.length === 0 || anyErrors) {
      return;
    }

    this.loading = true;
    forkJoin(urls$).subscribe(() => {
      this.loading = false;
      this.isEnableInlineMode = false;
      this.isProjectsChanged = false;
      this.timeService.setUnsavedChanges(false);
      this.timeService.reloading.next(true);
    });
  }

  public handleKeyDown(event: KeyboardEvent, project: any): void {
    switch (event.key) {
      case 'Enter':
        if (event.shiftKey) {
          return;
        }
        event.preventDefault();
        project.isChanged = true;
        this.saveAllProjects();
        break;

      case 'Tab':
        return;

      case 'Escape':
        return;

      default:
        this.markAsChanged(project);
        break;
    }
  }

  private resetData(): void {
    this.LogData = this.originalLogData;
    this.isProjectsChanged = false;
    this.isEnableInlineMode = false;
    this.timeService.setUnsavedChanges(false);
    this.setDataTable();
  }
}
