import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ModuleInterceptor } from '../../../../core/enums/module-interceptor';
import { BackendResponseInterface } from '../../../../core/interfaces/backend.response.interface';
import { UploadedFileInterface } from '../../uploaded-files/interfaces/uploaded-file.interface';
import { environment } from '../../../../../environments/environment';
import { UploadRoutes } from '../../uploaded-files/enums/routes';

@Injectable()
export class UploadedFilesNetwork {
  public headers;

  public constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.BOOKINGS, '1');
  }

  getList(params): Observable<BackendResponseInterface<UploadedFileInterface[]>> {
    return this.http.get<BackendResponseInterface<UploadedFileInterface[]>>(
      environment.url + UploadRoutes.UPLOADED_FILES_LIST,
      { params, headers: this.headers },
    );
  }
}
