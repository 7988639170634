import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ParentWindowRef } from '../../core/services/parent-window-ref.service';
import { TabInterface } from '../troi-data-listing-tabs/tab.interface';

@Component({
  selector: 'troi-data-tabs',
  templateUrl: './troi-data-tabs.component.html',
  styleUrls: ['./troi-data-tabs.component.scss'],
})
export class TroiDataTabsComponent {
  @Input() tabs: TabInterface[] = [];
  @Input() width = '185px';
  @Output() tabChanged = new EventEmitter();

  constructor(private parentWindowRef: ParentWindowRef) {}

  private changeParentWindowUrl(url: string) {
    if (url) {
      const tempUrl = `index.php?page=${url}`;
      this.parentWindowRef.nativeHistory.pushState('', '', tempUrl);
    }
  }

  private changeTab(tab: TabInterface) {
    this.changeParentWindowUrl(tab.parentWindowUrl);
    this.tabChanged.emit(tab);
  }
}
