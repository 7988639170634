import { Component, EventEmitter, Output, Input, SimpleChanges, OnInit, OnChanges, ViewChild } from '@angular/core';
import { debounce } from 'lodash';

@Component({
  selector: 'tnd-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
})
export class SearchSelectComponent implements OnInit, OnChanges {
  public searchString = '';
  public resultSelected = false;
  public open = false;
  public multiSelectValues = [];
  public processedInitValues = false;
  public placement = 'bottom-start top-start bottom-end top-end';
  public showAll = false;
  public maximumNumberOfShownParticipants = 5;

  @ViewChild('TroiDropDownPopover') troiDropdownList: any;
  @Output() public searchStringChanged = new EventEmitter<string>();
  @Output() public valueSelected = new EventEmitter<any>();
  @Input() public searchResult: Array<any> = [];
  @Input() public loading = false;
  @Input() public valueFieldName: string;
  @Input() public labelFieldName1 = '';
  @Input() public labelFieldName2 = '';
  @Input() public placeholder = 'Please choose an option';
  @Input() public icon = '';
  @Input() public multiSelect = false;
  @Input() public initialSearch = '';
  @Input() public initialValues: Array<any> = [];
  @Input() public iconStyleTopPx: number;
  @Input() public fontSize: number;
  @Input() public fontFamily: string;
  @Input() public dropDownWidthPx: number;
  @Input() public isWithPopover = false;
  @Input() public disabled = false;

  @Input() transparentBackground = false;

  @Input() showSpinner = true;

  @Input() hideCloseIcon = false;

  constructor() {
    this.fetchData = debounce(this.fetchData, 500);
    this.onBlur = debounce(this.onBlur, 250);
    this.searchResult = [];
  }

  ngOnInit() {
    if (null !== this.initialSearch) {
      this.searchString = this.initialSearch;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialSearch) {
      if (null !== changes.initialSearch.currentValue) {
        this.searchString = changes.initialSearch.currentValue;
      }
    }

    if (false === this.processedInitValues && 0 < this.initialValues.length) {
      for (const val of this.initialValues) {
        this.setValue(val);
      }

      this.processedInitValues = true;
    }
  }

  fetchData() {
    if (this.showSpinner) this.loading = true;
    this.resultSelected = false;
    this.open = true;
    this.searchStringChanged.emit(this.searchString);
  }

  setValue(value: any) {
    if (!this.multiSelect) {
      this.searchString = value[this.labelFieldName1];
      if (this.labelFieldName2) {
        this.searchString += ' ' + value[this.labelFieldName2];
      }

      this.disabled = true;
      this.valueSelected.emit(value);
    } else {
      this.multiSelectValues.push(value);
      this.searchString = '';

      this.valueSelected.emit(this.multiSelectValues);
    }
    this.resultSelected = true;
  }

  remove(i: any) {
    this.multiSelectValues.splice(i, 1);
    this.valueSelected.emit(this.multiSelectValues);
  }

  reset() {
    this.disabled = false;
    this.searchString = '';
    this.resultSelected = false;
    this.valueSelected.emit();
  }

  onBlur() {
    this.troiDropdownList?.close();
    this.open = false;
  }

  public toggle(event?): void {
    event?.preventDefault();
    event?.stopPropagation();

    if (this.troiDropdownList?.isOpen()) {
      this.troiDropdownList.close();
    } else {
      this.troiDropdownList?.open();
    }

    this.open = !this.open;
  }

  public isSelected(result: any): boolean {
    return this.multiSelectValues.some((val) => val[this.valueFieldName] === result[this.valueFieldName]);
  }

  public toggleShowAll(): void {
    this.showAll = !this.showAll;
  }
}
