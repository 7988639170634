<header-notification></header-notification>
<div class="security-center">
  <div class="security-center__body container-fluid">
    <h2>{{ 'SecurityCenter.General.Header' | translate }}</h2>
    <section *ngIf="internalAuthenticator">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-2 align-middle text-center d-sm-none d-md-block">
            <img
              src="/assets/images/modules/settings/security-center/password-icon.svg"
              alt="Password input fields icons"
            />
          </div>
          <div class="col-4 align-middle text-left">
            <h3>{{ 'SecurityCenter.PasswordChange.Header' | translate }}</h3>
            <p>{{ 'SecurityCenter.PasswordChange.Body' | translate }}</p>
          </div>
          <div class="col-1 d-none d-sm-block"></div>
          <div class="col-4">
            <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
              <div class="form-group">
                <troi-input
                  label="{{ 'SecurityCenter.PasswordChange.CurrentPassword' | translate }}"
                  type="password"
                  formControlName="currentPassword"
                  [control]="changePasswordForm.controls.currentPassword"
                ></troi-input>
                <small id="currentPasswordHelp" class="form-text text-muted">{{
                  'SecurityCenter.PasswordChange.Verify' | translate
                }}</small>
              </div>
              <div class="form-group">
                <troi-input
                  label="{{ 'SecurityCenter.PasswordChange.NewPassword' | translate }}"
                  type="password"
                  formControlName="newPassword1"
                  [control]="changePasswordForm.controls.newPassword1"
                ></troi-input>
              </div>
              <div class="form-group">
                <troi-input
                  label="{{ 'SecurityCenter.PasswordChange.ConfirmNewPassword' | translate }}"
                  type="password"
                  formControlName="newPassword2"
                  [control]="changePasswordForm.controls.newPassword2"
                ></troi-input>
              </div>
              <troi-btn type="submit" [disabled]="!changePasswordForm.valid">{{
                'SecurityCenter.PasswordChange.Button' | translate
              }}</troi-btn>
            </form>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-2 align-middle text-center d-xs-none">
            <img
              src="/assets/images/modules/settings/security-center/personal-access-tokens-icon.svg"
              alt="Personal access tokens icon"
            />
          </div>
          <div class="col-4">
            <h3>{{ 'SecurityCenter.PersonalAccessTokens.Header' | translate }}</h3>
            <p>{{ 'SecurityCenter.PersonalAccessTokens.Body' | translate }}</p>
          </div>
          <div class="col-1 d-xs-none"></div>
          <div class="col-5">
            <h4>API v2 / Troi App</h4>
            <form [formGroup]="apiV2Form" (ngSubmit)="refreshV2Token()">
              <div class="form-group">
                <troi-input
                  label="Token"
                  type="text"
                  formControlName="token"
                  [control]="apiV2Form.controls.token"
                  [readOnly]="true"
                ></troi-input>
              </div>
              <troi-btn type="submit">{{
                'SecurityCenter.PersonalAccessTokens.Refresh' | translate
              }}</troi-btn>
            </form>
            <br />
            <h4>API v3</h4>
            <form [formGroup]="apiV3Form" (ngSubmit)="refreshV3Token()">
              <div class="form-group">
                <troi-input
                  label="Public token"
                  type="text"
                  formControlName="publicToken"
                  [control]="apiV3Form.controls.publicToken"
                  [readOnly]="true"
                ></troi-input>
              </div>
              <div class="form-group">
                <troi-input
                  label="Secret token"
                  type="text"
                  formControlName="secretToken"
                  [control]="apiV3Form.controls.secretToken"
                  [readOnly]="true"
                ></troi-input>
              </div>
              <troi-btn type="submit">{{
                'SecurityCenter.PersonalAccessTokens.Refresh' | translate
              }}</troi-btn>
            </form>
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="internalAuthenticator">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-2 align-middle text-center d-xs-none">
            <img
              src="/assets/images/modules/settings/security-center/two-factor-auth-icon.svg"
              alt="Two factor auth icon"
            />
          </div>
          <div class="col-4">
            <h3>{{ 'SecurityCenter.2FactorAuthentication.Header' | translate }}</h3>
            <p>{{ 'SecurityCenter.2FactorAuthentication.Body' | translate }}</p>
          </div>
          <div class="col-1 d-xs-none"></div>
          <div class="col-2" *ngIf="!isTwoFactorAuthSetUp">
            <p>{{ 'SecurityCenter.2FactorAuthentication.Step1' | translate }}</p>
            <p>{{ 'SecurityCenter.2FactorAuthentication.Step2' | translate }}</p>
          </div>
          <div class="col-3">
            <img style="display: block" id="qrCode" [src]="qrCodePath" />
            <div class="security-center__2fa-qr-button">
              <troi-btn [submit]="false" (click)="copySecretToClipboard()">copy secret</troi-btn>
            </div>
            <form [formGroup]="otpForm" (ngSubmit)="saveOTPToken()">
              <div class="form-group">
                <troi-input
                  type="text"
                  formControlName="token"
                  [control]="otpForm.controls.token"
                ></troi-input>
              </div>
              <div class="security-center__2fa-buttons">
                <troi-btn [submit]="true">{{
                  (isTwoFactorAuthSetUp && isTwoFactorAuthEnabled
                    ? 'SecurityCenter.2FactorAuthentication.Reactivate'
                    : 'SecurityCenter.2FactorAuthentication.Active'
                  ) | translate
                }}</troi-btn>
                <troi-btn
                  [submit]="false"
                  [disabled]="!isTwoFactorAuthEnabled"
                  (click)="deactivateOTPToken()"
                  *ngIf="isTwoFactorAuthEnabled"
                  >{{ 'SecurityCenter.2FactorAuthentication.Deactivate' | translate }}</troi-btn
                >
              </div>
            </form>

            <br />
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="isWebAuthNEnabled">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-2 align-middle text-center d-xs-none">
            <img
              src="/assets/images/modules/settings/security-center/register-device-icon.svg"
              alt="Register device icon"
            />
          </div>
          <div class="col-4">
            <h3>{{ 'SecurityCenter.Webauthn.Header' | translate }}</h3>
            <p>{{ 'SecurityCenter.Webauthn.Body' | translate }}</p>
          </div>
          <div class="col-1 d-xs-none"></div>
          <div class="col-4">
            <p>{{ 'SecurityCenter.Webauthn.Step1' | translate }}</p>
            <form [formGroup]="webauthnDeviceForm" (ngSubmit)="generateWebauthnRequest()">
              <div class="form-group">
                <troi-input
                  type="text"
                  formControlName="name"
                  [control]="webauthnDeviceForm.controls.name"
                ></troi-input>
              </div>
              <troi-btn type="submit">{{
                'SecurityCenter.Webauthn.Activate' | translate
              }}</troi-btn>
            </form>
            <hr />
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">{{ 'SecurityCenter.Webauthn.Name' | translate }}</th>
                  <th scope="col">{{ 'SecurityCenter.Webauthn.Added' | translate }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let column of webauthnDevices; let in = index">
                  <td>{{ column.name }}</td>
                  <td>{{ column.dateAdded.date }}</td>
                  <td>
                    <troi-btn type="submit" (click)="deleteWebauthnDevice(column.id)">{{
                      'SecurityCenter.Webauthn.Delete' | translate
                    }}</troi-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-2 align-middle text-center d-xs-none">
            <img
              src="/assets/images/modules/settings/security-center/auth-log-icon.svg"
              alt="Auth log icon"
            />
          </div>
          <div class="col-4">
            <h3>{{ 'SecurityCenter.AuthenticationLog.Header' | translate }}</h3>
            <p>{{ 'SecurityCenter.AuthenticationLog.Body' | translate }}</p>
          </div>
          <div class="col-1 d-xs-none"></div>
          <div class="col-5">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">
                    {{ 'SecurityCenter.AuthenticationLog.DateAndTime' | translate }}
                  </th>
                  <th scope="col">{{ 'SecurityCenter.AuthenticationLog.Activity' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let column of userLog; let in = index">
                  <td>{{ column.date.date }}</td>
                  <td>{{ column.result }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="security-center__footer">
    <troi-footer
      [disabledCurrency]="true"
      [settingsEnabled]="false"
      [loggingWorkingTimeEnabled]="false"
    ></troi-footer>
  </div>
</div>
