import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'troi-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
})
export class ProgressCircleComponent implements OnInit, OnChanges {
  @Input() solvedItems: number;
  @Input() totalItems: number;
  @Input() size: number;
  @Input() isActive: boolean;
  @Input() isNegative: boolean;
  @Input() hexColorCode: string;
  @Input() consumptionPopoverAlignment = false;

  smallDevice = false;
  mediumDevice = false;
  largeDevice = false;
  solvedItemsString = '';

  ngOnInit() {
    this.checkDeviceHeight();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.solvedItems) {
      this.solvedItemsString = changes.solvedItems.currentValue?.toFixed(2);
    }
  }

  checkDeviceHeight() {
    const height = window.innerHeight;
    if (height < 800) this.smallDevice = true;
    else if (height > 800 && height < 1000) this.mediumDevice = true;
    else if (height > 1000) this.largeDevice = true;
  }

  get radius() {
    if (this.size) return this.size;
    else if (this.smallDevice) return 28;
    else if (this.mediumDevice) return 35;
    else return 40;
  }

  get curProgress() {
    return (this.solvedItems / this.totalItems) * 100;
  }

  get outerColor() {
    if (this.hexColorCode) return this.hexColorCode;
    else return '#4fadf9';
  }

  get innerColor(): string {
    return this.isActive ? '#4fadf9' : '#ECF6FB';
  }

  get top() {
    if (this.smallDevice && this.solvedItems >= 0) return '27px';
    else if (this.mediumDevice && this.solvedItems >= 0) return '35px';
    else if (this.largeDevice && this.solvedItems >= 0) return '40px';
    else if (this.smallDevice && !this.solvedItems) return '40px';
    else if (this.mediumDevice && !this.solvedItems) return '45px';
    else if (this.largeDevice && !this.solvedItems) return '50px';
  }

  constructor() {}
}
