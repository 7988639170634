import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TroiInputFieldComponent } from '../troi-input-field/troi-input-field.component';
import { TimeParserObject } from './interfaces/time-parser.interface';
import { TimeParserModel } from './models/time-parser.model';

@Component({
  selector: 'troi-time-input',
  templateUrl: './troi-time-input.component.html',
  styleUrls: ['./troi-time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TroiTimeInputComponent),
      multi: true,
    },
  ],
})
export class TroiTimeInputComponent implements ControlValueAccessor {
  @Input() public disabled = false;
  @Input() public placeholder = '';
  @Input() public icon: string;

  @Input() public comment = '';
  @Output() public commentChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() public focusOut = new EventEmitter<boolean>();

  public isInvalid = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  private _value: string | null;
  private _timeParser = new TimeParserModel();
  public localValue = '';

  @ViewChild('inputField', { static: true, read: TroiInputFieldComponent }) inputField: TroiInputFieldComponent;

  get value() {
    return this._value;
  }

  set value(val) {
    if (val !== this._value) {
      this._value = val;
      this.onChange(this.toModel(this._value));
      this.onTouched();
    }
  }

  writeValue(value: any) {
    if (value) {
      this.value = this.fromModel(value);
    } else {
      this.value = '';
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  fromModel(value: string | null): string | null {
    if (!value) {
      return null;
    }

    return value;
  }

  toModel(value: string | null): string | number | null {
    if (!value) {
      return null;
    }

    try {
      const parserResult = this._timeParser.parse(value);
      if (parserResult.error) {
        this.isInvalid = true;
        return '';
      }
      if (parserResult.isTimeRange) this.appendComment(parserResult);
      this.isInvalid = false;
      return parserResult.timeDiff;
    } catch (error) {
      this.isInvalid = true;
      return '';
    }
  }

  private appendComment(parserResult: TimeParserObject) {
    if (!this.comment) this.comment = '';
    this.comment = this.comment.split(' - (')[0].split('- (')[0];
    this.comment += ` - (${parserResult.formattedTime})`;
    this.commentChange.emit(this.comment);
  }

  public focus() {
    this.inputField.setFocusOnInput();
  }
}
