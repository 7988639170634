import { ClientInterface } from '../../../../../core/interfaces/client.interface';
import { MonthlyCostModel } from './monthly-cost.model';

export class CostCenterModel {
  constructor(
    public id: number,
    public client: ClientInterface,
    public year: number,
    public number: number,
    public description: string,
    public active: boolean,
    public fixedCosts: MonthlyCostModel[],
    public extraordinaryCosts: MonthlyCostModel[],
    public canBeDeleted: boolean,
  ) {}
}
