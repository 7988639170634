import { UserConfirmationSubscriber } from './../../../../../shared/troi-user-confirmation/user-confirmation.subscriber';
import { UserConfirmationEventEnum } from './../../../../../shared/troi-user-confirmation/user-confirmation-event.enum';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { BaseModalDirective } from '../../../../../shared/troi-base-modal/baseModal.component';
import { ModalService } from '../../../../../shared/troi-modals/modal.service';

import { ReminderValue } from '../../../enum/reminder-value';
import { TaskActionsEnum } from '../../../enum/task-actions';
import { TaskModel } from '../../../models/task.model';
import { ReminderInterface } from '../../../interfaces/reminder.interface';
import { ReminderService } from '../../../network/reminder.service';
import { StorageNotificationService } from '../../../../../core/notifications/storageNotification.service';

import { UserModel } from '../../../../../core/user/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'troi-reminder-task-modal',
  templateUrl: './reminder-task-modal.component.html',
  styleUrls: ['./reminder-task-modal.component.scss'],
})
export class ReminderTaskModalComponent extends BaseModalDirective implements OnInit {
  @Input() translations;
  @Input() reminder: ReminderInterface;
  @Input() action: TaskActionsEnum;
  @Input() task: TaskModel;
  @Input() user: UserModel;

  placeholder = 'Tasks.labels.reminderTaskModal.placeholder';

  public fromDate: number = Date.now();
  public toDate: number = Date.now();

  public showDatepicker = false;
  public selectedReminderDate: Date;

  public options = [
    {
      active: true,
      label: 'Tasks.labels.reminderTaskModal.options.oneDay',
      value: ReminderValue.ONE_DAY,
    },
    {
      active: true,
      label: 'Tasks.labels.reminderTaskModal.options.oneWeek',
      value: ReminderValue.ONE_WEEK,
    },
    {
      active: true,
      label: 'Tasks.labels.reminderTaskModal.options.twoWeeks',
      value: ReminderValue.TWO_WEEKS,
    },
    {
      active: true,
      label: 'Tasks.labels.reminderTaskModal.options.threeWeeks',
      value: ReminderValue.THREE_WEEKS,
    },
    {
      active: true,
      label: 'Tasks.labels.reminderTaskModal.options.individual',
      value: ReminderValue.INDIVIDUAL,
    },
  ];

  public reminderTaskForm = new FormGroup({
    selectReminder: new FormControl(),
    timePicker: new FormControl(),
  });

  constructor(
    public modalService: ModalService,

    private subscriber: UserConfirmationSubscriber,
    private reminderService: ReminderService,
    private notificationService: StorageNotificationService,
    private translate: TranslateService,
  ) {
    super(modalService, false, true);
  }

  ngOnInit() {
    if (this.reminder) {
      this.selectedReminderDate = new Date(this.reminder.reminderDate);
      this.checkSelectedDate(this.task.startDate);
    }
  }

  private checkSelectedDate(taskDate: Date) {
    const formattedTaskDate = new Date(taskDate);
    const timeDifference = this.selectedReminderDate.getTime() - formattedTaskDate.getTime();
    const days = timeDifference / (1000 * 60 * 60 * 24);

    if (days === -1) this.placeholder = 'Tasks.labels.reminderTaskModal.options.oneDay';
    else if (days === -7) this.placeholder = 'Tasks.labels.reminderTaskModal.options.oneWeek';
    else if (days === -14) this.placeholder = 'Tasks.labels.reminderTaskModal.options.twoWeeks';
    else if (days === -21) this.placeholder = 'Tasks.labels.reminderTaskModal.options.threeWeeks';
    else {
      this.placeholder = 'Tasks.labels.reminderTaskModal.options.individual';
      this.showDatepicker = true;
      this.fromDate = this.selectedReminderDate.getTime();
    }
  }

  public selectReminderDate(date: ReminderValue) {
    const msPerDay = 24 * 60 * 60 * 1000;
    const ms1Week = 1 * 7 * msPerDay;

    const tasksStartDate = new Date(this.task.startDate);

    if (date === ReminderValue.ONE_DAY) {
      this.selectedReminderDate = new Date(tasksStartDate.getTime() - msPerDay);
      this.showDatepicker = false;
    } else if (date === ReminderValue.ONE_WEEK) {
      this.selectedReminderDate = new Date(tasksStartDate.getTime() - ms1Week);
      this.showDatepicker = false;
    } else if (date === ReminderValue.TWO_WEEKS) {
      this.selectedReminderDate = new Date(tasksStartDate.getTime() - 2 * ms1Week);
      this.showDatepicker = false;
    } else if (date === ReminderValue.THREE_WEEKS) {
      this.selectedReminderDate = new Date(tasksStartDate.getTime() - 3 * ms1Week);
      this.showDatepicker = false;
    } else if (date === ReminderValue.INDIVIDUAL) {
      this.showDatepicker = true;
    }
  }

  public setIndividualDate(date: any) {
    this.selectedReminderDate = new Date(date.date[0]);
  }

  public execute() {
    if (this.reminder) {
      this.reminder.reminderDate = this.selectedReminderDate;
      this.reminderService.updateReminder(this.reminder);
    } else {
      this.reminderService.setReminder(this.user, this.task.id, this.selectedReminderDate);
    }

    this.subscriber.action.next(UserConfirmationEventEnum.EXECUTE);
    this.subscriber.actionWithData.next({
      event: UserConfirmationEventEnum.EXECUTE,
      data: { action: this.action },
    });
    super.closeSecond();
  }

  public deleteReminder() {
    this.reminderService.deleteReminder(this.reminder.id);
    this.notificationService.showSuccess(this.translate.instant('Tasks.labels.notifications.reminder.deleted'));

    super.closeSecond();
  }

  public close() {
    super.closeSecond();
  }
}
