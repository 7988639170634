import { Component, EventEmitter, Input, Output, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ModalService } from '../../../../shared/troi-modals/modal.service';
import { ApprovalsService } from '../../services/approvals.service';
import { ApprovalsNetwork } from '../../networks/approvals.network';
import { CancelApprovalComponent } from '../../modals/cancel-approval/cancel-approval.component';
import { DeclineApprovalComponent } from '../../modals/decline-approval/decline-approval.component';
import { ApproveApprovalComponent } from '../../modals/approve-approval/approve-approval.component';
import { ApprovalResponseInterface } from '../../interfaces/approvals-api.interfaces';

@Component({
  selector: 'approval-actions',
  templateUrl: './approval-actions.component.html',
  styleUrls: ['./approval-actions.component.scss'],
})
export class ApprovalActionsComponent implements AfterViewInit {
  @Input() id: string;
  @Input() approvalStatus: ApprovalResponseInterface;
  @Output() openStateChanged = new EventEmitter<boolean>();
  @Output() update = new EventEmitter<boolean>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public approvalsService: ApprovalsService,
    public approvalsNetwork: ApprovalsNetwork,
    public modalService: ModalService,
  ) {}

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  onCancel() {
    this.modalService.init(CancelApprovalComponent, {}, { parent: this }, '500px', '800px', true);
  }

  onDecline() {
    this.modalService.init(DeclineApprovalComponent, {}, { parent: this }, '500px', '800px', true);
  }

  onApprove() {
    if (this.approvalsService.isCommentForAllActionsEnabled(this.approvalStatus)) {
      this.modalService.init(ApproveApprovalComponent, {}, { parent: this }, '500px', '800px', true);
    } else {
      this.approveApproval();
    }
  }

  declineApproval(reason: string) {
    this.approvalsNetwork.decline(this.id, { reason }).subscribe((response) => {
      this.onApprovalStatusChange();
    });
  }
  cancelApproval(reason: string) {
    this.approvalsNetwork.cancel(this.id, { reason }).subscribe((response) => {
      this.onApprovalStatusChange();
    });
  }
  approveApproval(reason?: string) {
    this.approvalsNetwork.approve(this.id, { reason }).subscribe((response) => {
      this.onApprovalStatusChange();
    });
  }
  onApprovalStatusChange() {
    this.update.emit(true);
  }
}
