import { HttpEventType } from '@angular/common/http';

export enum BackendResponseType {
  TYPE_OBJECT = 'object',
  TYPE_COLLECTION = 'collection',
  TYPE_ERROR = 'error',
  TYPE_STRING = 'string',
  TYPE_NUMBER = 'number',
  TYPE_STATUS = 'status',
}

export enum AccessRight {
  READ = 'read',
  WRITE = 'write',
  NONE = 'none',
}

export interface BackendResponseInterface<T> {
  totalPerApproval?: {
    approved: string;
    pending: string;
    declined: string;
    started: string;
  };
  type: BackendResponseType;
  data: T;
  total?: string;
  totalCount?: number;
  pageTotalQuantity?: number;
  totalQuantity?: number;
  projectContextId?: number;
  access?: AccessRight;
  pageTotal?: string;
  allIds?: string[];
  tempId?: string;
  loaded?: HttpEventType.UploadProgress | HttpEventType.DownloadProgress;
}

export interface BackendResponseWithSettingsInterface<D, S> extends BackendResponseInterface<D> {
  settings: S;
}

export interface BackendResponseDropdownDataInterface<T> {
  items: T;
  errorMessage: string;
  page: {
    current_page: number;
    per_page: number;
    total_elements: number;
    total_pages: number;
  };
}

export interface ResponseStatusInterface {
  success: boolean;
  reason: string;
}

export interface ResponseImportedBookingReceiptsInterface {
  details?: {
    imported: number;
  };
  success: boolean;
  reason?: string;
}
