import { Component, Input } from '@angular/core';
import { BudgetStatusInterface } from './interfaces/budget-status.interface';
import { SliderValues } from '../troi-slider/troi-slider.component';

interface MouseHoverEvent extends MouseEvent {
  toElement: Element;
}

interface NumberConfig {
  digitsInfo: string;
  decimalMark: string;
  thousandsMark: string;
}

@Component({
  selector: 'troi-budget-status',
  templateUrl: './troi-budget-status.component.html',
  styleUrls: ['./troi-budget-status.component.scss'],
})
export class TroiBudgetStatusComponent {
  public showSpentBudgetInUnit = false;
  public showBudgetStatusTooltip = false;

  @Input() budgetStatus: BudgetStatusInterface;
  @Input() unitOfMeasure = '';
  @Input() warningPoints: SliderValues = {
    lo: 50,
    hi: 75,
  };
  @Input() numberConfig: NumberConfig = {
    digitsInfo: '1.2-2',
    decimalMark: ',',
    thousandsMark: '.',
  };

  public get filledBarWidth(): string {
    return `${this.budgetStatus.percentUsed}%`;
  }

  public get remainingBarWidth(): string {
    return `${100 - Number(this.budgetStatus.percentUsed)}%`;
  }

  private static mouseHoverEventTarget(event: MouseHoverEvent): Element | EventTarget {
    return event.toElement || event.relatedTarget || event.currentTarget;
  }

  public progressBarMouseOut(event: MouseEvent): void {
    const mouseHoverEvent = event as MouseHoverEvent;
    const mouseEventTarget = TroiBudgetStatusComponent.mouseHoverEventTarget(mouseHoverEvent);

    if (mouseEventTarget['className'] === 'values__percent') {
      this.showBudgetStatusTooltip = false;
    } else if (mouseEventTarget['className'] === 'progress__empty-bar') {
      this.showBudgetStatusTooltip = true;
      this.showSpentBudgetInUnit = false;
    } else {
      this.showSpentBudgetInUnit = false;
    }
  }

  valuesMouseOut(event: MouseEvent) {
    const mouseHoverEvent = event as MouseHoverEvent;
    const mouseEventTarget = TroiBudgetStatusComponent.mouseHoverEventTarget(mouseHoverEvent);

    if (mouseEventTarget['className'] !== 'progress__bar') {
      this.showBudgetStatusTooltip = false;
      this.showSpentBudgetInUnit = false;
    }
  }

  public progressEmptyBarMouseOut(event: MouseEvent): void {
    const mouseHoverEvent = event as MouseHoverEvent;
    const mouseEventTarget = TroiBudgetStatusComponent.mouseHoverEventTarget(mouseHoverEvent);

    if (mouseEventTarget['className'] === 'values__units') {
      this.showSpentBudgetInUnit = false;
    } else if (mouseEventTarget['className'] === 'progress__bar') {
      this.showSpentBudgetInUnit = true;
      this.showBudgetStatusTooltip = false;
    } else {
      this.showBudgetStatusTooltip = false;
    }
  }

  unitsMouseOut(event: MouseEvent) {
    const mouseHoverEvent = event as MouseHoverEvent;
    const mouseEventTarget = TroiBudgetStatusComponent.mouseHoverEventTarget(mouseHoverEvent);

    if (mouseEventTarget['className'] !== 'progress__empty-bar') {
      this.showBudgetStatusTooltip = false;
      this.showSpentBudgetInUnit = false;
    }
  }

  public getWarnClass(percentUsed: number | string): string {
    const percentUsedNumber = Number(percentUsed);

    if (isNaN(percentUsedNumber)) {
      return;
    }

    if (percentUsedNumber >= this.warningPoints.hi) {
      return 'troi-budget-status--high-warning';
    }

    if (percentUsedNumber >= this.warningPoints.lo) {
      return 'troi-budget-status--low-warning';
    }
  }
}
