import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

const reformatDate = (format) => {
  let newFormat = format;
  if (newFormat.includes('d')) {
    newFormat = newFormat.replace(/d/g, 'D');
  }
  if (newFormat.includes('y')) {
    newFormat = newFormat.replace(/y/g, 'Y');
  }
  return newFormat;
};

@Pipe({ name: 'troiDate' })
export class TroiDatePipe implements PipeTransform {
  transform(date: Date | string, format: string = 'dd.MM.YYYY'): string {
    const momentFormat = reformatDate(format);
    if (!date) {
      return '';
    }
    const _date = new Date(date);
    return moment(_date).format(momentFormat);
  }
}
