import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservedValuesFromArray } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ModuleInterceptor } from '../../../core/enums/module-interceptor';
import { Routes } from '../enum/routes';

@Injectable()
export class ExportService {
  private headers: HttpHeaders;
  private httpOptions;

  public constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');
    this.httpOptions = {
      headers: this.headers,
      responseType: 'text/csv',
    };
  }

  public exportTasklist(filterSet, defaultIds: number[]): Observable<any> {
    const body = {
      currentPage: 1,
      format: 'csv',
    };

    if (filterSet.dropdownFirst > 0) body['client'] = filterSet.dropdownFirst;
    if (filterSet.dropdownSecond > 0) body['projectId'] = filterSet.dropdownSecond;
    if (filterSet.dropdownThird && filterSet.dropdownThird.length > 0)
      body['assignee'] = filterSet.dropdownThird.join(',');

    if (filterSet.filters[0].value !== '') body['status'] = filterSet.filters[0].value.toString();
    else body['status'] = defaultIds.join(',');

    if (filterSet.filters[1].value[0]) body['timerangeFrom'] = filterSet.filters[1].value[0];
    if (filterSet.filters[1].value[1]) body['timerangeTo'] = filterSet.filters[1].value[1];
    if (filterSet.filters[2].value[0]) body['creationDateFrom'] = filterSet.filters[2].value[0];
    if (filterSet.filters[2].value[1]) body['creationDateTo'] = filterSet.filters[2].value[1];

    if (filterSet.filters[4].value !== '') body['creator'] = filterSet.filters[4].value;
    if (filterSet.filters[5].value !== '') body['priority'] = filterSet.filters[5].value;

    if (filterSet.search) body['searchPhrase'] = filterSet.search;

    return this.http.post(environment.url + Routes.EXPORT, body, this.httpOptions);
  }
}
