import { LanguageTranslationsBackend } from '../../../../../../../core/interfaces/languageTranslations.backend';

export class FolderFieldsModel {
  public constructor(
    public name: LanguageTranslationsBackend,
    public active: boolean,
    public hasActiveElement: boolean,
    public shortcut?: LanguageTranslationsBackend,
  ) {}
}
