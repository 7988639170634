<div
  #selectAssigneeDrowdown
  class="assignee-selection"
  ngbPopover="{{ 'Tasks.labels.createTaskModal.alreadyAssigned' | translate }}"
  placement="bottom"
  #assignmentPopover="ngbPopover"
  triggers="manual"
>
  <troi-icon [medium]="true" icon="icon-empty-person" class="icon"></troi-icon>
  <div [ngClass]="{ focus: showHighlightBorder }" class="assignee-selection__select">
    <div class="assignee-selection__select--selected-option">
      <div
        *ngIf="noTimerangeSet() || isDisabled()"
        class="disabled"
        ngbPopover="{{
          noTimerangeSet()
            ? ('Tasks.labels.createTaskModal.addTimerange' | translate)
            : ('Tasks.labels.createTaskModal.saveTaskFirst' | translate)
        }}"
        placement="bottom"
        #actionRequiredPopover="ngbPopover"
      ></div>
      <div class="row d-flex align-items-center justify-content-center w-100 m-0">
        <div class="col-8 p-0">
          <input
            [placeholder]="
              'Tasks.labels.createTaskModal.assigneeSelection.assignEmployee' | translate
            "
            [(ngModel)]="assigneeSearch"
            type="text"
            (focus)="toggleHighlightBorder()"
            (blur)="toggleHighlightBorder()"
            class="w-100"
          />
        </div>
        <div class="d-flex col-4 p-0" role="button">
          <span
            (click)="assignMe()"
            class="assignee-selection__select--assign-me p-0 d-flex justify-content-center"
            >{{ 'Tasks.labels.createTaskModal.assigneeSelection.assignMe' | translate }}</span
          >
          <troi-icon
            (click)="toggleOptions()"
            [medium]="true"
            icon="icon-bold-arrow-down"
          ></troi-icon>
        </div>
      </div>
    </div>

    <troi-task-modal-select-assignee-dropdown
      #dropdownComp
      *ngIf="showDropdown || assigneeSearch"
      [showDropdown]="showDropdown || assigneeSearch"
      [taskHasDate]="task.startDate && task.endDate"
      [assigneeSearch]="assigneeSearch"
      [task]="task"
      (assignedEmployee)="assignEmployee($event)"
      (assignedTeam)="assignTeam($event)"
    ></troi-task-modal-select-assignee-dropdown>
  </div>
</div>
