<div class="modal-confirmation">
  <troi-modal-header [noCloseButton]="true">
    <span>{{ translations.title | translate }}</span>
  </troi-modal-header>
  <troi-modal-content style="overflow-y: inherit">
    <p class="modal-confirmation__message">{{ translations.description | translate }}</p>
  </troi-modal-content>
  <troi-modal-footer>
    <troi-btn class="modal-confirmation__button" [outline]="true" (click)="close()">
      {{ translations.cancelBtnTitle | translate }}
    </troi-btn>
    <troi-btn class="modal-confirmation__button" (click)="execute()">
      {{ translations.executeBtnTitle | translate }}
    </troi-btn>
  </troi-modal-footer>
</div>
