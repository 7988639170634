import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import * as moment from 'moment';

export interface DateFocusOutInterface {
  date: string;
  isValid: boolean;
}

@Directive({
  exportAs: 'date-format',
  selector: 'date-format, [date-format]',
})
export class DateDirective {
  @Input('date-format') dateFormat = 'DD.MM.YYYY';
  @Output() dateFocusOut = new EventEmitter<DateFocusOutInterface>();

  private get dateSeparator(): string {
    const separator = this.dateFormat.split('').find((char: string) => {
      return !['D', 'M', 'Y', 'd', 'm', 'y'].includes(char);
    });

    return separator || '.';
  }

  constructor(private el: ElementRef, private cdRef: ChangeDetectorRef) {}

  @HostListener('focusout', ['$event.target'])
  focusout() {
    const { value } = this.el.nativeElement;
    const valueParts = value.split(' - ');
    this.el.nativeElement.value =
      valueParts.length === 2 ? valueParts.map(this.insertDotsInDate).join(' - ') : this.insertDotsInDate(value);
    this.cdRef.detectChanges();
    this.dateFocusOut.emit({
      date: this.el.nativeElement.value,
      isValid:
        valueParts.length === 2
          ? valueParts.every((part) => moment(part, this.dateFormat, true).isValid())
          : moment(this.el.nativeElement.value, this.dateFormat, true).isValid(),
    });
  }

  insertDotsInDate = (baseText: string): string => {
    const text = baseText.split(this.dateSeparator).join('');

    if (text.length < 6) {
      return baseText;
    }

    return moment(moment(baseText, this.dateFormat).toDate()).format(this.dateFormat);
  };
}
