<ng-container *ngIf="isWithPopover; else standardSelectSearch">
  <span
    #TroiDropDownPopover="ngbPopover"
    [ngbPopover]="dropDownTemplate"
    [autoClose]="'outside'"
    [placement]="placement"
    [positionTarget]="positionTarget"
    triggers="manual"
    (click)="toggle($event)"
    class="troi-dropdown-list"
    popoverClass="troi-dropdown-list-popover"
  >
    <troi-icon
      [icon]="icon"
      [style.top.px]="iconStyleTopPx"
      *ngIf="icon.length > 0"
      class="tnd-search-select__icon-type"
    >
    </troi-icon>
    <input
      #positionTarget
      type="text"
      (keyup)="fetchData()"
      (blur)="onBlur()"
      [(ngModel)]="searchString"
      (click)="toggle($event)"
      [disabled]="disabled"
      class="tnd-search-select__input"
      placeholder="{{ placeholder }}"
      [style.font-size.px]="fontSize"
      [style.font-family]="fontFamily"
    /><br />
    <troi-icon
      *ngIf="!TroiDropDownPopover.isOpen() && !disabled"
      icon="icon-bold-arrow-down"
      class="tnd-search-select__icon-state"
    ></troi-icon>
    <troi-icon
      *ngIf="TroiDropDownPopover.isOpen() && !disabled"
      icon="icon-bold-arrow-up"
      class="tnd-search-select__icon-state"
    ></troi-icon>
    <troi-icon
      *ngIf="disabled"
      icon="icon-close"
      class="tnd-search-select__icon-state"
      (click)="reset()"
    ></troi-icon>
  </span>
  <ng-container *ngTemplateOutlet="selectedOptionsTemplate"></ng-container>
</ng-container>

<ng-template #standardSelectSearch>
  <div class="tnd-search-select" [ngClass]="{ 'has-icon': icon }">
    <troi-icon
      [icon]="icon"
      *ngIf="icon.length > 0"
      class="tnd-search-select__icon-type"
    ></troi-icon>
    <input
      type="text"
      (keyup)="fetchData()"
      (blur)="onBlur()"
      [(ngModel)]="searchString"
      (click)="toggle()"
      [disabled]="disabled"
      class="tnd-search-select__input"
      placeholder="{{ placeholder }}"
    /><br />
    <troi-icon
      *ngIf="!open && !disabled"
      icon="icon-bold-arrow-down"
      class="tnd-search-select__icon-state"
    ></troi-icon>
    <troi-icon
      *ngIf="open && !disabled"
      icon="icon-bold-arrow-up"
      class="tnd-search-select__icon-state"
    ></troi-icon>
    <troi-icon
      *ngIf="disabled"
      icon="icon-close"
      class="tnd-search-select__icon-state"
      (click)="reset()"
    ></troi-icon>
    <ng-container *ngTemplateOutlet="dropDownTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="selectedOptionsTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #dropDownTemplate>
  <ul
    *ngIf="open"
    class="tnd-search-select__result-list tnd-scrollbar"
    [ngClass]="{
      'tnd-search-select__result-popover': isWithPopover,
      'tnd-search-select__result-list': !isWithPopover
    }"
    [style.width.px]="dropDownWidthPx"
  >
    <troi-loading-spinner *ngIf="loading"></troi-loading-spinner>
    <div
      class="sideBarOpen"
      *ngIf="!resultSelected && searchResult && searchResult.length > 0 && open"
    >
      <li
        (click)="setValue(result); toggle($event)"
        *ngFor="let result of searchResult"
        [class.tnd-search-select__list-item-selected]="isSelected(result)"
        class="tnd-search-select__list-item"
      >
        {{ labelFieldName1.length ? result[labelFieldName1] : '' }}&nbsp;
        {{ labelFieldName2.length ? result[labelFieldName2] : '' }}
      </li>
    </div>
    <div *ngIf="searchResult && searchResult.length === 0 && open && !loading">
      <span class="tnd-search-select__no-result font-italic">
        {{ 'Desktop.General.Messages.noResults' | translate }}
      </span>
    </div>
  </ul>
</ng-template>

<ng-template #selectedOptionsTemplate>
  <ul *ngIf="multiSelect" class="tnd-search-select__chip-list my-2">
    <ng-container
      *ngFor="
        let result of showAll
          ? multiSelectValues
          : (multiSelectValues | slice : 0 : maximumNumberOfShownParticipants);
        index as i
      "
    >
      <li class="tnd-search-select__chip">
        <div class="attendee attendee--no-avatar me-1">
          <div class="attendee__initials">
            {{
              result[labelFieldName1]?.substring(0, 2).toUpperCase() ||
                result[labelFieldName2]?.substring(0, 2).toUpperCase()
            }}
          </div>
        </div>
        <p class="tnd-search-select__chip-attendee-name mb-0 me-auto">
          {{ labelFieldName1.length ? result[labelFieldName1] : '' }}
          {{ labelFieldName2.length ? result[labelFieldName2] : '' }}
        </p>
        <span class="d-flex me-2">
          <troi-icon
            ngClass="delete-chip"
            icon="icon-close"
            (click)="remove(i)"
            [size]="'7px'"
          ></troi-icon>
        </span>
      </li>
    </ng-container>
    <li class="tnd-search-select__show-more-btn">
      <button
        *ngIf="multiSelectValues.length > maximumNumberOfShownParticipants && !showAll"
        (click)="toggleShowAll()"
      >
        {{
          'Desktop.Widgets.Calendar.CreateDates.MorePeople'
            | translate : { count: multiSelectValues.length - maximumNumberOfShownParticipants }
        }}
      </button>
      <button *ngIf="showAll" (click)="toggleShowAll()">Show Less</button>
    </li>
  </ul>
</ng-template>
