import { Component, EventEmitter, Input, Output, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ApprovalsService } from '../../services/approvals.service';
import { ApprovalsNetwork } from '../../networks/approvals.network';
import { ApprovalsStatusEnum } from '../../enums/approvals-enums';

@Component({
  selector: 'approval-status-bar',
  templateUrl: './approval-status-bar.component.html',
  styleUrls: ['./approval-status-bar.component.scss'],
})
export class ApprovalStatusBarComponent implements AfterViewInit {
  public approvalStatus;
  public approvalsStatusEnum = ApprovalsStatusEnum;
  @Input() placement = 'bottom top';
  @Input() alignTop = false;
  @Input() id: string;

  @Output() openStateChanged = new EventEmitter<boolean>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<boolean>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public approvalsService: ApprovalsService,
    public approvalsNetwork: ApprovalsNetwork,
  ) {}

  ngAfterViewInit(): void {
    this.fetchData();
    this.changeDetectorRef.detectChanges();
  }

  private fetchData(): void {
    this.approvalsNetwork.status(this.id).subscribe((result) => {
      this.approvalStatus = result;
    });
  }

  openOverlay(popover): void {
    if (popover.isOpen()) {
      return;
    }

    popover.open();
    this.openStateChanged.emit(true);

    this.changeDetectorRef.detectChanges();
  }

  onApprovalProcessChange() {
    this.fetchData();
    this.change.emit(true);
  }

  onUpdate() {
    this.fetchData();
    this.change.emit(true);
  }
  closeOverlay(popover): void {
    if (!popover.isOpen()) {
      return;
    }

    popover.close();
    this.openStateChanged.emit(false);
  }
}
