import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'troi-icon',
  templateUrl: './troi-icon.component.html',
  styleUrls: ['./troi-icon.component.scss'],
})
export class TroiIconComponent {
  @Input() public icon: string;

  @Input()
  get large(): boolean {
    return this._large;
  }
  set large(value: boolean) {
    this._large = coerceBooleanProperty(value);
  }
  private _large: boolean;

  @Input()
  public get medium(): boolean {
    return this._medium;
  }
  public set medium(value: boolean) {
    this._medium = coerceBooleanProperty(value);
  }
  private _medium: boolean;

  @Input()
  public get small(): boolean {
    return this._small;
  }
  public set small(value: boolean) {
    this._small = coerceBooleanProperty(value);
  }
  private _small: boolean;

  @Input()
  public get bolder(): boolean {
    return this._bolder;
  }
  public set bolder(value: boolean) {
    this._bolder = coerceBooleanProperty(value);
  }
  private _bolder: boolean;

  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled: boolean;

  @Input() public size: string;
}
