import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapValues } from 'lodash';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { ModuleInterceptor } from '../../../../../core/enums/module-interceptor';
import { BackendResponseInterface } from '../../../../../core/interfaces/backend.response.interface';
import { TreeListItemResponseInterface } from '../../../../../shared/troi-tree-list/interfaces/tree-list-item-response.interface';
import { FiltersParamsInterface } from '../../../../common/interfaces/filters-params.interface';
import { Routes } from '../../../enum/routes';
import { FiltersService } from '../../../services/filters.service';
import { FolderModel } from '../components/folder/models/folder.model';
import { ProjectTypeEnum } from '../enums/project-type.enum';
import { ProjectPropertiesInterface } from '../interfaces/project-properties.interface';

@Injectable()
export class TreeViewListNetworkService {
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.PROJECT_LIST, '1');
  }

  public isFirstTreeLoad = true;
  public initialSearchPhrase = '';

  public getTreeStructure(
    filtersParams: FiltersParamsInterface,
    lang: string,
  ): Observable<
    BackendResponseInterface<TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>[]>
  > {
    if (this.isFirstTreeLoad) {
      this.isFirstTreeLoad = false;
      if (this.initialSearchPhrase) {
        filtersParams.searchPhrase = this.initialSearchPhrase;
      }
    }

    const params = new HttpParams({
      fromObject: {
        ...mapValues(filtersParams, FiltersService.stringifyFilterParam),
        lang,
      },
    });

    return this.http.get<
      BackendResponseInterface<TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>[]>
    >(`${environment.url}${Routes.TREE_LIST}`, {
      params,
      headers: this.headers,
    });
  }

  public moveToFolder(source: string, target: string, type: string): Observable<Record<string, string>> {
    return this.http.post<Record<string, string>>(`${environment.url}${Routes.TREE_LIST_MOVE_ITEM}`, {
      source,
      target,
      type,
      headers: this.headers,
    });
  }

  public getChildrenTreeStructure(
    folder: FolderModel,
    filtersParams: FiltersParamsInterface,
  ): Observable<
    BackendResponseInterface<TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>[]>
  > {
    const params = new HttpParams({
      fromObject: {
        ...mapValues(filtersParams, FiltersService.stringifyFilterParam),
        parentFolder: folder.id,
        // projectFolder: folder.isCustomer() ? '' : folder.id,
      },
    });

    return this.http.get<
      BackendResponseInterface<TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>[]>
    >(`${environment.url}${Routes.TREE_LIST_CHILDREN_LAZY}`, {
      params,
      headers: this.headers,
    });
  }
}
