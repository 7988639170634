import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Routes } from '../routes/routes';

@Injectable()
export class LegacyAjaxServerNetworkService {
  public constructor(public http: HttpClient) {}

  public postLegacyAjaxServer(controller: string, action: string): Observable<string> {
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'text';
      withCredentials?: boolean;
    } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      }),
      responseType: 'text',
    };

    const body = `controller=${controller}&action=${action}`;
    return this.http.post(environment.url + Routes.LEGACY_AJAX_SERVER, body, options);
  }
}
