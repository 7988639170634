import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { AccountsNetwork } from '../networks/accounts.network';
import { ModelsFactory } from '../../../common/factories/models.factory';
import { AccountModel } from '../models/account.model';
import { FiltersInterface } from '../../../../../shared/troi-data-listing-filters/filters.interface';
import { AccountInterface } from '../interfaces/account.interface';
import { FiltersService } from './filters.service';
import {
  BackendResponseInterface,
  ResponseStatusInterface,
} from '../../../../../core/interfaces/backend.response.interface';
import { FiltersFormService } from './filters-form.service';
import { OpeningBalanceInterface } from '../interfaces/opening-balance.interface';
import { AccessRightService } from '../../../../../core/services/access-right.service';
import { Module } from '../../../../../core/enums/module';

@Injectable()
export class AccountsService {
  accounts: AccountModel[] = [];

  isLoading = true;

  totalCount = 0;

  constructor(
    private network: AccountsNetwork,
    public filterFormService: FiltersFormService,
    public filterService: FiltersService,
    public accessRightService: AccessRightService,
    public modelsFactory: ModelsFactory,
  ) {}

  loadList(filters: FiltersInterface) {
    this.isLoading = true;
    this.network.getList(this.filterFormService.prepareSearchRequestParams(filters)).subscribe((result) => {
      this.accessRightService.setAccessRight(Module.ACCOUNT_LIST, result.access);
      this.isLoading = false;
      this.accounts = [];
      this.totalCount = result.totalCount;
      _.forEach(result.data, (objectResponse: AccountInterface) => {
        this.accounts.push(this.modelsFactory.buildAccountModel(objectResponse));
      });
    });
    this.filterService.putFiltersInUrl(this.filterFormService.prepareSearchRequestParams(filters));
  }

  getAccount(id: string): Observable<BackendResponseInterface<AccountInterface>> {
    return this.network.getAccount(id);
  }

  delete(id: string): Observable<BackendResponseInterface<ResponseStatusInterface>> {
    return this.network.delete(id);
  }

  create(
    formGroup: UntypedFormGroup,
    openingBalances: OpeningBalanceInterface[],
  ): Observable<BackendResponseInterface<AccountInterface>> {
    return this.network.create(formGroup, openingBalances);
  }

  edit(
    formGroup: UntypedFormGroup,
    openingBalances: OpeningBalanceInterface[],
  ): Observable<BackendResponseInterface<AccountInterface>> {
    return this.network.edit(formGroup, openingBalances);
  }

  inlineEdit(account: AccountModel): Observable<BackendResponseInterface<AccountInterface>> {
    return this.network.inlineEdit(account);
  }

  updateInList(account: AccountInterface) {
    _.forEach(this.accounts, (accountModel: AccountModel) => {
      if (accountModel.id === account.id) {
        accountModel.number = account.number;
        accountModel.name = account.name;
        accountModel.group = this.modelsFactory.findAccountGroupDropdown(account.group);
        accountModel.tax = this.modelsFactory.findTaxRate(account.tax);
        accountModel.counterAccount = this.modelsFactory.findAccountDropdown(account.counterAccount);
        accountModel.cashAccount = account.cashAccount;
        accountModel.bankAccount = account.bankAccount;
        accountModel.automaticAccount = account.automaticAccount;
        accountModel.depositAccount = account.depositAccount;
        accountModel.datevLock = account.datevLock;
        accountModel.active = account.active;
      }
    });
  }
}
