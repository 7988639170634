<div class="menu-layer" [ngClass]="[('menu-layer--level-' + level), searchMode ? 'menu-layer--search-mode' : '']" *ngIf="_isOpen">
    <div class="menu-layer__container" [ngStyle]="{ width: menuWidth }" [ngClass]="{ 'menu-layer__container--close': !isOpen}">
        <div class="menu-layer__wrapper">
            <div class="menu-layer__header {{ 'menu-layer__header--' + level }}">
                <div class="menu-layer__title" *ngIf="title">{{ title }}</div>
                <div class="menu-layer__details" *ngIf="level === 1"><ng-content></ng-content></div>
            </div>
            <div class="menu-layer__content menu-item-container" [ngClass]="{ 'menu-item-container--search-no-items': searchMode && menu && !menu?.length, 'menu-item-container--open': layerOpen }">
                <div class="menu-layer__no-items" *ngIf="menu && !menu?.length && !loading">{{ 'Menu.no_items_to_display' | translate }}</div>
                <troi-loading-spinner class="menu-layer__loading" *ngIf="loading"></troi-loading-spinner>
                <menu-item (dragStartedEmitter)="onDragStarted($event)" *ngFor="let menuItem of menu" [item]="menuItem" (selectedItemEmitter)="selectItem(menuItem)" [active]="isSelectedItem(menuItem)" [level]="level"></menu-item>
            </div>
            <div class="menu-layer__footer">
                {{ footer }}
            </div>
        </div>
    </div>
    <menu-layer [menu]="selectedItem?.subMenu" [level]="level + 1" [isOpen]="isSecondLevelOpen" [title]="getLanguageValue(selectedItem?.label)" (dragStarted)="onDragStarted($event)"></menu-layer>
</div>
