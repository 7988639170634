/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';
import { TroiDaterangepickerComponent } from './troi-daterangepicker.component';
import { DefaultLocaleConfig, LocaleConfig } from './troi-daterangepicker.config';

@Directive({
  selector: 'input[troi-daterangepicker]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TroiDaterangepickerDirective),
      multi: true,
    },
  ],
})
export class TroiDaterangepickerDirective implements OnInit, OnChanges, DoCheck {
  public picker: TroiDaterangepickerComponent;
  private _onChange = Function.prototype;
  private _onTouched = Function.prototype;
  private _validatorChange = Function.prototype;
  private _value: any;
  private localeDiffer: KeyValueDiffer<string, any>;
  @Input()
  public minDate: moment.Moment;
  @Input()
  public maxDate: moment.Moment;
  @Input()
  public autoApply: boolean;
  @Input()
  public alwaysShowCalendars: boolean;
  @Input()
  public showCustomRangeLabel: boolean;
  @Input()
  public linkedCalendars: boolean;
  @Input()
  public dateLimit: number = null;
  @Input()
  public singleDatePicker: boolean;
  @Input()
  public showWeekNumbers: boolean;
  @Input()
  public showISOWeekNumbers: boolean;
  @Input()
  public showDropdowns: boolean;
  @Input()
  public isInvalidDate: () => void;
  @Input()
  public isCustomDate: () => void;
  @Input()
  public isTooltipDate: () => void;
  @Input()
  public showClearButton: boolean;
  @Input()
  public customRangeDirection: boolean;
  @Input()
  public ranges: any;
  @Input()
  public opens: string;
  @Input()
  public drops: string;
  firstMonthDayClass: string;
  @Input()
  public lastMonthDayClass: string;
  @Input()
  public emptyWeekRowClass: string;
  @Input()
  public firstDayOfNextMonthClass: string;
  @Input()
  public lastDayOfPreviousMonthClass: string;
  @Input()
  public keepCalendarOpeningWithRange: boolean;
  @Input()
  public showRangeLabelOnInput: boolean;
  @Input()
  public showCancel = false;
  @Input()
  public lockStartDate = false;
  // timepicker variables
  @Input()
  public timePicker = false;
  @Input()
  public timePicker24Hour = false;
  @Input()
  public timePickerIncrement = 1;
  @Input()
  public timePickerSeconds = false;
  @Input() closeOnAutoApply = true;

  @Input()
  public set locale(value) {
    this._locale = { ...DefaultLocaleConfig, ...value };
  }
  public get locale(): any {
    return this._locale;
  }
  private _locale: LocaleConfig = {};

  @Input()
  public set startKey(value: string) {
    if (value !== null) {
      this._startKey = value;
    } else {
      this._startKey = 'startDate';
    }
  }
  private _startKey = 'startDate';

  @Input()
  public set endKey(value: string) {
    if (value !== null) {
      this._endKey = value;
    } else {
      this._endKey = 'endDate';
    }
  }
  private _endKey = 'endDate';

  public notForChangesProperty: Array<string> = ['locale', 'endKey', 'startKey'];

  public get value() {
    return this._value || null;
  }
  public set value(val) {
    this._value = val;
    this._onChange(val);
    this._changeDetectorRef.markForCheck();
  }

  @Output('change') onChange: EventEmitter<object> = new EventEmitter();
  @Output('rangeClicked') rangeClicked: EventEmitter<object> = new EventEmitter();
  @Output('datesUpdated') datesUpdated: EventEmitter<object> = new EventEmitter();
  @Output() startDateChanged: EventEmitter<object> = new EventEmitter();
  @Output() endDateChanged: EventEmitter<object> = new EventEmitter();

  @HostListener('keyup.esc')
  onHostEsc() {
    this.hide();
  }

  @HostListener('blur')
  onHostBlur() {
    this.onBlur();
  }

  @HostListener('click')
  onHostClick() {
    this.open();
  }

  @HostListener('keyup', ['$event'])
  onHostKeyup(event) {
    this.inputChanged(event);
  }

  constructor(
    public viewContainerRef: ViewContainerRef,
    public _changeDetectorRef: ChangeDetectorRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _el: ElementRef,
    private _renderer: Renderer2,
    private differs: KeyValueDiffers,
    private elementRef: ElementRef,
  ) {
    this.drops = 'down';
    this.opens = 'auto';
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(TroiDaterangepickerComponent);
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
    this.picker = componentRef.instance;
    this.picker.inline = false; // set inline to false for all directive usage
  }
  ngOnInit() {
    this.picker.startDateChanged.asObservable().subscribe((itemChanged: any) => {
      this.startDateChanged.emit(itemChanged);
    });
    this.picker.endDateChanged.asObservable().subscribe((itemChanged: any) => {
      this.endDateChanged.emit(itemChanged);
    });
    this.picker.rangeClicked.asObservable().subscribe((range: any) => {
      this.rangeClicked.emit(range);
    });
    this.picker.datesUpdated.asObservable().subscribe((range: any) => {
      this.datesUpdated.emit(range);
    });
    this.picker.choosedDate.asObservable().subscribe((change: any) => {
      if (change) {
        const value = {};
        value[this._startKey] = change.startDate;
        value[this._endKey] = change.endDate;
        this.value = value;
        this.onChange.emit(value);
        if (typeof change.chosenLabel === 'string') {
          this._el.nativeElement.value = change.chosenLabel;
        }
      }
    });
    this.picker.firstMonthDayClass = this.firstMonthDayClass;
    this.picker.lastMonthDayClass = this.lastMonthDayClass;
    this.picker.emptyWeekRowClass = this.emptyWeekRowClass;
    this.picker.firstDayOfNextMonthClass = this.firstDayOfNextMonthClass;
    this.picker.lastDayOfPreviousMonthClass = this.lastDayOfPreviousMonthClass;
    this.picker.drops = this.drops;
    this.picker.opens = this.opens;
    this.localeDiffer = this.differs.find(this.locale).create();
    this.picker.closeOnAutoApply = this.closeOnAutoApply;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const change in changes) {
      if (changes.hasOwnProperty(change)) {
        if (this.notForChangesProperty.indexOf(change) === -1) {
          this.picker[change] = changes[change].currentValue;
        }
      }
    }
  }

  ngDoCheck() {
    if (this.localeDiffer) {
      const changes = this.localeDiffer.diff(this.locale);
      if (changes) {
        this.picker.updateLocale(this.locale);
      }
    }
  }

  onBlur() {
    this._onTouched();
  }

  open(event?: any) {
    this.picker.show(event);
    setTimeout(() => {
      this.setPosition();
    });
  }

  hide(e?) {
    this.picker.hide(e);
  }
  toggle(e?) {
    if (this.picker.isShown) {
      this.hide(e);
    } else {
      this.open(e);
    }
  }

  clear() {
    this.picker.clear();
  }

  writeValue(value) {
    this.setValue(value);
  }
  registerOnChange(fn) {
    this._onChange = fn;
  }
  registerOnTouched(fn) {
    this._onTouched = fn;
  }
  private setValue(val: any) {
    if (val) {
      this.value = val;
      if (val[this._startKey]) {
        this.picker.setStartDate(val[this._startKey]);
      }
      if (val[this._endKey]) {
        this.picker.setEndDate(val[this._endKey]);
      }
      this.picker.calculateChosenLabel();
      if (this.picker.chosenLabel) {
        this._el.nativeElement.value = this.picker.chosenLabel;
      }
    } else {
      this.picker.clear();
    }
  }
  /**
   * Set position of the calendar
   */
  setPosition() {
    const container: Element = this.picker.pickerContainer.nativeElement;
    const target: Element = this._el.nativeElement;

    this._renderer.setStyle(container, 'transform', 'translate(0, 0)');

    const containerRect = container.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();

    const containerXCenter = containerRect.x + containerRect.width / 2;
    const targetXCenter = targetRect.x + targetRect.width / 2;
    const containerToTargetX = -Math.abs(containerXCenter - targetXCenter);

    this._renderer.setStyle(container, 'transform', `translate(${containerToTargetX}px, 4px)`);

    const updatedContainerRect = container.getBoundingClientRect();

    const containerEdges = {
      top: updatedContainerRect.y,
      right: updatedContainerRect.x + updatedContainerRect.width,
      bottom: updatedContainerRect.y + updatedContainerRect.height,
      left: updatedContainerRect.x,
    };

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const edgeOffset = 65;

    const isBeyondEdge = {
      right: containerEdges.right > windowWidth - edgeOffset,
      bottom: containerEdges.bottom > windowHeight,
      left: containerEdges.left < edgeOffset,
    };

    // if the container is beyond the right edge
    if (isBeyondEdge.right) {
      this._renderer.setStyle(
        container,
        'transform',
        `translate(${containerToTargetX - (containerEdges.right - windowWidth) - edgeOffset}px, 4px)`,
      );
    }

    // if the container is beyond the left edge
    if (isBeyondEdge.left) {
      this._renderer.setStyle(
        container,
        'transform',
        `translate(${containerToTargetX + Math.abs(containerEdges.left) + edgeOffset}px, 4px)`,
      );
    }

    if (!isBeyondEdge.bottom) {
      return;
    }

    const scrollContainerOffset = containerRect.height - this.findParentWithScrollOffset();
    const actualOffset = scrollContainerOffset > -containerRect.height ? -containerRect.height : scrollContainerOffset;

    // if the container is beyond the bottom edge
    if (isBeyondEdge.bottom && !isBeyondEdge.right && !isBeyondEdge.left) {
      this._renderer.setStyle(container, 'transform', `translate(${containerToTargetX}px, ${actualOffset}px)`);
    }

    // if the container is beyond the bottom-right edge
    if (isBeyondEdge.right && isBeyondEdge.bottom) {
      this._renderer.setStyle(
        container,
        'transform',
        `translate(${containerToTargetX - (containerEdges.right - windowWidth) - edgeOffset}px, ${actualOffset}px)`,
      );
    }

    // if the container is beyond the bottom-left edge
    if (isBeyondEdge.left && isBeyondEdge.bottom) {
      this._renderer.setStyle(
        container,
        'transform',
        `translate(${containerToTargetX + Math.abs(containerEdges.left) + edgeOffset}px, ${actualOffset}px)`,
      );
    }
  }

  private findParentWithScrollOffset(): number {
    const target = this._el.nativeElement;
    let node = target;
    let offset = 0;
    while (node) {
      offset += node.offsetTop;
      node = node.offsetParent;
    }
    return offset;
  }

  inputChanged(e) {
    if (e.target.tagName.toLowerCase() !== 'input') {
      return;
    }
    if (!e.target.value.length) {
      return;
    }
    const dateString = e.target.value.split(this.picker.locale.separator);
    let start = null;
    let end = null;
    if (dateString.length === 2) {
      start = moment(dateString[0], this.picker.locale.format);
      end = moment(dateString[1], this.picker.locale.format);
    }
    if (this.singleDatePicker || start === null || end === null) {
      start = moment(e.target.value, this.picker.locale.format);
      end = start;
    }
    if (!start.isValid() || !end.isValid()) {
      return;
    }
    this.picker.setStartDate(start);
    this.picker.setEndDate(end);
    this.picker.updateView();
  }

  /**
   * For click outside of the calendar's container
   *
   * @param event event object
   */
  @HostListener('document:click', ['$event'])
  outsideClick(event): void {
    if (!event.target) {
      return;
    }

    if (event.target.classList.contains('ngx-daterangepicker-action')) {
      return;
    }

    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.hide();
    }
  }
}
