import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TaskInterface } from '../../interfaces/task.interface';
import { ConsumptionPopoverService } from '../../network/consumption-popover.service';
import {
  ConsumptionBookingsInterface,
  ConsumptionPlanInterface,
  PlannedValueInterface,
} from '../../interfaces/consumption.interface';
import { firstValueFrom, Subscription, take } from 'rxjs';
import { TasksHelperService } from '../../services/helper.service';

@Component({
  selector: 'troi-consumption-popover',
  templateUrl: './consumption-popover.component.html',
  styleUrls: ['./consumption-popover.component.scss'],
})
export class ConsumptionPopoverComponent implements OnInit, OnDestroy {
  @Input() task: TaskInterface;
  @Output() totalTaskHours = new EventEmitter<number>();

  public totalHoursOfAssignedTasks = 0;
  public calculationPositionData: ConsumptionPlanInterface;
  public sumOfBookedAssignments: number;
  public sumOfBookedAssignmentsRounded: string;
  public totalItems: string;
  public sumIsNegative: boolean;
  public actualAndTargetDifference: string;
  public notAssignedHours: string;
  public loaded = false;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private consumptionPopoverService: ConsumptionPopoverService,
    private helperService: TasksHelperService,
  ) {}

  ngOnInit() {
    if (this.task.calculationPosition) {
      this.getBookedAssignments();
      this.getAssignedTasks();
    }
    this.onTaskHourChange();
  }

  public onTaskHourChange() {
    this.subscriptions.add(
      this.helperService.taskHoursChangedSub.pipe(take(1)).subscribe(() => {
        this.getAssignedTasks();
      }),
    );
  }

  private async getAssignedTasks(): Promise<void> {
    this.calculationPositionData = (
      await firstValueFrom(this.consumptionPopoverService.getAllPlans(this.task.calculationPosition.id))
    )?.data;
    this.totalTaskHours.emit(+this.calculationPositionData.sum);
    this.totalItems = this.getTotalItems();
    this.sumIsNegative = this.calculationPositionData.calculationPosition
      ? +this.calculationPositionData.calculationPosition.hours - this.sumOfBookedAssignments < 0
      : false;
    this.actualAndTargetDifference = this.getActualAndTargetDifference();
    this.notAssignedHours = this.getNotAssignedHours();
    this.formatEntries(this.calculationPositionData.entries);
    this.loaded = true;
  }

  private formatEntries(entries: PlannedValueInterface[]) {
    entries?.forEach((entry) => {
      entry.hoursBudgetString = this.helperService.formatTime(entry.hoursBudget);
    });
  }

  public getNotAssignedHours(): string {
    if (this.calculationPositionData.calculationPosition && this.isUnitTypeTime) {
      const calculatedTotal = +this.calculationPositionData.calculationPosition.hours;
      const notAssigned = calculatedTotal - +this.calculationPositionData.sum;
      return this.helperService.formatTime(notAssigned);
    } else {
      return this.helperService.formatTime();
    }
  }

  public getActualAndTargetDifference(): string {
    if (this.calculationPositionData.calculationPosition) {
      const calculatedTotal = this.isUnitTypeTime ? +this.calculationPositionData.calculationPosition.hours : 0;
      const difference = calculatedTotal - this.sumOfBookedAssignments;
      return this.helperService.formatTime(difference);
    } else {
      return this.helperService.formatTime();
    }
  }

  public getTotalItems(): string {
    return this.calculationPositionData.calculationPosition
      ? this.formatString(this.calculationPositionData.calculationPosition.hours)
      : this.helperService.formatTime();
  }

  public get isUnitTypeTime(): boolean {
    return this.calculationPositionData.calculationPosition?.unitType === 'T';
  }

  public formatString(hours: string): string {
    return hours && this.isUnitTypeTime ? this.helperService.formatTime(hours) : this.helperService.formatTime();
  }

  private async getBookedAssignments(): Promise<void> {
    const bookings: ConsumptionBookingsInterface = (
      await firstValueFrom(this.consumptionPopoverService.getAllBookings(this.task.calculationPosition.id))
    )?.data;
    this.sumOfBookedAssignments = this.helperService.calculateSumOfAssignments(bookings?.booked);
    this.sumOfBookedAssignmentsRounded = this.helperService.formatTime(this.sumOfBookedAssignments);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
