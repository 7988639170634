export enum Routes {
  SETTING_COUNTRIES = '/components/settings/countries',
  SETTING_CLIENTS = '/components/pricelist/filtersdropdown/clients',
  SETTING_TIMETRACKER = '/components/billing/time-tracker/settings',
  LEGACY_AJAX_SERVER = '/site/ajax/jQueryAjaxServer.php',

  MATH_MULTIPLY = '/components/common/math/multiply',
  MATH_SUM = '/components/common/math/sum-collection',
  MATH_COUNT_UNASSIGNED_AMOUNT = '/components/common/math/count-unassigned-amount',
  MATH_CALCULATE_NET_GROSS = '/components/common/math/calculate-net-gross',
}
