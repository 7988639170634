<div class="troi-record-working-time-table">
  <troi-timepicker
    *ngIf="showInput"
    [ngModel]="inputValue"
    [asString]="true"
    (keyup.enter)="onEnterUp($event)"
    (ngModelChange)="onModelChange($event, recordProjectTime)"
    [disabled]="saving"
    class="troi-timepicker"
  ></troi-timepicker>
  <troi-icon
    #recordIcon
    icon="icon-plus"
    [size]="'13px'"
    class="toggle-add-dialog"
    [ngbPopover]="popContent"
    [popoverTitle]="popTitle"
    triggers="manual"
    #recordProjectTime="ngbPopover"
    (hidden)="onHidden()"
    popoverClass="troi-popover record-project-time"
    (click)="toggleOverlay(recordProjectTime)"
    [placement]="placement"
    autoClose="outside"
  ></troi-icon>
</div>
<ng-template #popTitle>
  <h3 *ngIf="type === 'Gross working time'" class="popover-title">
    {{ 'Timerecording.Gross_working_time' | translate }} - {{ dateFor | date : 'dd.MM.yyyy' }}
  </h3>
  <h3 *ngIf="type === 'Breaks Total'" class="popover-title">
    {{ 'Timerecording.Breaks_Total' | translate }} - {{ dateFor | date : 'dd.MM.yyyy' }}
  </h3>
  <h3 *ngIf="type === 'Absences'" class="popover-title">
    {{ 'Timerecording.Absences' | translate }} - {{ dateFor | date : 'dd.MM.yyyy' }}
  </h3>
  <h3 *ngIf="type === 'Net Working Time'" class="popover-title">
    {{ 'Timerecording.Net_Working_Time' | translate }} - {{ dateFor | date : 'dd.MM.yyyy' }}
  </h3>
</ng-template>
<ng-template #popContent>
  <form [formGroup]="createForm" class="popover-content">
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <p class="mt-2">{{ 'Timerecording.Start' | translate }}</p>
        </div>
        <div class="col-6">
          <troi-timepicker
            [asString]="true"
            formControlName="startTime"
            (keydown.enter)="onEnterUp($event)"
          ></troi-timepicker>
          <div *ngIf="isErrorField('startTime')" class="tnd-error-msg">
            {{ getErrorForField('startTime') | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <p class="mt-2">{{ 'Timerecording.End' | translate }}</p>
        </div>
        <div class="col-6">
          <troi-timepicker
            [asString]="true"
            formControlName="endTime"
            (keydown.enter)="onEnterUp($event)"
          ></troi-timepicker>
          <div *ngIf="isErrorField('endTime')" class="tnd-error-msg">
            {{ getErrorForField('endTime') | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex mt-5">
      <button
        class="btn btn-secondary button-cancel"
        (click)="closeOverlay(recordProjectTime)"
        [disabled]="saving"
      >
        {{ 'Desktop.Widgets.Projecttimes.Cancel' | translate }}
      </button>
      <button
        #submitButton
        id="submitButton"
        type="submit"
        class="btn bin-primary button-log"
        [disabled]="saving"
        (click)="onSubmit(recordProjectTime)"
        focus-directive
      >
        {{ 'Timerecording.confirm' | translate }}
      </button>
    </div>
  </form>
</ng-template>
