import { BaseModalDirective } from '../../../../../shared/troi-base-modal/baseModal.component';
import { ModalService } from '../../../../../shared/troi-modals/modal.service';
import { LanguagesService } from '../../../../../core/services/languages.service';
import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ExportListService } from './services/export-list.service';
import { TroiDropdownListModel } from '../../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { ProjectListSettingsService } from '../../../services/project-list-settings.service';
import { BaseSettingsDropdownModel } from '../../../../../core/models/settings/dropdowns/base-settings-dropdown.model';
import { BackendResponseInterface } from '../../../../../core/interfaces/backend.response.interface';
import { ExportListInterface } from '../../../interfaces/export-list.interface';
import { exhaustMap, takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { ExportStatusEnum } from '../../../enum/export-status.enum';

@Component({
  selector: 'export-list',
  templateUrl: 'export-list.component.html',
  styleUrls: ['./export-list.component.scss'],
})
export class ExportListComponent extends BaseModalDirective {
  public exportListForm: UntypedFormGroup;
  public repeatPollingExportStatus: Subject<void> = new Subject<void>();
  public exportStatus = ExportStatusEnum;

  constructor(
    public modalService: ModalService,
    public languagesService: LanguagesService,
    public exportListService: ExportListService,
    private projectListSettingsService: ProjectListSettingsService,
  ) {
    super(modalService);
    this.initForm();
  }

  public initForm(): void {
    this.exportListService.submitted = false;
    this.exportListService.pending = false;
    this.exportListForm = this.exportListService.initForm();
  }

  public get exportTypeOptions(): TroiDropdownListModel[] {
    return this.projectListSettingsService.settings.exportTypes.map((calculationType: BaseSettingsDropdownModel) => ({
      label: this.languagesService.getLanguageValue(calculationType.name),
      value: calculationType.id,
      active: true,
      group: false,
    }));
  }

  public get progress(): string {
    return `${this.exportListService.exportState.progress}%`;
  }

  public export(): void {
    if (!this.exportListService.pending) {
      this.exportListService.submitted = true;
      this.exportListService.pending = true;

      if (this.exportListForm.invalid) {
        this.exportListService.pending = false;
        return;
      }

      this.exportListService.exportState.status = ExportStatusEnum.REQUESTED;
      this.exportListService.create(this.exportListForm).subscribe(
        (response: BackendResponseInterface<ExportListInterface>) => {
          this.exportListService.exportState = response.data;
          this.processExport();
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.exportListService.pending = false;
          this.exportListService.submitted = false;
        },
      );
    }
  }

  public processExport(): void {
    timer(0, 1000)
      .pipe(
        exhaustMap(() => this.exportListService.getStatus(this.exportListService.exportState.progress)),
        takeUntil(this.exportListService.cancelPollingExportStatus.asObservable()),
      )
      .subscribe(
        (response: BackendResponseInterface<ExportListInterface>) => {
          this.exportListService.exportState = response.data;

          if (
            (response.data.status === ExportStatusEnum.COMPLETED && response.data.progress === 100) ||
            response.data.status === ExportStatusEnum.ERROR
          ) {
            this.exportListService.cancelPollingExportStatus.next();

            if (response.data.directLink) {
              this.exportListService.downloadFile(response.data.directLink);
            }
          }
        },
        (error) => {
          console.error(error);
          this.exportListService.exportState.status = ExportStatusEnum.ERROR;
        },
        () => {
          this.exportListService.pending = false;
          this.exportListService.submitted = false;
        },
      );
  }

  public get description(): string {
    const base = 'ProjectList.labels.modals.exportList.';

    if (this.exportListService.exportState.status === ExportStatusEnum.UNKNOWN) {
      return `${base}selectTypeDescription`;
    } else if (this.exportListService.exportState.status === ExportStatusEnum.ERROR) {
      return `${base}error`;
    } else if (this.isExporting) {
      return `${base}inProgressDescription`;
    } else {
      return `${base}finishedDescription`;
    }
  }

  public get isExporting(): boolean {
    return [ExportStatusEnum.REQUESTED, ExportStatusEnum.QUEUED, ExportStatusEnum.EXPORTING].includes(
      this.exportListService.exportState.status,
    );
  }

  public close(): void {
    if (
      [ExportStatusEnum.REQUESTED, ExportStatusEnum.QUEUED, ExportStatusEnum.EXPORTING].includes(
        this.exportListService.exportState.status,
      ) &&
      this.exportListService.exportState.id
    ) {
      this.exportListService.delete().subscribe((response: BackendResponseInterface<ExportListInterface>) => {
        this.exportListService.exportState = response.data;
      });
    }

    this.exportListService.cancelPollingExportStatus.next();
    super.close();
  }
}
