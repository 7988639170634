import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TimetrackerInterface } from '../interfaces/timetracker.interface';
import { environment } from '../../../../environments/environment';
import { Routes } from '../../../core/routes/routes';

@Injectable()
export class FooterNetworkService {
  public constructor(private http: HttpClient) {}

  public getTimetrackerSetting(): Observable<TimetrackerInterface> {
    return this.http.get<TimetrackerInterface>(environment.url + Routes.SETTING_TIMETRACKER);
  }
}
