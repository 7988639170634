import { Injectable } from '@angular/core';
import { any } from 'codelyzer/util/function';

@Injectable()
export class WebAuthNService {
  private enc = new TextEncoder();

  // another function to go from string to ByteArray, but we first encode the
  // string as base64 - note the use of the atob() function
  private strToBin(str) {
    return Uint8Array.from(atob(str), (c) => c.charCodeAt(0));
  }

  /**
   * convert RFC 1342-like base64 strings to array buffer
   *
   * @param obj
   * @returns
   */
  private recursiveBase64StrToArrayBuffer(obj) {
    const prefix = '=?BINARY?B?';
    const suffix = '?=';
    if (typeof obj === 'object') {
      for (const key in obj) {
        if (typeof obj[key] === 'string') {
          let str = obj[key];
          if (str.substring(0, prefix.length) === prefix && str.substring(str.length - suffix.length) === suffix) {
            str = str.substring(prefix.length, str.length - suffix.length);

            const binaryString = window.atob(str);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            obj[key] = bytes.buffer;
          }
        } else {
          this.recursiveBase64StrToArrayBuffer(obj[key]);
        }
      }
    }
  }

  /**
   * Convert a ArrayBuffer to Base64
   *
   * @param buffer
   * @returns
   */
  private arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  // function to encode raw binary to string, which is subsequently
  // encoded to base64 - note the use of the btoa() function
  private binToStr(bin) {
    return btoa(new Uint8Array(bin).reduce((s, byte) => s + String.fromCharCode(byte), ''));
  }

  public createCreds = function (publicKey) {
    this.recursiveBase64StrToArrayBuffer(publicKey);
    return new Promise((resolve, reject) => {
      navigator.credentials
        .create({
          publicKey,
        })
        .then((data: PublicKeyCredential) => {
          const publicKeyCredential = {
            id: data.id,
            type: data.type,
            rawId: this.arrayBufferToBase64(data.rawId),
            response: {
              clientDataJSON: this.arrayBufferToBase64(data.response.clientDataJSON),
              attestationObject: any(),
            },
          };

          if (data.response instanceof AuthenticatorAttestationResponse) {
            if (data.response.attestationObject !== undefined) {
              publicKeyCredential.response.attestationObject = this.arrayBufferToBase64(
                data.response.attestationObject,
              );
            }
          }

          resolve(publicKeyCredential);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  public validateCreds = function (publicKey) {
    this.recursiveBase64StrToArrayBuffer(publicKey);
    return new Promise((resolve, reject) => {
      navigator.credentials
        .get({
          publicKey,
        })
        .then((data: PublicKeyCredential) => {
          const publicKeyCredential = {
            id: data.id,
            type: data.type,
            rawId: this.arrayBufferToBase64(data.rawId),
            response: {
              clientDataJSON: this.arrayBufferToBase64(data.response.clientDataJSON),
              authenticatorData: any(),
              signature: any(),
            },
          };

          if (data.response instanceof AuthenticatorAssertionResponse) {
            if (data.response.authenticatorData !== undefined) {
              publicKeyCredential.response.authenticatorData = this.arrayBufferToBase64(
                data.response.authenticatorData,
              );
            }

            if (data.response.signature !== undefined) {
              publicKeyCredential.response.signature = this.arrayBufferToBase64(data.response.signature);
            }
          }

          resolve(publicKeyCredential);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
