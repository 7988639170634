import { SettingsInterface } from '../interfaces/settings-response.interface';

export class SettingsModel {
  private _lastDownload: number;

  constructor(public settings: SettingsInterface) {}

  get lastDownload(): number {
    return this._lastDownload;
  }

  set lastDownload(value: number) {
    this._lastDownload = value;
  }
}
