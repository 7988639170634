import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Routes } from '../enum/routes';
import { TaskInterface } from '../interfaces/task.interface';
import { CommentInterface } from '../interfaces/comment.interface';
import { UserService } from '../../../core/user/user.service';
import { ModuleInterceptor } from '../../../core/enums/module-interceptor';
import { CommentResponseInterface } from '../interfaces/responses.interface';
import { CommentLikeInterface } from '../interfaces/commentLike.interface';

@Injectable()
export class CommentsService {
  private headers: HttpHeaders;

  public constructor(private http: HttpClient, private userService: UserService) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');
  }

  public getComments(taskId: string): Observable<CommentResponseInterface> {
    return this.http.get<CommentResponseInterface>(environment.url + Routes.COMMENTS + `?taskId=${taskId}`, {
      headers: this.headers,
    });
  }

  public createComment(content: string, task: TaskInterface): Observable<CommentResponseInterface> {
    const comment = {
      content,
      likes: 0,
      createdBy: {
        id: this.userService.getUser().id,
      },
      answerCount: 0,
      projecttask: {
        id: task.id,
      },
    };
    return this.http.post<CommentResponseInterface>(environment.url + Routes.COMMENTS, comment, {
      headers: this.headers,
    });
  }

  public updateComment(
    comment: CommentInterface,
    isAnswer: boolean,
    task: TaskInterface,
  ): Observable<CommentResponseInterface> {
    comment.projecttask = {
      id: task.id,
    };
    if (isAnswer)
      return this.http.put<CommentResponseInterface>(
        environment.url + Routes.COMMENTS_ANSWER + `/${comment.id}`,
        comment,
        {
          headers: this.headers,
        },
      );
    else
      return this.http.put<CommentResponseInterface>(environment.url + Routes.COMMENTS + `/${comment.id}`, comment, {
        headers: this.headers,
      });
  }

  public deleteComment(commentId: string, isAnswer: boolean): Observable<CommentResponseInterface> {
    if (isAnswer)
      return this.http.delete<CommentResponseInterface>(environment.url + Routes.COMMENTS_ANSWER + `/${commentId}`);
    else return this.http.delete<CommentResponseInterface>(environment.url + Routes.COMMENTS + `/${commentId}`);
  }

  public getCommentAnswers(commentId: string): Observable<CommentResponseInterface> {
    return this.http.get<CommentResponseInterface>(
      environment.url + Routes.COMMENTS_ANSWER + `?commentId=${commentId}`,
      {
        headers: this.headers,
      },
    );
  }

  public createCommentAnswer(content: string, mainComment: CommentInterface): Observable<CommentResponseInterface> {
    const answerComment = {
      content,
      createdBy: {
        id: this.userService.getUser().id,
      },
      likes: 0,
      taskComment: {
        id: mainComment.id,
      },
    };

    return this.http.post<CommentResponseInterface>(environment.url + Routes.COMMENTS_ANSWER, answerComment, {
      headers: this.headers,
    });
  }

  public createCommentLike(comment: CommentInterface, isAnswer: boolean) {
    const like = {
      liker: {
        id: this.userService.getUser().id,
      },
      [isAnswer ? 'answer' : 'taskComment']: {
        id: comment.id,
      },
    };

    const route = isAnswer ? Routes.COMMENTS_ANSWER_LIKE : Routes.COMMENTS_LIKE;

    return this.http.post<any>(environment.url + route, like, {
      headers: this.headers,
    });
  }

  public deleteCommentLike(commentLikeId: string, isAnswer: boolean): Observable<any> {
    if (isAnswer) return this.http.delete<any>(environment.url + Routes.COMMENTS_ANSWER_LIKE + `/${commentLikeId}`);
    else return this.http.delete<any>(environment.url + Routes.COMMENTS_LIKE + `/${commentLikeId}`);
  }
}
