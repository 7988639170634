<div>
  <form *ngIf="loaded" [formGroup]="taskForm">
    <div class="create-task">
      <div class="create-task__close" (click)="onModalClose()">
        <troi-icon icon="icon-close"></troi-icon>
      </div>
      <div class="create-task__content">
        <div class="content-menu-col">
          <div class="content-menu-col__task-id">
            {{ taskId ? 'A ' + taskId : ('Tasks.listview.labels.actions.newTask' | translate) }}
          </div>
          <div class="content-menu-col__menu mt">
            <ul class="task-navbar">
              <div
                *ngIf="showCalcPosContent || disableMenu"
                class="content-menu-col__menu--overlay"
              ></div>
              <div class="hoverable">
                <troi-progress-circle
                  class="first-item"
                  (click)="setPage(pageType.GENERAL)"
                  [isActive]="curPage === pageType.GENERAL"
                >
                  <troi-icon icon="icon-can-edit" size="18px"></troi-icon>
                </troi-progress-circle>

                <span class="content-menu-col__menu--title">{{
                  'Tasks.labels.createTaskModal.menuItems.info' | translate
                }}</span>
              </div>

              <div class="content-menu-col__menu--relative hoverable">
                <troi-progress-circle
                  (click)="setPage(pageType.RESOURCES)"
                  [isActive]="curPage === pageType.RESOURCES"
                >
                  <troi-icon icon="icon-empty-person" size="18px"></troi-icon>
                </troi-progress-circle>

                <span class="content-menu-col__menu--title">{{
                  'Tasks.labels.createTaskModal.menuItems.resources' | translate
                }}</span>
              </div>

              <div class="content-menu-col__menu--relative hoverable">
                <div
                  [autoClose]="'outside'"
                  [placement]="'right'"
                  [popoverClass]="'timeentries-tooltip-popover'"
                  ngbPopover="{{
                    !task.calculationPosition
                      ? ('Tasks.labels.createTaskModal.addCalcPosition' | translate)
                      : ('Tasks.labels.createTaskModal.timeBookingBlocked' | translate)
                  }}"
                  [ngClass]="{ disabled: !isBookingEnabled(task) }"
                ></div>
                <troi-progress-circle
                  (click)="setPage(pageType.TIMEENTRIES)"
                  [solvedItems]="roundToInteger(timeEntriesSum)"
                  [totalItems]="task.hoursBudget ? roundToInteger(task.hoursBudget) : 0"
                  [isActive]="curPage === pageType.TIMEENTRIES"
                >
                  <troi-icon icon="icon-timer" size="18px"></troi-icon>
                </troi-progress-circle>

                <span class="content-menu-col__menu--title">{{
                  'Tasks.labels.createTaskModal.menuItems.timeEntry' | translate
                }}</span>
              </div>

              <div class="content-menu-col__menu--relative hoverable">
                <div
                  [autoClose]="'outside'"
                  [placement]="'right'"
                  [popoverClass]="'timeentries-tooltip-popover'"
                  ngbPopover="{{ 'Tasks.labels.createTaskModal.saveTaskFirst' | translate }}"
                  [ngClass]="{ disabled: task.subtaskAll === undefined }"
                ></div>

                <troi-progress-circle
                  (click)="setPage(pageType.SUBTASKS)"
                  [solvedItems]="task.subtaskFinished"
                  [totalItems]="task.subtaskAll"
                  [isActive]="curPage === pageType.SUBTASKS"
                >
                  <troi-icon icon="icon-folded-list" size="18px"></troi-icon>
                </troi-progress-circle>

                <span class="content-menu-col__menu--title">{{
                  'Tasks.labels.createTaskModal.menuItems.subtasks' | translate
                }}</span>
              </div>

              <div class="content-menu-col__menu--relative hoverable">
                <div
                  [autoClose]="'outside'"
                  [placement]="'right'"
                  [popoverClass]="'timeentries-tooltip-popover'"
                  ngbPopover="{{ 'Tasks.labels.createTaskModal.saveTaskFirst' | translate }}"
                  [ngClass]="{ disabled: task.subtaskAll === undefined }"
                ></div>
                <div
                  *ngIf="amountOfUnreadComments > 0 && currentAssignee"
                  class="content-menu-col__menu--comment-dot"
                  [ngStyle]="{
                    backgroundColor: curPage === pageType.COMMENTS ? 'white' : ''
                  }"
                ></div>
                <troi-progress-circle
                  (click)="setPage(pageType.COMMENTS)"
                  [solvedItems]="currentAssignee ? amountOfUnreadComments : 0"
                  [totalItems]="amountOfComments"
                  [isActive]="curPage === pageType.COMMENTS"
                >
                  <troi-icon icon="icon-empty-bubble" size="18px"></troi-icon>
                </troi-progress-circle>

                <span class="content-menu-col__menu--title">{{
                  'Tasks.labels.createTaskModal.menuItems.comments' | translate
                }}</span>
              </div>
            </ul>
          </div>
        </div>
        <div *ngIf="showCalcPosContent" class="content-calc-pos-col">
          <troi-task-calc-pos-content
            [task]="task"
            (closeCalcPosContent)="onCloseCalcPos()"
          ></troi-task-calc-pos-content>
        </div>
        <div *ngIf="!showCalcPosContent" class="content-subpage-col">
          <div class="content-subpage-col__title-row mb">
            <div class="content-subpage-col__title-col">
              <troi-input-field
                formControlName="title"
                focus-directive
                cssSelector=".troi-input-field__input"
                [placeholder]="'Tasks.labels.createTaskModal.enterTaskTitle' | translate"
                [fieldInvalid]="taskForm.status === 'INVALID' && submitted"
                [validationEnabled]="true"
                [outline]="true"
                requiredFieldErrorMessage="Tasks.labels.createTaskModal.fillTaskTitleField"
              ></troi-input-field>
            </div>
            <div class="content-subpage-col__share-col">
              <troi-icon icon="icon-link" size="20px" (click)="onSharedUrlClick()"></troi-icon>
            </div>
          </div>
          <troi-task-modal-general
            class="content-subpage-col__body"
            *ngIf="curPage === pageType.GENERAL"
            [(curTask)]="task"
          ></troi-task-modal-general>
          <troi-task-modal-resources
            class="content-subpage-col__body"
            [task]="task"
            *ngIf="curPage === pageType.RESOURCES"
          ></troi-task-modal-resources>
          <troi-task-modal-timeentries-content
            class="content-subpage-col__body"
            *ngIf="curPage === pageType.TIMEENTRIES"
            [task]="task"
            [timeEntries]="timeEntries"
            [timeEntriesSum]="timeEntriesSum"
            (timeEntriesUpdated)="getTimeEntries()"
            [isSubtask]="false"
          ></troi-task-modal-timeentries-content>
          <troi-task-modal-subtasks
            class="content-subpage-col__body"
            *ngIf="curPage === pageType.SUBTASKS"
            (showEditSubtaskContent)="toggleSubtaskContent($event)"
            [task]="task"
          ></troi-task-modal-subtasks>
          <troi-task-modal-comments
            class="content-subpage-col__body"
            [task]="task"
            [currentAssignee]="currentAssignee"
            (commentsChanged)="getAllComments($event)"
            (disableMenu)="onDisableMenu($event)"
            *ngIf="curPage === pageType.COMMENTS"
          ></troi-task-modal-comments>
        </div>
        <troi-task-edit-subtask
          [task]="task"
          *ngIf="showSubtaskContent"
          (showEditSubtaskContent)="toggleSubtaskContent($event)"
        ></troi-task-edit-subtask>
        <div *ngIf="!showCalcPosContent && !showSubtaskContent" class="content-resources-col">
          <div class="mtb">
            <span class="paragraph-title">{{
              'Tasks.labels.createTaskModal.statusCP' | translate
            }}</span>
          </div>
          <div *ngIf="task.calculationPosition && helperService.isUnitTypeTime(task.calculationPosition?.unit?.unitType)" class="selected-calc-pos">
            <span class="selected-calc-pos__assigned-hours"
              >{{ helperService.formatQuantity(timeEntriesSum, task.calculationPosition?.unit?.unitRate, task.calculationPosition?.unit?.unitType) }}h</span
            ><span class="selected-calc-pos__calc-pos-hours"
              >{{ 'Tasks.labels.createTaskModal.taskTotal' | translate }}
              {{ helperService.formatQuantity(task.calculationPosition.quantity, task.calculationPosition?.unit?.unitRate, task.calculationPosition?.unit?.unitType) }}h</span
            >
          </div>
          <troi-progress-bar
            *ngIf="task.calculationPosition && helperService.isUnitTypeTime(task.calculationPosition?.unit?.unitType)"
            class="content-resources-col__progress-bar"
            [progressValue]="calculateUtilization()"
            [height]="'0.55rem'"
          ></troi-progress-bar>
          <div class="calc-pos-btn-container">
            <troi-btn
              *ngIf="task.calculationPosition"
              [submit]="false"
              [outline]="true"
              class="calc-pos-button delete-btn"
              (click)="onDeleteCalcPosClick(); openPopover()"
              [disabled]="!this.isCpDeletionAllowed()"
              ngbPopover="{{ 'Tasks.labels.createTaskModal.deleteTimeentries' | translate }}"
              triggers="manual"
              placement="bottom"
              #deleteTimeetriesPopover="ngbPopover"
              popoverClass="timeentries-tooltip-popover"
            >
              <span>{{ 'Tasks.labels.createTaskModal.deleteCalcPosition' | translate }}</span>
            </troi-btn>
            <troi-btn
              [submit]="false"
              id="save-btn"
              class="calc-pos-button add-btn"
              (click)="onAddCalcPos()"
            >
              <troi-icon *ngIf="!task.calculationPosition" icon="icon-plus" size="10px"></troi-icon>
              <span>
                {{
                  task.calculationPosition
                    ? ('Tasks.labels.createTaskModal.changeCalcPosition' | translate)
                    : ('Tasks.labels.createTaskModal.connectCalcPosition' | translate)
                }}
              </span>
            </troi-btn>
          </div>

          <div class="mtb content-resources-col__ressources-planning">
            <span class="paragraph-title">{{
              'Tasks.labels.createTaskModal.resourcePlanning' | translate
            }}</span>
            <troi-task-modal-resource-planning [task]="task"></troi-task-modal-resource-planning>
          </div>
        </div>
      </div>
      <div *ngIf="showCalcPosContent" class="create-task__footer flex-end">
        <troi-btn
          [disabled]="!selectedCalculationPosition"
          id="save-btn"
          (click)="onSavePositionClick()"
        >
          <span>{{ 'Tasks.labels.createTaskModal.addPosition' | translate }}</span>
        </troi-btn>
      </div>
      <div *ngIf="!showCalcPosContent" class="create-task__footer">
        <div class="modal-footer-col-dropdowns">
          <div class="modal-footer-col-dropdowns__priority">
            <troi-dropdown-select
              [initValue]="task?.priority"
              [options]="filterService.generatePrioOptions()"
              size="11px"
              maxWidth="50px"
              textAlignment="center"
              [noBorder]="true"
              (selected)="onPrioChange($event)"
              [top]="true"
            ></troi-dropdown-select>
          </div>
          <div class="modal-footer-col-dropdowns__state">
            <troi-dropdown-select
              [initValue]="task.status?.id"
              [options]="getTaskStatusOptions()"
              size="11px"
              textAlignment="center"
              [noBorder]="true"
              (selected)="onStatusChange($event)"
              [top]="true"
            ></troi-dropdown-select>
          </div>
        </div>
        <div class="modal-footer-col-btns">
          <div class="modal-footer-col-btns__ctx-menu">
            <div *ngIf="editModeOn" class="ctx-menu-actions">
              <troi-icon
                *ngIf="isBookingEnabled(task)"
                icon="icon-timer"
                class="actions__icon"
                (click)="onStopwatchClick(task)"
              ></troi-icon>
              <troi-icon
                ngbPopover="{{ 'Tasks.labels.deletionDenied' | translate }}"
                placement="top"
                #deletePopover="ngbPopover"
                triggers="manual"
                icon="icon-trash-can"
                class="actions__icon"
                (click)="onDeleteTaskClick()"
              ></troi-icon>
              <troi-dropdown-list
                textAlignment="left"
                [options]="moreActionsOptions"
                (selectedEmitter)="onActionSelect($event)"
                (openChange)="onDropdownActionOpeningState($event)"
              >
                <troi-icon
                  icon="icon-circles-full"
                  class="actions__icon actions__icon--large"
                ></troi-icon>
              </troi-dropdown-list>
            </div>
          </div>
          <div class="modal-footer-col-btns__btn-group">
            <troi-btn [submit]="false" id="cancel-btn" (click)="onModalClose()">
              <span>{{ 'Common.labels.cancel' | translate }}</span>
            </troi-btn>
            <troi-btn [submit]="false" id="save-btn" (click)="onSave(false)">
              <span>{{ 'Common.labels.save' | translate }}</span>
            </troi-btn>
            <troi-btn [submit]="false" id="save-close-btn" (click)="onSave(true)">
              <span>{{ 'Tasks.labels.createTaskModal.saveAndClose' | translate }}</span>
            </troi-btn>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
