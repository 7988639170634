<div class="subtasks">
  <div class="subtasks__headline">
    <span class="subtasks__headline--subtitle">{{
      'Tasks.labels.createTaskModal.subtasks.subtasks' | translate
    }}</span>
    <div class="subtasks__headline--progress-bar">
      <troi-progress-bar
        [height]="'0.4rem'"
        [borderRadius]="'0.2rem'"
        [progressValue]="calculateProgressValue()"
      ></troi-progress-bar>
      <span> {{ task.subtaskFinished }} / {{ task.subtaskAll }}</span>
    </div>
  </div>

  <div class="subtasks__create-subtask">
    <form
      [formGroup]="createSubtaskForm"
      class="subtasks__create-subtask--create-new-container"
      [ngClass]="{ 'subtasks__create-subtask--border': enableInput }"
      (click)="onCreateSubtaskClick()"
    >
      <div class="subtasks__plus-icon">
        <troi-icon
          *ngIf="!enableInput"
          class="subtasks__plus-icon--icon-plus"
          [icon]="'icon-plus'"
          [size]="'12px'"
        ></troi-icon>
      </div>
      <span *ngIf="!enableInput" class="subtasks__create-subtask--create-new">{{
        'Tasks.labels.createTaskModal.subtasks.newSubtask' | translate
      }}</span>
      <troi-input-field
        (keyup.enter)="createSubtask()"
        formControlName="title"
        *ngIf="enableInput"
        focus-directive
        cssSelector=".troi-input-field__input"
        [placeholder]="'Tasks.labels.createTaskModal.subtasks.addTitle' | translate"
        [fieldInvalid]="false"
      ></troi-input-field>
      <troi-icon
        *ngIf="enableInput"
        class="subtasks__plus-icon--icon-plus"
        [icon]="'icon-plus'"
        (click)="createSubtask()"
      ></troi-icon>
    </form>
    <div class="subtasks__container">
      <div class="subtasks__container--scrollable">
        <troi-task-subtask
          (showEditSubtaskContent)="openEditSubtaskModal($event)"
          *ngFor="let subtask of task.subtasks"
          [subtask]="subtask"
          [task]="task"
        ></troi-task-subtask>
      </div>
    </div>
  </div>
</div>
