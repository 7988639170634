import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class RefreshFormEmitter {
  emitter = new Subject();

  refresh() {
    this.emitter.next(true);
  }
}
