export enum ApprovalsStatusEnum {
  NOT_STARTED = 'not started',
  PENDING = 'pending',
  DECLINED = 'declined',
  APPROVED = 'approved',
  CANCELLED = 'cancelled',
}

export enum ApprovalsActionsEnum {
  START = 'start',
  EDIT = 'edit',
  CANCEL = 'cancel',
  HISTORY = 'history',
  ACCEPT = 'accept',
  DECLINE = 'decline',
  DELEGATE = 'delegate',
}
