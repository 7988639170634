import { Priority } from '../enum/priority';
import { AssigneeInterface } from '../interfaces/assignee.interface';
import { AssignmentInterface } from '../interfaces/assignment.interface';
import { AttachmentInterface } from '../interfaces/attachment.interface';
import { CalculationPositionInterface } from '../interfaces/calculationPosition.interface';

import { CommentInterface } from '../interfaces/comment.interface';
import { ProjectInterface } from '../interfaces/project.interface';

import { StatusInterface } from '../interfaces/status.interface';
import { StatusGroupInterface } from '../interfaces/statusGroup.interface';
import { SubtaskInterface } from '../interfaces/subtask.interface';
import { TagInterface } from '../interfaces/tag.interface';
import { TimelineSubprojectInterface, TimelineTaskInterface } from '../interfaces/task-timeline.interface';
import { TaskInterface } from '../interfaces/task.interface';

export class TaskModel implements TaskInterface {
  id?: string;
  title: string;
  description: string;
  priority: Priority;
  startDate: Date;
  endDate: Date;
  subtaskAll: number;
  subtaskFinished: number;
  hoursBudget: number;
  hoursSpent: number;
  averageDailyHours: number;
  projectpath: string;
  status: StatusInterface;
  statusGroup: StatusGroupInterface;
  clientId: string;
  kanbanOrder: number;
  project?: ProjectInterface;
  subproject?: any;
  calculationPosition?: CalculationPositionInterface;
  assignees?: AssigneeInterface[];
  projectunit?: string;
  subtasks?: SubtaskInterface[];
  attachments?: AttachmentInterface[];
  comments?: CommentInterface[];
  tags?: TagInterface[];
  created?: Date;
  modified?: Date;
  deleted?: Date;
  isDeleted?: boolean;
  createdBy?: number;
  modifiedBy?: number;
  deletedBy?: number;

  constructor(task: TaskInterface | TimelineTaskInterface) {
    // converting interface into class object to get date values into correct object types
    Object.keys(task).forEach((attribute: string) => {
      if (attribute === 'endDate') this.endDate = new Date(task.endDate);
      else this[attribute] = task[attribute];
    });

    // converting assignment dates into JS-Date objects
    if (task.assignees) {
      task.assignees.forEach((a: AssigneeInterface) => {
        if (a.assignments) {
          a.assignments.forEach((as: AssignmentInterface) => {
            as.startDate = new Date(as.startDate);
            as.endDate = new Date(as.endDate);
          });
        }
      });
    }
  }

  public getEndDateTs(): number {
    if (this.endDate && this.endDate !== null) return this.endDate.getTime();
  }
}
