import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AttachmentService } from '../../network/attachment.service';
import { Subscription } from 'rxjs';
import { TaskInterface } from '../../../../../interfaces/task.interface';
import { AttachmentType } from '../../../../../enum/attachment-type';
import { AttachmentResponseInterface } from '../../../../../interfaces/responses.interface';
import { AttachmentInterface } from '../../../../../interfaces/attachment.interface';
import { StorageNotificationService } from '../../../../../../../core/notifications/storageNotification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'troi-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  @Input() curTask: TaskInterface;

  public attachmentOptions: AttachmentInterface[] = [];
  public isLoading = false;

  constructor(
    private attachmentService: AttachmentService,
    private notificationService: StorageNotificationService,
    private translationService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.subscriptions.add(
      this.attachmentService
        .getAttachments(this.curTask.id, AttachmentType.TASK)
        .subscribe((res: AttachmentResponseInterface) => {
          this.attachmentOptions = res.data as AttachmentInterface[];
          this.isLoading = false;
        }),
    );
  }

  public onFileUpload(files: File[], dropzone): void {
    files.forEach((file: File) => {
      const newAttachment: AttachmentInterface = {
        parentId: this.curTask.id,
        attachmentType: AttachmentType.TASK,

        file,
      };

      this.subscriptions.add(
        this.attachmentService.uploadAttachment(newAttachment).subscribe((res: AttachmentResponseInterface) => {
          this.attachmentOptions.push(res.data as AttachmentInterface);
          this.notificationService.showSuccess(
            this.translationService.instant('Tasks.labels.notifications.attachment.upload'),
          );

          dropzone.reset();
        }),
      );
    });
  }

  public onFileDelete(attachment: AttachmentInterface) {
    this.attachmentOptions = this.attachmentOptions.filter((x: AttachmentInterface) => x.id !== attachment.id);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
