<div class="ProjectLogTable">
  <!-- <div class="error" *ngIf="showError">{{ showErrorMessage }}</div> -->
  <troi-loading-spinner *ngIf="loading"></troi-loading-spinner>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">{{ 'Timerecording.TrackedHours(Projects)' | translate }}</th>
        <!-- <th scope="col" style="width: 5%">{{ 'Timerecording.status' | translate }}</th> -->
        <th scope="col" *ngIf="timeService.isBillingInternalCommentVisibility()">
          {{ 'Timerecording.Internal_Comment' | translate }}
        </th>
        <th scope="col">{{ 'Timerecording.External_Comment' | translate }}</th>
        <th scope="col" style="width: 90px">{{ 'Timerecording.Tracked' | translate }}</th>
        <th scope="col" style="width: 90px" *ngIf="isEnableTimerecordingAdditionalFields">
          {{ 'Timerecording.Actual_Hours' | translate }}
        </th>
        <th scope="col" style="width: 90px" *ngIf="isRestExpenseEstimationActive">
          {{ 'Timerecording.Rest_Estimation' | translate }}
        </th>
        <th scope="col" *ngIf="progressBar && isDisplayDebitActual">
          {{ 'Timerecording.a/din(hours)' | translate }}
        </th>
        <th scope="col">{{ 'Timerecording.NB' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let project of LogData; let i = index">
        <tr>
          <td style="width: 400px">
            <div class="d-flex justify-content-between project-path">
              <div class="contentDiv d-flex">
                <div style="width: 100%">
                  <span class="contentHead" [class.contentHeadText]="project.cp.editMode">{{
                    project.cp.cpPathHeader
                  }}</span
                  ><br />
                  <span class="contentDesc" [innerHTML]="project.cp.cpPathTail"></span>
                  <br />
                  <div style="display: flex">
                    <div>
                      <span class="contentId">K-{{ project.cp.cpId }}</span>
                    </div>
                    <div class="contentStatus mx-2">
                      <tr-project-approval
                        [billingIsApprovalRequired]="project.billing.billingIsApprovalRequired"
                        [billingApprovalStatus]="project.billing.billingApprovalStatus"
                      ></tr-project-approval>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center ms-5">
                <ng-container *ngIf="!project.cp.editMode && isEditable">
                  <troi-icon-button
                    class="actions__icon"
                    icon="icon-can-edit"
                    title=" {{ 'Timerecording.edit' | translate }}"
                    (click)="toggleEditMode(i)"
                    *ngIf="!isEnableInlineMode"
                  ></troi-icon-button>
                  <troi-icon-button
                    class="actions__icon"
                    title=" {{ 'Timerecording.rebooking' | translate }}"
                    (click)="cloneData(project)"
                    *ngIf="!project.cp.cpNewAdded"
                  >
                    <svg
                      width="18px"
                      height="22px"
                      viewBox="0 0 512 512"
                      data-name="Layer 1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M64,256H34A222,222,0,0,1,430,118.15V85h30V190H355V160h67.27A192.21,192.21,0,0,0,256,64C150.13,64,64,150.13,64,256Zm384,0c0,105.87-86.13,192-192,192A192.21,192.21,0,0,1,89.73,352H157V322H52V427H82V393.85A222,222,0,0,0,478,256Z"
                      />
                    </svg>
                  </troi-icon-button>
                  <troi-icon-button
                    class="actions__icon"
                    icon="icon-trash-can"
                    title=" {{ 'Timerecording.delete' | translate }}"
                    (click)="deleteLog(project, true)"
                  ></troi-icon-button>
                </ng-container>
              </div>
            </div>
          </td>
          <td
            *ngIf="!project.cp.editMode && timeService.isBillingInternalCommentVisibility()"
            (dblclick)="!isEnableInlineMode ? toggleInlineEditMode(i, 'internalComment') : null"
          >
            <troi-project-tooltip
              [projectItem]="project"
              [type]="'billingInternalComment'"
              ngClass="gridster-item-content"
              *ngIf="!isEnableInlineMode"
            >
            </troi-project-tooltip>
            <div class="commentDiv" *ngIf="isEnableInlineMode">
              <span class="commentDiv__input">
                <troi-textarea
                  name="internalcomment"
                  [placeholder]="'Desktop.Widgets.Calendar.CreateDates.Description' | translate"
                  [(ngModel)]="project.billing.billingInternalComment"
                  (keydown)="handleKeyDown($event, project)"
                  name="internalcomment"
                  [placeholder]="
                    isTimeRecordingInternalCommentRequired
                      ? ('Timerecording.Internal_Comment_Required' | translate)
                      : ('Timerecording.Internal_Comment' | translate)
                  "
                  [class.red-border]="project.cp.internalCommentError && !project.billing.billingInternalComment.trim()"
                  autocomplete="off"
                  fontSize="12"
                  #internalComment
                ></troi-textarea>
                <troi-dropdown-list
                  #dropdownList
                  [options]="boilerPlates.internalComment"
                  (selectedEmitter)="project.billing.billingInternalComment = $event.value"
                  [ngbTooltip]="'Timerecording.boiler_plates' | translate"
                  *ngIf="isEnabledBoilerPlatesInComments && boilerPlates.internalComment.length > 0"
                >
                  <troi-icon-button icon="icon-arrow-down-list" skipStopPropagation></troi-icon-button>
                </troi-dropdown-list>
              </span>
              <span
                *ngIf="project.cp.internalCommentError && !project.billing.billingInternalComment.trim()"
                class="red-text"
              >
                {{ 'Timerecording.Please_enter_internal_comment' | translate }}
              </span>
            </div>
          </td>
          <td
            *ngIf="!project.cp.editMode"
            (dblclick)="!isEnableInlineMode ? toggleInlineEditMode(i, 'externalComment') : null"
          >
            <troi-project-tooltip
              [projectItem]="project"
              [type]="'billingExternalComment'"
              ngClass="gridster-item-content"
              *ngIf="!isEnableInlineMode"
            >
            </troi-project-tooltip>
            <div class="commentDiv" *ngIf="isEnableInlineMode">
              <span class="commentDiv__input">
                <troi-textarea
                  [(ngModel)]="project.billing.billingExternalComment"
                  name="externalcomment"
                  [placeholder]="
                    isTimeRecordingExternalCommentRequired
                      ? ('Timerecording.External_Comment_Required' | translate)
                      : ('Timerecording.External_Comment' | translate)
                  "
                  (keydown)="handleKeyDown($event, project)"
                  [class.red-border]="project.cp.externalCommentError && !project.billing.billingExternalComment.trim()"
                  autocomplete="off"
                  fontSize="12"
                  #externalComment
                ></troi-textarea>
                <troi-dropdown-list
                  #dropdownList
                  [options]="boilerPlates.externalComment"
                  (selectedEmitter)="project.billing.billingExternalComment = $event.value"
                  [ngbTooltip]="'Timerecording.boiler_plates' | translate"
                  *ngIf="isEnabledBoilerPlatesInComments && boilerPlates.externalComment.length > 0"
                >
                  <troi-icon-button icon="icon-arrow-down-list" skipStopPropagation></troi-icon-button>
                </troi-dropdown-list>
              </span>
              <span
                *ngIf="project.cp.externalCommentError && !project.billing.billingExternalComment.trim()"
                class="red-text"
              >
                {{ 'Timerecording.Please_enter_external_comment' | translate }}
              </span>
            </div>
          </td>

          <ng-container *ngIf="!isEnableInlineMode">
            <td *ngIf="!project.cp.editMode" (dblclick)="toggleInlineEditMode(i, 'quantityTime')">
              <div class="d-flex totaltimeDiv">
                <troi-icon icon="troi-menu-timerecording"></troi-icon>
                <div *ngIf="project.billing.billingQuantityTime !== undefined">
                  <span class="mx-2" *ngIf="!project.cp.editMode">
                    {{
                      project.billing.billingQuantityTime === '' || project.billing.billingQuantityTime === undefined
                        ? timeService.setFormat('00:00')
                        : timeService.setFormat(
                            timeService.formatTime(timeService.getNonNegativeValue(project.billing.billingQuantityTime))
                          )
                    }}
                  </span>
                </div>
              </div>
            </td>
            <td
              *ngIf="!project.cp.editMode && isEnableTimerecordingAdditionalFields"
              (dblclick)="toggleInlineEditMode(i, 'actualHoursTime')"
            >
              <div class="d-flex totaltimeDiv">
                <troi-icon icon="troi-menu-timerecording"></troi-icon>
                <div>
                  <span class="mx-2" *ngIf="!project.cp.editMode">
                    {{
                      project.billing.billingActualHoursTime === '' ||
                      project.billing.billingActualHoursTime === undefined
                        ? timeService.setFormat('00:00')
                        : timeService.setFormat(
                            timeService.formatTime(
                              timeService.getNonNegativeValue(project.billing.billingActualHoursTime)
                            )
                          )
                    }}
                  </span>
                </div>
              </div>
            </td>
            <td
              *ngIf="!project.cp.editMode && isRestExpenseEstimationActive"
              (dblclick)="toggleInlineEditMode(i, 'estimationTime')"
            >
              <div class="d-flex totaltimeDiv">
                <troi-icon icon="troi-menu-timerecording"></troi-icon>
                <div>
                  <span class="mx-2" *ngIf="!project.cp.editMode">
                    {{
                      project.billing.billingEstimationTime === '' ||
                      project.billing.billingEstimationTime === undefined
                        ? timeService.setFormat('00:00')
                        : timeService.setFormat(
                            timeService.formatTime(
                              timeService.getNonNegativeValue(project.billing.billingEstimationTime)
                            )
                          )
                    }}
                  </span>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container *ngIf="isEnableInlineMode">
            <td>
              <div class="d-flex totaltimeDiv">
                <troi-icon icon="troi-menu-timerecording"></troi-icon>
                <div *ngIf="project.billing.billingQuantityTime !== undefined">
                  <div class="mx-2">
                    <troi-time-input
                      [(ngModel)]="project.billing.billingQuantityTime"
                      (keydown)="handleKeyDown($event, project)"
                      id="time-picker"
                      #timePicker
                      [placeholder]="'00:00'"
                      [comment]="project.billing.billingExternalComment"
                      (commentChange)="project.billing.billingExternalComment = $event"
                      #quantityTime
                    ></troi-time-input>
                    <span *ngIf="project.cp.timeError" class="red-text">{{
                      'Timerecording.Please_enter_time' | translate
                    }}</span>
                  </div>
                </div>
              </div>
            </td>
            <td *ngIf="isEnableTimerecordingAdditionalFields">
              <div class="d-flex totaltimeDiv">
                <troi-icon icon="troi-menu-timerecording"></troi-icon>
                <div>
                  <div class="mx-2">
                    <troi-time-input
                      [(ngModel)]="project.billing.billingActualHoursTime"
                      (keydown)="handleKeyDown($event, project)"
                      [placeholder]="'00:00'"
                      #actualHoursTime
                    ></troi-time-input>
                  </div>
                </div>
              </div>
            </td>
            <td *ngIf="isRestExpenseEstimationActive">
              <div class="d-flex totaltimeDiv">
                <troi-icon icon="troi-menu-timerecording"></troi-icon>
                <div>
                  <div class="mx-2">
                    <troi-time-input
                      [(ngModel)]="project.billing.billingEstimationTime"
                      (keydown)="handleKeyDown($event, project)"
                      [placeholder]="'00:00'"
                      #estimationTime
                    ></troi-time-input>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <td *ngIf="progressBar && isDisplayDebitActual && !project.cp.editMode">
            <div>
              <div class="progress-label">
                <div class="progress-current">
                  {{ timeService.setFormat(project.cp.cpUsedUnits.toFixed(2).replace('.', ',')) }}h
                </div>
                <div class="progress-max">
                  {{ timeService.setFormat(project.cp.cpPlannedUnits.toFixed(2).replace('.', ',')) }}h
                </div>
              </div>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [ngClass]="{ 'red-background': project.cp.percentDisplay > 100 }"
                  [ngStyle]="{ width: project.cp.percentDisplay + '%' }"
                  aria-valuenow="{{ project.cp.percentDisplay }}"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </td>
          <td
            *ngIf="!project.cp.editMode || isEnableInlineMode"
            (dblclick)="!isEnableInlineMode ? toggleInlineEditMode(i, 'isBillable') : null"
          >
            <div class="checkbox--wrapper" #wrapper>
              <div class="checkbox--focused"></div>
              <input
                type="checkbox"
                [ngModel]="!project.billing.billingIsBillable"
                (ngModelChange)="markAsChanged(project); project.billing.billingIsBillable = !$event"
                (change)="!isEnableInlineMode ? getValueInputField(project, 'checkbox', i) : null"
                (keydown)="handleKeyDown($event, project)"
                allowFocusHighlight
                [disabled]="
                  (!project.cp.editMode && !isEnableInlineMode) || timeService.isHideBillableCheckboxInTimeRecording()
                "
                (focus)="wrapper.classList.add('checkbox--wrapper__focused')"
                (blur)="wrapper.classList.remove('checkbox--wrapper__focused')"
                #isBillable
              />
            </div>
          </td>
          <td *ngIf="project.cp.editMode && timeService.isBillingInternalCommentVisibility()"></td>
          <td *ngIf="project.cp.editMode"></td>
          <td *ngIf="project.cp.editMode">
            <div class="d-flex totaltimeDiv">
              <troi-icon icon="troi-menu-timerecording"></troi-icon>
              <div *ngIf="project.billing.billingQuantityTime !== undefined">
                <span class="mx-2" *ngIf="!project.cp.editMode">
                  {{
                    project.billing.billingQuantityTime === '' || project.billing.billingQuantityTime === undefined
                      ? '00:00'
                      : timeService.setFormat(project.billing.billingQuantityTime)
                  }}
                </span>

                <div class="mx-2">
                  <troi-time-input
                    [(ngModel)]="project.billing.billingQuantityTime"
                    (keyup.enter)="onEnterUp($event, project, 'time', i)"
                    id="time-picker"
                    #timePicker
                    [placeholder]="'00:00'"
                    [comment]="project.billing.billingExternalComment"
                    (commentChange)="project.billing.billingExternalComment = $event"
                  ></troi-time-input>
                  <span *ngIf="project.cp.editMode && project.cp.timeError" class="red-text">{{
                    'Timerecording.Please_enter_time' | translate
                  }}</span>
                </div>
              </div>
            </div>
          </td>
          <td *ngIf="project.cp.editMode && isEnableTimerecordingAdditionalFields">
            <div class="d-flex totaltimeDiv">
              <troi-icon icon="troi-menu-timerecording"></troi-icon>
              <div>
                <span class="mx-2" *ngIf="!project.cp.editMode">
                  {{
                    project.billing.billingActualHoursTime === '' ||
                    project.billing.billingActualHoursTime === undefined
                      ? '00:00'
                      : timeService.setFormat(project.billing.billingActualHoursTime)
                  }}
                </span>

                <div class="mx-2">
                  <troi-time-input
                    [(ngModel)]="project.billing.billingActualHoursTime"
                    (keyup.enter)="onEnterUp($event, project, 'time', i)"
                    [placeholder]="'00:00'"
                  ></troi-time-input>
                </div>
              </div>
            </div>
          </td>
          <td *ngIf="project.cp.editMode && isRestExpenseEstimationActive">
            <div class="d-flex totaltimeDiv">
              <troi-icon icon="troi-menu-timerecording"></troi-icon>
              <div>
                <span class="mx-2" *ngIf="!project.cp.editMode">
                  {{
                    project.billing.billingEstimationTime === '' || project.billing.billingEstimationTime === undefined
                      ? '00:00'
                      : timeService.setFormat(project.billing.billingEstimationTime)
                  }}
                </span>

                <div class="mx-2">
                  <troi-time-input
                    [(ngModel)]="project.billing.billingEstimationTime"
                    (keyup.enter)="onEnterUp($event, project, 'time', i)"
                    [placeholder]="'00:00'"
                  ></troi-time-input>
                </div>
              </div>
            </div>
          </td>
          <td *ngIf="progressBar && isDisplayDebitActual && project.cp.editMode">
            <div>
              <div class="progress-label">
                <div class="progress-current">
                  {{ timeService.setFormat(project.cp.cpUsedUnits.toFixed(2).replace('.', ',')) }}h
                </div>
                <div class="progress-max">
                  {{ timeService.setFormat(project.cp.cpPlannedUnits.toFixed(2).replace('.', ',')) }}h
                </div>
              </div>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [ngClass]="{ 'red-background': project.cp.percentDisplay > 100 }"
                  [ngStyle]="{ width: project.cp.percentDisplay + '%' }"
                  aria-valuenow="{{ project.cp.percentDisplay }}"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </td>
          <td *ngIf="project.cp.editMode">
            <div>
              <input
                type="checkbox"
                [(ngModel)]="!project.billing.billingIsBillable"
                (change)="getValueInputField(project, 'checkbox', i)"
                [disabled]="timeService.isHideBillableCheckboxInTimeRecording()"
                style="width: 15px"
                [attr.aria-checked]="project.billing.billingIsBillable ? 'true' : 'false'"
              />
            </div>
          </td>
        </tr>

        <!-- Edit mode -->
        <tr *ngIf="project.cp.editMode && timeService.isBillingInternalCommentVisibility()">
          <td [attr.colspan]="timeService.isBillingInternalCommentVisibility() ? '3' : '2'">
            <div class="commentDiv" *ngIf="timeService.isBillingInternalCommentVisibility()">
              <span class="commentDiv__input">
                <input
                  *ngIf="project.cp.editMode"
                  type="text"
                  [(ngModel)]="project.billing.billingInternalComment"
                  name="internalcomment"
                  [placeholder]="
                    isTimeRecordingInternalCommentRequired
                      ? ('Timerecording.Internal_Comment_Required' | translate)
                      : ('Timerecording.Internal_Comment' | translate)
                  "
                  (keyup.enter)="getValueInputField(project, 'internal', i)"
                  [class.red-border]="project.cp.internalCommentError && !project.billing.billingInternalComment.trim()"
                  autocomplete="off"
                />
                <troi-dropdown-list
                  #dropdownList
                  [options]="boilerPlates.internalComment"
                  (selectedEmitter)="project.billing.billingInternalComment = $event.value"
                  [ngbTooltip]="'Timerecording.boiler_plates' | translate"
                  *ngIf="isEnabledBoilerPlatesInComments && boilerPlates.internalComment.length > 0"
                >
                  <troi-icon-button icon="icon-arrow-down-list" skipStopPropagation></troi-icon-button>
                </troi-dropdown-list>
              </span>
              <span
                *ngIf="
                  project.cp.editMode &&
                  project.cp.internalCommentError &&
                  !project.billing.billingInternalComment.trim()
                "
                class="red-text"
                >{{ 'Timerecording.Please_enter_internal_comment' | translate }}</span
              >
            </div>
          </td>
          <td colspan="3">
            <tnd-datepicker
              *ngIf="project.cp.editMode"
              [fieldInvalid]="false"
              [validationEnabled]="true"
              [rangeFrom]="fromDate"
              [rangeTo]="toDate"
              [singleDatePicker]="true"
              [emitIfEmpty]="true"
              [disabled]="false"
              (rangeChanged)="dateChanged($event)"
              [requiredFieldErrorMessage]="'Booking.error.receiptDateIsRequired'"
              [hideRanges]="true"
              ngClass="tnd-create-event-datepicker"
            >
            </tnd-datepicker>
          </td>
        </tr>

        <tr *ngIf="project.cp.editMode && timeService.isBillingInternalCommentVisibility()">
          <td [attr.colspan]="timeService.isBillingInternalCommentVisibility() ? '3' : '2'">
            <div class="commentDiv">
              <span class="commentDiv__input">
                <input
                  *ngIf="project.cp.editMode"
                  type="text"
                  [(ngModel)]="project.billing.billingExternalComment"
                  name="externalcomment"
                  [placeholder]="
                    isTimeRecordingExternalCommentRequired
                      ? ('Timerecording.External_Comment_Required' | translate)
                      : ('Timerecording.External_Comment' | translate)
                  "
                  (keyup.enter)="getValueInputField(project, 'external', i)"
                  [class.red-border]="project.cp.externalCommentError && !project.billing.billingExternalComment.trim()"
                  autocomplete="off"
                />
                <troi-dropdown-list
                  #dropdownList
                  [options]="boilerPlates.externalComment"
                  (selectedEmitter)="project.billing.billingExternalComment = $event.value"
                  [ngbTooltip]="'Timerecording.boiler_plates' | translate"
                  *ngIf="isEnabledBoilerPlatesInComments && boilerPlates.externalComment.length > 0"
                >
                  <troi-icon-button icon="icon-arrow-down-list" skipStopPropagation></troi-icon-button>
                </troi-dropdown-list>
              </span>
              <span
                *ngIf="
                  project.cp.editMode &&
                  project.cp.externalCommentError &&
                  !project.billing.billingExternalComment.trim()
                "
                class="red-text"
                >{{ 'Timerecording.Please_enter_external_comment' | translate }}</span
              >
            </div>
          </td>
          <td colspan="3">
            <troi-icon-button
              icon="icon-save"
              title=" {{ 'Booking.labels.save' | translate }}"
              (click)="saveData(project)"
            ></troi-icon-button>
          </td>
        </tr>

        <tr *ngIf="project.cp.editMode && !timeService.isBillingInternalCommentVisibility()">
          <td [attr.colspan]="timeService.isBillingInternalCommentVisibility() ? '3' : '2'">
            <div class="commentDiv">
              <span class="commentDiv__input">
                <input
                  *ngIf="project.cp.editMode"
                  type="text"
                  [(ngModel)]="project.billing.billingExternalComment"
                  name="externalcomment"
                  [placeholder]="
                    isTimeRecordingExternalCommentRequired
                      ? ('Timerecording.External_Comment_Required' | translate)
                      : ('Timerecording.External_Comment' | translate)
                  "
                  (keyup.enter)="getValueInputField(project, 'external', i)"
                  [class.red-border]="project.cp.externalCommentError && !project.billing.billingExternalComment.trim()"
                  autocomplete="off"
                />
                <troi-dropdown-list
                  #dropdownList
                  [options]="boilerPlates.externalComment"
                  (selectedEmitter)="project.billing.billingExternalComment = $event.value"
                  [ngbTooltip]="'Timerecording.boiler_plates' | translate"
                  *ngIf="isEnabledBoilerPlatesInComments && boilerPlates.externalComment.length > 0"
                >
                  <troi-icon-button icon="icon-arrow-down-list" skipStopPropagation></troi-icon-button>
                </troi-dropdown-list>
              </span>
              <span
                *ngIf="
                  project.cp.editMode &&
                  project.cp.externalCommentError &&
                  !project.billing.billingExternalComment.trim()
                "
                class="red-text"
                >{{ 'Timerecording.Please_enter_external_comment' | translate }}</span
              >
            </div>
          </td>
          <td [attr.colspan]="progressBar && isDisplayDebitActual ? '2' : '1'">
            <tnd-datepicker
              *ngIf="project.cp.editMode"
              [fieldInvalid]="false"
              [validationEnabled]="true"
              [rangeFrom]="fromDate"
              [rangeTo]="toDate"
              [singleDatePicker]="true"
              [emitIfEmpty]="true"
              [disabled]="false"
              (rangeChanged)="dateChanged($event)"
              [requiredFieldErrorMessage]="'Booking.error.receiptDateIsRequired'"
              [hideRanges]="true"
              ngClass="tnd-create-event-datepicker"
            >
            </tnd-datepicker>
          </td>
          <td>
            <troi-icon-button
              icon="icon-save"
              title=" {{ 'Booking.labels.save' | translate }}"
              (click)="saveData(project)"
            ></troi-icon-button>
          </td>
        </tr>
      </ng-container>
    </tbody>

    <tfoot>
      <tr>
        <td [attr.colspan]="timeService.isBillingInternalCommentVisibility() ? '3' : '2'">
          <span>{{ 'Timerecording.SumTotals' | translate }}</span>
          <br />
          <div class="d-flex mt-3" *ngIf="isEditable">
            <troi-btn class="list-top-bar__button trackhours_btn mt-3" (click)="toggleMyProjectsVisibility()">
              <troi-icon icon="icon-plus" size="14px" [bolder]="true"></troi-icon>
              <span class="mx-2">{{ 'Timerecording.Trackhours' | translate }}</span>
            </troi-btn>
            <troi-btn
              class="list-top-bar__button mt-3 ms-4"
              (click)="assignProject()"
              *ngIf="showAssignToProjectButton && !hideSelfAssign"
            >
              <troi-icon icon="icon-plus" size="14px" [bolder]="true"></troi-icon>
              <span class="mx-2">{{ 'Timerecording.Assign' | translate }}</span>
            </troi-btn>
          </div>
        </td>
        <td>
          <div class="d-flex totalProjectTime" style="margin-bottom: 42px">
            <troi-icon icon="troi-menu-timerecording"></troi-icon>
            <span class="mx-2">{{
              timeService.setFormat(timeService.formatTime(timeService.getNonNegativeValue(totalProjectTime)))
            }}</span>
          </div>
        </td>
        <td *ngIf="isEnableTimerecordingAdditionalFields">
          <div class="d-flex totalProjectTime" style="margin-bottom: 42px">
            <troi-icon icon="troi-menu-timerecording"></troi-icon>
            <span class="mx-2">{{
              timeService.setFormat(timeService.formatTime(timeService.getNonNegativeValue(totalActualHours)))
            }}</span>
          </div>
        </td>
        <td *ngIf="isRestExpenseEstimationActive">
          <div class="d-flex totalProjectTime" style="margin-bottom: 42px">
            <troi-icon icon="troi-menu-timerecording"></troi-icon>
            <span class="mx-2">{{
              timeService.setFormat(timeService.formatTime(timeService.getNonNegativeValue(totalRestEstimation)))
            }}</span>
          </div>
        </td>
        <td *ngIf="progressBar && isDisplayDebitActual"></td>
        <td>
          <span class="d-flex mt-5">
            <troi-icon-button
              (click)="toggleInlineEditMode()"
              [icon]="!isEnableInlineMode ? 'icon-can-edit' : 'icon-close'"
              [disabled]="LogData.length === 0"
            ></troi-icon-button>
            <troi-icon-button
              (click)="saveAllProjects()"
              class="ms-2"
              [icon]="'icon-save'"
              [disabled]="!isProjectsChanged"
              *ngIf="isEnableInlineMode"
            ></troi-icon-button>
          </span>
        </td>
      </tr>
    </tfoot>
  </table>
</div>

<!-- HTML template -->
<ng-template #popTitle></ng-template>
<ng-template #popContent>
  <div class="mt-1">
    <span [style.font-size.px]="13">{{ popup.text }}</span>
  </div>
</ng-template>
