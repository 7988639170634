import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, pipe } from 'rxjs';
import {
  BackendResponseInterface,
  BackendResponseType,
  ResponseStatusInterface,
} from '../../../core/interfaces/backend.response.interface';
import { delay, map } from 'rxjs/operators';
import { FilterSetModel } from '../models/filter-set.model';
import { FilterSetInterface } from '../interfaces/filter-set.interface';
import { FilterSetsFactory } from '../factories/filter-sets.factory';
import { FilterSetTypeEnum } from '../enums/filter-set-type.enum';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../core/user/user.service';
import { Routes } from '../../../modules/tasks/enum/routes';

@Injectable()
export class TroiFilterSetsNetworkService {
  public route = '/components';
  public headers: HttpHeaders = new HttpHeaders();

  constructor(
    private httpClient: HttpClient,
    private filterSetsFactory: FilterSetsFactory,
    private userService: UserService,
  ) {}

  public fetchFilterSets(): Observable<BackendResponseInterface<FilterSetModel[]>> {
    const userId = String(this.userService.getUser().id);
    const params = new HttpParams().set('user', userId);

    let apiUrl = `${environment.url}${this.route}`;
    if (this.route === Routes.FILTER_SETS) {
      apiUrl += `/getByUser/${userId}`;
    }

    return this.httpClient
      .get<BackendResponseInterface<FilterSetInterface[]>>(apiUrl, {
        headers: this.headers,
        params: this.route === Routes.FILTER_SETS ? null : params,
      })
      .pipe(
        map((response: BackendResponseInterface<FilterSetInterface[]>) => ({
          ...response,
          data: this.filterSetsFactory.buildFilterSetModels(response.data),
        })),
      );
  }

  public saveFilterSet(filterSet: FilterSetModel): Observable<BackendResponseInterface<FilterSetModel>> {
    return this.httpClient
      .post<BackendResponseInterface<FilterSetInterface>>(
        environment.url + this.route,
        {
          name: filterSet.name,
          value: filterSet.value,
          type: FilterSetTypeEnum.OWN,
          isDefault: filterSet.isDefault,
          user: this.userService.getUser().id,
        },
        { headers: this.headers },
      )
      .pipe(
        map((response: BackendResponseInterface<FilterSetInterface>) => {
          return {
            ...response,
            data: this.filterSetsFactory.buildFilterSetModel(response.data),
          };
        }),
      );
  }

  public updateFilterSet(filterSet: FilterSetModel): Observable<ResponseStatusInterface> {
    return this.httpClient.put<ResponseStatusInterface>(
      `${environment.url}${this.route}/${filterSet.id}`,
      {
        isDefault: filterSet.isDefault,
        name: filterSet.name,
        value: filterSet.value,
        user: this.userService.getUser().id,
      },
      {
        headers: this.headers,
      },
    );
  }

  public deleteFilterSet(filterSet: FilterSetModel): Observable<ResponseStatusInterface> {
    const params = new HttpParams({
      fromObject: {
        user: String(this.userService.getUser().id),
      },
    });

    return this.httpClient.delete<ResponseStatusInterface>(`${environment.url}${this.route}/${filterSet.id}`, {
      headers: this.headers,
      params,
    });
  }
}
