import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnSelectInterface } from './column-select.interface';

@Component({
  selector: 'troi-columns-selector',
  templateUrl: './troi-columns-selector.component.html',
  styleUrls: ['./troi-columns-selector.component.scss'],
})
export class TroiColumnsSelectorComponent {
  public open = false;
  public forceOpen = false;

  @Input() disabled = false;
  @Input() columnList: ColumnSelectInterface[] = [];

  @Output() columnsSaved = new EventEmitter<ColumnSelectInterface[]>();

  public onOpen(state: boolean): void {
    this.open = state;
  }

  public toggleOpen(): void {
    this.open = !this.open;
    this.forceOpen = this.open;
  }

  public onColumnsSaved(columns: ColumnSelectInterface[]): void {
    this.columnsSaved.emit(columns);
  }
}
