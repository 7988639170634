import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LanguagesService } from '../../../../core/services/languages.service';
import { MenuInterface } from '../../interface/menu.interface';
import { MenuService } from '../../services/menu.service';
import { TranslationMode } from '../../../../core/enums/translationMode';
import { TimeRecordingService } from '../../../time-recording/time-recording.service';

@Component({
  selector: 'menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
  public dragStarted = false;
  public subMenuIcons: string[];

  constructor(
    public languagesService: LanguagesService,
    public menuService: MenuService,
    public timeService: TimeRecordingService,
  ) {}

  @Input() item: MenuInterface;
  @Input() active: boolean;
  @Input() level: number;
  @Output() selectedItemEmitter = new EventEmitter<boolean>();
  @Output() dragStartedEmitter = new EventEmitter<Record<string, boolean | MenuInterface>>();

  ngOnInit(): void {
    if (!this.item || !this.item.subMenu || !this.item.subMenu.length) {
      return;
    }
    this.subMenuIcons = this.item.subMenu.map((sItem) => sItem.iconName).slice(0, 4);
  }

  get isAnchor(): boolean {
    return Boolean(this.item.link);
  }

  public selectItem() {
    this.selectedItemEmitter.emit(true);
  }

  public onDragStart(event) {
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('application/json', JSON.stringify(this.item));
    this.dragStarted = true;
    this.dragStartedEmitter.emit({ state: true, item: this.item });
  }

  public onDragEnd(event) {
    this.dragStartedEmitter.emit({ state: false, item: this.item });
  }

  checkAnyUnsavedItem() {
    if (localStorage.getItem('unsavedChanges') === 'true') {
      const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (confirmLeave) {
        this.timeService.setUnsavedChanges(false);
        this.timeService.clearRedBorders();
        localStorage.setItem('unsavedChanges', JSON.stringify(false));
        return true;
      } else {
        this.timeService.highlightUnsavedItems();
        return false;
      }
      return confirmLeave;
    }
    localStorage.setItem('unsavedChanges', JSON.stringify(false));
    return true;
  }
}
