<div *ngIf="!isTableViewParametersLoaded" class="loading-spinnner-container loading-spinnner-container__full-width">
  <troi-loading-spinner class="loading-spinner"></troi-loading-spinner>
</div>

<div class="resources" *ngIf="isTableViewParametersLoaded" [ngClass]="{ isResourcesContext: isResourcesContext }">
  <div class="rescources__headline" *ngIf="!isResourcesContext">
    <span class="resources__headline--subtitle">{{
      'Tasks.labels.createTaskModal.resources.subtitle' | translate
    }}</span>
  </div>

  <div class="resources__filters" *ngIf="!isResourcesContext">
    <div class="resources__filters--select resources__filters--team-options">
      <troi-dropdown-select
        class="resources__filters--team-select"
        placeholder="{{ 'Tasks.labels.createTaskModal.resources.projectteam' | translate }}"
        [options]="generateTeamOptions()"
        size="11px"
        textAlignment="center"
        [noBorder]="true"
        (selected)="onTeamChange($event)"
        [top]="true"
        [labelIcon]="'icon-persons-with-my'"
      ></troi-dropdown-select>
    </div>

    <div class="resources__filters--select resources__filters--employee-options">
      <troi-dropdown-select
        [noBorder]="true"
        [search]="true"
        [options]="generateEmployeesOptions()"
        [multipleSelect]="true"
        [selectAllOption]="true"
        size="11px"
        (selected)="onEmployeeChange($event)"
        [reloadPreloadedOptions]="reloadEmployeeDropdownOptions"
        [resetMultipleSelect]="resetEmployeeDropdown"
        placeholder="{{ 'Tasks.labels.createTaskModal.resources.selectEmployee' | translate }}"
      >
      </troi-dropdown-select>
    </div>

    <div class="resources__filters--select resources__filters--range-date">
      <troi-icon class="resources__filters--icon" icon="icon-list-points" size="14px"></troi-icon>
      <troi-range-date
        class="resources__filters--date-select"
        [rangeFrom]="task?.startDate ? task.startDate : dateNow"
        [rangeTo]="task?.endDate ? task.endDate : dateNow"
        [minDate]="getMinDate()"
        (rangeChanged)="updateCalendarRange($event)"
        [settings]="{ format: settingsService.settings?.settings.dateFormat }"
        [placeholder]="'Tasks.labels.dateFormat' | translate"
        [defaultAlignment]="false"
        [assignEmployeeAlignment]="true"
        [rangesInPast]="false"
        [emitIfEmpty]="false"
      ></troi-range-date>
    </div>

    <div class="resources__filters--select resources__filters--settings">
      <div
        (mouseenter)="showDropdown = true"
        (mouseleave)="showDropdown = false"
        class="resources__filters--dropdown-container"
      >
        <troi-icon class="resources__filters--icon" icon="icon-setting" size="14px"></troi-icon>
        <div *ngIf="showDropdown" class="resources__filters--dropdown">
          <div class="resources__filters--dropdown--view">
            <div
              *ngFor="let view of resourcesService.viewSelectionDropdown[0].rangeSelect"
              class="resources__filters--option-container"
              (click)="onSelectViewClick(view.value)"
            >
              <div class="resources__filters--option-container--radio">
                <div
                  *ngIf="selectedDateView && selectedDateView === view.value"
                  class="resources__filters--option-container--radio--clicked"
                ></div>
              </div>
              <span>{{ view.option | translate }}</span>
            </div>
          </div>
          <div class="resources__filters--dropdown--section">
            <div
              *ngFor="let view of resourcesService.viewSelectionDropdown[1].utilSelect"
              class="resources__filters--option-container"
              (click)="onSelectUtilizationClick(view.value)"
            >
              <div class="resources__filters--option-container--radio">
                <div
                  *ngIf="selectedUtilization === view.value"
                  class="resources__filters--option-container--radio--clicked"
                ></div>
              </div>
              <span>{{ view.option | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="selection-header">
    <span class="selection-header__current-view">{{ getCurViewTitle() | translate }}</span>
  </div>

  <div *ngIf="isLoading" class="loading-spinnner-container">
    <troi-loading-spinner class="loading-spinner"></troi-loading-spinner>
  </div>
  <div class="container-fixer">
    <div class="calendar-container" style="margin-bottom: 32px">
      <div class="calendar-container__team">
        <div>
          <div class="calendar">
            <div class="calendar__header">
              <div class="calendar__header-column">
                <span class="calendar__header__title stretch-to-250px">
                  <span>
                    {{ 'Tasks.labels.allTeams' | translate }}
                  </span>
                  <span class="me-2">
                    <ng-container *ngIf="!selectedUtilization">
                      {{ 'Tasks.labels.createTaskModal.resources.availableTime' | translate }}
                    </ng-container>
                    <ng-container *ngIf="selectedUtilization">
                      {{ 'Timerecording.Utilization' | translate }}
                    </ng-container>
                  </span>
                </span>
              </div>
              <div class="calendar__column">
                <div
                  class="calendar__column-title calendar__column-title--colHeader text-center"
                  *ngFor="let colTitle of columns; let colIdx = index"
                  [ngClass]="{ isSunday: isSunday(colTitle) && columns[colIdx + 1] }"
                >
                  <span *ngIf="selectedDateView !== 'day'">{{ getColTitle(colTitle) }}</span>
                  <span *ngIf="selectedDateView === 'day'">
                    <span *ngIf="getColSubtitle(colTitle)">
                      {{ getColSubtitle(colTitle) }}
                    </span>
                    <span class="calendar__column-title--date">
                      {{ removeYear(getColTitle(colTitle) | troiDate : settingsService.settings.settings.dateFormat) }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let team of forecastValues; let teamIndex = index; trackBy: trackByIndex">
              <div class="calendar__row">
                <div class="calendar__person flex-wrap stretch-to-250px ps-0">
                  <div class="d-flex align-items-center w-100 cell">
                    <span
                      class="d-flex align-items-center cell"
                      (click)="team.showMembers = !team.showMembers"
                      [ngClass]="{ pointer: !team.isEmpty }"
                    >
                      <div class="ms-1">
                        <troi-icon
                          [icon]="team.showMembers ? 'icon-bold-arrow-up' : 'icon-bold-arrow-down'"
                          [ngStyle]="{ visibility: team.isEmpty ? 'hidden' : 'visible' }"
                        ></troi-icon>
                      </div>
                      <span class="calendar__person--name calendar__team">
                        <ng-container *ngIf="team.unitId === -1; else elseTemplate">
                          {{ 'Tasks.labels.allEmployees' | translate }}
                        </ng-container>
                        <ng-template #elseTemplate> {{ team.title }} </ng-template>
                      </span>
                    </span>
                    <span
                      class="ms-auto d-flex align-items-center cell"
                      *ngIf="isResourcesContext && !team.isEmpty"
                      style="margin-right: 20px"
                    >
                      <p class="mb-0">
                        <ng-container *ngIf="!selectedUtilization"> {{ team.totalFreeTime.toFixed(2) }}h </ng-container>
                        <ng-container *ngIf="selectedUtilization">
                          {{ team.totalUtilizationPercent?.toFixed(2) || 0 }}%
                        </ng-container>
                      </p>
                      <troi-icon
                        [ngbTooltip]="teamClockTooltip"
                        [container]="'body'"
                        class="calendar__person-icon"
                        [medium]="true"
                        icon="icon-clock-with-minus"
                      ></troi-icon>
                      <ng-template #teamClockTooltip>
                        <span class="clock__tooltip">
                          <span class="clock__tooltip--row">
                            <span>{{ 'Tasks.labels.createTaskModal.resources.availableTime' | translate }}</span>
                            <span>{{ team.totalFreeTime.toFixed(2) }}h</span>
                          </span>
                          <span class="clock__tooltip--row">
                            <span>{{ 'Timerecording.Utilization' | translate }}</span>
                            <span>{{ team.totalUtilizationPercent?.toFixed(2) || 0 }}%</span>
                          </span>
                        </span>
                      </ng-template>
                    </span>
                  </div>
                </div>

                <div class="calendar__column" *ngIf="!team.isEmpty; else emptyTemplate">
                  <div
                    class="utilizaion-field"
                    *ngFor="let teamTotalValue of team.totalValues; let teamTotalValueIndex = index"
                    [ngClass]="{
                      isSunday: isSunday(columns[teamTotalValueIndex]) && columns[teamTotalValueIndex + 1],
                      isEmpty: teamTotalValue.plannedWorkingTime <= 0
                    }"
                  >
                    <div
                      class="column-mark"
                      [ngStyle]="{
                        opacity:
                          teamTotalValue.plannedWorkingTime <= 0 || teamTotalValue.utilizationPercent === 0 ? 0.5 : 1
                      }"
                    >
                      <ng-container
                        *ngTemplateOutlet="
                          cellContent;
                          context: {
                            plannedWorkingTime: teamTotalValue.plannedWorkingTime,
                            percent: teamTotalValue.utilizationPercent,
                            hours: teamTotalValue.utilizationHours
                          }
                        "
                      ></ng-container>
                    </div>
                  </div>
                </div>
                <ng-template #emptyTemplate>
                  <div class="calendar__column calendar__column--empty">
                    <div class="utilizaion-field utilizaion-field--empty ps-3 pe-3">
                      {{ 'Tasks.labels.teamIsEmpty' | translate }}
                    </div>
                  </div>
                </ng-template>
              </div>

              <ng-container *ngIf="team.showMembers">
                <ng-container *ngFor="let member of team.values.members; let rowIdx = index; trackBy: trackByIndex">
                  <div class="calendar__row">
                    <div
                      class="calendar__person flex-wrap stretch-to-250px"
                      [ngClass]="{
                        assigned: member.alreadyAssigned
                      }"
                    >
                      <div
                        class="d-flex align-items-center w-100 cell"
                        (click)="
                          isResourcesContext
                            ? seeTaskDetails(rowIdx, team.title, member.employee.id, member, team.unitId)
                            : null
                        "
                        [attr.role]="isResourcesContext ? 'button' : null"
                      >
                        <troi-task-modal-employee-avatar
                          [employee]="member.employee"
                          [position]="'absolute'"
                          [addEmployee]="!isResourcesContext"
                          [utilization]="member.employee.utilization"
                          [alreadyAssigned]="member.alreadyAssigned"
                          [isAssignable]="member.isAssignable"
                          (selectEmployee)="onAssignEmployeeClick(member.employee)"
                          triggers="mouseenter:mouseleave"
                          [animationDuration]="0"
                          placement="right"
                          [ngbPopover]="assigneeNamePopover"
                          #assigneePopover="ngbPopover"
                        ></troi-task-modal-employee-avatar>
                        <ng-template #assigneeNamePopover>
                          <span>{{ member.employee.lastName }}, {{ member.employee.firstName }} </span>
                        </ng-template>
                        <span class="calendar__person--name">
                          {{ member.employee.lastName }}, {{ member.employee.firstName }}
                        </span>
                        <ng-container [ngSwitch]="isResourcesContext">
                          <p class="mb-0 ms-auto" *ngSwitchCase="true">
                            <ng-container *ngIf="!selectedUtilization">
                              {{ member.freeTime.toFixed(2) }}h
                            </ng-container>
                            <ng-container *ngIf="selectedUtilization">
                              {{ member.totalUtilizationPercent?.toFixed(2) ?? 0 }}%
                            </ng-container>
                          </p>
                          <span class="ms-auto" *ngSwitchCase="false">
                            <ng-container *ngIf="!selectedUtilization">
                              {{ member.freeTime.toFixed(2) }}h
                            </ng-container>
                            <ng-container *ngIf="selectedUtilization">
                              {{ member.totalUtilizationPercent?.toFixed(2) ?? 0 }}%
                            </ng-container>
                          </span>
                        </ng-container>
                        <troi-icon
                          [ngbTooltip]="teamClockTooltip"
                          [container]="'body'"
                          class="calendar__person-icon"
                          [medium]="true"
                          icon="icon-clock-with-minus"
                        ></troi-icon>
                        <ng-template #teamClockTooltip>
                          <span class="clock__tooltip">
                            <span class="clock__tooltip--row">
                              <span>{{ 'Tasks.labels.createTaskModal.resources.availableTime' | translate }}</span>
                              <span>{{ member.freeTime.toFixed(2) }}h</span>
                            </span>
                            <span class="clock__tooltip--row">
                              <span>{{ 'Timerecording.Utilization' | translate }}</span>
                              <span>{{ member.totalUtilizationPercent?.toFixed(2) ?? 0 }}%</span>
                            </span>
                          </span>
                        </ng-template>
                        <div class="me-2" *ngIf="isResourcesContext">
                          <troi-icon
                            [icon]="
                              visibleRows[team.title]?.has(rowIdx) ? 'icon-bold-arrow-up' : 'icon-bold-arrow-down'
                            "
                          ></troi-icon>
                        </div>
                      </div>
                    </div>

                    <div class="calendar__column" [ngClass]="{ assigned: member.alreadyAssigned }">
                      <div
                        class="utilizaion-field"
                        *ngFor="let utilVal of team.values.values[rowIdx]; let cellIdx = index"
                        [ngClass]="{
                          isSunday: isSunday(columns[cellIdx]) && columns[cellIdx + 1],
                          isEmpty: utilVal.plannedWorkingTime <= 0
                        }"
                      >
                        <div
                          [ngClass]="
                            getConcatenatedTaskBar(
                              utilVal.utilizationPercent,
                              team,
                              rowIdx,
                              cellIdx,
                              utilVal.plannedWorkingTime
                            )
                          "
                          class="column-mark"
                          [ngStyle]="{
                            opacity: utilVal.plannedWorkingTime <= 0 || utilVal.utilizationPercent === 0 ? 0.5 : 1
                          }"
                        >
                          <ng-container
                            *ngTemplateOutlet="
                              cellContent;
                              context: {
                                plannedWorkingTime: utilVal.plannedWorkingTime,
                                percent: utilVal.utilizationPercent,
                                hours: utilVal.utilizationHours
                              }
                            "
                          ></ng-container>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ng-container *ngIf="visibleRows[team.title]?.has(rowIdx)">
                    <div class="calendar__row">
                      <div
                        class="calendar__row--bar"
                        [ngStyle]="{ height: 'calc(' + (100 + (member.projects?.length ?? 0) * 100) + '% - 24px)' }"
                      ></div>
                      <div class="calendar__person flex-wrap stretch-to-250px ps-0">
                        <div class="d-flex align-items-center w-100 cell">
                          <div class="task-details">
                            {{ 'Tasks.labels.createTaskModal.resources.leaveTime' | translate }}
                          </div>
                        </div>
                      </div>

                      <div class="calendar__column" [ngClass]="{ assigned: member.alreadyAssigned }">
                        <div
                          class="utilizaion-field"
                          *ngFor="let utilVal of team.values.values[rowIdx]; let cellIdx = index"
                          [ngClass]="{
                            isSunday: isSunday(columns[cellIdx]) && columns[cellIdx + 1],
                            isEmpty: team.values.values[rowIdx][cellIdx].plannedWorkingTime <= 0
                          }"
                        >
                          <div class="task-details">
                            <div
                              class="bar-pill"
                              [ngClass]="getLeaveTimeBarClasses(team, rowIdx, cellIdx)"
                              [ngStyle]="{
                                opacity:
                                  team.values.values[rowIdx][cellIdx].plannedWorkingTime <= 0 ||
                                  team.values.values[rowIdx][cellIdx].leaveTimePercent === 0
                                    ? 0.5
                                    : 1
                              }"
                            >
                              <ng-container
                                *ngTemplateOutlet="
                                  cellContent;
                                  context: {
                                    plannedWorkingTime: team.values.values[rowIdx][cellIdx].plannedWorkingTime,
                                    percent: team.values.values[rowIdx][cellIdx].leaveTimePercent,
                                    hours: team.values.values[rowIdx][cellIdx].leaveTimeHours
                                  }
                                "
                              ></ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ng-container *ngFor="let task of member.projects">
                      <div class="calendar__row">
                        <div class="calendar__person flex-wrap stretch-to-250px ps-0">
                          <div class="d-flex align-items-center w-100 cell">
                            <div
                              [ngbTooltip]="task.projectTaskName"
                              [container]="'body'"
                              class="task-details"
                              (click)="openEditTaskModal(task)"
                              role="button"
                            >
                              {{ task.projectTaskName }}
                            </div>
                          </div>
                        </div>

                        <div class="calendar__column" [ngClass]="{ assigned: member.alreadyAssigned }">
                          <div
                            class="utilizaion-field"
                            *ngFor="let utilVal of team.values.values[rowIdx]; let cellIdx = index"
                            [ngClass]="{
                              isSunday: isSunday(columns[cellIdx]) && columns[cellIdx + 1],
                              isEmpty: task.values[cellIdx].plannedWorkingTime <= 0
                            }"
                          >
                            <div class="task-details">
                              <div
                                class="bar-pill"
                                [ngClass]="getTaskBarClasses(task, cellIdx)"
                                [ngStyle]="{
                                  opacity:
                                    task.values[cellIdx].plannedWorkingTime <= 0 ||
                                    task.values[cellIdx].utilizationPercent === 0
                                      ? 0.5
                                      : 1
                                }"
                              >
                                <ng-container
                                  *ngTemplateOutlet="
                                    cellContent;
                                    context: {
                                      plannedWorkingTime: task.values[cellIdx].plannedWorkingTime,
                                      percent: task.values[cellIdx].utilizationPercent,
                                      hours: task.values[cellIdx].utilizationHours
                                    }
                                  "
                                ></ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #cellContent let-plannedWorkingTime="plannedWorkingTime" let-percent="percent" let-hours="hours">
  {{ plannedWorkingTime <= 0 ? '―' : selectedUtilization ? roundPercentage(percent) : hours }}
  <span *ngIf="selectedUtilization && plannedWorkingTime > 0">%</span>
  <span *ngIf="!selectedUtilization && plannedWorkingTime > 0">h</span>
</ng-template>
