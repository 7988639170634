import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TasksHelperService } from './services/helper.service';
import { TasksComponent } from './tasks.component';
import { TasksSettingsService } from './services/tasks-settings.service';
import { TasksService } from './network/tasks.service';
import { SubtaskService } from './network/subtask.service';
import { ExportService } from './network/export.service';
import { SharedModule } from '../../shared/shared.module';
import { SharedTaskModule } from './shared.module';
import { HeaderNotificationModule } from '../header-notification/headerNotification.module';
import { FiltersFormService } from './services/filters-form.service';
import { CalcPositionService } from './network/calc-pos.service';
import { ReminderService } from './network/reminder.service';
import { TopBarService } from './services/top-bar.service';
import { TaskFiltersService } from './services/filters.service';
import { ColumnsService } from './modules/listview/services/columns.service';
import { TaskFormService } from './components/modals/create-task-modal/services/task-form.service';
import { CreateTaskModalComponent } from './components/modals/create-task-modal/create-task-modal.component';
/* import { ImportCalcPosModalComponent } from './components/modals/import-calc-pos-modal/import-calc-pos-modal.component'; */
import { PhrasesService } from './components/modals/create-task-modal/network/phrases.service';
import { TagService } from './components/modals/create-task-modal/network/tag.service';
import { GeneralContentComponent } from './components/modals/create-task-modal/modules/general-content/general-content.component';
import { ResourcePlanningComponent } from './components/modals/create-task-modal/components/resource-planning/resource-planning.component';
import { CalcPosContentComponent } from './components/modals/create-task-modal/modules/calc-pos-content/calc-pos-content.component';
import { CalculationPositionComponent } from './components/modals/create-task-modal/modules/calc-pos-content/components/calc-pos.component';

import { TimeentriesContentComponent } from './components/modals/create-task-modal/modules/timeentries/timeentries-content.component';
import { TimeentryComponent } from './components/modals/create-task-modal/modules/timeentries/components/timeentry.component';
import { EditSubtaskComponent } from './components/modals/create-task-modal/modules/subtasks-content/components/edit-subtask/edit-subtask.component';
import { SubtaskComponent } from './components/modals/create-task-modal/modules/subtasks-content/components/subtask/subtask.component';
import { ViewSwitchComponent } from './components/view-switch/view-switch.component';
import { TimeentryService } from './components/modals/create-task-modal/network/timeentry.service';
import { SubtasksContentComponent } from './components/modals/create-task-modal/modules/subtasks-content/subtasks-content.component';
import { CommentsContentComponent } from './components/modals/create-task-modal/modules/comments-content/comments-content.component';
import { CommentComponent } from './components/modals/create-task-modal/modules/comments-content/comment/comment.component';
import { TagManagerComponent } from './components/modals/create-task-modal/modules/general-content/components/tag-manager/tag-manager.component';
import { ColorPaletteComponent } from './components/modals/create-task-modal/modules/general-content/components/color-palette/color-palette.component';
import { TagChipComponent } from './components/modals/create-task-modal/modules/general-content/components/tag-chip/tag-chip.component';
import { AttachmentsComponent } from './components/modals/create-task-modal/components/attachments/attachments.component';
import { AttachmentComponent } from './components/modals/create-task-modal/components/attachments/components/attachment/attachment.component';
import { AttachmentService } from './components/modals/create-task-modal/network/attachment.service';
import { RecordProjectTimeService } from '../../modules/desk/widgets/popover/record-project-time/record-project-time.service';
import { AssignmentService } from './components/modals/create-task-modal/network/assignment.service';
import { CommentsService } from './network/comments.service';
import { ResourcesService } from './network/resources.service';
import { ProjectService } from './network/project.service';
import { NotAllowedAssigneesModalComponent } from './components/modals/not-allowed-assignees-modal/not-allowed-assignees-modal.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { QuillModule } from 'ngx-quill';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StopwatchService } from '../desk/widgets/stopwatch/stopwatch.service';

const appRoutes: Routes = [
  {
    path: '',
    component: TasksComponent,
  },
];

@NgModule({
  declarations: [
    TasksComponent,
    CreateTaskModalComponent,
    GeneralContentComponent,
    TimeentriesContentComponent,
    SubtasksContentComponent,
    CommentsContentComponent,
    ResourcePlanningComponent,
    /*     ImportCalcPosModalComponent, */
    CalcPosContentComponent,
    CalculationPositionComponent,
    TagManagerComponent,
    ColorPaletteComponent,
    TagChipComponent,
    AttachmentsComponent,
    AttachmentComponent,
    EditSubtaskComponent,
    SubtaskComponent,
    TimeentryComponent,
    CommentComponent,
    ViewSwitchComponent,
    NotAllowedAssigneesModalComponent,
  ],
  imports: [
    RouterModule.forChild(appRoutes),
    CommonModule,
    HeaderNotificationModule,
    SharedModule,
    SharedTaskModule,
    NgxDropzoneModule,
    NgCircleProgressModule.forRoot(),
    QuillModule.forRoot(),
    NgbModule,
  ],
  providers: [
    TaskFiltersService,
    TasksSettingsService,
    TasksService,
    ColumnsService,
    ExportService,
    FiltersFormService,
    TopBarService,
    TaskFormService,
    PhrasesService,
    TagService,
    TasksHelperService,
    CalcPositionService,
    ReminderService,
    AttachmentService,
    RecordProjectTimeService,
    AssignmentService,
    SubtaskService,
    TimeentryService,
    CommentsService,
    ResourcesService,
    ProjectService,
    StopwatchService,
  ],
})
export class TasksModule {
  constructor(public settingsService: TasksSettingsService) {}
}
