import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FooterNetworkService } from '../network/footer.network.service';
import { TimetrackerInterface } from '../interfaces/timetracker.interface';
import { FooterSettingsService } from '../../../core/services/footer-settings.service';

@Injectable()
export class FooterService {
  public constructor(
    private footerNetworkService: FooterNetworkService,
    public settingsService: FooterSettingsService,
  ) {}

  public timetracker: TimetrackerInterface;

  public getTimetrackerSetting(): Observable<TimetrackerInterface> {
    return this.footerNetworkService.getTimetrackerSetting().pipe(
      map((result) => {
        this.timetracker = result;
        return result;
      }),
    );
  }
}
