import { Injectable } from '@angular/core';
import { StrategyInterface } from './strategy.interface';
import { CLickStrategy } from './click.strategy';
import { DblCLickStrategy } from './dblclick.strategy';
import { FocusStrategy } from './focus.strategy';

@Injectable()
export class VirtualEventStrategiesFactory {
  getStrategies = (): Array<StrategyInterface> => {
    return [new FocusStrategy(), new CLickStrategy(), new DblCLickStrategy()];
  };
}
