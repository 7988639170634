<div
  style="cursor: pointer"
  [popoverClass]="popoverClass"
  [autoClose]="autoClose"
  [placement]="placement"
  [ngbPopover]="troiPopover"
  [ngStyle]="{ color: subtitleColor }"
>
  <ng-content select="[popoverTrigger]"></ng-content>
</div>

<ng-template #troiPopover>
  <div [ngStyle]="{ width: width, 'max-width': maxWidth }">
    <ng-content *ngIf="!content" select="[popoverContent]"></ng-content>
  </div>

  <div
    [ngStyle]="{ width: width, 'max-width': maxWidth }"
    [innerHTML]="content"
    *ngIf="content"
  ></div>
</ng-template>
