import { Injectable } from '@angular/core';
import { AccessRightService } from '../../../../core/services/access-right.service';
import { BookingSettingsService } from './booking-settings.service';

@Injectable()
export class BookingAccessService {
  constructor(private accessRightService: AccessRightService, private settingsService: BookingSettingsService) {}

  canDeleteBooking(): boolean {
    return this.accessRightService.isWriteAccess(this.settingsService.settings?.permissions?.createBooking);
  }

  canCreateBooking(): boolean {
    return this.accessRightService.isWriteAccess(this.settingsService.settings?.permissions?.createBooking);
  }

  canEditBooking(): boolean {
    return (
      this.accessRightService.isWriteAccess(this.settingsService.settings?.permissions?.createBooking) ||
      this.accessRightService.isWriteAccess(this.settingsService.settings?.permissions?.transferBooking)
    );
  }

  canFinalizeBooking(): boolean {
    return this.accessRightService.isWriteAccess(this.settingsService.settings?.permissions?.transferBooking);
  }
}
