import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ProjectModel } from '../../../models/project.model';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subscriber } from 'rxjs';
import {
  BackendResponseInterface,
  BackendResponseType,
  ResponseStatusInterface,
} from '../../../../../../../core/interfaces/backend.response.interface';
import { environment } from '../../../../../../../../environments/environment';
import { Routes } from '../../../../../enum/routes';
import {
  TreeListItemResponseInterface,
  TreeListItemWithParentsResponseInterface,
} from '../../../../../../../shared/troi-tree-list/interfaces/tree-list-item-response.interface';
import { ProjectTypeEnum } from '../../../enums/project-type.enum';
import { ProjectPropertiesInterface } from '../../../interfaces/project-properties.interface';
import { FiltersInterface } from '../../../../../../../shared/troi-data-listing-filters/filters.interface';
import { ModuleInterceptor } from '../../../../../../../core/enums/module-interceptor';
import { ProjectNumberSettingsInterface } from '../../../../../interfaces/settings.interface';
import { FolderModel } from '../../folder/models/folder.model';
import { delay } from 'rxjs/operators';

@Injectable()
export class ItemNetworkService {
  private readonly headers: HttpHeaders;

  constructor(private httpClient: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.PROJECT_LIST, '1');
  }

  public getProjectNumberSettings(
    client: number,
    customer: string,
    reportingDate: number,
    folder: number,
  ): Observable<BackendResponseInterface<ProjectNumberSettingsInterface>> {
    const params = new HttpParams({
      fromObject: {
        client: String(client),
        customer,
        reportingDate: String(reportingDate),
        folder: String(folder),
      },
    });

    return this.httpClient.get<BackendResponseInterface<ProjectNumberSettingsInterface>>(
      `${environment.url}${Routes.ITEM_PROJECT_NUMBER_SETTINGS}`,
      { params, headers: this.headers },
    );
  }

  public create(
    item: ProjectModel,
    formParams: { [key: string]: any },
    filters: FiltersInterface,
  ): Observable<
    BackendResponseInterface<{
      item: TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>;
      parents: any;
    }>
  > {
    const params = {
      ...formParams,
      client: filters.dropdownFirst,
      name: item.name,
    };

    return this.httpClient.post<
      BackendResponseInterface<{
        item: TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>;
        parents: any;
      }>
    >(`${environment.url}${Routes.ITEM_CREATE}`, params, { headers: this.headers });
  }

  public copy(
    item: ProjectModel,
    formParams: { [key: string]: any },
    filters: FiltersInterface,
    copyReference: string | number,
  ): Observable<
    BackendResponseInterface<{
      item: TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>;
      parents: any;
    }>
  > {
    const params = {
      ...formParams,
      client: filters.dropdownFirst,
      name: item.name,
      project: copyReference,
    };

    return this.httpClient.post<
      BackendResponseInterface<{
        item: TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>;
        parents: any;
      }>
    >(`${environment.url}${Routes.ITEM_COPY}`, params, { headers: this.headers });
  }

  public inlineSave(
    item: ProjectModel,
    formValues: UntypedFormGroup,
    filters: FiltersInterface,
  ): Observable<
    BackendResponseInterface<TreeListItemWithParentsResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>>
  > {
    const params = {
      client: filters.dropdownFirst,
      customer: item.customer,
      parentFolder: item.parent ? item.parent.id : '',
      inFavorites: item.properties.inFavorites,
      projectStatus: item.properties.projectStatus,
      projectStartEndDate: item.properties.projectStartEndDate,
    };

    return this.httpClient.put<
      BackendResponseInterface<TreeListItemWithParentsResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>>
    >(`${environment.url}${Routes.ITEM_INLINE_SAVE}/${item.id}`, params, { headers: this.headers });
  }

  public delete(project: ProjectModel, force: boolean): Observable<ResponseStatusInterface> {
    return this.httpClient.delete<ResponseStatusInterface>(
      `${environment.url}${Routes.ITEM_CREATE}/${project.id}` + '?force=' + force,
      {
        headers: this.headers,
      },
    );
  }

  // TODO: TROR-19639 remove filters params when are not needed
  public createProjectPlan(
    item: ProjectModel,
    formValues: UntypedFormGroup,
    filters: FiltersInterface,
  ): Observable<BackendResponseInterface<TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>>> {
    const params = {
      client: filters.dropdownFirst,
      customer: item.customer,
      ...formValues.value,
    };

    return this.httpClient.post<
      BackendResponseInterface<TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>>
    >(`${environment.url}${Routes.ITEM_NEW_PROJECT_PLAN}/${item.id}`, params, {
      headers: this.headers,
    });
  }
}
