<div class="troi-slider">
  <span>{{ description | translate }}</span>
  <ngx-slider
    class="troi-slider__slider"
    [(value)]="value"
    [(highValue)]="highValue"
    [options]="options"
    (valueChange)="onSliderValuesChange()"
    (highValueChange)="onSliderValuesChange()"
  ></ngx-slider>
</div>
