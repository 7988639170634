<div
  class="troi-dropdown-option"
  [ngClass]="{
    'troi-dropdown-option--selected': selected && (parent || !group),
    'troi-dropdown-option--disabled': disabled,
    'troi-dropdown-option--group': group,
    'troi-dropdown-option--parent': parent,
    'troi-dropdown-option--child': child,
    'troi-dropdown-option--required': isRequired,
    'troi-dropdown-option--padding': group && showCheckbox,
    'troi-dropdown-option--hasOverride': hasOverride,
    'troi-dropdown-option--orange-background': colorizeBackground,
    'troi-dropdown-option--colorize-border': colorizeBorder
  }"
  *ngIf="active"
>
  <troi-icon *ngIf="icon" class="troi-dropdown-option__icon" [icon]="icon"></troi-icon>

  <troi-checkbox
    [asLabel]="false"
    [value]="selected"
    [topStyle]="'0'"
    style="height: 0"
    *ngIf="showCheckbox"
    [disabled]="false"
    [noPropagation]="false"
    (click)="$event.preventDefault()"
    (checkboxChange)="checkboxChange($event)"
  ></troi-checkbox>

  <ng-container *ngIf="showRadio">
    <troi-icon
      *ngIf="selected; else nonSelectedRadioOption"
      [medium]="true"
      icon="icon-radio-button-checked"
    ></troi-icon>
    <ng-template #nonSelectedRadioOption>
      <troi-icon [medium]="true" icon="icon-radio-button-unchecked"></troi-icon>
    </ng-template>
  </ng-container>

  <span style="width: 20px" *ngIf="leftPadding"></span>

  <span class="troi-dropdown-option__label" title="{{ label | translate }}" [ngClass]="{ 'ms-2': showRadio }">
    {{ label | translate }}
    <span *ngIf="isRequired">*</span>
    <span *ngIf="labelInfo" class="label__info">{{ labelInfo | translate }}</span>
  </span>

  <div *ngIf="actions && actions.length > 0" (click)="$event.preventDefault()" class="troi-dropdown-option__actions">
    <div
      *ngFor="let actionId of actionsIds; let i = index"
      placement="top"
      [ngbTooltip]="getActionTooltip(actionId) ? actionTooltip : null"
      tooltipClass="actions__tooltip actions__tooltip--icon"
      class="actions__icon"
      (click)="actionSelected(actionId)"
    >
      <troi-icon [icon]="getActionIcon(actionId)"></troi-icon>
      <ng-template #actionTooltip>{{ getActionTooltip(actionId) | translate }}</ng-template>
    </div>
  </div>
  <span class="troi-dropdown-option__count" *ngIf="count !== undefined"> ({{ count }}) </span>
  <troi-loading class="troi-dropdown-option__loading" *ngIf="loading"></troi-loading>
</div>
