import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from '../modules/login/security/authentication.service';
import { HttpHeaderInterceptorProvider } from './interceptiors/http.interceptor';
import { HttpErrorInterceptorProvider } from './interceptiors/httpError.interceptor';
import { UserService } from './user/user.service';
import { UserNetwork } from './user/user.network';
import { ErrorTranslationService } from './language/error.translation.service';
import { StorageNotificationService } from './notifications/storageNotification.service';
import { HttpUrlInterceptorProvider } from './interceptiors/httpUrl.interceptor';
import { StorageService } from './storage/storage.service';
import { CodesTranslationService } from './codes/codes.translation.service';
import { BackendErrorTranslationService } from './services/backendError.translation.service';
import { ColorSwitcherService } from './colorSwitcher/colorSwitcher.service';
import { LanguagesService } from './services/languages.service';
import { BasicFiltersService } from './services/basic-filters.service';
import { BasicFiltersNetwork } from './network/basic-filters.network';
import { FooterSettingsService } from './services/footer-settings.service';
import { WindowService } from './services/window.service';
import { WebAuthNService } from './services/webauthn.service';
import { SettingsFactory } from './factories/settings/settings.factory';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  bootstrap: [],
  providers: [
    AuthenticationService,
    ErrorTranslationService,
    StorageService,
    HttpUrlInterceptorProvider,
    HttpHeaderInterceptorProvider,
    HttpErrorInterceptorProvider,
    UserService,
    UserNetwork,
    StorageNotificationService,
    CodesTranslationService,
    BackendErrorTranslationService,
    ColorSwitcherService,
    LanguagesService,
    BasicFiltersService,
    BasicFiltersNetwork,
    FooterSettingsService,
    WindowService,
    SettingsFactory,
    WebAuthNService,
  ],
  exports: [],
})
export class CoreModule {}
