<div class="tnd-search-select" [ngClass]="{ 'has-icon': icon.length > 0 }">
  <troi-icon
    [icon]="icon"
    *ngIf="icon.length > 0"
    class="tnd-search-select__icon-type"
    [ngStyle]="{ 'top.px': topStyle }"
  ></troi-icon>
  <input
    type="text"
    [(ngModel)]="searchString"
    (ngModelChange)="onSearchStringChange()"
    (click)="toggle()"
    (blur)="onBlur()"
    (keyup.backspace)="reset()"
    (keyup.delete)="reset()"
    class="tnd-search-select__input"
    placeholder="{{ placeholder }}{{ required ? '*' : '' }}"
    [style.font-family]="fontFamily"
    [style.font-size.px]="fontSize"
  />
  <troi-icon
    *ngIf="!open"
    icon="icon-bold-arrow-down"
    class="tnd-search-select__icon-state me-0"
  ></troi-icon>
  <troi-icon
    *ngIf="open"
    icon="icon-bold-arrow-up"
    class="tnd-search-select__icon-state me-0"
  ></troi-icon>
  <ul class="tnd-search-select__result-list tnd-scrollbar" *ngIf="open">
    <div *ngIf="open">
      <li
        (click)="setValue(option)"
        *ngFor="let option of filteredOptions"
        class="tnd-search-select__list-item"
        [ngClass]="{ 'tnd-search-select__list-item--disabled': option.disabled }"
      >
        <span>{{ option.label }}</span>
      </li>
    </div>
  </ul>
</div>
