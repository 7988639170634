import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { DateFocusOutInterface } from './date.directive';
import { TroiRangeDateSettingsInterface } from './interfaces/troi-range-date-settings.interface';

export interface RangeDateChangedInterface {
  isValid: boolean;
  date: number[];
}

@Component({
  selector: 'troi-range-date',
  templateUrl: './troi-range-date.component.html',
  styleUrls: ['./troi-range-date.component.scss'],
})
export class TroiRangeDateComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('datePickerInput') datePickerInput;

  @Input() public defaultAlignment = true;

  @Input() public assignEmployeeAlignment = false;

  @Input() singleDatePicker = false;

  @Input() disabled = false;

  @Input() public rangeFrom: string | Date;

  @Input() public rangeTo: string | Date;

  @Input() public year: string;

  @Input() public limitDate = false;

  @Input() public isStartOfEndDateDay = false;

  @Input() public fieldInvalid = false;

  @Input() public validationEnabled = false;

  @Input() public emitIfEmpty = true;

  @Input() public requiredFieldErrorMessage = 'Form.Error.Required';

  @Input() public hideRanges = false;

  @Input() public placeholder = '';

  @Input() public readOnly = false;

  @Input() public rangesInPast = true;

  @Input()
  public set showDropdowns(value: boolean | string) {
    this._showDropdowns = coerceBooleanProperty(value);
  }
  public get showDropdowns(): boolean {
    return this._showDropdowns;
  }
  private _showDropdowns = false;

  private _minDate: Moment | Date;
  private _maxDate: Moment | Date;

  @Input()
  set minDate(date: Moment | Date | string) {
    if (typeof date === 'string' || date instanceof Date) {
      this._minDate = moment(date);
    } else {
      this._minDate = date;
    }
  }

  get minDate(): string {
    return this._minDate?.toISOString() || '';
  }

  @Input()
  set maxDate(date: Moment | Date | string) {
    if (typeof date === 'string' || date instanceof Date) {
      this._maxDate = moment(date);
    } else {
      this._maxDate = date;
    }
  }

  get maxDate(): string {
    return this._maxDate?.toISOString() || '';
  }

  @Output() public rangeChanged = new EventEmitter<RangeDateChangedInterface>();

  @Input() selected: { startDate: Moment; endDate: Moment } = null;

  ranges: any;

  private _settings: TroiRangeDateSettingsInterface = {
    format: 'DD.MM.YYYY',
    applyLabel: 'Apply',
    clearLabel: 'Clear',
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 1,
  };

  @Input() set settings(settings: TroiRangeDateSettingsInterface) {
    const dateFormat = settings.format || this._settings.format;
    this._settings = {
      ...this._settings,
      ...settings,
      format: dateFormat.toUpperCase(),
    };
  }

  get settings(): TroiRangeDateSettingsInterface {
    return this._settings;
  }

  validDate = true;

  @ViewChild(DaterangepickerDirective) picker: DaterangepickerDirective;

  constructor(private translationService: TranslateService, private renderer: Renderer2) {}

  ngOnInit() {
    this.translationService.get('Booking').subscribe((result) => {
      this.settings.daysOfWeek = _.values(result.daysShort);
      this.settings.monthNames = _.values(result.months);
    });
    this.translationService.get('Common.labels').subscribe((result) => {
      this.prepareRanges(result);
      this.settings.applyLabel = result.apply;
      this.settings.clearLabel = result.clear;
      this.settings.customRangeLabel = result.customRange;
    });

    if (_.isNull(this.rangeFrom) && _.isNull(this.rangeTo)) {
      this.selected = null;
    } else {
      this.selected = {
        startDate: this.prepareDateValue(this.rangeFrom),
        endDate: this.prepareDateValue(this.rangeTo),
      };
    }
    if (this.limitDate) {
      this.setMinMaxDate();
    }
  }

  ngAfterViewInit(): void {
    this.prepareDropdowns();
  }

  private prepareDropdowns(): void {
    if (!this.picker || !this.showDropdowns) {
      return;
    }

    const pickerElement: HTMLElement = this.picker.picker['el'].nativeElement;
    ['left', 'right'].forEach((side) => {
      const currentYear = this.picker.picker.calendarVariables[side].dropdowns.currentYear;
      this.hideYearOptionsOutsideRange(pickerElement.querySelectorAll(`.calendar.${side} thead .dropdowns`)[1], [
        currentYear - 5,
        currentYear + 5,
      ]);
    });
  }

  private hideYearOptionsOutsideRange(dropdown: Element, range: [number, number]): void {
    const options = dropdown.querySelectorAll('select option');
    options.forEach((option) => {
      if (option.textContent === '') {
        return;
      }

      const year = parseInt(option.textContent, 10);
      if (year < range[0] || year > range[1]) {
        this.renderer.setStyle(option, 'display', 'none');
      }
    });
  }

  setMinMaxDate() {
    if (!this.year) {
      this.maxDate = moment().endOf('year');
      this.minDate = moment().startOf('year');
    } else {
      const lastYearDay = `${this.year}-12-31`;
      const firstYearDay = `${this.year}-01-01`;
      this.maxDate = moment(lastYearDay);
      this.minDate = moment(firstYearDay);
    }
  }

  prepareRanges(translationData) {
    if (this.rangesInPast)
      this.ranges = {
        [translationData.today]: [moment(), moment()],
        [translationData.yesterday]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        [translationData.last7days]: [moment().subtract(6, 'days'), moment()],
        [translationData.last30days]: [moment().subtract(29, 'days'), moment()],
        [translationData.thisMonth]: [moment().startOf('month'), moment().endOf('month')],
        [translationData.lastMonth]: [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
      };
    else {
      this.ranges = {
        [translationData.today]: [moment(), moment()],
        [translationData.thisMonth]: [moment().startOf('month'), moment().endOf('month')],
        [translationData.next7days]: [moment(), moment().add(7, 'days')],
        [translationData.next30days]: [moment(), moment().add(30, 'days')],
        [translationData.nextMonth]: [
          moment().add(1, 'month').startOf('month'),
          moment().add(1, 'month').endOf('month'),
        ],
      };
    }
  }

  dateChanged(event, valid = true) {
    this.rangeChanged.emit({
      isValid: valid,
      date: [
        _.isNull(event.startDate) ? null : event.startDate.startOf('day').unix() * 1000,
        _.isNull(event.endDate)
          ? null
          : (this.isStartOfEndDateDay ? event.endDate.startOf('day') : event.endDate.endOf('day')).unix() * 1000,
      ],
    });
    this.validDate = true;
  }

  prepareDateValue = (value): Moment => {
    if (_.isNull(value)) {
      return value;
    }

    return moment(value).isValid() ? moment(value) : moment(parseInt(value));
  };

  onDateChange(data: DateFocusOutInterface) {
    if (data.date === '') {
      if (this.emitIfEmpty && (this.selected.startDate || this.selected.endDate)) {
        this.rangeChanged.emit({
          isValid: false,
          date: [null, null],
        });
        this.selected.startDate = null;
        this.selected.endDate = null;
      }
      return;
    }

    // check if data is different from selected
    const [controlStart, controlEnd] = data.date.split(' - ');
    const selectedStart = this.selected.startDate ? this.selected.startDate.format(this.settings.format) : null;
    const selectedEnd = this.selected.endDate ? this.selected.endDate.format(this.settings.format) : null;
    if (controlStart === selectedStart && controlEnd === selectedEnd) return;

    if (this.singleDatePicker && data.isValid) {
      this.selected = {
        startDate: moment(data.date, this.settings.format),
        endDate: null,
      };
    }
    this.dateChanged(this.selected, data.isValid);
    this.validDate = data.isValid;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rangeFrom && changes.rangeFrom.currentValue) {
      this.selected = {
        startDate: this.prepareDateValue(changes.rangeFrom.currentValue),
        endDate: changes.rangeTo
          ? this.prepareDateValue(changes.rangeTo.currentValue)
          : this.selected
          ? this.selected.endDate
          : null,
      };
    }

    if (!changes.rangeFrom?.firstChange && changes.rangeFrom?.currentValue === null) {
      this.datePickerInput.nativeElement.value = '';
    }
  }
}
