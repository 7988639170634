<div class="troi-table">
  <div class="troi-table__header">
    <troi-table-row *ngFor="let row of tableData.header" [row]="row"></troi-table-row>
  </div>
  <div class="troi-table__content">
    <troi-table-row *ngFor="let row of tableData.content" [row]="row"> </troi-table-row>
  </div>
  <div class="troi-table__footer">
    <troi-table-row *ngFor="let row of tableData.footer" [row]="row"></troi-table-row>
  </div>
</div>
