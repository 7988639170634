import { Component, Input } from '@angular/core';

@Component({
  selector: 'troi-progress-bar',
  templateUrl: './troi-progress-bar.component.html',
  styleUrls: ['./troi-progress-bar.component.scss'],
})
export class TroiProgressBarComponent {
  @Input() public progressValue = 0;
  @Input() public color: string;
  @Input() public showProgressValue = false;

  @Input() public height = '';
  @Input() public borderRadius = '0';

  convertProgressValue() {
    return this.progressValue.toFixed();
  }
}
