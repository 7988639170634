import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModuleInterceptor } from '../../../core/enums/module-interceptor';
import { Routes } from '../enum/routes';
import { SubprojectDataResponseInerface } from '../interfaces/responses.interface';
import { Observable } from 'rxjs';

@Injectable()
export class ProjectService {
  private headers: HttpHeaders;

  public constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');
  }

  public getProjectInfo(projectId: string): Observable<any> {
    return this.http.get<any>(Routes.PROJECT_INFO + `?projectId=${projectId}`, {
      headers: this.headers,
    });
  }

  public getSubprojects(projectId: string): Observable<SubprojectDataResponseInerface> {
    return this.http.get<SubprojectDataResponseInerface>(Routes.TASKS_SUBPROJECTS + `?project=${projectId}`, {
      headers: this.headers,
    });
  }

  public getSubprojectsWithCP(projectId: string): Observable<any> {
    return this.http.get<any>(Routes.TASKS_SUBPROJECTS_WITH_CP + `?project=${projectId}`, {
      headers: this.headers,
    });
  }
}
