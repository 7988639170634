import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { ModuleInterceptor } from '../../../../../core/enums/module-interceptor';
import {
  BackendResponseInterface,
  ResponseStatusInterface,
} from '../../../../../core/interfaces/backend.response.interface';
import { Routes } from '../enums/routes';
import { PaymentMethodInterface } from '../interfaces/payment-method.interface';

@Injectable({ providedIn: 'root' })
export class PaymentMethodNetwork {
  public headers;

  public constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.ACCOUNTING, '1');
  }

  getList(client: number, isDropdown = false): Observable<PaymentMethodInterface[]> {
    const buildParams: any = {};
    if (client > -1) {
      buildParams.client = client;
    }

    if (isDropdown) {
      buildParams.isDropdown = 1;
    }

    return this.http.get<PaymentMethodInterface[]>(environment.url + Routes.PAYMENT_METHOD, {
      params: buildParams,
      headers: this.headers,
    });
  }

  getPaymentMethod(paymentMethodId: number): Observable<PaymentMethodInterface> {
    return this.http.get<PaymentMethodInterface>(`${environment.url + Routes.PAYMENT_METHOD}/${paymentMethodId}`, {
      headers: this.headers,
    });
  }

  create(formGroup: UntypedFormGroup, client: number): Observable<BackendResponseInterface<PaymentMethodInterface>> {
    const buildParams: any = {};
    if (client > -1) {
      buildParams.client = client;
    }
    return this.http.post<BackendResponseInterface<PaymentMethodInterface>>(
      environment.url + Routes.PAYMENT_METHOD,
      {
        name: formGroup.value.name,
        mappingValue: formGroup.value.mappingValue,
        active: formGroup.value.active,
        ...buildParams,
      },
      {
        headers: this.headers,
      },
    );
  }

  edit(formGroup: UntypedFormGroup, client: number): Observable<BackendResponseInterface<PaymentMethodInterface>> {
    const buildParams: any = {};
    if (client > -1) {
      buildParams.client = client;
    }
    return this.http.put<BackendResponseInterface<PaymentMethodInterface>>(
      `${environment.url + Routes.PAYMENT_METHOD}/${formGroup.value.id}`,
      {
        name: formGroup.value.name,
        mappingValue: formGroup.value.mappingValue,
        active: formGroup.value.active,
      },
      {
        headers: this.headers,
      },
    );
  }

  delete(paymentMethodId: number): Observable<BackendResponseInterface<ResponseStatusInterface>> {
    return this.http.delete<BackendResponseInterface<ResponseStatusInterface>>(
      `${environment.url + Routes.PAYMENT_METHOD}/${paymentMethodId}`,
      { headers: this.headers },
    );
  }
}
