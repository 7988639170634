<div class="comment-container">
  <troi-person [large]="true" [person]="creator"></troi-person>
  <div (mouseenter)="toggleTrashIcon()" (mouseleave)="toggleTrashIcon()" class="comment">
    <div class="comment__headline">
      <div class="comment__headline--creation-info">
        <span>{{ creator.firstName }} {{ creator.lastName }},</span>
        <span>{{ formatDate(comment.created) }}</span>
      </div>

      <troi-icon
        *ngIf="showTrashIcon"
        (click)="onDeleteCommentClick(comment.id)"
        class="comment__headline--trash-can"
        [icon]="'icon-trash-can'"
        [size]="'14px'"
      ></troi-icon>
    </div>
    <div class="comment__content" [innerHTML]="comment.content"></div>
    <div class="file-container">
      <div
        *ngFor="let attachment of comment.attachments; let i = index"
        title="{{ attachment.filename }}"
      >
        <troi-attachment
          [attachment]="attachment"
          [attachmentType]="'taskcomment'"
          (deleteFile)="onFileDelete($event)"
        ></troi-attachment>
      </div>
    </div>
    <div class="comment__footer">
      <div class="comment__footer--action-container">
        <span
          [ngClass]="{ 'comment__footer--like--user-liked': userHasLiked() }"
          (click)="onLikeClick()"
          class="comment__footer--like"
          >{{ 'Tasks.labels.createTaskModal.comments.like' | translate }}</span
        >
        <span *ngIf="!isAnswer" class="comment__footer--reply" (click)="onReplyClick()">{{
          'Tasks.labels.createTaskModal.comments.answer' | translate
        }}</span>
      </div>
      <div class="comment__footer--info-container">
        <div
          class="comment__footer--info-container--likes"
          [ngbPopover]="likesPopover"
          (mouseenter)="multiplePoppver.open()"
          (mouseleave)="multiplePoppver.close()"
          #multiplePoppver="ngbPopover"
          placement="right bottom"
        >
          <span>{{ comment.likes }}</span>
          <troi-icon [icon]="'icon-arrow-head-up'" [size]="'16px'"></troi-icon>
        </div>
        <ng-template #likesPopover>
          <div *ngFor="let like of comment.likeDetails">
            <div>
              {{ like.liker.lastName }}
              {{ like.liker.firstName }}
            </div>
          </div>
        </ng-template>
        <div *ngIf="!isAnswer" class="comment__footer--info-container--comments">
          <span>{{ comment.answers?.length || 0 }}</span>
          <troi-icon [icon]="'icon-empty-bubble'" [size]="'16px'"></troi-icon>
        </div>
      </div>
    </div>
  </div>
</div>
