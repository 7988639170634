import { Component, EventEmitter, OnInit, Input, Output, AfterViewInit } from '@angular/core';

@Component({
  selector: 'tr-project-approval',
  templateUrl: './tr-project-approval.component.html',
  styleUrls: ['./tr-project-approval.component.scss'],
})
export class TrProjectApprovalComponent {
  @Input() billingIsApprovalRequired = false;
  @Input() billingApprovalStatus;
}
