import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TagInterface } from '../../../../../../../interfaces/tag.interface';

@Component({
  selector: 'troi-tag-chip',
  templateUrl: './tag-chip.component.html',
  styleUrls: ['./tag-chip.component.scss'],
})
export class TagChipComponent {
  @Input() curTag: TagInterface;
  @Output() tagDeleted: EventEmitter<TagInterface> = new EventEmitter();

  constructor() {}

  onTagDelete(): void {
    this.tagDeleted.emit(this.curTag);
  }
}
