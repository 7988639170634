import { FilterSetTypeEnum } from '../enums/filter-set-type.enum';
import { FiltersInterface } from '../../troi-data-listing-filters/filters.interface';

export class FilterSetModel {
  constructor(
    public id: string | number,
    public name: string,
    public value: string,
    public type: FilterSetTypeEnum,
    public isDefault: boolean,
    public filters: FiltersInterface,
  ) {}
}
