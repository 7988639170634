<span
  [ngStyle]="{ display: showArrow ? 'flex' : '' }"
  tabindex="0"
  (keydown)="onKeyDown($event)"
  class="troi-dropdown-select-wrapper"
  [ngClass]="{
    'troi-dropdown-select-wrapper--disabled': !enabled
  }"
>
  <troi-icon
    [title]="leftArrowTitle"
    *ngIf="showArrow"
    style="cursor: pointer; padding-right: 8px"
    icon="icon-arrow-head-left"
    (click)="onLeftArrowClicked($event)"
  ></troi-icon>
  <troi-dropdown-list
    #optionList
    [textAlignment]="textAlignment"
    [forceOpen]="forceOpen"
    (openChange)="onOpen($event)"
    (loadOptions)="onLoadOptions($event)"
    [options]="options"
    [actions]="actions"
    [value]="value"
    [static]="static"
    [top]="top"
    [totalOptions]="totalOptions"
    [lazyLoading]="lazyLoading"
    [noMinWith]="noMinWith"
    [width]="width"
    [multipleSelect]="multipleSelect"
    [treeMode]="treeMode"
    [disable]="disable"
    [search]="search"
    [searchPrefix]="searchPrefix"
    [searchInputType]="searchInputType"
    [isLoading]="isLoading"
    (selectedEmitter)="onSelect($event)"
    [defaultEmptyValue]="defaultEmptyValue"
    [initLazyOptionOnInit]="initLazyOptionOnInit"
    [returnSelectedObject]="returnSelectedObject"
    [predefinedOptionsCount]="predefinedOptions.length"
    (searchEvent)="onSearchOptions($event)"
    [bypassOptionsReset]="bypassOptionsReset"
  >
    <span
      class="troi-dropdown-select"
      [ngClass]="{
        'troi-dropdown-select--active': open,
        'troi-dropdown-select--no-border': noBorder,
        'troi-dropdown-select--full-width': fullWidth,
        'troi-dropdown-select--invalid': fieldInvalid && validationEnabled,
        'troi-dropdown-select--double-height': validationEnabled || doubleHeight
      }"
    >
      <span
        class="troi-dropdown-select__label"
        [ngClass]="{ toRight: toRight }"
        [ngStyle]="{ 'font-size': size, 'max-width': maxWidth }"
      >
        <span class="troi-dropdown-select__label-wrapper" *ngIf="label">
          <troi-icon *ngIf="labelIcon" [icon]="labelIcon" class="label-wrapper__icon"></troi-icon>
          {{ label | translate }}
          <span *ngIf="labelInfo" class="label-wrapper__info">{{ labelInfo | translate }}</span>
        </span>
        <span class="troi-dropdown-select__label-wrapper" *ngIf="!label && placeholder">
          <troi-icon *ngIf="labelIcon" [icon]="labelIcon" class="label-wrapper__icon"></troi-icon>
          {{ placeholder }}
        </span>
      </span>
      <troi-icon
        *ngIf="showClearButton()"
        class="troi-dropdown-select__clear-button"
        [small]="true"
        icon="icon-close"
        (click)="clearValue($event)"
      ></troi-icon>
      <span class="troi-dropdown-select__icon">
        <troi-icon *ngIf="!open" icon="icon-bold-arrow-down"></troi-icon>
        <troi-icon *ngIf="open" icon="icon-bold-arrow-up"></troi-icon>
      </span>
    </span>
    <div class="troi-dropdown-select__error" *ngIf="validationEnabled && fieldInvalid">
      <span *ngIf="fieldInvalid">{{ validationMessage | translate }}</span>
    </div>
    <span options>
      <ng-content select="[options]"> </ng-content>
    </span>
  </troi-dropdown-list>
  <troi-icon
    [title]="rightArrowTitle"
    *ngIf="showArrow"
    style="cursor: pointer"
    icon="icon-arrow-head-right"
    (click)="onRightArrowClicked($event)"
  ></troi-icon>
</span>
