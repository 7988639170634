import { PaymentMethodTranslation } from '../interfaces/payment-method.interface';

export class PaymentMethodModel {
  constructor(
    public id: number,
    public name: string,
    public mappingValue: string,
    public active: boolean,
    public deletedAt: Date,
    public client: null | { active: boolean; deleted: boolean; id: number; name: string },
    public translations: PaymentMethodTranslation,
    public isDefault: boolean,
  ) {}
}
