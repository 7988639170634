<label
  *ngIf="asLabel"
  class="troi-checkbox"
  [ngClass]="{
    'troi-checkbox--disabled': disabled,
    'troi-checkbox--checked': _value,
    pointer: !disabled || pointer,
    'troi-checkbox--remove-left-border': removeLeftBorder
  }"
>
  <input
    type="checkbox"
    [(ngModel)]="value"
    (change)="toggleCheckbox($event)"
    [disabled]="disabled || noPropagation"
  />
  {{ label }}
</label>
<label
  class="container"
  *ngIf="!asLabel"
  [ngClass]="{
    'container--disabled': disabled,
    'd-flex align-items-center h-auto': label,
    'ps-0': isLabelBeforeCheckbox
  }"
>
  <input
    type="checkbox"
    [(ngModel)]="value"
    (change)="toggleCheckbox($event)"
    [disabled]="disabled || noPropagation"
  />
  <span
    class="checkmark"
    [ngClass]="{ 'checkmark--accent': accent, 'right-0': isLabelBeforeCheckbox }"
    [ngStyle]="{ top: topStyle }"
  ></span>
  <span
    *ngIf="label"
    class="ms-3"
    [ngClass]="{ 'c-primary': accent, 'ms-3': !isLabelBeforeCheckbox }"
    [style.font-family]="fontFamily"
    [style.font-size.px]="fontSize"
  >
    {{ label }}
  </span>
</label>
