<span
  [ngClass]="icon"
  [class.troi-icon--large]="large"
  [class.troi-icon--medium]="medium"
  [class.troi-icon--small]="small"
  [class.troi-icon--bolder]="bolder"
  [class.troi-icon--disabled]="disabled"
  [ngStyle]="{ 'font-size': size }"
>
</span>
