import { ElementRef } from '@angular/core';
import { StrategyInterface } from './strategy.interface';
import { EventStrategies } from './event-strategies.enum';
import { BaseStrategy } from './base.strategy';

export class FocusStrategy extends BaseStrategy implements StrategyInterface {
  support = (type: EventStrategies): boolean => {
    return type === EventStrategies.FOCUS;
  };

  handle(element: ElementRef): void {
    const event = new MouseEvent('focus', { bubbles: true });
    this.invokeElementMethod(element.nativeElement, 'dispatchEvent', [event]);
  }
}
