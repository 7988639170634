import { Component, OnInit } from '@angular/core';
import { FooterService } from '../../../../shared/troi-footer/services/footer.service';

@Component({
  selector: 'menu-ai-chat',
  templateUrl: './menu-ai-chat.component.html',
  styleUrls: ['./menu-ai-chat.component.scss'],
})
export class MenuAiChatComponent implements OnInit {
  constructor(public footerService: FooterService) {}
  public el: any;

  ngOnInit(): void {
    this.el = window.top.document.getElementsByTagName('twc-ai-chat')[0];
  }

  clickAiChat() {
    this.el.inputToggle = !Boolean(this.el.inputToggle);
    this.el.contextInput = undefined;
  }
}
