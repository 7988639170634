<div class="assign_project_model">
  <troi-modal-header>
    <div style="display: flex">
      <troi-icon
        [icon]="'icon-folder'"
        [size]="'20px'"
        class="tnd-search-select__icon-type"
      ></troi-icon>
      <p style="margin-top: 18px; margin-left: 10px">
        {{ 'Timerecording.assign_to_time_recording' | translate }}
      </p>
    </div>
  </troi-modal-header>
  <troi-modal-content style="overflow-y: inherit">
    <nav class="assign_project_model__nav">
      <ul>
        <li>
          <a [class.active]="activeTab.assign" (click)="tabChanged('assign')">
              <troi-icon
                      [icon]="'icon-import'"
                      [size]="'16px'"
                      class="tnd-search-select__icon-type"
              ></troi-icon>
              {{ 'Timerecording.Assign' | translate }}</a
          >
        </li>
        <li>
          <a [class.active]="activeTab.unassign" (click)="tabChanged('unassign')">
              <troi-icon
                      [icon]="'icon-export'"
                      [size]="'16px'"
                      class="tnd-search-select__icon-type"
              ></troi-icon>
              {{ 'Timerecording.Unassign' | translate }}</a
          >
        </li>
      </ul>
    </nav>
    <div class="assign_project_model__filters">
      <div class="row align-items-end">
        <div class="col-12 col-md-2">
          <troi-dropdown-select
            [size]="'13px'"
            [initValue]="initValue"
            class="troi-dropdown-select--full"
            (selected)="selectClient($event)"
            [options]="generateClientDropdownOptions()"
          >
          </troi-dropdown-select>
        </div>
        <div class="col-12 col-md-6">
          <div class="filters-section__search">
            <troi-filter class="filters__filter filters__filter--search">
              <troi-icon icon="icon-loop-with-arrow-down"></troi-icon>
              <input
                type="text"
                name="search"
                autocomplete="off"
                (keyup)="searchInput.next($event)"
                [(ngModel)]="filters.search"
                placeholder="{{ 'Timerecording.Search' | translate }}"
              />
            </troi-filter>
          </div>
        </div>
      </div>
    </div>
    <div class="assign_project_model__table mt-4">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">
              <input
                *ngIf="activeTab.assign && assignedProjectData.length > 0"
                type="checkbox"
                [(ngModel)]="selectAll"
                (change)="selectAllProjects()"
              />
              <input
                *ngIf="activeTab.unassign && unassignedProjectData.length > 0"
                type="checkbox"
                [(ngModel)]="selectAll"
                (change)="selectAllProjects()"
              />
            </th>
            <th scope="col">{{ 'Timerecording.Customer' | translate }}</th>
            <th scope="col">{{ 'Timerecording.ProjectNumber' | translate }}</th>
            <th scope="col">{{ 'Timerecording.ProjectName' | translate }}</th>
          </tr>
        </thead>
        <!-- assign -->
        <tbody *ngIf="activeTab.assign">
          <ng-container *ngIf="assignedProjectData.length > 0; else noData">
            <tr
              *ngFor="let aProject of assignedProjectData; let i = index"
              (click)="toggleCheckbox(aProject)"
            >
              <td>
                <input
                  type="checkbox"
                  [(ngModel)]="aProject.project.checked"
                  (change)="updateCheckedProjects(aProject, 'assign')"
                />
              </td>
              <td>{{ aProject.project.customer.name }}</td>
              <td>{{ aProject.project.number }}</td>
              <td>{{ aProject.project.name }}</td>
            </tr>
          </ng-container>
          <ng-template #noData>
            <tr>
              <td colspan="4" class="text-center">
                <p>No data found</p>
              </td>
            </tr>
          </ng-template>
        </tbody>

        <!-- unassign -->
        <tbody *ngIf="activeTab.unassign">
          <ng-container *ngIf="unassignedProjectData.length > 0; else noData">
            <tr
              *ngFor="let unaProject of unassignedProjectData; let i = index"
              (click)="toggleCheckbox(unaProject)"
            >
              <td>
                <input
                  type="checkbox"
                  [(ngModel)]="unaProject.project.checked"
                  (change)="updateCheckedProjects(unaProject, 'unassign')"
                />
              </td>
              <td>{{ unaProject.project.customer.name }}</td>
              <td>{{ unaProject.project.number }}</td>
              <td>{{ unaProject.project.name }}</td>
            </tr>
          </ng-container>
          <ng-template #noData>
            <tr class="text-center">
              <td colspan="4" class="text-center">
                <p>No data found</p>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </troi-modal-content>
  <troi-modal-footer>
    <troi-btn class="delete-recorded__button mt-3 mx-2" [outline]="true" (click)="close()">
      {{ 'Approval.labels.cancel' | translate }}
    </troi-btn>
    <troi-btn class="add-edit-account-group__button mt-3" (click)="addAndremoveAssignProject()">
      <span *ngIf="activeTab.assign">{{ 'Timerecording.Add' | translate }}</span>
      <span *ngIf="activeTab.unassign">{{ 'Timerecording.Remove' | translate }}</span>
    </troi-btn>
  </troi-modal-footer>
</div>
