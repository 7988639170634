export enum ColumnsEnum {
  PRIORITY = 'priority',
  TICKET = 'ticket',
  TITLE = 'title',
  CALCULATION_POSITION = 'calculationPosition',
  STATUS = 'status',
  ASSIGNEES = 'assignees',
  PROGRESS = 'progress',
  SUBTASKS = 'subtasks',
  DEADLINE = 'deadline',
  DESCRIPTION = 'description',
}
