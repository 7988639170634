export enum DataTypeEnum {
  ACCOUNTS = 'ACCOUNTS',
  ACCOUNTS_CREATE_BOOKING = 'ACCOUNTS_CREATE_BOOKING',
  COST_CENTERS = 'COST_CENTERS',
  ACCOUNT_GROUPS = 'ACCOUNT_GROUPS',
  SUPPLIER = 'SUPPLIER',
  PROTOCOL_STATUS = 'PROTOCOL_STATUS',
  TAX_RATES = 'TAX_RATES',
  UNITS = 'UNITS',
  CUSTOMERS = 'CUSTOMERS',
  PROJECT_TYPES = 'PROJECT_TYPES',
  PROJECT_FOLDERS = 'PROJECT_FOLDERS',
  PROJECTS_BY_CUSTOMER = 'PROJECTS_BY_CUSTOMER',
  PROJECTS_BY_CLIENT = 'PROJECTS_BY_CLIENT',
  CP_BY_K_NUMBER = 'CP_BY_K_NUMBER',
  SUB_PROJECTS = 'SUB_PROJECTS',
  CPS = 'CPS',
  EMPLOYEES = 'EMPLOYEES',
  PROJECT_LEADERS = 'PROJECT_LEADERS',
  TASK_STATES = 'TASK_STATES',
  TASK_PROJECTS = 'TASK_PROJECTS',
  TASK_TAGS = 'TASK_TAGS',
}
