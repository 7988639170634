import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { ModalService } from '../../../../../shared/troi-modals/modal.service';
import { BaseModalDirective } from '../../../../../shared/troi-base-modal/baseModal.component';

@Component({
  selector: 'tr-projecttime-month-model',
  templateUrl: './tr-projecttime-month-model.component.html',
  styleUrls: ['./tr-projecttime-month-model.component.scss'],
})
export class TrProjecttimeMonthModelComponent extends BaseModalDirective implements OnInit {
  @Output() parent;
  @Output() reloadList;
  public modalObject: any;
  public workLogDate: any = new Date();

  public constructor(public modalService: ModalService) {
    super(modalService, false, true);
    this.modalObject = this.modalService.object;
  }

  ngOnInit(): void {
    this.workLogDate = this.modalObject.date;
  }
}
