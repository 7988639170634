import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { ModuleInterceptor } from '../../../../../../core/enums/module-interceptor';
import { Routes } from '../../../../enum/routes';
import {
  AssignmentResponseInterface,
  TimeEntryHelperResponseInterface,
  TimeEntryResponseInterface,
} from '../../../../interfaces/responses.interface';
import { TimeEntryInterface, TimeEntryOverviewInterface } from '../../../../interfaces/timeEntry.interface';

@Injectable()
export class TimeentryService {
  private headers: HttpHeaders;

  public constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');
  }

  public getTimeentries(taskId: string, isSubtask?: boolean): Observable<TimeEntryHelperResponseInterface> {
    if (!isSubtask) {
      return this.http.get<TimeEntryHelperResponseInterface>(
        environment.url + Routes.TIMEENTRY_HELPER + `?taskId=${taskId}`,
        {
          headers: this.headers,
        },
      );
    } else {
      return this.http.get<TimeEntryHelperResponseInterface>(
        environment.url + Routes.TIMEENTRY_HELPER_SUBTASK + `?subtaskId=${taskId}`,
        {
          headers: this.headers,
        },
      );
    }
  }

  public createTimeentry(newEntry: TimeEntryInterface): Observable<TimeEntryResponseInterface> {
    return this.http.post<TimeEntryResponseInterface>(environment.url + Routes.TIMEENTRY, newEntry, {
      headers: this.headers,
    });
  }

  public updateTimeentry(entry: TimeEntryOverviewInterface): Observable<TimeEntryResponseInterface> {
    const updatedTimeEntry: TimeEntryInterface = {
      calculationPosition: entry.calculationPosition.toString(),
      date: `${entry.billingDate.getFullYear()}-${entry.billingDate.getMonth() + 1}-${entry.billingDate.getDate()}`,
      workTime: entry.quantity,
      internalComment: entry.internalComment,
      externalComment: entry.externalComment,
      billable: true,
      projectTask: entry.projecttask,
      projectSubTask: entry.subtask,
      employeeId: entry.employee.id,
    };

    return this.http.put<TimeEntryResponseInterface>(
      environment.url + Routes.TIMEENTRY + `/${entry.id}`,
      updatedTimeEntry,
      {
        headers: this.headers,
      },
    );
  }

  public deleteTimeentry(entry: TimeEntryOverviewInterface): Observable<TimeEntryResponseInterface> {
    return this.http.delete<TimeEntryResponseInterface>(environment.url + Routes.TIMEENTRY + `/${entry.id}`, {
      headers: this.headers,
    });
  }
}
