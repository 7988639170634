import { Injectable } from '@angular/core';
import { StorageService } from '../../../core/storage/storage.service';
import { StorageKeys } from '../../../core/storage/storage.keys';
import { BasicSettingsNetwork } from '../../../core/network/basic-settings.network';
import { SettingsEmitter } from '../../../core/emitters/settings.emitter';
import { BaseBookingSettings } from '../../common/classes/base-booking-settings';
import { Routes } from '../../../core/enums/routes';

@Injectable()
export class MenuSettingsService extends BaseBookingSettings<any> {
  public storageKey = StorageKeys.ACCOUNTING_SETTINGS;

  constructor(
    protected storageService: StorageService,
    public basicSettingsNetwork: BasicSettingsNetwork,
    protected settingsEmitter: SettingsEmitter,
  ) {
    super(storageService, basicSettingsNetwork, settingsEmitter);
  }

  public getSettingsRoute(): string {
    return Routes.ACCOUNTING_SETTINGS;
  }
}
