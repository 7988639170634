import {
  Component,
  EventEmitter,
  Output,
  Input,
  ChangeDetectorRef,
  SimpleChanges,
  AfterViewInit,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import * as moment from 'moment';
import { animationInterval } from './animationInterval';
import { timePresentation } from './timePresentation';

@Component({
  selector: 'troi-time-switch',
  templateUrl: './troi-time-switch.component.html',
  styleUrls: ['./troi-time-switch.component.scss'],
})
export class TroiTimeSwitchComponent implements AfterViewInit, OnChanges, OnDestroy {
  public readonly stateNew: string = 'new';
  public readonly stateRunning: string = 'running';
  public readonly statePaused: string = 'paused';
  public readonly stateStopped: string = 'stopped';

  public readonly stateAwaitingStart: string = 'await_start';
  public readonly stateAwaitingPauseStart: string = 'await_pause_start';
  public readonly stateAwaitingPauseEnd: string = 'await_pause_end';

  public state: string = this.stateNew;
  public presentation = '00:00:00';
  public startDate: Date;
  public elapsedTime: number | null = null;
  public interval: AbortController = new AbortController();

  public moment: any = moment;

  @Input() id = '';
  @Input() initialElapsedTime = 0;
  @Input() initialStartDate: Date | null = null;
  @Input() initialState = 'new';
  @Input() switchToState = 'new';
  @Input() awaitActionTime = '00:00';

  @Output() stateChanged = new EventEmitter<Record<string, unknown>>();

  public constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public ngAfterViewInit() {
    this.startDate = null !== this.initialStartDate ? new Date(this.initialStartDate) : new Date();
    this.elapsedTime = null !== this.initialElapsedTime ? this.initialElapsedTime : 0;
    this.elapsedTime = this.elapsedTime + this.calculateElapsedSeconds(false);
    this.changeState(this.initialState, false);

    this.updateUI();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.switchToState) {
      if (!this.switchToState) {
        return false;
      }
      this.elapsedTime = 0;
      this.changeState(this.switchToState, false);
      this.updateUI();
    }
  }

  public ngOnDestroy(): void {
    if (this.interval instanceof AbortController) {
      this.interval.abort();
    }
  }

  public changeState(state: string, doEmit: boolean = true): void {
    this.state = state;

    const elapsed: number = this.calculateElapsedSeconds();
    const presentation: string = this.calculatePresentation(elapsed);

    if (doEmit) {
      this.stateChanged.emit({
        id: this.id,
        state,
        elapsedTime: elapsed,
        presentation,
      });
    }

    if (this.stateRunning === state || this.stateAwaitingPauseStart === state) {
      this.startDate = new Date();
      this.interval = new AbortController();

      animationInterval(1000, this.interval.signal, () => {
        this.updateUI();
      });
      this.updateUI();
    } else {
      this.interval.abort();
    }

    if (this.statePaused === state) {
      this.elapsedTime = elapsed;
    }
  }

  private updateUI(): void {
    const elapsed: number = this.calculateElapsedSeconds();

    this.presentation = this.calculatePresentation(elapsed);
    this.changeDetectorRef.detectChanges();
  }

  private calculateElapsedSeconds(addElapsedTime: boolean = true): number {
    const elapsed: number = new Date().valueOf() - this.startDate.valueOf();

    return Math.round(elapsed / 1000) + (addElapsedTime ? this.elapsedTime : 0);
  }

  private calculatePresentation(counter: number): string {
    if (counter >= 0) {
      return timePresentation(counter);
    } else {
      timePresentation(0);
    }
  }
}
