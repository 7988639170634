import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UserSettings } from '../../../../core/services/user-settings';
import { StorageService } from '../../../../core/storage/storage.service';
import { AddProjectTypeEnum } from '../interfaces/add-project-type.enum';

@Injectable()
export class AddProjectOptionSettingService extends UserSettings {
  private settingsKey = 'add-project-option-settings';

  public newSettingsDataSet = new Subject<any>();

  constructor(public storage: StorageService) {
    super(storage);
    this.initLSUserData(this.settingsKey);
  }

  readFromLS(client: number): AddProjectTypeEnum {
    const option = this.getSettingForUser(this.settingsKey)[client];

    return option || this.getDefaultSettings();
  }

  setOption(client: number, option: AddProjectTypeEnum) {
    const settings = this.getAllUsersSettings();
    settings[this.getUserId()][this.settingsKey][client] = option;

    this.saveUserSetting(settings);
  }

  public getDefaultSettings(): AddProjectTypeEnum {
    return AddProjectTypeEnum.CUSTOMER;
  }
}
