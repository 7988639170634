import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { isArray } from 'lodash';
import * as moment from 'moment';
import { Subject, Subscription, delay, firstValueFrom, of } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';
import { DataTypeEnum } from '../../../../../../../core/enums/data-type.enum';
import { DropdownsService } from '../../../../../../../modules/common/services/dropdowns.service';
import { TroiDropdownListModel } from '../../../../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { PersonInterface } from '../../../../../../../shared/troi-person/interfaces/person.interface';
import { RangeDateChangedInterface } from '../../../../../../../shared/troi-range-date/troi-range-date.component';
import { Routes } from '../../../../../enum/routes';
import { TaskActionsEnum } from '../../../../../enum/task-actions';
import { AssigneeInterface } from '../../../../../interfaces/assignee.interface';
import {
  ForcecastValuesInterface,
  ForecastMemberInterface,
  ForecastTeamInterface,
  ResourcesResponseInterface,
} from '../../../../../interfaces/forecast.interface';
import { ForecastResponseInterface, TeamResponseInterface } from '../../../../../interfaces/responses.interface';
import { TeamOptionInterface } from '../../../../../interfaces/teamOption.interface';
import { TaskModel } from '../../../../../models/task.model';
import { TimerangeView } from '../../../../../modules/timelineview/enum/view';
import { ResourcesService } from '../../../../../network/resources.service';
import { TasksService } from '../../../../../network/tasks.service';
import { TaskFiltersService } from '../../../../../services/filters.service';
import { TasksHelperService } from '../../../../../services/helper.service';
import { TasksSettingsService } from '../../../../../services/tasks-settings.service';

interface ProjectTaskSimplified {
  projectTaskId: number;
  projectTaskName: string;
  projectId: number;
  projectName: string;
  projectNumber: string;
  values: number[];
}

@Component({
  selector: 'troi-task-modal-resources',
  templateUrl: './resources-content.component.html',
  styleUrls: ['./resources-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesContentComponent implements OnDestroy, OnInit {
  @ViewChild('container') containerRef: ElementRef<HTMLElement>;
  @Input() isResourcesContext = false;
  @Input() task: TaskModel; // Used for Project List and Kanban view.
  @Input() resourceTasks: ForecastTeamInterface[]; // Used for Resources View.

  @Input() enableTeamFilter = true;

  @Input() reloadEmployeeDropdownOptions = 0;
  @Input() resetEmployeeDropdown: Subject<boolean>;

  private subscriptions: Subscription = new Subscription();

  public isLoading = false;
  public showDropdown = false;
  public dateNow = new Date();
  public dateNowMilliseconds = this.dateNow.setHours(0, 0, 0, 0);
  public thirdDaysAheadFromDateNowMilliseconds = new Date(
    this.dateNowMilliseconds + 30 * 24 * 60 * 60 * 1000,
  ).getTime();

  public projectTeamId = 0;
  public employeeIds: string[] = [];
  public employeeOptions = [
    ...this.dropdownService.buildOptionList(this.tasksService.employeeOptions, DataTypeEnum.EMPLOYEES),
  ];
  public timerangeFrom: number;
  public timerangeTo: number;
  public selectedDateView = TimerangeView.DAY;
  public selectedUtilization = true;

  public projectTeams: TeamOptionInterface[] = [];
  public forecastValues: ForecastTeamInterface[];
  public visibleRows: { [teamTitle: string]: Set<number> } = {};
  public heavyLoadPercent: number;
  public mediumLoadPercent: number;

  public isTableViewParametersLoaded = false;

  public columns: string[] = [];

  constructor(
    public resourcesService: ResourcesService,
    public settingsService: TasksSettingsService,
    public tasksService: TasksService,
    private helperService: TasksHelperService,
    private dropdownService: DropdownsService,
    private filtersService: TaskFiltersService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.subscriptions.add(
      this.resourcesService.getProjectGroups().subscribe((res: TeamResponseInterface) => {
        this.projectTeams = res.data;
      }),
    );

    this.subscriptions.add(
      this.helperService.onUnassignEmployee.subscribe((employeeId) => {
        this.forecastValues.forEach((team: ForecastTeamInterface) => {
          const member = team.values?.members?.find(
            (m: ForecastMemberInterface) => m.employee.id === Number(employeeId),
          );
          if (member) {
            member.alreadyAssigned = false;
          }
        });
        this.cdRef.detectChanges();
      }),
    );
  }

  ngOnInit(): void {
    if (this.isResourcesContext) {
      if (!isArray(this.resourceTasks)) {
        this.prepareResponse(this.resourceTasks as any as ResourcesResponseInterface);
      }
    }
    this.getSelectedUtilization();
    this.getSelectedDateView();

    const selectedClientId = new URLSearchParams(window.parent.location.search).get('client');
    this.downloadSettings(Number(selectedClientId));
    this.cdRef.detectChanges();
  }

  private downloadSettings(id: number) {
    this.subscriptions.add(
      this.settingsService.downloadSettings(id).subscribe((res) => {
        this.heavyLoadPercent = res.tableUnitView?.heavyLoadPercent || 80;
        this.mediumLoadPercent = res.tableUnitView?.mediumLoadPercent || 50;
        this.isTableViewParametersLoaded = true;
        this.cdRef.detectChanges();
      }),
    );
  }

  private getSelectedUtilization() {
    this.subscriptions.add(
      this.helperService.selectedUtilization.subscribe((value) => {
        this.selectedUtilization = value;
        this.cdRef.detectChanges();
      }),
    );
  }

  private getSelectedDateView() {
    this.subscriptions.add(
      this.helperService.selectedDateView.subscribe((value) => {
        this.selectedDateView = value;
        this.cdRef.detectChanges();
      }),
    );
  }

  private getForecastValues() {
    this.isLoading = true;
    this.subscriptions.add(
      this.resourcesService
        .getForecastValues(
          this.resourcesService.buildForecastUrl(
            this.timerangeFrom,
            this.timerangeTo,
            this.selectedUtilization,
            this.selectedDateView,
            this.task?.project?.id,
            this.projectTeamId,
            this.employeeIds,
          ),
        )
        .subscribe((res: ForecastResponseInterface) => {
          this.prepareResponse(res as any as ResourcesResponseInterface);
          this.isLoading = false;
          this.cdRef.detectChanges();
        }),
    );
  }

  public generateTeamOptions(): TroiDropdownListModel[] {
    return [
      {
        active: true,
        label: 'Tasks.labels.all',
        value: 0,
      },
      ...this.projectTeams.map((item: TeamOptionInterface) => ({
        active: true,
        label: item.description,
        value: item.id.toString(),
      })),
    ];
  }

  public generateEmployeesOptions(): TroiDropdownListModel[] {
    const options = [
      {
        active: true,
        label: 'Tasks.labels.all',
        value: '*',
      },
      ...this.employeeOptions,
    ];
    return this.sortAlphabetic(options, true);
  }

  private sortAlphabetic(options: TroiDropdownListModel[], sortNames: boolean) {
    if (sortNames)
      return options.sort((a, b) => {
        if (a.fullObject && b.fullObject)
          return (a.fullObject.lastName as string).localeCompare(b.fullObject.lastName as string);
      });
    else return options.sort((a, b) => a.label.localeCompare(b.label));
  }

  public onSelectViewClick(selectedView: TimerangeView): void {
    this.selectedDateView = selectedView;
    this.getForecastValues();
  }

  public onSelectUtilizationClick(selectedUtil: boolean): void {
    this.selectedUtilization = selectedUtil;
  }

  public onAssignEmployeeClick(employee: PersonInterface): void {
    this.tasksService
      .getAssigneeOptions(
        this.task.project?.id,
        this.task.calculationPosition?.customer.mandant.id,
        this.getTimestamp('start'),
        this.getTimestamp('end'),
      )
      .subscribe((res) => {
        const assignableEmployees = res.data;
        if (assignableEmployees && assignableEmployees.some((e) => e.id === employee.id)) {
          this.task.assignees.push({
            id: employee.id.toString(),
            user: employee,
            projectTask: +this.task.id,
            assignments: [],
            utilization: 0,
          } as AssigneeInterface);
          // update team member
          this.forecastValues.forEach((team: ForecastTeamInterface) => {
            team.values.members.map((m: ForecastMemberInterface) => {
              if (!m.alreadyAssigned) m.alreadyAssigned = m.employee.id === employee.id;
            });
          });
          this.cdRef.detectChanges();
        }
      });
  }

  private getTimestamp(type: string): number {
    if (type === 'start' && this.task.startDate) return new Date(this.task.startDate).getTime();
    if (type === 'end' && this.task.endDate) return new Date(this.task.endDate).getTime();

    return undefined;
  }

  public onTeamChange(teamId: number): void {
    this.projectTeamId = teamId;
    this.filterEmployeesByTeam(teamId);
    this.getForecastValues();
  }

  private filterEmployeesByTeam(teamId: number) {
    if (teamId === 0) {
      this.employeeOptions = [
        ...this.dropdownService.buildOptionList(this.tasksService.employeeOptions, DataTypeEnum.EMPLOYEES),
      ];
    } else {
      const selectedTeam = this.projectTeams.find((team) => team.id === +teamId);
      if (selectedTeam && selectedTeam.employees) {
        this.employeeOptions = selectedTeam.employees.map((employee) => ({
          active: true,
          label: `${employee.firstName} ${employee.lastName}`,
          value: employee.id.toString(),
        }));
      }
    }
    this.employeeIds = ['*'];
  }

  public onEmployeeChange(employeeIds: string[]) {
    if ([...employeeIds].includes('*')) {
      this.employeeIds = ['*'];
    } else this.employeeIds = [...employeeIds];

    this.getForecastValues();
  }

  public updateCalendarRange(newTimerange: RangeDateChangedInterface): void {
    // if timestamp is null, we use current timestamp without millisecond (required by backend)
    this.timerangeFrom =
      newTimerange.date[0] !== null ? newTimerange.date[0] : Math.floor(new Date().getTime() / 1000) * 1000;
    this.timerangeTo =
      newTimerange.date[1] !== null && newTimerange.date[1] > this.timerangeFrom
        ? newTimerange.date[1]
        : this.timerangeFrom;

    this.getForecastValues();
  }

  public getMinDate() {
    if (this.task?.startDate) {
      return moment(this.task.startDate);
    } else {
      return moment(this.dateNow);
    }
  }

  public checkBorder(current: number, neighbour: number): boolean {
    if (!neighbour) {
      return true;
    } else if (current >= this.heavyLoadPercent && neighbour < this.heavyLoadPercent) {
      return true;
    } else if (
      current >= this.mediumLoadPercent &&
      current < this.heavyLoadPercent &&
      (neighbour < this.mediumLoadPercent || neighbour >= this.heavyLoadPercent)
    ) {
      return true;
    }
    return false;
  }

  public getColTitle(title: string): string {
    const lang = JSON.parse(localStorage.getItem('lang'));
    if (this.selectedDateView === TimerangeView.DAY) {
      return title;
    } else if (this.selectedDateView === TimerangeView.MONTH) {
      const d = new Date();
      d.setMonth(+title - 1);
      return d.toLocaleDateString(lang, { month: 'short' });
    } else {
      return title;
    }
  }

  getColSubtitle(date: string): string {
    const lang = JSON.parse(localStorage.getItem('lang'));
    if (this.selectedDateView === TimerangeView.DAY) {
      return `${new Date(date).toLocaleDateString(lang, { weekday: 'short' })}`;
    }
    return '';
  }

  public removeYear(date: string): string {
    const parts = date.split('.');
    const filteredParts = parts.filter((part) => !/^\d{4}$/.test(part));
    return filteredParts.join('.');
  }

  public getCurViewTitle(): string {
    if (this.selectedDateView === TimerangeView.DAY) return 'Tasks.labels.createTaskModal.resources.filter.dayView';
    else if (this.selectedDateView === TimerangeView.WEEK)
      return 'Tasks.labels.createTaskModal.resources.filter.weekView';
    else return 'Tasks.labels.createTaskModal.resources.filter.monthView';
  }

  public openEditTaskModal(taskSimplified: ProjectTaskSimplified): void {
    const value = TaskActionsEnum.EDIT;
    let task: TaskModel = null;

    this.tasksService.findTaskById(taskSimplified.projectTaskId).subscribe((response) => {
      task = response.data[0];
      this.helperService.taskActionSub.next({ value, task });
    });
  }

  async seeTaskDetails(
    rowIdx: number,
    teamTitle: string,
    assignee: string,
    member: ForecastMemberInterface,
    unitId: number,
  ) {
    if (!this.visibleRows[teamTitle]) {
      this.visibleRows[teamTitle] = new Set<number>();
    }

    if (this.visibleRows[teamTitle].has(rowIdx)) {
      this.visibleRows[teamTitle].delete(rowIdx);
    } else {
      this.visibleRows[teamTitle].add(rowIdx);
      await this.appendAssigneeProjects(assignee, member, unitId);
    }
  }

  private async appendAssigneeProjects(assignee: string, member: ForecastMemberInterface, unitId: number) {
    let url = this.filtersService.putFiltersInUrl(environment.url + Routes.RESOURCES);
    if (url.includes('&assignee=')) url = url.replace(/&assignee=[^&]*/g, '');
    if (assignee) url += `&assignee=${assignee}`;
    url += '&moreDetails=1';
    const response: any = await firstValueFrom(this.tasksService.getTasks(url));
    if (!member.projects) member.projects = [];
    if (unitId === -1) member.projects = response.data.values?.valuesProject ?? [];
    else {
      member.projects = [];
      (response.data.values?.valuesProject ?? []).forEach((entry) => {
        if (entry?.projectUnitId === unitId) {
          member.projects.push(entry);
        }
      });
    }

    // we use delay(0) to wait for the DOM to update, pushing to end of call stack
    await firstValueFrom(of(0).pipe(delay(0)));
    this.cdRef.detectChanges();
  }

  public getConcatenatedTaskBar(workLoad, team, rowIdx, cellIdx, plannedWorkingTime): object {
    const neighbourLeft =
      team.values.values[rowIdx][cellIdx - 1]?.plannedWorkingTime > 0
        ? team.values.values[rowIdx][cellIdx - 1]?.utilizationPercent
        : 0;
    const neighbourRight =
      team.values.values[rowIdx][cellIdx + 1]?.plannedWorkingTime > 0
        ? team.values.values[rowIdx][cellIdx + 1]?.utilizationPercent
        : 0;

    const classes = {
      'red-mark': workLoad >= this.heavyLoadPercent && plannedWorkingTime > 0,
      'orange-mark': workLoad >= this.mediumLoadPercent && workLoad < this.heavyLoadPercent && plannedWorkingTime > 0,
      'border-left': this.checkBorder(workLoad, neighbourLeft),
      'border-right': this.checkBorder(workLoad, neighbourRight),
    };
    return classes;
  }

  public getTaskBarClasses(task: ForecastTeamInterface, cellIdx: number): { [key: string]: boolean } {
    const value = task.values[cellIdx]?.utilizationPercent;
    const valuePrev = task.values[cellIdx - 1]?.utilizationPercent;
    const valueNext = task.values[cellIdx + 1]?.utilizationPercent;
    const classes = {
      'red-mark': value >= this.heavyLoadPercent,
      'orange-mark': value >= this.mediumLoadPercent && value < this.heavyLoadPercent,
      'border-left': this.checkBorder(value, valuePrev),
      'border-right': this.checkBorder(value, valueNext),
    };

    return classes;
  }

  public getLeaveTimeBarClasses(
    team: ForecastTeamInterface,
    rowIdx: number,
    cellIdx: number,
  ): { [key: string]: boolean } {
    const cellWorkValue = team.values.values[rowIdx][cellIdx]?.leaveTimePercent;
    const nextCellWorkValue = team.values.values[rowIdx][cellIdx + 1]?.leaveTimePercent;
    const prevCellWorkValue = team.values.values[rowIdx][cellIdx - 1]?.leaveTimePercent;
    const classes = {
      'red-mark': cellWorkValue >= this.heavyLoadPercent,
      'orange-mark': cellWorkValue >= this.mediumLoadPercent && cellWorkValue < this.heavyLoadPercent,
      'border-left': this.checkBorder(cellWorkValue, prevCellWorkValue),
      'border-right': this.checkBorder(cellWorkValue, nextCellWorkValue),
    };

    return classes;
  }

  public isSunday(date: string | Date): boolean {
    return moment(date).day() === 0 && this.selectedDateView === TimerangeView.DAY;
  }

  public roundPercentage(value: number): number {
    return value && value >= 1 ? Math.round(value) : value;
  }

  public trackByIndex(index: number, item: any): number {
    return index;
  }

  public anyMembersUnfolded(team: ForecastTeamInterface): boolean {
    return Array.from(this.visibleRows[team.title] ?? []).length > 0;
  }

  public toggleAllFromTeam(team: ForecastTeamInterface): void {
    team.showMembers = true;

    if (!this.visibleRows[team.title]) {
      this.visibleRows[team.title] = new Set<number>();
    }

    // check if some members are already visible
    const someVisible = Array.from(this.visibleRows[team.title]).length > 0;

    if (!someVisible) {
      team.values.members.forEach((member, rowIdx) => {
        this.seeTaskDetails(rowIdx, team.title, member.employee.id.toString(), member, team.unitId);
      });
    } else {
      team.values.members.forEach((member, rowIdx) => {
        this.visibleRows[team.title].delete(rowIdx);
      });
    }
  }

  private prepareResponse(res: ResourcesResponseInterface): void {
    const {
      data: { columns, projectUnits },
    } = res;

    this.columns = columns;

    projectUnits.forEach((team: ForecastTeamInterface) => {
      if (!team.values.members) {
        team.values.members = [];
        team.isEmpty = true;
        return;
      }
      if (!team.values.values) team.values.values = [];

      // Team total free time calculation
      team.totalFreeTime = team.values.members.reduce((acc, member) => acc + member.freeTime, 0);

      team.totalValues = [];

      // loop through each day by team.values.columns
      columns.forEach((column, columnIndex) => {
        // map of values to make it easier to loop through
        const valueMap = ['utilizationHours', 'leaveTimeHours', 'leaveTimePercent', 'plannedWorkingTime'];

        const totalDayValues: Partial<ForcecastValuesInterface> = {};
        valueMap.forEach((key) => {
          totalDayValues[key] = 0;
        });

        // now we loop through each value for each member
        team.values.values.forEach((memberValues) => {
          // now we pick the value for the current day
          const value = memberValues[columnIndex];

          // now we add the values to the total
          valueMap.forEach((key) => {
            totalDayValues[key] += value[key];
          });
        });

        // now we can round the values
        valueMap.forEach((key) => {
          if (key === 'utilizationHours') {
            totalDayValues[key] = Math.round(totalDayValues[key]);
          }
        });

        // and push the total values to the team

        totalDayValues.utilizationPercent =
          (totalDayValues.utilizationHours + totalDayValues.leaveTimeHours) / totalDayValues.plannedWorkingTime;

        totalDayValues.utilizationPercent *= 100;

        // round to two decimal places
        totalDayValues.utilizationPercent = Math.round(totalDayValues.utilizationPercent * 100) / 100;

        team.totalValues.push(totalDayValues as ForcecastValuesInterface);
      });

      // now we can calculate the totalUtilizationPercent
      // we need to sum all the values of the totalValues.utilizationPercent and divide by the number of days
      let subtractor = 0;
      team.totalUtilizationPercent =
        team.totalValues.reduce((acc, val) => {
          if (isNaN(val.utilizationPercent)) {
            subtractor++;
            return acc;
          }
          return acc + val.utilizationPercent;
        }, 0) /
        (team.totalValues.length - subtractor);

      // we combine the arrays here to sort on last name, while we also keep the values in sync
      const combinedArray = team.values?.members?.map((member, index) => ({
        member,
        value: team.values.values[index],
      }));

      // sort by last name
      combinedArray.sort((a, b) => {
        return a.member.employee.lastName.toLowerCase().localeCompare(b.member.employee.lastName.toLowerCase());
      });

      // now we can update the team values
      team.values.members = combinedArray.map((item) => item.member);
      team.values.values = combinedArray.map((item) => item.value);
    });

    this.forecastValues = projectUnits;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
