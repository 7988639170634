import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
@Component({
  selector: 'troi-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.scss'],
})
export class ColorPaletteComponent implements OnInit {
  @Input() colors: string[];
  @Output() selectedColor: EventEmitter<string> = new EventEmitter();
  @Output() closeCreation: EventEmitter<boolean> = new EventEmitter();

  shownColors: string[] = [
    '#123456',
    '#d0137b',
    '#31752f',
    '#8080ea',
    '#da223a',
    '#e67e22',
    '#8080ea',
    '#da223a',
    '#e67e22',
  ];

  constructor() {}

  ngOnInit(): void {
    if (this.colors) this.shownColors = this.colors;
  }

  onColorSelect(color: string): void {
    this.selectedColor.emit(color);
  }

  onTagCreationClose(): void {
    this.closeCreation.emit(true);
  }
}
