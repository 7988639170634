<div class="edit-subtask">
  <troi-btn class="edit-subtask__back-btn" [outline]="true" (click)="onBackClick()">
    {{ 'Main.Back' | translate }}
  </troi-btn>
  <div class="edit-subtask__content" style="position: relative; height: 100%">
    <div class="edit-subtask__content--scrollable" style="overflow: auto; height: inherit">
      <div class="edit-subtask__title">
        <div
          class="edit-subtask__icon-container"
          ngbPopover="{{ 'Tasks.labels.deletionDenied' | translate }}"
          placement="bottom"
          #deletePopover="ngbPopover"
          triggers="manual"
        >
          <troi-icon
            [disabled]="task.project?.projectStatus?.blockedForBooking"
            (click)="onAddTimerClick()"
            [size]="'20px'"
            [icon]="'icon-timer'"
          ></troi-icon>

          <troi-dropdown-list
            textAlignment="left"
            [options]="dropdownOptions"
            (selectedEmitter)="onActionSelect($event)"
            (openChange)="onDropdownActionOpeningState($event)"
          >
            <troi-icon [size]="'20px'" [icon]="'icon-circles-full'"></troi-icon>
          </troi-dropdown-list>
        </div>
        <troi-input-field
          focus-directive
          cssSelector=".troi-input-field__input"
          [placeholder]="'Tasks.labels.createTaskModal.enterTaskTitle' | translate"
          [fieldInvalid]="subtask.title.length <= 0"
          [validationEnabled]="true"
          [outline]="true"
          requiredFieldErrorMessage="Tasks.labels.createTaskModal.fillTaskTitleField"
          [(ngModel)]="subtask.title"
        ></troi-input-field>
      </div>

      <div class="edit-subtask__description">
        <span class="edit-subtask__subtitle">{{
          'Tasks.labels.createTaskModal.subtasks.description' | translate
        }}</span>
        <div>
          <quill-editor
            class="edit-subtask__editor"
            [(ngModel)]="subtask.description"
            (ngModelChange)="convertURL($event)"
            [placeholder]="'Tasks.labels.createTaskModal.subtasks.addDescription' | translate"
            [modules]="helperService.quillConfig"
          ></quill-editor>
        </div>
      </div>
      <div>
        <span class="edit-subtask__subtitle">{{
          'Tasks.labels.createTaskModal.subtasks.resourcePlanning' | translate
        }}</span>
        <div class="edit-subtask__resource-planning">
          <div
            class="edit-subtask__resource-planning--daterange-picker"
            ngbPopover="{{ 'Tasks.labels.createTaskModal.addTimerange' | translate }}"
            placement="bottom"
            #addDatesPopover="ngbPopover"
            popoverClass="timeentries-tooltip-popover"
            triggers="manual"
          >
            <troi-range-date
              [rangeFrom]="fromDate"
              [rangeTo]="toDate"
              [minDate]="getMinDate()"
              [maxDate]="getMaxDate()"
              [hideRanges]="true"
              (rangeChanged)="updateRuntimeRange($event)"
              [settings]="{ format: settingsService.settings?.settings.dateFormat }"
              [placeholder]="'Tasks.labels.dateFormat' | translate"
              [emitIfEmpty]="false"
            ></troi-range-date>
          </div>

          <troi-task-modal-select-assignee
            [task]="subtask"
            [isSubtask]="true"
            (assignedEmployeesChanged)="onAssignedEmployeeChange()"
          ></troi-task-modal-select-assignee>

          <div class="edit-subtask__assigned-employee">
            <troi-task-modal-assigned-employee
              *ngFor="let assignee of subtask.assignees"
              [assignee]="assignee"
              [showProgressCircle]="task.startDate && task.endDate"
              [task]="subtask"
              [parentTask]="task"
              [isEmpty]="true"
              [subtaskLayout]="true"
              [isSubtask]="true"
              (assigneeToRemove)="removeAssignee($event)"
              (showPopover)="showAddTimerangePopover()"
            ></troi-task-modal-assigned-employee>
          </div>
        </div>
      </div>
      <div
        *ngIf="!task.project?.projectStatus?.blockedForBooking"
        class="edit-subtask__time-entry-container"
        #timeEntryPopover="ngbPopover"
        (click)="openPopover('timeentry')"
        triggers="manual"
        ngbPopover="{{ 'Tasks.labels.createTaskModal.addCalcPosition' | translate }}"
        placement="bottom"
        popoverClass="timeentries-tooltip-popover"
      >
        <span class="edit-subtask__subtitle">{{
          'Tasks.labels.createTaskModal.subtasks.timeTracking' | translate
        }}</span>
        <troi-task-modal-timeentries-content
          [task]="task"
          [subtask]="subtask"
          [timeEntries]="timeEntries"
          [timeEntriesSum]="timeEntriesSum"
          [singleComment]="true"
          [disabled]="!subtask.calculationPosition"
          [isScrollable]="false"
          (timeEntriesUpdated)="getTimeEntries()"
        ></troi-task-modal-timeentries-content>
      </div>
    </div>
  </div>
</div>
