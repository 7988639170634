import { Component, Input } from '@angular/core';
import { ApprovalsService } from '../../services/approvals.service';

@Component({
  selector: 'approval-status-icon',
  templateUrl: './approval-status-icon.component.html',
  styleUrls: ['./approval-status-icon.component.scss'],
})
export class ApprovalStatusIconComponent {
  @Input() approvalState: string;
  constructor(public approvalsService: ApprovalsService) {}
}
