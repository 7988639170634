import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { StorageNotificationService } from '../notifications/storageNotification.service';
import { BackendErrorInterface } from '../interfaces/backend.error.interface';
import { MultipleBackendMessageInterface, MultipleMessageInterface } from '../interfaces/multipleMessage.interface';
import { LanguagesService } from './languages.service';

@Injectable()
export class BackendErrorTranslationService {
  private generalTranslationKey = 'Common.error.general';

  constructor(
    private translateService: TranslateService,
    private languagesService: LanguagesService,
    private notificationService: StorageNotificationService,
  ) {}

  showAccessToActionDeniedError() {
    this.translateService.get('Common.error.access_to_action_denied').subscribe((msg) => {
      this.notificationService.showError(msg);
    });
  }

  showError(errors: BackendErrorInterface[]) {
    const messages = this.parseError(errors);
    _.head(messages).subscribe((message) => {
      this.notificationService.showError(message);
    });
  }

  parseError(errors: BackendErrorInterface[]): Observable<string>[] {
    const messageObservables = [];
    if (_.isEmpty(errors)) {
      throw new TypeError('Other error');
    }
    _.forEach(errors, (error: BackendErrorInterface) => {
      if (_.isUndefined(error.messages)) {
        throw new TypeError('No messages!');
      }
      _.forEach(error.messages, (message: string) => {
        messageObservables.push(
          this.translateService.get(message).pipe(
            map((translatedMessage) => {
              if (translatedMessage === message) {
                return this.translateService.instant(this.generalTranslationKey);
              }

              return translatedMessage;
            }),
          ),
        );
      });
    });

    return messageObservables;
  }

  getGeneralError(): Observable<string> {
    return this.translateService.get(this.generalTranslationKey);
  }

  parseErrors(errors: MultipleBackendMessageInterface[]): MultipleMessageInterface[] {
    const data = [];
    _.forEach(errors, (error: MultipleBackendMessageInterface) => {
      if (error.reason[0]) {
        data.push({
          name: this.languagesService.getLanguageValue(error.name),
          reason: this.translateService.instant(error.reason[0].messages[0]),
        });
      }
    });

    return data;
  }

  parseDeletingErrors(errors: MultipleMessageInterface[]): MultipleMessageInterface[] {
    const data = [];
    _.forEach(errors, (error: MultipleMessageInterface) => {
      data.push({
        name: typeof error.name === 'string' ? error.name : this.languagesService.getLanguageValue(error.name),
        reason: error.reason,
      });
    });

    return data;
  }

  showMultipleErrors(errors: MultipleBackendMessageInterface[]) {
    this.notificationService.showError('', this.parseErrors(errors));
  }

  showDeletingErrors(errors: MultipleMessageInterface[]) {
    this.notificationService.showError('', this.parseDeletingErrors(errors));
  }
}
