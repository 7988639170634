import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
  ComponentRef,
} from '@angular/core';

@Injectable()
export class DomService {
  public attachedComponents = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  public appendComponentTo(parentId: string, child: any, childConfig?: ChildConfig) {
    // Create a component reference from the component
    const childComponentRef = this.createNodeFromComponent(child);

    // Attach the config to the child (inputs and outputs)
    this.attachConfig(childConfig, childComponentRef);

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(childComponentRef.hostView);

    // Get DOM element from component
    const childDomElem = (childComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.getElementById(parentId).appendChild(childDomElem);
    this.attachedComponents[parentId] = childComponentRef;
  }

  public createNodeFromComponent(component): ComponentRef<unknown> {
    return this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
  }

  public removeComponent(key: string) {
    const component = this.attachedComponents[key];
    if (component) {
      this.appRef.detachView(component.hostView);
      component.destroy();
      this.attachedComponents[key] = null;
    }
  }

  private attachConfig = (config, componentRef) => {
    const _componentRef = componentRef;
    const { inputs } = config;
    const { outputs } = config;
    for (const key in inputs) {
      if (key) {
        _componentRef.instance[key] = inputs[key];
      }
    }
    for (const key in outputs) {
      if (key) {
        _componentRef.instance[key] = outputs[key];
      }
    }
  };
}

export interface ChildConfig {
  inputs: Record<string, unknown>;
  outputs: Record<string, unknown>;
}
