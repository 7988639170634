import { Injectable } from '@angular/core';
import { StorageKeys } from '../../../../../../core/storage/storage.keys';
import { StorageService } from '../../../../../../core/storage/storage.service';
import { SliderValues } from '../../../../../../shared/troi-slider/troi-slider.component';

@Injectable()
export class BudgedThresholdsService {
  private storageKey = StorageKeys.PROJECT_BUDGET_THRESHOLDS;
  public lowWarningPoint: number;
  public highWarningPoint: number;

  constructor(private storageService: StorageService) {
    this.setInitialThresholds();
  }

  public get thresholdObject(): SliderValues {
    return {
      lo: this.lowWarningPoint,
      hi: this.highWarningPoint,
    };
  }

  public setInitialThresholds(): void {
    const storage = this.storageService.getItem(this.storageKey);
    this.lowWarningPoint = storage ? storage.lo : 75;
    this.highWarningPoint = storage ? storage.hi : 100;
  }

  public saveThresholds(): void {
    this.storageService.setItem(this.storageKey, this.thresholdObject);
  }
}
