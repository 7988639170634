<div
  class="modal-row"
  [ngClass]="{ 'modal-row--disabled': disabled, 'modal-row--without-label': !withLabel }"
>
  <div *ngIf="withLabel" class="modal-row__label responsive-label">
    <ng-content select="[row-label]"></ng-content>
  </div>
  <div class="modal-row__value responsive-value">
    <ng-content select="[row-value]"></ng-content>
  </div>
</div>
