import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AssigneeInterface } from '../../../../../../../interfaces/assignee.interface';
import { ForecastMemberInterface, ForecastTeamInterface } from '../../../../../../../interfaces/forecast.interface';
import { TaskModel } from '../../../../../../../models/task.model';
import { TasksHelperService } from '../../../../../../../services/helper.service';
import { Subscription, finalize, forkJoin } from 'rxjs';
import { TasksService } from '../../../../../../../network/tasks.service';
import { PersonInterface } from '../../../../../../../../../shared/troi-person/interfaces/person.interface';
import { EmployeeResponseInterface, TeamResponseInterface } from '../../../../../../../interfaces/responses.interface';
import { TeamOptionInterface } from '../../../../../../../interfaces/teamOption.interface';

@Component({
  selector: 'troi-task-modal-select-assignee-dropdown',
  templateUrl: './select-assignee-dropdown.component.html',
  styleUrls: ['./select-assignee-dropdown.component.scss'],
})
export class SelectAssigneeDropdownComponent implements OnInit, OnDestroy {
  @Input() task: TaskModel;
  @Input() showDropdown = false;
  @Input() taskHasDate = false;
  @Input() assigneeSearch = '';
  @Input() showUtilization = true;

  @Output() assignedEmployee = new EventEmitter<AssigneeInterface>();
  @Output() assignedTeam = new EventEmitter<ForecastTeamInterface>();

  private subscriptions: Subscription = new Subscription();

  assignees: AssigneeInterface[] = [];
  teams: TeamOptionInterface[] = [];
  loading = true;

  constructor(public helperService: TasksHelperService, private tasksService: TasksService) {}

  ngOnInit() {
    this.helperService.projectId.subscribe((id) => {
      const projectId = id !== null ? +id : null;
      this.getAssigneeAndTeamOptions(projectId);
    });
  }

  private getAssigneeAndTeamOptions(projectId: number) {
    const assigneeOptions$ = this.tasksService.getAssigneeOptions(
      projectId ?? this.task.calculationPosition?.project.id,
      projectId ?? this.task.calculationPosition?.customer.mandant.id,
      this.getTimestamp('start'),
      this.getTimestamp('end'),
    );

    const teamOptions$ = this.tasksService.getTeamAssigneeOptions(
      projectId ?? this.task.calculationPosition?.project.id,
      this.getTimestamp('start'),
      this.getTimestamp('end'),
    );

    this.subscriptions.add(
      forkJoin({
        assigneeOptions: assigneeOptions$,
        teamOptions: teamOptions$,
      })
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe(({ assigneeOptions, teamOptions }) => {
          if (assigneeOptions.data) {
            this.assignees = assigneeOptions.data.map((emp: PersonInterface) => {
              return {
                employeeId: emp.id.toString(),
                user: emp,
                projectTask: +this.task.id,
                assignments: [],
                minutesTotal: 0,
                utilization: emp.utilization,
              } as AssigneeInterface;
            });
          }

          this.teams = teamOptions.data;
        }),
    );
  }

  private getTimestamp(type: string): number {
    if (type === 'start' && this.task.startDate) return new Date(this.task.startDate).getTime();
    if (type === 'end' && this.task.endDate) return new Date(this.task.endDate).getTime();

    return undefined;
  }

  public filterAssignees(): AssigneeInterface[] {
    const filteredAssignees = this.assignees.filter((assignee) => {
      return (
        assignee.user.firstName.toLowerCase().includes(this.assigneeSearch.toLowerCase()) ||
        assignee.user.lastName.toLowerCase().includes(this.assigneeSearch.toLowerCase())
      );
    });

    return filteredAssignees?.sort((a, b) => {
      return a.user.lastName.toLowerCase().localeCompare(b.user.lastName.toLowerCase());
    });
  }

  public filterTeams(): TeamOptionInterface[] {
    const matchingTeams: TeamOptionInterface[] = this.teams?.filter((team) =>
      team.employees.some(
        (employee) =>
          employee.firstName.toLowerCase().includes(this.assigneeSearch.toLowerCase()) ||
          employee.lastName.toLowerCase().includes(this.assigneeSearch.toLowerCase()),
      ),
    );

    return matchingTeams?.sort((a, b) => {
      return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
    });
  }

  public checkIfTeamIsAssigned(team: AssigneeInterface[]): boolean {
    for (const employee of team) {
      const foundAssignee = this.task.assignees?.find((a) => a.user.id === +employee.id);
      if (!foundAssignee) return true;
    }
    return false;
  }

  public assignTeam(team: ForecastTeamInterface): void {
    this.assignedTeam.emit(team);
  }
  public assignEmployee(assignee: AssigneeInterface) {
    this.assignedEmployee.emit(assignee);
  }

  public getTeamMembers(members: ForecastMemberInterface[]) {
    const teamMembers = members.map((m) => m.employee);
    return teamMembers;
  }

  public getAvatarSrc(assignee: AssigneeInterface): string {
    return !assignee.user['image'] ? `` : assignee.user.image;
  }

  public getInitials(assignee: AssigneeInterface): string {
    return assignee.user.firstName[0]?.toUpperCase() + assignee.user.lastName[0]?.toUpperCase();
  }

  public getUtilizationColor(utilization: number): string {
    if (utilization < 50) return 'green';
    else if (utilization >= 50 && utilization < 95) return 'orange';
    else if (utilization >= 95) return 'red';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
