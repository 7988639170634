import { Injectable } from '@angular/core';
import { StorageKeys } from '../../core/storage/storage.keys';
import { StorageService } from '../../core/storage/storage.service';
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(private storageService: StorageService) {}

  getColor(): string {
    const color = this.storageService.getItem(StorageKeys.THEMECOLOR);
    switch (color) {
      case null:
        return '#4fadf9';
      case 'grey':
        return '#7f8c8d';
      case 'blue':
        return '#4fadf9';
      case 'red':
        return '#e74c3c';
      case 'orange':
        return '#e67e22';
      case 'green':
        return '#27ae60';
      default:
        return '#4fadf9';
    }
  }

  getLightColor(color: string): string {
    switch (color) {
      case '':
        return 'rgba(46, 135, 186, 0.1)';
      case '#7f8c8d':
        return 'rgba(153, 182, 201, 0.1)';
      case '#4fadf9':
        return 'rgba(46, 135, 186, 0.1)';
      case '#e74c3c':
        return 'rgba(227, 161, 154, 0.1)';
      case '#e67e22':
        return 'rgba(199, 159, 123, 0.1)';
      case '#27ae60':
        return 'rgba(116, 219, 160, 0.1)';
      default:
        return 'rgba(46, 135, 186, 0.1)';
    }
  }

  getFullDarkColor_overview(color: string): string {
    switch (color) {
      case '':
        return 'rgba(46, 135, 186, 0.75)';
      case '#7f8c8d':
        return 'rgba(109, 126, 137, 0.75)';
      case '#4fadf9':
        return 'rgba(46, 135, 186, 0.75)';
      case '#e74c3c':
        return 'rgba(196, 65, 51, 0.75)';
      case '#e67e22':
        return 'rgba(198, 120, 26, 0.75)';
      case '#27ae60':
        return 'rgba(37, 135, 86, 0.75)';
      default:
        return 'rgba(46, 135, 186, 0.75)';
    }
  }

  getDarkColor_overview(color: string): string {
    switch (color) {
      case '':
        return 'rgba(62, 175, 233, 0.70)';
      case '#7f8c8d':
        return 'rgba(109, 126, 137, 0.70)';
      case '#4fadf9':
        return 'rgba(62, 175, 233, 0.70)';
      case '#e74c3c':
        return 'rgba(196, 65, 51, 0.70)';
      case '#e67e22':
        return 'rgba(198, 120, 26, 0.70)';
      case '#27ae60':
        return 'rgba(37, 135, 86, 0.70)';
      default:
        return 'rgba(62, 175, 233, 0.70)';
    }
  }

  getBGColorTimeSheets(): string {
    const color = this.storageService.getItem(StorageKeys.THEMECOLOR);
    switch (color) {
      case null:
        return 'rgba(79, 173, 249, 0.5)';
      case 'grey':
        return 'rgba(127, 140, 141, 0.5)';
      case 'blue':
        return 'rgba(79, 173, 249, 0.5)';
      case 'red':
        return 'rgba(231, 76, 60, 0.5)';
      case 'orange':
        return 'rgba(230, 126, 34, 0.5)';
      case 'green':
        return 'rgba(39, 174, 96, 0.5)';
      default:
        return 'rgba(79, 173, 249, 0.5)';
    }
  }

  getColorProjectTime(): string {
    const color = this.storageService.getItem(StorageKeys.THEMECOLOR);

    switch (color) {
      case null:
        return 'rgba(46, 135, 186, 1)';
      case 'grey':
        return 'rgba(127, 140, 141, 1)';
      case 'blue':
        return 'rgba(46, 135, 186, 1)';
      case 'red':
        return 'rgba(231, 76, 60, 1)';
      case 'orange':
        return 'rgba(230, 126, 34, 1)';
      case 'green':
        return 'rgba(39, 174, 96, 1)';
      default:
        return 'rgba(46, 135, 186, 1)';
    }
  }
}
