export enum Routes {
  BOOKINGS_SELECTED = '/components/booking/booking-receipt/dropdown-selected',
  BOOKING = '/components/booking/booking-receipt',
  BOOKING_ASSIGN_FILES = '/components/booking/files/assign-to-booking-receipt',

  PROJECTS_BY_K_NUMBER = '/components/booking/calculation-position/search',
  PROJECTS_BY_ORDER_NUMBER = '/components/booking/calculation-position/search-by-order-number',
  PROJECTS_BY_CUSTOMER = '/components/booking/customer/search',
  PROJECTS_BY_PROJECT_NAME = '/components/booking/project/search',
  SUBPROJECTS_BY_CUSTOMER = '/components/booking/subprojects',

  PROJECT_STATUS_CHANGE = '/components/booking/subproject/reactivate',

  CALCULATION_POSITION = '/components/booking/calculation-position',

  NEXT_INT_NUMBER = '/components/booking/internal-number/next-number',
}
