import * as _ from 'lodash';
import { StorageService } from '../../core/storage/storage.service';

export class LocalStorageStatesService {
  public _statesLSKey = '';

  public _savedIds: string[];

  constructor(public storageService: StorageService) {}

  get statesLSKey(): string {
    return this._statesLSKey;
  }

  set statesLSKey(value: string) {
    this._statesLSKey = value;
  }

  get savedIds() {
    return this._savedIds;
  }

  set savedIds(ids: string[]) {
    this._savedIds = _.uniq(ids);
    this.storageService.setItem(this.statesLSKey, ids);
  }

  public fetchSavedIdsFromLS(): string[] {
    const ids = this.storageService.getItem(this.statesLSKey);

    return ids || [];
  }

  public isSaved(id: string): boolean {
    return this.savedIds.includes(id);
  }

  public addSavedId(id: string): void {
    const ids = this.savedIds;
    ids.push(id);
    this.savedIds = ids;
  }

  public removeSavedId(idToRemove: string): void {
    this.savedIds = _.filter(this.savedIds, (id: string) => id !== idToRemove);
  }

  public clearSavedIds(): void {
    this.savedIds = [];
  }
}
