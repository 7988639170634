<div class="my_project" (click)="$event.stopPropagation()">
  <div class="my_project__heading px-4 py-3">
    <div class="d-flex justify-content-between">
      <h3>{{ 'Timerecording.My_projects' | translate }}</h3>
      <troi-icon-button icon="icon-close" (click)="timeService.toggleMyProjectsVisibility()"></troi-icon-button>
    </div>
  </div>
  <div class="my_project__filter px-4 py-2">
    <div class="row">
      <div class="col-4">
        <troi-filter class="filters__filter filters__filter--search">
          <troi-icon icon="icon-loop-with-arrow-down"></troi-icon>
          <input
            type="text"
            name="search"
            autocomplete="off"
            (keyup)="searchInput.next($event)"
            [(ngModel)]="filters.search"
            placeholder="{{ 'Timerecording.Search' | translate }}"
          />
        </troi-filter>
      </div>
      <div class="col-4">
        <troi-my-projects-filter
          (filtersApplied)="filtersApplied($event)"
          (filtersReset)="emitFiltersReset()"
          [applyFiltersDataResend]="filters"
        >
        </troi-my-projects-filter>
      </div>
      <div class="col-4"></div>
    </div>
  </div>
  <div class="scroll-container my_project__content px-4" id="endOfContent" (scroll)="onScroll()">
    <!-- class="position-absolute top-50 start-50 translate-middle" -->
    <div *ngIf="LogData.length > 0; else noResults">
      <div class="mt-2 p-2 my_project__content__box" *ngFor="let project of LogData; let i = index; let isLast = last">
        <div class="row align-items-center" (click)="cloneData ? AddnewProjectItem(project) : null">
          <div class="col-12 col-lg-1 col-xl-1 no-vertical-center p-0 ps-2">
            <troi-icon-button
              [icon]="project.cp.cpIsFavorite ? 'icon-black-star' : 'icon-star'"
              (click)="onFavClick(project, i)"
            ></troi-icon-button>
          </div>
          <div class="col-12 col-lg-4 col-xl-4 grow-1 sideBarOpen">
            <div class="d-flex flex-nowrap">
              <div class="">
                <span class="heading">{{ project.cp.cpPathHeader }}</span>
              </div>
              <div class="grow-1" *ngIf="isDisplayDebitActual">
                <div class="d-flex flex-nowrap">
                  <div class="grow-1">
                    <div class="d-flex flex-nowrap progress_wrapper justify-content-end">
                      <div class="progress_bar grow-1 mt-2">
                        <div class="progress">
                          <div
                            class="progress-bar"
                            role="progressbar"
                            [ngClass]="{ 'red-background': project.cp.percentDisplay > 100 }"
                            [ngStyle]="{ width: project.cp.percentDisplay + '%' }"
                            aria-valuenow="{{ project.cp.percentDisplay }}"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="progress_number">
                        <small>{{ project.cp.percentDisplay | number : '1.2-2' }} %</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p class="content" [innerHTML]="project.cp.cpPathTail"></p>

            <div class="d-flex justify-content-between">
              <p class="contentId">K-{{ project.cp.cpId }}</p>
              <div class="my_pro_hoverbox" *ngIf="!cloneData">
                <div class="d-flex justify-content-end">
                  <ng-container>
                    <troi-icon-button icon="icon-timer" (click)="toggleStopwatchVisibility(project)"></troi-icon-button>
                    <ng-container *ngIf="isEditable">
                      <troi-icon-button
                        [icon]="project.cp.editMode ? 'icon-minus' : 'icon-plus'"
                        (click)="toggleEditMode(i)"
                      ></troi-icon-button>
                      <troi-icon-button
                        icon="icon-arrow-head-right"
                        (click)="AddnewProjectItem(project)"
                      ></troi-icon-button>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="fillupForm mt-2" *ngIf="project.cp.editMode">
              <!-- if neither or both fields are enabled -->
              <ng-container
                *ngIf="
                  (!isEnableTimerecordingAdditionalFields && !isRestExpenseEstimationActive) ||
                  (isEnableTimerecordingAdditionalFields && isRestExpenseEstimationActive)
                "
              >
                <div class="row formHead">
                  <div class="col-5">{{ 'Timerecording.Tracked' | translate }}</div>
                  <div class="col-5">{{ 'Timerecording.date' | translate }}</div>
                  <div class="col-2">{{ 'Timerecording.NB' | translate }}</div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <ng-container *ngTemplateOutlet="hoursInput; context: { project, i }"></ng-container>
                  </div>
                  <div class="col-5">
                    <ng-container *ngTemplateOutlet="datepicker"></ng-container>
                  </div>
                  <div class="col-2">
                    <ng-container *ngTemplateOutlet="billableCheckbox; context: { project, i }"></ng-container>
                  </div>
                </div>

                <div
                  class="row formHead"
                  *ngIf="isEnableTimerecordingAdditionalFields && isRestExpenseEstimationActive"
                >
                  <div class="col-5" *ngIf="isEnableTimerecordingAdditionalFields">
                    {{ 'Timerecording.Actual_Hours' | translate }}
                  </div>
                  <div class="col-5" *ngIf="isRestExpenseEstimationActive">
                    {{ 'Timerecording.Rest_Estimation' | translate }}
                  </div>
                  <div class="col-2"></div>
                </div>
                <div class="row">
                  <div class="col-5" *ngIf="isEnableTimerecordingAdditionalFields">
                    <ng-container *ngTemplateOutlet="actualHoursInput; context: { project, i }"></ng-container>
                  </div>
                  <div class="col-5" *ngIf="isRestExpenseEstimationActive">
                    <ng-container *ngTemplateOutlet="estimationInput; context: { project, i }"></ng-container>
                  </div>
                  <div class="col-2"></div>
                </div>
              </ng-container>

              <!-- if either but not both are true -->
              <ng-container
                *ngIf="
                  (isEnableTimerecordingAdditionalFields || isRestExpenseEstimationActive) &&
                  !(isEnableTimerecordingAdditionalFields && isRestExpenseEstimationActive)
                "
              >
                <div class="row formHead">
                  <div class="col-3">
                    {{ 'Timerecording.Tracked' | translate }}
                  </div>
                  <div class="col-3" *ngIf="isEnableTimerecordingAdditionalFields">
                    {{ 'Timerecording.Actual_Hours' | translate }}
                  </div>
                  <div class="col-3" *ngIf="isRestExpenseEstimationActive">
                    {{ 'Timerecording.Rest_Estimation' | translate }}
                  </div>
                  <div class="col-4">
                    {{ 'Timerecording.date' | translate }}
                  </div>
                  <div class="col-2">{{ 'Timerecording.NB' | translate }}</div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <ng-container *ngTemplateOutlet="hoursInput; context: { project, i }"></ng-container>
                  </div>
                  <div class="col-3" *ngIf="isEnableTimerecordingAdditionalFields">
                    <ng-container *ngTemplateOutlet="actualHoursInput; context: { project, i }"></ng-container>
                  </div>
                  <div class="col-3" *ngIf="isRestExpenseEstimationActive">
                    <ng-container *ngTemplateOutlet="estimationInput; context: { project, i }"></ng-container>
                  </div>
                  <div class="col-4">
                    <ng-container *ngTemplateOutlet="datepicker"></ng-container>
                  </div>
                  <div class="col-2">
                    <ng-container *ngTemplateOutlet="billableCheckbox; context: { project, i }"></ng-container>
                  </div>
                </div>
              </ng-container>

              <div class="row commentDiv pb-2">
                <div class="col-10" *ngIf="timeService.isBillingInternalCommentVisibility()">
                  <input
                    type="text"
                    [(ngModel)]="project.billing.billingInternalComment"
                    name="internalcomment"
                    [placeholder]="
                      isTimeRecordingInternalCommentRequired
                        ? ('Timerecording.Internal_Comment_Required' | translate)
                        : ('Timerecording.Internal_Comment' | translate)
                    "
                    (keyup.enter)="saveData(project)"
                    [class.red-border]="
                      project.cp.internalCommentError && !project.billing.billingInternalComment.trim()
                    "
                    autocomplete="off"
                  />
                  <span
                    *ngIf="
                      project.cp.editMode &&
                      project.cp.internalCommentError &&
                      !project.billing.billingInternalComment.trim()
                    "
                    class="red-text"
                    >{{ 'Timerecording.Please_enter_internal_comment' | translate }}</span
                  >
                </div>
                <div class="col-10">
                  <input
                    type="text"
                    [(ngModel)]="project.billing.billingExternalComment"
                    name="externalcomment"
                    [placeholder]="
                      isTimeRecordingExternalCommentRequired
                        ? ('Timerecording.External_Comment_Required' | translate)
                        : ('Timerecording.External_Comment' | translate)
                    "
                    (keyup.enter)="saveData(project)"
                    [class.red-border]="
                      project.cp.externalCommentError && !project.billing.billingExternalComment.trim()
                    "
                    autocomplete="off"
                  />
                  <span
                    *ngIf="
                      project.cp.editMode &&
                      project.cp.externalCommentError &&
                      !project.billing.billingExternalComment.trim()
                    "
                    class="red-text"
                    >{{ 'Timerecording.Please_enter_external_comment' | translate }}</span
                  >
                </div>
                <div
                  class="col-2 p-0 pe-2 ps-2"
                  style="display: flex; align-items: flex-end; justify-content: flex-start"
                >
                  <troi-icon-button
                    title="{{ 'Booking.labels.save' | translate }}"
                    icon="icon-save"
                    (click)="saveData(project)"
                  ></troi-icon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="align-items-center" style="position: relative">
      <troi-loading-spinner *ngIf="loading"></troi-loading-spinner>
    </div>

    <ng-template #noResults>
      <div class="mt-2 p-3 text-center">
        {{ 'Timerecording.No_results_found' | translate }}
      </div>
    </ng-template>
  </div>
</div>

<!-- billable datepicker -->
<ng-template #datepicker>
  <tnd-datepicker
    [fieldInvalid]="false"
    [validationEnabled]="true"
    [rangeFrom]="fromDate"
    [rangeTo]="toDate"
    [singleDatePicker]="true"
    [emitIfEmpty]="true"
    [disabled]="false"
    (rangeChanged)="dateChanged($event)"
    [requiredFieldErrorMessage]="'Booking.error.receiptDateIsRequired'"
    [hideRanges]="true"
    ngClass="tnd-create-event-datepicker"
  >
  </tnd-datepicker>
</ng-template>

<!-- billable checkbox template, need to pass project and index -->
<ng-template #billableCheckbox let-project="project" let-i="index">
  <input
    style="margin-top: 13px"
    type="checkbox"
    [ngModel]="!project.billing.billingIsBillable"
    (ngModelChange)="project.billing.billingIsBillable = !$event"
    (change)="getValueInputField(project, 'checkbox', i)"
    [disabled]="timeService.isHideBillableCheckboxInTimeRecording()"
  />
</ng-template>

<ng-template #estimationInput let-project="project" let-i="index">
  <div class="d-flex">
    <troi-icon
      icon="troi-menu-timerecording"
      style="margin-top: 14px !important; align-items: flex-start !important"
    ></troi-icon>
    <div class="ms-2">
      <troi-time-input
        [(ngModel)]="project.billing.billingEstimation"
        (keyup.enter)="onEnterUp($event, project, 'time', i)"
        id="estimation"
        [placeholder]="'00:00'"
      ></troi-time-input>
    </div>
  </div>
</ng-template>

<ng-template #actualHoursInput let-project="project" let-i="index">
  <div class="d-flex">
    <troi-icon
      icon="troi-menu-timerecording"
      style="margin-top: 14px !important; align-items: flex-start !important"
    ></troi-icon>
    <div class="ms-2">
      <troi-time-input
        [(ngModel)]="project.billing.billingActualHours"
        (keyup.enter)="onEnterUp($event, project, 'time', i)"
        id="actualHours"
        [placeholder]="'00:00'"
      ></troi-time-input>
    </div>
  </div>
</ng-template>

<ng-template #hoursInput let-project="project" let-i="index">
  <div class="d-flex">
    <troi-icon
      icon="troi-menu-timerecording"
      style="margin-top: 14px !important; align-items: flex-start !important"
    ></troi-icon>
    <div class="mx-2">
      <troi-time-input
        [(ngModel)]="project.billing.billingQuantityTime"
        (keyup.enter)="onEnterUp($event, project, 'time', i)"
        id="time-picker"
        #timePicker
        [placeholder]="'00:00'"
        [comment]="project.billing.billingExternalComment"
        (commentChange)="project.billing.billingExternalComment = $event"
      ></troi-time-input>
      <span *ngIf="project.cp.editMode && project.cp.timeError" class="red-text">{{
        'Timerecording.Please_enter_time' | translate
      }}</span>
    </div>
  </div>
</ng-template>
