import { COMPILER_OPTIONS, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Routes } from '../enums/routes';
import {
  BookingCP,
  BookingReceiptFullInterface,
  BookingReceiptFullRequestInterface,
} from '../interfaces/booking-receipt-full.interface';
import {
  CPOptionInterface,
  ProjectOptionInterface,
  SubprojectOptionInterface,
} from '../interfaces/project-list.interface';
import {
  BackendResponseDropdownDataInterface,
  BackendResponseInterface,
  ResponseStatusInterface,
} from '../../../../core/interfaces/backend.response.interface';
import { BookingsCommonNetwork } from './bookings-common.network';
import { NextIntNumberInterface } from '../interfaces/next-int-number.interface';

@Injectable()
export class BookingFormNetwork extends BookingsCommonNetwork {
  public constructor(public http: HttpClient) {
    super(http);
  }

  create(data: BookingReceiptFullRequestInterface): Observable<BackendResponseInterface<BookingReceiptFullInterface>> {
    return this.http.post<BackendResponseInterface<BookingReceiptFullInterface>>(
      environment.url + Routes.BOOKING,
      data,
    );
  }

  update(data: BookingReceiptFullRequestInterface): Observable<BackendResponseInterface<BookingReceiptFullInterface>> {
    return this.http.put<BackendResponseInterface<BookingReceiptFullInterface>>(
      `${environment.url + Routes.BOOKING}/${data.id}`,
      data,
    );
  }

  get(id: string): Observable<BackendResponseInterface<BookingReceiptFullInterface>> {
    return this.http.get<BackendResponseInterface<BookingReceiptFullInterface>>(
      `${environment.url + Routes.BOOKING}/${id}`,
    );
  }

  fetchProjectByCustomerList(
    searchPhrase: string,
    client: number,
    page: number,
    pageSize: number,
    lang = 'de',
    subPageSize = 5,
  ): Observable<BackendResponseDropdownDataInterface<ProjectOptionInterface[]>> {
    const params = new HttpParams({
      fromObject: {
        client: client.toString(),
        searchPhrase,
        page: page.toString(),
        pageSize: pageSize.toString(),
        subPageSize: subPageSize.toString(),
        lang,
      },
    });
    return this.http.get<BackendResponseDropdownDataInterface<ProjectOptionInterface[]>>(
      environment.url + Routes.PROJECTS_BY_CUSTOMER,
      { params },
    );
  }

  fetchProjectByProjectName(
    searchPhrase: string,
    client: number,
    page: number,
    pageSize: number,
    lang = 'de',
    subPageSize = 5,
  ): Observable<BackendResponseDropdownDataInterface<ProjectOptionInterface[]>> {
    const params = new HttpParams({
      fromObject: {
        client: client.toString(),
        searchPhrase,
        page: page.toString(),
        pageSize: pageSize.toString(),
        subPageSize: subPageSize.toString(),
        lang,
      },
    });

    return this.http
      .get<BackendResponseDropdownDataInterface<ProjectOptionInterface[]>>(
        environment.url + Routes.PROJECTS_BY_PROJECT_NAME,
        { params },
      )
      .pipe(
        map((response) => {
          response.items.forEach((item) => {
            item.subProjects = item.subProjects.filter((subProject) => !subProject.deleted);
          });
          return response;
        }),
      );
  }

  fetchSubprojectList(
    project: number,
    page: number,
    pageSize: number,
  ): Observable<BackendResponseDropdownDataInterface<SubprojectOptionInterface[]>> {
    const params = new HttpParams({
      fromObject: {
        project: project.toString(),
        page: page.toString(),
        pageSize: pageSize.toString(),
      },
    });
    return this.http.get<BackendResponseDropdownDataInterface<SubprojectOptionInterface[]>>(
      environment.url + Routes.SUBPROJECTS_BY_CUSTOMER,
      { params },
    );
  }

  fetchCpList(
    searchPhrase: string,
    client: number,
    page: number,
    pageSize: number,
  ): Observable<BackendResponseDropdownDataInterface<CPOptionInterface[]>> {
    const params = new HttpParams({
      fromObject: {
        client: client.toString(),
        cpNumber: searchPhrase,
        page: page.toString(),
        pageSize: pageSize.toString(),
      },
    });
    return this.http.get<BackendResponseDropdownDataInterface<CPOptionInterface[]>>(
      environment.url + Routes.PROJECTS_BY_K_NUMBER,
      { params },
    );
  }

  fetchCPListByOrderNumber(
    searchPhrase: string,
    client: number,
    page: number,
    pageSize: number,
  ): Observable<BackendResponseDropdownDataInterface<CPOptionInterface[]>> {
    const params = new HttpParams({
      fromObject: {
        client: client.toString(),
        orderNumber: searchPhrase,
        page: page.toString(),
        pageSize: pageSize.toString(),
      },
    });

    return this.http.get<BackendResponseDropdownDataInterface<CPOptionInterface[]>>(
      environment.url + Routes.PROJECTS_BY_ORDER_NUMBER,
      { params },
    );
  }

  changeProjectStatus(
    subproject: number,
    status: number,
  ): Observable<BackendResponseInterface<ResponseStatusInterface>> {
    return this.http.put<BackendResponseInterface<ResponseStatusInterface>>(
      environment.url + Routes.PROJECT_STATUS_CHANGE,
      {
        subProject: subproject,
        projectStatus: status,
      },
    );
  }

  createCP(
    client: number,
    customer: string,
    project: number,
    subProject: number,
    name: string,
    unit: number,
    tempId: string,
  ): Observable<BackendResponseInterface<BookingCP>> {
    return this.http.post<BackendResponseInterface<BookingCP>>(environment.url + Routes.CALCULATION_POSITION, {
      subproject: subProject,
      project,
      customer,
      client,
      name,
      tempId,
      unit,
    });
  }

  fetchNextBookingIntNumber(
    client: number,
    year: number,
  ): Observable<BackendResponseInterface<NextIntNumberInterface>> {
    const params = new HttpParams({
      fromObject: {
        client: client.toString(),
        year: year.toString(),
      },
    });
    return this.http.get<BackendResponseInterface<NextIntNumberInterface>>(environment.url + Routes.NEXT_INT_NUMBER, {
      params,
    });
  }
}
