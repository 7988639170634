import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HeaderNotificationModule } from '../header-notification/headerNotification.module';
import { SharedModule } from '../../shared/shared.module';
import { SecurityCenterService } from './security-center/security-center.service';
import { SecurityCenterComponent } from './security-center/security-center.component';

const appRoutes: Routes = [
  {
    path: 'security-center',
    component: SecurityCenterComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes),
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderNotificationModule,
    TranslateModule,
    SharedModule,
  ],
  declarations: [SecurityCenterComponent],
  bootstrap: [],
  providers: [SecurityCenterService],
  exports: [RouterModule],
})
export class SettingsModule {}
