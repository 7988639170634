import { Component, Input } from '@angular/core';

@Component({
  selector: 'menu-core-icon',
  templateUrl: './menu-core-icon.component.html',
  styleUrls: ['./menu-core-icon.component.scss'],
})
export class MenuCoreIconComponent {
  @Input() open: boolean;
}
