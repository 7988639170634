import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from '../../../../../shared/troi-modals/modal.service';
import { CalculationPositionInterface } from '../../../interfaces/calculationPosition.interface';
import { SubprojectDataInterface } from '../../../interfaces/subproject.interface';
import { TaskModel } from '../../../models/task.model';
import { ProjectService } from '../../../network/project.service';
import { TasksService } from '../../../network/tasks.service';
import { TaskFiltersService } from '../../../services/filters.service';
import { TasksHelperService } from '../../../services/helper.service';

@Component({
  selector: 'troi-import-calc-pos-modal',
  templateUrl: './import-calc-pos-modal.component.html',
  styleUrls: ['./import-calc-pos-modal.component.scss'],
})
export class ImportCalcPosModalComponent implements OnInit, OnDestroy {
  public subprojects: SubprojectDataInterface[] = [];
  private subscriptions: Subscription = new Subscription();
  public task: TaskModel = {} as TaskModel;

  public isImportCancelled = false;
  public cpsToImport: CalculationPositionInterface[] = [];
  public occupiedCPs: CalculationPositionInterface[] = [];
  public tasksCreated = [];
  public importingCPs = false;
  public loaded = false;
  public progressValue = 0;

  private projectId: string;

  private header: HTMLElement | null = window.top.document.querySelector('#appHeader');
  private subheader: HTMLElement | null = window.top.document.querySelector('#projectNaviPanel');

  constructor(
    private projectSerivce: ProjectService,
    private tasksService: TasksService,
    public modalService: ModalService,
    private filtersService: TaskFiltersService,
    private helperSerivce: TasksHelperService,
  ) {
    this.helperSerivce.projectId.subscribe((id: string) => {
      if (id) {
        this.projectId = id;
        this.occupiedCPs = this.tasksService.tasks?.map((task) => task.calculationPosition || null).filter((cp) => cp);
        this.getSubprojects();
      }
    });
  }

  ngOnInit(): void {
    // disables header and subheader to let modal take whole page

    if (this.filtersService.isProjectContext) {
      this.header.style.display = 'none';
      this.subheader.style.display = 'none';
    }
  }

  private getSubprojects() {
    this.projectSerivce.getSubprojectsWithCP(this.projectId).subscribe((res) => {
      this.subprojects = res.data;
      this.subprojects.forEach((subproject) => {
        subproject.cps.forEach((cp) => {
          if (!this.isCPDisabled(cp)) {
            this.cpsToImport.push(cp);
          }
        });
      });
      this.loaded = true;
    });
  }

  public checkboxChange(event) {
    if (event.value && !this.cpsToImport.includes(event.calcPos)) this.cpsToImport.push(event.calcPos);
    else if (!event.value) this.cpsToImport = this.cpsToImport.filter((cp) => cp !== event.calcPos);
  }

  public deleteAllCP() {
    if (!this.importingCPs) this.cpsToImport = [];
  }

  public onModalClose() {
    this.modalService.destroy();
  }

  public onSave() {
    if (this.cpsToImport.length) {
      this.importingCPs = true;
      this.createTasks();
    }
  }

  public createTasks() {
    this.createTaskRecursively(0);
  }

  private createTaskRecursively(index: number) {
    if (this.isImportCancelled) {
      this.importingCPs = false;
      this.removeTasks();
      return;
    } else if (!this.isImportCancelled && index === this.cpsToImport.length) {
      this.navigateToKanbanview();
      return;
    }

    const cp = this.cpsToImport[index];
    this.task.title = cp.serviceName;
    this.task.status = this.tasksService.statusOptions[0];

    this.task.id =
      this.tasksService.tasks.length > 0 ? this.tasksService.tasks[this.tasksService.tasks.length - 1].id + 1 : '1000';
    this.task.clientId = this.getClient().toString();
    this.task.priority = 0;
    this.task.calculationPosition = cp;
    this.task.project = {
      id: +this.projectId,
    };

    this.tasksService.createTask(this.task).subscribe({
      next: (res) => {
        const taskCreated = res.data[0];

        // updates projectpath and project number of created task
        taskCreated.project.number = `${taskCreated.calculationPosition.project.number}`;
        this.tasksService.updateTask(taskCreated).subscribe();

        this.tasksCreated.push(taskCreated);
        this.progressValue = (this.tasksCreated.length / this.cpsToImport.length) * 100;
        this.createTaskRecursively(index + 1);
      },
      error: () => {
        this.removeTasks();
      },
    });
  }

  private isCPDisabled(cp: CalculationPositionInterface): boolean {
    return this.occupiedCPs.some((occupiedCP) => occupiedCP.id === cp.id);
  }

  public removeTasks() {
    this.tasksCreated.forEach((createdTask) => {
      this.tasksService.deleteTask(createdTask).subscribe((res) => {
        const deletedTask = res.data[0];
        this.tasksCreated = this.tasksCreated.filter((task) => task.id !== deletedTask.id);
      });
    });
    this.importingCPs = false;
    this.isImportCancelled = false;
    this.progressValue = 0;
  }

  public navigateToKanbanview() {
    this.filtersService.projecttaskview = 'kanban';
    const filters = this.filtersService.putFiltersInUrl();
    const params = filters.split('?')[1];
    const newUrl = `/site/index.php?page=project_task_list&${params}`;
    window.parent.window.location.href = newUrl;
  }

  public cancelImport() {
    this.isImportCancelled = true;
  }

  public getClient(): number {
    return this.filtersService.getSelectedClientId() ?? this.filtersService.getDefaultClientId();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.filtersService.isProjectContext && this.header && this.subheader) {
      this.header.style.display = 'flex';
      this.subheader.style.display = 'flex';
    }
  }
}
