import { Injectable } from '@angular/core';
import { CalculationPositionInterface } from '../interfaces/calculationPosition.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ProjectListNetwork } from '../../project-list/network/project-list.network';
import { LanguagesService } from '../../../core/services/languages.service';
import { InvoiceAssignNumberModel } from '../../project-list/models/invoice-assign-number.model';
import { environment } from '../../../../environments/environment';
import { Routes } from '../enum/routes';

import { MandantInterface } from '../interfaces/mandant.interface';
import { ProjectInterface } from '../interfaces/project.interface';
import { ClientInterface } from '../interfaces/client.interface';
import { CalcPosResponseInterface, SubprojectResponseInterface } from '../interfaces/responses.interface';

@Injectable()
export class CalcPositionService {
  private headers: HttpHeaders;

  private _selectedCalcPosition: BehaviorSubject<CalculationPositionInterface> =
    new BehaviorSubject<CalculationPositionInterface>(null);

  constructor(private http: HttpClient, public languagesService: LanguagesService) {}

  public setSelectedCalcPosition(value: CalculationPositionInterface): void {
    this._selectedCalcPosition.next(value);
  }

  public getSelectedCalcPosition(): BehaviorSubject<CalculationPositionInterface> {
    return this._selectedCalcPosition;
  }

  public searchCustomers(term: string, clientId: string = ''): Observable<ClientInterface[]> {
    const search: string = term.trim().length > 0 ? term.trim() : '*';
    const params: Array<string> = ['search=' + encodeURIComponent(search)];

    if (clientId.length) {
      params.push('clientId=' + encodeURIComponent(clientId));
    }

    return this.http.get(Routes.CUSTOMER + '?' + params.join('&')).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  public searchClients(term: string): Observable<MandantInterface[]> {
    const search: string = term.trim().length > 0 ? term.trim() : '*';

    return this.http.get<ClientInterface[]>(Routes.CLIENT + '?search=' + search).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  public searchProjects(term: string, clientId: string = '', customerId: string = ''): Observable<ProjectInterface[]> {
    const search: string = term.trim().length > 0 ? term.trim() : '*';
    const params: Array<string> = ['search=' + encodeURIComponent(search)];

    if (clientId.length) {
      params.push('clientId=' + encodeURIComponent(clientId));
    }
    if (customerId.length) {
      params.push('customerId=' + encodeURIComponent(customerId));
    }

    return this.http.get<ProjectInterface[]>(Routes.PROJECT + '?' + params.join('&')).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  public fetchSubprojectList(projectId: string = ''): Observable<SubprojectResponseInterface> {
    return this.http.get<SubprojectResponseInterface>(environment.url + Routes.SUBPROJECTS + '?project=' + projectId, {
      headers: this.headers,
    });
  }

  public getCalcPositions(
    clientId: string = '',
    customerId: string = '',
    projectId: string = '',
    subprojectId: string = '',
  ): Observable<CalcPosResponseInterface> {
    const params: Array<string> = [];
    if (clientId.length) {
      params.push('clientId=' + encodeURIComponent(clientId));
    }
    if (customerId.length) {
      params.push('customerId=' + encodeURIComponent(customerId));
    }
    if (projectId.length) {
      params.push('projectId=' + encodeURIComponent(projectId));
    }
    if (subprojectId.length) {
      params.push('subprojectId=' + encodeURIComponent(subprojectId));
    }

    return this.http.get<CalcPosResponseInterface>(environment.url + Routes.CALCPOS + '?' + params.join('&'), {
      headers: this.headers,
    });
  }
}
