import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  SimpleChanges,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { TasksHelperService } from '../../../../../../../services/helper.service';
import { SubtaskInterface } from '../../../../../../../interfaces/subtask.interface';
import { TaskInterface } from '../../../../../../../interfaces/task.interface';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TroiDropdownListModel } from '../../../../../../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { TaskActionsEnum } from '../../../../../../../enum/task-actions';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../../../../../../../shared/troi-modals/modal.service';
import { TroiUserConfirmationComponent } from '../../../../../../../../../shared/troi-user-confirmation/troi-user-confirmation.component';
import { UserConfirmationEventEnum } from '../../../../../../../../../shared/troi-user-confirmation/user-confirmation-event.enum';
import { UserConfirmationSubscriber } from '../../../../../../../../../shared/troi-user-confirmation/user-confirmation.subscriber';
import { DomService } from '../../../../../../../../../shared/troi-modals/dom.service';
import { Subscription, first } from 'rxjs';
import { SubtaskService } from '../../../../../../../network/subtask.service';
import { AssigneeInterface } from '../../../../../../../interfaces/assignee.interface';
import { TimeentryService } from '../../../../network/timeentry.service';
import { TimeEntryOverviewInterface } from '../../../../../../../interfaces/timeEntry.interface';
import { TimeEntryHelperResponseInterface } from '../../../../../../../interfaces/responses.interface';
import { TasksService } from '../../../../../../../network/tasks.service';
import { TaskModel } from '../../../../../../../models/task.model';
import { TasksSettingsService } from '../../../../../../../services/tasks-settings.service';

@Component({
  selector: 'troi-task-edit-subtask',
  templateUrl: './edit-subtask.component.html',
  styleUrls: ['./edit-subtask.component.scss'],
})
export class EditSubtaskComponent implements OnInit, OnDestroy {
  @ViewChild('resourcePopover') resourcePopover: NgbPopover;
  @ViewChild('timeEntryPopover') timeEntryPopover: NgbPopover;
  @ViewChild('addDatesPopover') addDatesPopover: NgbPopover;
  @ViewChild('timePicker') timePicker: ElementRef<HTMLInputElement>;
  @ViewChild('deletePopover') deletePopover: NgbPopover;

  @Input() task: TaskModel;
  @Output() showEditSubtaskContent = new EventEmitter<boolean>();

  public subtask: SubtaskInterface;
  public timeEntries: TimeEntryOverviewInterface[] = [];
  public timeEntriesSum = 0;

  public fromDate = null;
  public toDate = null;
  public minDate: any;
  public maxDate: any;
  private subscriptions: Subscription = new Subscription();
  public dateNow = new Date();

  public assignHours: boolean;

  public get dropdownOptions(): TroiDropdownListModel[] {
    return [
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.delete'),
        value: TaskActionsEnum.DELETE_SUBTASK,

        icon: 'icon-number-calendar',
      },
    ];
  }

  constructor(
    public helperService: TasksHelperService,
    public settingsService: TasksSettingsService,
    private translationService: TranslateService,
    public modalService: ModalService,
    private domService: DomService,
    private confirmationSubscriber: UserConfirmationSubscriber,
    private subtaskService: SubtaskService,
    private timeEntryService: TimeentryService,
    private tasksService: TasksService,
  ) {}

  ngOnInit() {
    this.helperService.selectedSubtask.subscribe((subtask: SubtaskInterface) => {
      this.subtask = subtask;
      this.getTimeEntries();
      if (this.subtask.startDate && this.subtask.endDate) {
        const fromDate = new Date(this.subtask.startDate).getTime();
        const toDate = new Date(this.subtask.endDate).getTime();
        if (fromDate === 0 && toDate === 0) return;
        this.fromDate = fromDate;
        this.toDate = toDate;
      }
    });
  }

  public onAssignedEmployeeChange() {
    this.helperService.onAssignedEmployeeChange(true);
  }

  public getTimeEntries() {
    this.subscriptions.add(
      this.timeEntryService
        .getTimeentries(this.subtask.id, true)
        .subscribe((fetchedEntries: TimeEntryHelperResponseInterface) => {
          this.helperService.onTimeEntriesChanged(true);
          this.timeEntriesSum = +fetchedEntries.data.sum;
          this.timeEntries = this.helperService.formatTimeEntries(fetchedEntries.data.entries);
        }),
    );
  }

  public removeAssignee(assignee: AssigneeInterface) {
    this.subtask.assignees = this.subtask.assignees.filter((x) => x.id !== assignee.id);
    this.subscriptions.add(
      this.subtaskService.deleteSubtaskAssignee(this.subtask, assignee).subscribe((res) => {
        console.log('🚀 - file: edit-subtask.component.ts:109 - res:', res);
      }),
    );
    this.onAssignedEmployeeChange();
  }

  public convertURL(description: string) {
    this.subtask.description = this.helperService.addHTTPSToURL(description);
  }

  public onActionSelect(action): void {
    if (this.timeEntries.length) this.deletePopover.open();
    else if (action.value === TaskActionsEnum.DELETE_SUBTASK) {
      const modalService = new ModalService(this.domService);
      const translations = {
        title: 'Tasks.labels.deleteTaskModal.title',
        executeBtnTitle: 'Common.labels.continue',
        cancelBtnTitle: 'Common.labels.cancel',
        description: 'Tasks.labels.deleteTaskModal.description',
      };

      modalService.init(TroiUserConfirmationComponent, { translations }, {}, '400px', '500px', true);

      this.confirmationSubscriber.action.pipe(first()).subscribe((result) => {
        if (result === UserConfirmationEventEnum.EXECUTE) {
          this.subscriptions.add(
            this.subtaskService.deleteSubtask(this.subtask.id).subscribe(() => {
              this.helperService.setSubtaskToDelete(this.subtask);
              this.task.subtaskAll--;
              this.tasksService.updateTask(this.task);
            }),
          );
        }
      });
    }
  }

  public onAssignHoursClick(assignHours: boolean): void {
    if (this.hoursAssigned && !assignHours) return;
    else {
      this.helperService.setAssignedHoursState(assignHours);
      this.assignHours = assignHours;
    }
  }

  public hoursAssigned(): boolean {
    return this.subtask.assignees.some((assignee) => assignee.minutesTotal > 0);
  }

  public onDropdownActionOpeningState(event): void {
    console.log(event);
  }

  public getMinDate() {
    if (this.minDate) return this.minDate;
  }

  public getMaxDate() {
    if (this.maxDate) return this.maxDate;
  }

  public startDateChanged(event): void {
    this.subtask.startDate = new Date(event.date[0]);
    this.helperService.setSubtask(this.subtask);
  }

  public endDateChanged(event): void {
    this.subtask.endDate = new Date(event.date[0]);
    this.helperService.setSubtask(this.subtask);
  }

  public updateRuntimeRange(dates: any): void {
    if (!dates.date[0] && !dates.date[1]) return;
    this.subtask.startDate = new Date(dates.date[0]);
    this.subtask.endDate = new Date(dates.date[1]);
  }

  public onBackClick(): void {
    this.showEditSubtaskContent.emit(false);
  }

  public openPopover(section: string): void {
    if (!this.subtask.calculationPosition)
      if (section === 'resource') this.resourcePopover.open();
      else this.timeEntryPopover.open();
  }

  public showTimePicker(): void {
    if (this.subtask.calculationPosition) this.timePicker.nativeElement.showPicker();
  }

  public onAddTimerClick(): void {
    console.log('open add timer');
  }

  public showAddTimerangePopover() {
    this.addDatesPopover.open();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
