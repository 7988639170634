<div
  class="troi-input-field"
  [ngClass]="{
    'troi-input-field--disabled': !enabled,
    'troi-input-field--with-icon': icon,
    'troi-input-field--rounded': rounded,
    'troi-input-field--rounded-with-icon': rounded && icon
  }"
>
  <div class="troi-input-field__field">
    <troi-icon
      class="troi-input-field__icon troi-input-field__icon--left"
      *ngIf="icon"
      [icon]="icon"
      [size]="iconSize"
    ></troi-icon>
    <input
      #input
      [ngClass]="{
        'troi-input-field__input--invalid': fieldInvalid && validationEnabled,
        'troi-input-field__input--outline': outline
      }"
      class="troi-input-field__input"
      [ngStyle]="{ height: height, 'text-align': align }"
      [type]="type"
      (keypress)="filterContent($event)"
      [placeholder]="placeholder"
      [attr.data-value]="value"
      [style.font-size.px]="fontSize"
      [style.font-family]="fontFamily"
      ngDefaultControl
      [disabled]="disabled"
    />
    <troi-icon
      class="troi-input-field__icon troi-input-field__icon--clear"
      *ngIf="showClearButton && innerValue"
      (click)="clearValue()"
      [icon]="'icon-close'"
      [size]="'12px'"
    ></troi-icon>
  </div>
  <div class="troi-input-field__error" *ngIf="validationEnabled">
    <span *ngIf="fieldInvalid" title="{{ requiredFieldErrorMessage | translate }}">{{
      requiredFieldErrorMessage | translate
    }}</span>
  </div>
</div>
