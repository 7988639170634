import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import ErrorHandlerService from '../../../shared/error-handling/ErrorHandlerService';

@Injectable()
export class RecordProjectTimeService {
  private readonly apiEndpointLog: string = '/components/desktop/project-timerecording/log';

  private httpOptions = {};

  public constructor(private http: HttpClient, private errorHandler: ErrorHandlerService) {}

  loadProjectTimeRecords(): Observable<any> {
    return this.http.get(this.apiEndpointLog).pipe(
      tap(
        (response) => response,
        (error) => {
          this.errorHandler.error(error);
        },
      ),
    );
  }

  logWorkTime(form: UntypedFormGroup, stopwatchId: string = ''): Observable<any> {
    const url: string = this.apiEndpointLog + (stopwatchId.length ? '?stopwatchId=' + stopwatchId : '');
    return this.http.post(url, form.value, this.httpOptions).pipe(
      tap(
        (response) => response,
        (error) => {
          this.errorHandler.error(error);
        },
      ),
    );
  }
}
