import { ClientInterface } from '../../../../../core/interfaces/client.interface';
import { OpeningBalanceModel } from './opening-balance.model';
import {
  AccountDropdownInterface,
  AccountGroupDropdownInterface,
  TaxRateInterface,
} from '../../../../common/interfaces/basic-settings.interface';

export class AccountModel {
  constructor(
    public id: string,
    public client: ClientInterface,
    public number: number,
    public name: string,
    public group: AccountGroupDropdownInterface,
    public tax: TaxRateInterface,
    public openingBalances: OpeningBalanceModel[],
    public counterAccount: AccountDropdownInterface,
    public cashAccount: boolean,
    public bankAccount: boolean,
    public automaticAccount: boolean,
    public depositAccount: boolean,
    public datevLock: boolean,
    public active: boolean,
    public canBeDeleted?: boolean,
  ) {}
}
