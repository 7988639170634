import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { cloneDeep } from 'lodash';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { SettingsEmitter } from '../../../core/emitters/settings.emitter';
import { DataTypeEnum } from '../../../core/enums/data-type.enum';
import { ModuleInterceptor } from '../../../core/enums/module-interceptor';
import { ClientInterface } from '../../../core/interfaces/client.interface';
import { FiltersBaseInterface } from '../../../core/interfaces/filtersBase.interface';
import { BaseSettingsDropdownModel } from '../../../core/models/settings/dropdowns/base-settings-dropdown.model';
import { BasicFiltersService } from '../../../core/services/basic-filters.service';
import { FooterSettingsService } from '../../../core/services/footer-settings.service';
import { LanguagesService } from '../../../core/services/languages.service';
import { ParentWindowRef } from '../../../core/services/parent-window-ref.service';
import { StorageService } from '../../../core/storage/storage.service';
import { FiltersInterface } from '../../../shared/troi-data-listing-filters/filters.interface';
import { TroiDropdownListModel } from '../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { TroiDropdownSelectConfigInterface } from '../../../shared/troi-dropdown-select/interfaces/troi-dropdown-select-config.interface';
import { FilterSetTypeEnum } from '../../../shared/troi-filter-sets/enums/filter-set-type.enum';
import { FilterSetLoadedInterface } from '../../../shared/troi-filter-sets/interfaces/filter-set-loaded.interface';
import { FilterSetModel } from '../../../shared/troi-filter-sets/models/filter-set.model';
import { TroiFilterSetsNetworkService } from '../../../shared/troi-filter-sets/network/troi-filter-sets.network';
import { FilterElementDefaultStateInterface } from '../../../shared/troi-filter-with-modal/filter-element-default-state.interface';
import { FilterElementInterface } from '../../../shared/troi-filter-with-modal/filter-element.interface';
import { FilterTypeEnum } from '../../../shared/troi-filter-with-modal/filter-type.enum';
import { CommonFiltersService } from '../../common/services/common-filters.service';
import { DropdownsService } from '../../common/services/dropdowns.service';
import { Routes } from '../enum/routes';
import { DropdownDataSelectedInterface } from '../interfaces/dropdown-data-selected.interface';
import { SettingsInterface } from '../interfaces/settings.interface';
import { SettingsModel } from '../models/settings.model';
import { ProjectListNetwork } from '../network/project-list.network';
import { ProjectListSettingsService } from './project-list-settings.service';

@Injectable()
export class FiltersService extends CommonFiltersService implements OnDestroy {
  public haveFiltersChanged = new Subject<FiltersInterface>();
  public areFiltersEdited = false;
  public customersDropdown: TroiDropdownSelectConfigInterface;
  public reloadCustomersData: number;
  public thirdDropdown: TroiDropdownSelectConfigInterface;
  public reloadProjectStatusesData: number;
  public resetQuickFilters: Subject<boolean> = new Subject<boolean>();
  public reloadFilterChips: Subject<FilterElementInterface[]> = new Subject<FilterElementInterface[]>();
  private clientsLoadedSubscription: Subscription;
  private filterSetsLoadedSubscription: Subscription;
  private filterSetSelectedSubscription: Subscription;
  private emitSettingsSubscription: Subscription;

  // Getters, setters and recreateFiltersBasedOnSettings implemented due to circular reference
  // between subscribeToEmitSettings and subscribeToFilterSetsLoaded. Ticket: TROR-20712.
  private _filterSettings: SettingsInterface;
  private _loadedFilterState: FilterSetLoadedInterface;

  get filterSettings() {
    return this._filterSettings;
  }
  set filterSettings(value: SettingsInterface) {
    this._filterSettings = value;
    this.recreateFiltersBasedOnSettings();
  }

  get loadedFilterState() {
    return this._loadedFilterState;
  }
  set loadedFilterState(value: FilterSetLoadedInterface) {
    this._loadedFilterState = value;
    this.recreateFiltersBasedOnSettings();
  }
  public recreateFiltersBasedOnSettings() {
    if (this.loadedFilterState?.initialLoad) {
      this.applyFiltersFromUrl(
        this.mergeFilterSetWithUrl(this.loadedFilterState.filterSet.value, this.filterSettings).toString(),
      );
    } else {
      this.applyFiltersFromUrl(this.loadedFilterState.filterSet.value);
    }
  }

  // TODO: temporarily removed &approvalDateFrom=&approvalDateTo= from predefined filter set, revert when respective column is ready
  get predefinedFilterSets(): FilterSetModel[] {
    return [
      {
        id: 'predefined-all',
        name: 'FilterSets.predefined.all',
        value:
          `searchPhrase=&client=${this.getDefaultClientId()}&currentPage=1&pageSize=100&projectManager=` +
          '&showInactiveFolderProjects=false&showOnlyFavorites=false&customer=*&projectStatus=*' +
          '&projectType=&startAndEndDateFrom=&startAndEndDateTo=&reportingDateFrom=&reportingDateTo=' +
          '&orderingDateFrom=&orderingDateTo=&showInactiveFolderProjects=false' +
          '&showOnlyFavorites=false',
        type: FilterSetTypeEnum.ALL,
        isDefault: false,
        filters: new FiltersInterface(),
      },
      // TODO: temporarily removed '&approvalDateFrom=&approvalDateTo=' + from predefined filter set, revert when respective column is ready
      {
        id: 'predefined-favourite',
        name: 'FilterSets.predefined.favourites',
        value:
          `searchPhrase=&client=${this.getDefaultClientId()}&currentPage=1&pageSize=100&sortDir=undefined&sortBy=undefined&projectType=` +
          '&projectManager=&startAndEndDateFrom=&startAndEndDateTo=' +
          '&reportingDateFrom=&reportingDateTo=' +
          '&orderingDateFrom=&orderingDateTo=&showInactiveFolderProjects=false' +
          '&showOnlyFavorites=true&customer=&projectStatus=',
        type: FilterSetTypeEnum.FAVOURITES,
        isDefault: true,
        filters: new FiltersInterface(),
      },
    ];
  }

  constructor(
    public basicFiltersService: BasicFiltersService,
    public parentWindowRef: ParentWindowRef,
    public footerSettingsService: FooterSettingsService,
    protected localStorage: StorageService,
    private projectListSettingsService: ProjectListSettingsService,
    private settingsEmitter: SettingsEmitter,
    private languagesService: LanguagesService,
    private projectListNetwork: ProjectListNetwork,
    private dropdownService: DropdownsService,
    protected filterSetsNetworkService: TroiFilterSetsNetworkService,
  ) {
    super(basicFiltersService, parentWindowRef, footerSettingsService, localStorage, filterSetsNetworkService, false);
    this.customersDropdown = FiltersService.initQuickFilterMultiSelectDropdown();
    this.thirdDropdown = FiltersService.initQuickFilterMultiSelectDropdown();

    this.actualFilters = this.defaultFilterValues;
    this.pageUrl = this.parentWindowRef.nativeWindow.location;

    this.filterSetsNetworkService.route = Routes.FILTER_SETS;
    this.filterSetsNetworkService.headers = new HttpHeaders().set(ModuleInterceptor.PROJECT_LIST, '1');

    this.subscribeToClientsLoaded();
    this.subscribeToFilterSetsLoaded();
    this.subscribeToFilterSetSelected();
    this.subscribeToEmitSettings();
  }

  public static stringifyFilterParam(value: string | number): string {
    return String(value);
  }

  private static initQuickFilterMultiSelectDropdown(): TroiDropdownSelectConfigInterface {
    return {
      values: [],
      search: true,
      multiple: true,
      disable: true,
      selectAllOption: true,
    };
  }

  private subscribeToClientsLoaded(): void {
    this.clientsLoadedSubscription = this.basicFiltersService.clientsLoaded.subscribe(() => {
      this.actualFilters.dropdownFirst = this.getDefaultClientId();
      this.loadFilterSets();
    });
  }

  private subscribeToFilterSetsLoaded(): void {
    this.filterSetsLoadedSubscription = this.filterSetsLoaded.subscribe(
      (loadedFilterState: FilterSetLoadedInterface) => {
        this.loadedFilterState = loadedFilterState;
        this.assignActualFiltersToFilterSet(loadedFilterState.filterSet.id);
        this.defaultClientAssigned.next(true);
      },
    );
  }

  private restoreActualFiltersFromLS(key: string): void {
    const returnFromProjectBaseDataFilters = localStorage.getItem(key);
    if (returnFromProjectBaseDataFilters) {
      this.actualFilters = JSON.parse(returnFromProjectBaseDataFilters);
    }
    localStorage.removeItem(key);
  }

  private subscribeToFilterSetSelected(): void {
    this.filterSetSelectedSubscription = this.filterSetSelected.subscribe((filterSet: FilterSetModel) => {
      this.applyFiltersFromUrl(filterSet.value);
      this.generateCustomersDropdown(this.basicFiltersService.clients);
      this.reloadProjectStatusesData = Math.random();
      this.assignActualFiltersToFilterSet(filterSet.id);
    });
  }

  private subscribeToEmitSettings(): void {
    this.emitSettingsSubscription = this.settingsEmitter.getEmitter().subscribe((data: SettingsModel) => {
      this.thirdDropdown = {
        ...this.thirdDropdown,
        values: data.projectStatuses?.map(this.mapProjectStatusToDropdownOption) || this.thirdDropdown.values,
        selectAllOptionLabel: 'ProjectList.labels.filters.projectStatus.allSelected',
        placeholder: 'ProjectList.labels.filters.projectStatus.placeholder',
        disable: !this.clientId || data.projectStatuses?.length <= 1,
      };

      this.reloadProjectStatusesData = Math.random();

      this.filterSettings = data.settings;
    });
  }

  private get defaultFilterValues(): FiltersInterface {
    return {
      filters: this.defaultFilters(),
      search: '',
      dropdownFirst: null,
      dropdownSecond: null,
      dropdownThird: null,
      currentPage: 1,
      pageSize: this.getPageSize(),
    };
  }

  public resetFilters(filters?: FiltersInterface, keepClientId = false): FiltersInterface {
    const resetFilter = {
      ...(filters || this.actualFilters),
      filters: this.defaultFilterValues.filters,
      search: this.defaultFilterValues.search,
      dropdownFirst: filters && keepClientId ? filters.dropdownFirst : this.clientId,
      dropdownSecond: this.defaultFilterValues.dropdownSecond,
      dropdownThird: this.defaultFilterValues.dropdownThird,
    };

    if (!filters) {
      this.actualFilters = resetFilter;
      this.resetQuickFilters.next(true);
      this.resetPagination();
      return this.actualFilters;
    }

    this.resetPagination();
    return resetFilter;
  }

  private defaultFilters(filterSettings?: SettingsInterface): FilterElementInterface[] {
    const filters: FilterElementInterface[] = [
      {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'ProjectList.labels.filters.projectType.label',
        formName: 'projectType',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'ProjectList.labels.filters.projectType.label',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dataType: DataTypeEnum.PROJECT_TYPES,
        dropdownData: [],
        dropdownSelectAllOption: true,
        dropdownSelectAllOptionLabel: 'ProjectList.labels.filters.projectType.allSelected',
        dropdownMultipleSelect: true,
        dropdownReturnSelectedObject: true,
        pageSize: 100,
        preloadedOptions: [
          {
            active: true,
            disabled: false,
            label: 'ProjectList.labels.filters.projectType.allSelected',
            value: '*',
          },
        ],
        withSearch: false,
        icon: 'icon-files-tree',
      },
      {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'ProjectList.labels.filters.projectManager.label',
        formName: 'projectManager',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'ProjectList.labels.filters.projectManager.label',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dataType: DataTypeEnum.PROJECT_LEADERS,
        dropdownData: [],
        dropdownSelectAllOption: false,
        dropdownSelectAllOptionLabel: 'ProjectList.labels.filters.projectManager.allSelected',
        dropdownMultipleSelect: true,
        dropdownReturnSelectedObject: false,
        pageSize: 100,
        preloadedOptions: [],
        icon: 'icon-empty-person',
      },
      {
        formName: 'startAndEndDate',
        label: 'ProjectList.labels.columns.projectStartEndDate',
        type: FilterTypeEnum.RANGE_DATE,
        value: [null, null],
        defaultValue: [null, null],
        chips: [
          {
            label: 'ProjectList.labels.columns.projectStartEndDate',
            value: undefined,
            valueIsTranslatable: true,
          },
        ],
        icon: 'icon-calendar',
      },
      {
        formName: 'reportingDate',
        label: 'ProjectList.labels.columns.reportingDate',
        type: FilterTypeEnum.RANGE_DATE,
        value: [null, null],
        defaultValue: [null, null],
        chips: [
          {
            label: 'ProjectList.labels.columns.reportingDate',
            value: undefined,
            valueIsTranslatable: true,
          },
        ],
        icon: 'icon-calendar',
      },
      //TROR-19923 - hide approval columns
      // {
      //   formName: 'approvalDate',
      //   label: 'ProjectList.labels.columns.approvalDate',
      //   type: FilterTypeEnum.RANGE_DATE,
      //   value: [null, null],
      //   defaultValue: [null, null],
      //   chips: [
      //     {
      //       label: 'ProjectList.labels.columns.approvalDate',
      //       value: undefined,
      //       valueIsTranslatable: true,
      //     },
      //   ],
      //   icon: 'icon-calendar',
      // },
      {
        formName: 'orderingDate',
        label: 'ProjectList.labels.columns.orderingDate',
        type: FilterTypeEnum.RANGE_DATE,
        value: [null, null],
        defaultValue: [null, null],
        chips: [
          {
            label: 'ProjectList.labels.columns.orderingDate',
            value: undefined,
            valueIsTranslatable: true,
          },
        ],
        icon: 'icon-calendar',
      },
      {
        type: FilterTypeEnum.GROUP,
        label: '',
        group: {
          singleColumn: true,
          inputAfterLabel: true,
        },
        formName: 'group',
        value: [
          {
            type: FilterTypeEnum.SWITCH,
            label: 'ProjectList.labels.filters.showOnlyFavorites.label',
            formName: 'showOnlyFavorites',
            value: false,
            defaultValue: false,
            chips: [
              {
                label: 'ProjectList.labels.filters.showOnlyFavorites.chipLabel',
                value: 'ProjectList.labels.filters.showOnlyFavorites.selected',
                valueIsTranslatable: true,
              },
            ],
            icon: 'icon-black-star',
          },
          {
            type: FilterTypeEnum.SWITCH,
            label: 'ProjectList.labels.filters.projectIsOrdered.label',
            formName: 'projectIsOrdered',
            value: false,
            defaultValue: false,
            chips: [
              {
                label: 'ProjectList.labels.filters.projectIsOrdered.chipLabel',
                value: 'ProjectList.labels.filters.projectIsOrdered.selected',
                valueIsTranslatable: true,
              },
            ],
          },
        ],
      },
    ];

    if (filterSettings?.showInactiveFolders) {
      const inactiveFoldersFilter = {
        type: FilterTypeEnum.SWITCH,
        label: 'ProjectList.labels.filters.showInactiveFolderProjects.label',
        formName: 'showInactiveFolderProjects',
        value: false,
        defaultValue: false,
        chips: [
          {
            label: 'ProjectList.labels.filters.showInactiveFolderProjects.chipLabel',
            value: 'ProjectList.labels.filters.showInactiveFolderProjects.selected',
            valueIsTranslatable: true,
          },
        ],
        icon: 'icon-radio-button-unchecked',
      };

      for (const filter of filters) {
        if (filter.formName === 'group') {
          filter.value.push(inactiveFoldersFilter);
          break;
        }
      }
    }

    if (this.actualFilters?.filters) {
      this.actualFilters.filters = filters;
    }

    return filters;
  }

  public generateCustomersDropdown(clients: ClientInterface[]): void {
    const selectedClientId = this.clientId;
    const selectedClient = clients.find((client: ClientInterface) => client.id === selectedClientId);
    const customerValues = selectedClient?.customers
      ? selectedClient.customers.map(this.mapCustomerToDropdownOption)
      : [];

    if (customerValues.length > 0) {
      this.applyCustomerDropdownValues(customerValues);

      return;
    }

    this.projectListNetwork.getCustomers(selectedClientId).subscribe((customersData) => {
      if (customerValues.length < 1) {
        const fallbackValues = customersData.data.map(this.mapCustomerToDropdownOption);

        selectedClient.customers = customersData.data;

        this.applyCustomerDropdownValues(fallbackValues);
      }
    });
  }

  private applyCustomerDropdownValues(
    customerValues: { active: boolean; label: string; value: string; group: boolean }[],
  ) {
    this.customersDropdown = {
      ...this.customersDropdown,
      values: customerValues,
      disable: !customerValues || customerValues.length <= 1,
      selectAllOptionLabel: 'ProjectList.labels.filters.customer.allSelected',
      placeholder: 'ProjectList.labels.filters.customer.placeholder',
    };

    this.reloadCustomersData = Math.random();
  }

  public get clientId(): number {
    return this.getSelectedClientId() ?? this.getDefaultClientId();
  }

  private async applyFiltersFromUrl(explicitParams?: string): Promise<void> {
    const canFetch = await this.prepareTempUrlNEW(explicitParams);
    if (!canFetch) return;
    // this.fetchSelectedData(explicitParams);
  }

  private _isRunning = false;
  private async prepareTempUrlNEW(explicitParams?: string): Promise<boolean> {
    if (this._isRunning) return false;

    this._isRunning = true;

    const tempUrl = explicitParams
      ? new URL(this.pageUrl.href.split('?')[0] + '?page=project_list_2&' + explicitParams)
      : new URL(this.pageUrl.href);

    // INFO: tempUrl already contains the wrong client id
    // we have yet to figure out why this is happening
    // the fix below is a temporary solution to the issue
    const originalDropdownFirst = this.actualFilters.dropdownFirst;
    const newFilters = this.applyCommonUrlFilters(tempUrl, cloneDeep(this.actualFilters), true, true, true);
    if (originalDropdownFirst && newFilters.dropdownFirst !== originalDropdownFirst) {
      newFilters.dropdownFirst = originalDropdownFirst;
    }
    // this.actualFilters = newFilters;

    if (tempUrl.searchParams.has('customer')) {
      const customer = tempUrl.searchParams.get('customer');
      if (customer) {
        newFilters.dropdownSecond = customer === '*' ? customer : customer.split(';');
      } else {
        newFilters.dropdownSecond = [];
      }
    }

    if (tempUrl.searchParams.has('client')) {
      const client = tempUrl.searchParams.get('client');
      if (client) {
        newFilters.dropdownFirst = Number(client);
      } else {
        newFilters.dropdownFirst = this.getDefaultClientId();
      }
    }

    if (tempUrl.searchParams.has('projectStatus')) {
      const projectStatus = tempUrl.searchParams.get('projectStatus');
      if (projectStatus) {
        newFilters.dropdownThird =
          projectStatus === '*' ? projectStatus : projectStatus.split(';').map((statusId: string) => Number(statusId));
      } else {
        newFilters.dropdownThird = [];
      }
    }

    if (tempUrl.searchParams.has('searchPhrase')) {
      newFilters.search = tempUrl.searchParams.get('searchPhrase');
    }

    if (tempUrl.searchParams.has('showOnlyFavorites')) {
      const group = newFilters.filters.find((filter) => filter.formName === 'group');
      const showOnlyFavorites = group?.value.find((filter) => filter.formName === 'showOnlyFavorites');
      showOnlyFavorites.value = tempUrl.searchParams.get('showOnlyFavorites') === 'true';
    }

    this.setFilterFromUrl(tempUrl, 'projectType', false, false, newFilters);
    this.setFilterFromUrl(tempUrl, 'projectManager', false, false, newFilters);
    this.setDateFilterFromUrl(tempUrl, 'startAndEndDate', newFilters);
    this.setDateFilterFromUrl(tempUrl, 'reportingDate', newFilters);
    this.setDateFilterFromUrl(tempUrl, 'orderingDate', newFilters);
    this.setFilterFromUrl(tempUrl, 'showInactiveFolderProjects', true, false, newFilters);
    this.setFilterFromUrl(tempUrl, 'showOnlyFavorites', true, false, newFilters);

    await this.applyNewFilters(newFilters);
    this._isRunning = false;
    return true;
  }

  public async applyNewFilters(filters: FiltersInterface): Promise<void> {
    // checks if the client has changed
    let hasClientChanged = this.actualFilters.dropdownFirst !== filters.dropdownFirst;
    // if client has changed, check if it can be accessed
    const canAccessClient = this.basicFiltersService.clients.some((client) => client.id === filters.dropdownFirst);
    // if client has changed but can't be accessed, reset client to previous value
    if (hasClientChanged && !canAccessClient) {
      filters.dropdownFirst = this.actualFilters.dropdownFirst;
      hasClientChanged = false;
    }

    if (hasClientChanged || this.customersDropdown.values.length === 1) {
      console.log('client has changed, updating customers dropdown', filters.dropdownFirst);
      await this.generateCustomersDropdownAsync(this.basicFiltersService.clients, filters.dropdownFirst);
      console.log('customers dropdown updated', this.customersDropdown);
      const settings = await firstValueFrom(
        this.projectListSettingsService.prepareSettings(filters.dropdownFirst, true),
      );

      this.settingsEmitter.setSettings(settings);
    }

    console.log('filters', filters.dropdownSecond);

    if (filters.dropdownSecond !== '*' && (filters.dropdownSecond as any[])?.length > 0) {
      const availableCustomers = this.customersDropdown.values.map((customer) => customer.value);
      const selectedCustomers = filters.dropdownSecond as string | string[];
      const generatedList = availableCustomers.filter((customer) => selectedCustomers.includes(customer));
      filters.dropdownSecond = generatedList.length > 0 ? generatedList : '*';
    } else {
      filters.dropdownSecond = [];
    }

    if (filters.dropdownThird !== '*' && (filters.dropdownThird as any[])?.length > 0) {
      const availableStatuses = this.thirdDropdown.values.map((status) => status.value);
      const selectedStatuses = filters.dropdownThird as number[];
      const generatedStatuses = availableStatuses.filter((status) => selectedStatuses.includes(status));

      filters.dropdownThird = generatedStatuses.length > 0 ? generatedStatuses : '*';
    } else {
      filters.dropdownThird = [];
    }

    const areFiltersEdited = JSON.stringify(this.actualFilters) !== JSON.stringify(filters);
    this.areFiltersEdited = areFiltersEdited;

    this.actualFilters = filters;

    this.haveFiltersChanged.next(this.actualFilters);
  }

  public async generateCustomersDropdownAsync(clients: ClientInterface[], clientId?: number): Promise<void> {
    const selectedClientId = clientId || this.clientId;
    const selectedClient = clients.find((client: ClientInterface) => client.id === selectedClientId);
    // const customerValues = selectedClient?.customers
    //   ? selectedClient.customers.map(this.mapCustomerToDropdownOption)
    //   : [];

    // if (customerValues.length > 0) {
    //   this.applyCustomerDropdownValues(customerValues);
    //   // use observable pipe wait to wait 150ms before returning
    //   // this is to prevent the dropdown from being disabled
    //   await new Promise((resolve) => setTimeout(resolve, 150));
    //   return;
    // }

    const customersData = await firstValueFrom(this.projectListNetwork.getCustomers(selectedClientId));
    const fallbackValues = customersData.data.map(this.mapCustomerToDropdownOption);
    selectedClient.customers = customersData.data;
    this.applyCustomerDropdownValues(fallbackValues);
  }

  private getFilterValueByDataType(dataType: string) {
    const filterValue = this.actualFilters.filters.find((filter) => filter.dataType === dataType)?.value;
    if (filterValue && typeof filterValue === 'object') {
      return filterValue.map((value) => value.value || value).join(';');
    }
    if (filterValue && typeof filterValue === 'string') {
      return filterValue;
    }
    return '';
  }

  private lastFilterValue: string;

  private fetchSelectedData(explicitParams?: string): void {
    this.restoreActualFiltersFromLS('actualFilters');
    const tempUrl = explicitParams
      ? new URL(this.pageUrl.href.split('?')[0] + '?page=project_list_2' + explicitParams)
      : new URL(this.pageUrl.href);

    const actualProjectTypeValue = this.getFilterValueByDataType('PROJECT_TYPES');
    const actualProjectManagerValue = this.getFilterValueByDataType('PROJECT_LEADERS');

    const projectType = actualProjectTypeValue || tempUrl.searchParams.get('projectType') || '';
    const projectManager = actualProjectManagerValue || tempUrl.searchParams.get('projectManager') || '';

    this.projectListNetwork.getDropdownSelectedData(projectType, projectManager).subscribe(
      (response: DropdownDataSelectedInterface) => {
        if (response.projectType) {
          const selectedOptions = this.dropdownService.buildOptionList(
            response.projectType.items,
            DataTypeEnum.PROJECT_TYPES,
          );

          const flattenedSelectedOptions = selectedOptions.reduce(
            (acc: TroiDropdownListModel[], option: TroiDropdownListModel) => {
              if (option.groupValues) {
                acc = acc.concat(option.groupValues);
              }
              return acc;
            },
            [],
          );

          this.setDropdownFilterValue('projectType', selectedOptions, flattenedSelectedOptions, projectType);
        }

        if (response.projectManager) {
          const selectedOptionsData = this.dropdownService.buildOptionList(
            response.projectManager.items,
            DataTypeEnum.EMPLOYEES,
          );
          const selectedOptionsValue = response.projectManager.items.map((option) => option.id);

          this.setDropdownFilterValue('projectManager', selectedOptionsData, selectedOptionsValue, projectManager);
        }

        // INFO: removing this fails the initial load of the project list, but loads the currently inaccessable client
        // the dropdown still breaks, even if this is removed, so the issue is not here
        // but we should probably look into this emit since it appears to be the source of the double request
        // actualFilters already contains the wrong client id when its being used here
        // the if statement was added in an attempt to fix the issue, but it didn't work since the filters are already wrong
        if (!this.lastFilterValue || this.lastFilterValue !== JSON.stringify(this.actualFilters)) {
          this.lastFilterValue = JSON.stringify(this.actualFilters);
          this.haveFiltersChanged.next(this.actualFilters);
        }

        this.reloadFilterChips.next(this.actualFilters.filters);
      },
      () => {
        this.setDropdownFilterValue('projectType', [], '', projectType);
        this.setDropdownFilterValue('projectManager', [], '', projectManager);
      },
    );
  }

  private setDropdownFilterValue(
    filterName: string,
    data: TroiDropdownListModel[],
    value: string | TroiDropdownListModel[],
    urlValue: string,
  ): void {
    const filter = this.actualFilters.filters[this.getFilterParamIndex(filterName)];
    filter.value = urlValue === '*' ? '*' : value;
    filter.preloadedOptions = data;
  }

  private mapCustomerToDropdownOption(customer: FiltersBaseInterface) {
    return {
      label: customer.name,
      value: customer.id,
      active: true,
      group: false,
    };
  }

  private mapProjectStatusToDropdownOption = (projectStatus: BaseSettingsDropdownModel) => ({
    label: this.languagesService.getLanguageValue(projectStatus.name),
    value: projectStatus.id,
    active: true,
    group: false,
  });

  private mergeFilterSetWithUrl(filterSetValue: string, filterSettings: SettingsInterface): string {
    const initialUrlSearchParams = new URL(this.pageUrl.href).searchParams;
    const filterSetUrlSearchParams = new URL(this.pageUrl.href.split('?')[0] + '?page=project_list_2&' + filterSetValue)
      .searchParams;
    const mergedUrlSearchParams = new URL(this.pageUrl.href.split('?')[0] + '?page=project_list_2&' + filterSetValue)
      .searchParams;
    const defaultFiltersState = this.getDefaultFiltersState(this.defaultFilters(filterSettings));

    defaultFiltersState.push(
      ...[
        {
          formName: 'client',
          defaultValue: String(this.getDefaultClientId()),
        },
        {
          formName: 'customer',
          defaultValue: '',
        },
        {
          formName: 'projectStatus',
          defaultValue: '',
        },
        {
          formName: 'searchPhrase',
          defaultValue: '',
        },
      ],
    );

    defaultFiltersState.forEach((filterElemDefaultState: FilterElementDefaultStateInterface) => {
      if (
        initialUrlSearchParams.has(filterElemDefaultState.formName) &&
        initialUrlSearchParams.get(filterElemDefaultState.formName) !==
          filterSetUrlSearchParams.get(filterElemDefaultState.formName) &&
        initialUrlSearchParams.get(filterElemDefaultState.formName) !== filterElemDefaultState.defaultValue
      ) {
        mergedUrlSearchParams.set(
          filterElemDefaultState.formName,
          initialUrlSearchParams.get(filterElemDefaultState.formName),
        );
      }
    });

    if (mergedUrlSearchParams.toString() !== filterSetUrlSearchParams.toString()) {
      this.areFiltersEdited = true;
    }

    return mergedUrlSearchParams.toString();
  }

  public ngOnDestroy(): void {
    this.clientsLoadedSubscription.unsubscribe();
    this.filterSetsLoadedSubscription.unsubscribe();
    this.filterSetSelectedSubscription.unsubscribe();
    this.emitSettingsSubscription.unsubscribe();
  }
}
