<div class="approval-status-bar">
  <span class="approval-status-bar__label"
    >{{ 'Approval.labels.approvalStatus' | translate }}:</span
  >
  <approval-process
    *ngIf="
      approvalStatus?.approvalState === approvalsStatusEnum.NOT_STARTED &&
      approvalsService.canStart(this.approvalStatus)
    "
    [placement]="'top'"
    [id]="id"
    (refreshApprovalStatus)="onApprovalProcessChange()"
  >
    <troi-btn class="approval-status-bar__start-approval">{{
      'Approval.labels.startApproval' | translate
    }}</troi-btn>
  </approval-process>
  <div
    class="approval-status-bar__state"
    *ngIf="
      approvalStatus?.approvalState &&
      (approvalStatus?.approvalState !== approvalsStatusEnum.NOT_STARTED ||
        (approvalStatus?.approvalState === approvalsStatusEnum.NOT_STARTED &&
          !approvalsService.canStart(this.approvalStatus)))
    "
  >
    <approval-status-icon [approvalState]="approvalStatus?.approvalState"></approval-status-icon>
    <span
      class="approval-status-bar__status"
      [ngClass]="approvalsService.getApprovalStatusClass(approvalStatus?.approvalState)"
    >
      {{ 'Approval.states.' + approvalStatus?.approvalState | translate }}</span
    >
  </div>
  <approval-actions
    class="approval-status-bar__actions"
    [id]="id"
    [approvalStatus]="approvalStatus"
    (update)="onUpdate()"
  ></approval-actions>
</div>
