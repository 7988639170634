export enum Routes {
  BOOKING_APPROVAL = '/components/booking/approval/',
  HISTORY = '/history',
  START = '/start',
  DIRECT = '/direct-approve',
  APPROVE = '/approve',
  DECLINE = '/decline',
  CANCEL = '/cancel',
  DELEGATE = '/delegate',
  EDIT = '/edit',
}
