<div class="menu-stopwatch">
    <troi-icon class="menu-stopwatch__stopwatch" [icon]="'icon-timer'" (click)="clickStopwatch()"></troi-icon>
    <button class="twc-button-round" (click)="toggleActivityState()" *ngIf="latestStopwatch"
            placement="right"
            [ngbTooltip]="tooltipContent"
            tooltipClass="tooltip"
            container="body">
        <div class="twc-play" [ngClass]="{'twc-play--pause': !latestStopwatch.paused && latestStopwatch.startDate}"></div>

    </button>
    <ng-template #tooltipContent>
        <div class="stopwatch-tooltip">
            <div class="stopwatch-tooltip__content">
                <div class="stopwatch-tooltip__name">{{ latestStopwatch?.name }}</div>
                <div class="stopwatch-tooltip__structure">
                    {{ getStopwatchProjectDescription(latestStopwatch?.additionalData) }}
                </div>
            </div>
            <div class="stopwatch-tooltip__time">
                    {{ getTime(latestStopwatch?.elapsedTime2 || latestStopwatch?.elapsedTime) }}
            </div>
        </div>
    </ng-template>
</div>
