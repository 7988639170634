import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ColumnInterface } from '../../interfaces/column.interface';
import { ColumnType } from '../../interfaces/column-type';

@Component({
  selector: '[troi-table-column]',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss'],
})
export class TroiTableColumnComponent implements OnInit {
  @Input() columnData: ColumnInterface;

  @ViewChild('component', { read: ViewContainerRef, static: true }) component;

  ngOnInit() {
    if (this.columnData.type === ColumnType.DATE) {
      this.component.insert(this.columnData.data.hostView);
    }
  }

  public isStringComponent() {
    return this.columnData.type === ColumnType.STRING;
  }
}
