import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ColumnSelectInterface } from '../../../../../shared/troi-columns-selector/column-select.interface';
import { ColumnsSelectService } from '../../../../../shared/troi-columns-selector/columns-select.service';
import { ColumnServiceInterface } from '../../../../../shared/troi-columns-selector/column-service.interface';
import { StorageService } from '../../../../../core/storage/storage.service';
import { StorageKeys } from '../../../../../core/storage/storage.keys';
import { ColumnsEnum } from '../enums/columns.enum';

@Injectable()
export class ColumnsService extends ColumnsSelectService implements ColumnServiceInterface {
  keyLS = StorageKeys.TASKS_COLUMNS;

  fetchedColumns: ColumnSelectInterface[];

  defaultColumnList: ColumnSelectInterface[] = [
    {
      id: ColumnsEnum.PRIORITY,
      name: 'Tasks.listview.labels.columns.priority',
      enable: true,
      active: true,
    },
    {
      id: ColumnsEnum.TICKET,
      name: 'Tasks.listview.labels.columns.ticket',
      enable: true,
      active: true,
    },
    {
      id: ColumnsEnum.TITLE,
      name: 'Tasks.listview.labels.columns.title',
      enable: true,
      active: true,
    },
    {
      id: ColumnsEnum.CALCULATION_POSITION,
      name: 'Tasks.listview.labels.columns.calculationPosition',
      enable: true,
      active: true,
    },
    {
      id: ColumnsEnum.STATUS,
      name: 'Tasks.listview.labels.columns.status',
      enable: true,
      active: true,
    },
    {
      id: ColumnsEnum.ASSIGNEES,
      name: 'Tasks.listview.labels.columns.assignees',
      enable: true,
      active: true,
    },
    {
      id: ColumnsEnum.PROGRESS,
      name: 'Tasks.listview.labels.columns.progress',
      enable: true,
      active: true,
    },
    {
      id: ColumnsEnum.SUBTASKS,
      name: 'Tasks.listview.labels.columns.subtasks',
      enable: true,
      active: true,
    },
    {
      id: ColumnsEnum.DEADLINE,
      name: 'Tasks.listview.labels.columns.deadline',
      enable: true,
      active: true,
    },
    {
      id: ColumnsEnum.DESCRIPTION,
      name: 'Tasks.listview.labels.columns.description',
      enable: true,
      active: true,
    },
  ];

  constructor(public storage: StorageService) {
    super(storage);
  }

  getColumnList(): ColumnSelectInterface[] {
    return super.getColumnList(this.defaultColumnList, this.keyLS);
  }

  getColumnListWithoutSome(excludeIds: string[] = []): ColumnSelectInterface[] {
    const columns = super.getColumnList(this.defaultColumnList, this.keyLS);
    return _.filter(columns, (column: ColumnSelectInterface) => !excludeIds.includes(column.id));
  }

  isColumnVisible(columnName): boolean {
    return super.isColumnVisible(this.getColumnList(), columnName);
  }

  saveColumnList(columns: ColumnSelectInterface[]) {
    return super.saveColumnList(columns, this.keyLS);
  }
}
