import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AttachmentInterface } from '../../../../../../../interfaces/attachment.interface';
import { AttachmentService } from '../../../../network/attachment.service';
import { AttachmentType } from '../../../../../../../enum/attachment-type';
import { StorageNotificationService } from '../../../../../../../../../core/notifications/storageNotification.service';
import { TranslateService } from '@ngx-translate/core';
import { UserConfirmationSubscriber } from '../../../../../../../../../shared/troi-user-confirmation/user-confirmation.subscriber';
import { ModalService } from '../../../../../../../../../shared/troi-modals/modal.service';
import { Subscription, first } from 'rxjs';
import { UserConfirmationEventEnum } from '../../../../../../../../../shared/troi-user-confirmation/user-confirmation-event.enum';
import { TroiUserConfirmationComponent } from '../../../../../../../../../shared/troi-user-confirmation/troi-user-confirmation.component';
import { AttachmentResponseInterface } from '../../../../../../../interfaces/responses.interface';

@Component({
  selector: 'troi-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public showIcons = false;

  @Input() attachment: AttachmentInterface;
  @Input() attachmentType: AttachmentType;
  @Input() isCreateComment = false;

  @Output() downloadFile = new EventEmitter<AttachmentInterface>();
  @Output() deleteFile = new EventEmitter<AttachmentInterface>();

  public thumbnailUrl: string;
  public hoverOption: boolean;

  constructor(
    private attachmentService: AttachmentService,
    private notificationService: StorageNotificationService,
    private translationService: TranslateService,
    private confirmationSubscriber: UserConfirmationSubscriber,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    if (this.attachment.thumbnailPath) this.getThumbnail();
  }

  public onFileDownload() {
    this.subscriptions.add(
      this.attachmentService.downloadAttachment(this.attachment, this.attachmentType).subscribe(() => {
        this.notificationService.showSuccess(
          this.translationService.instant('Tasks.labels.notifications.attachment.download'),
        );
      }),
    );
  }

  public onFileDelete() {
    const translations = {
      title: 'Tasks.labels.deleteAttachmentModal.title',
      executeBtnTitle: 'Common.labels.continue',
      cancelBtnTitle: 'Common.labels.cancel',
      description: 'Tasks.labels.deleteAttachmentModal.description',
    };

    this.modalService.init(TroiUserConfirmationComponent, { translations }, {}, '400px', '500px', true);

    this.confirmationSubscriber.action.pipe(first()).subscribe((result) => {
      if (result === UserConfirmationEventEnum.EXECUTE) {
        if (!this.isCreateComment) {
          this.subscriptions.add(
            this.attachmentService
              .deleteAttachment(this.attachment.id, this.attachmentType)
              .subscribe((res: AttachmentResponseInterface) => {
                this.deleteFile.emit(this.attachment);
                this.notificationService.showSuccess(
                  this.translationService.instant('Tasks.labels.notifications.attachment.delete'),
                );
              }),
          );
        } else {
          this.deleteFile.emit(this.attachment);
        }
      }
    });
  }

  private getThumbnail() {
    this.attachmentService.getAttachmentThumbnail(this.attachment.id, this.attachmentType).subscribe((res) => {
      this.displayThumbnail(res);
    });
  }

  public getFileExtension() {
    const splitPath = this.attachment.filename.split('.');
    return splitPath[splitPath.length - 1];
  }

  private displayThumbnail(imageData: Blob) {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.thumbnailUrl = reader.result as string;
    };
    reader.readAsDataURL(imageData);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
