<div class="columns">
  <div class="columns__title">
    <p>{{ 'Booking.labels.columns' | translate }}</p>
  </div>
  <troi-modal-content>
    <troi-modal-row *ngFor="let column of _columns" [disabled]="!column.enable">
      <span row-label>
        {{ column.name | translate }}
      </span>
      <troi-switch row-value small [(ngModel)]="column.active"></troi-switch>
    </troi-modal-row>
  </troi-modal-content>
  <troi-modal-footer>
    <troi-btn [outline]="true" (click)="onCancel()">{{
      'Booking.labels.cancel' | translate
    }}</troi-btn>
    <troi-btn (click)="onSave()">{{ 'Booking.labels.save' | translate }}</troi-btn>
  </troi-modal-footer>
</div>
