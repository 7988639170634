<div class="time-switch-wrapper">
    <div class="time-switch-outer state-new" *ngIf="state === stateNew">
      <span class="time-switch-inner" (click)="changeState(stateRunning)"></span>
      <span class="time-switch-counter" [innerText]="presentation"></span>
    </div>
  
    <div class="time-switch-outer state-running" *ngIf="state === stateRunning">
      <span
        class="time-switch-counter"
        (click)="changeState(stateStopped)"
        [innerText]="presentation"
      ></span>
      <span class="time-switch-inner" (click)="changeState(statePaused)">
        <img src="/dist/assets/images/icons/pause.svg" class="time-switch-state-icon" />
      </span>
    </div>
  
    <div class="time-switch-outer state-paused" *ngIf="state === statePaused">
      <span class="time-switch-counter" (click)="changeState(stateStopped)">
        {{ 'Desktop.Widgets.Stopwatch.Paused' | translate }}
      </span>
      <span class="time-switch-inner" (click)="changeState(stateRunning)">
        <img src="/dist/assets/images/icons/play.svg" class="time-switch-state-icon" />
      </span>
    </div>
  
    <div class="time-switch-outer state-stopped" *ngIf="state === stateStopped">
      <span class="time-switch-inner">
        <img src="/dist/assets/images/icons/stop.svg" class="time-switch-state-icon" />
      </span>
      <span class="time-switch-counter" [innerText]="presentation"></span>
    </div>
  
    <div
      class="time-switch-outer state-new state-awaiting-start"
      *ngIf="state === stateAwaitingStart"
    >
      <span class="time-switch-inner"></span>
      <span class="time-switch-counter" [innerHTML]="presentation"></span>
    </div>
  
    <div
      class="time-switch-outer state-running state-awaiting-pause-start"
      *ngIf="state === stateAwaitingPauseStart"
    >
      <span class="time-switch-counter" [innerText]="presentation"></span>
      <span class="time-switch-inner" (click)="changeState(statePaused)">
        <img src="/dist/assets/images/icons/pause.svg" class="time-switch-state-icon" />
      </span>
    </div>
  
    <div
      class="time-switch-outer state-paused state-awaiting-pause-end"
      *ngIf="state === stateAwaitingPauseEnd"
    >
      <span class="time-switch-counter">
        {{ 'Desktop.Widgets.Stopwatch.Paused' | translate }}
      </span>
      <span class="time-switch-inner">
        <img src="/dist/assets/images/icons/play.svg" class="time-switch-state-icon" />
      </span>
    </div>
  
    <div *ngIf="state === stateAwaitingStart">Start time: {{ awaitActionTime }}</div>
    <div *ngIf="state === stateAwaitingPauseStart">Pause starts at {{ awaitActionTime }}</div>
    <div *ngIf="state === stateAwaitingPauseEnd">Pause ends at {{ awaitActionTime }}</div>
  </div>
  