import { Injectable } from '@angular/core';

const _history = (): any => {
  return window.parent.history;
};

const _window = (): any => {
  return window.parent;
};

@Injectable()
export class ParentWindowRef {
  get nativeHistory(): any {
    return _history();
  }

  get nativeWindow(): any {
    return _window();
  }
}
