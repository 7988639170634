import { Injectable } from '@angular/core';
import { ProjectListSettingsService } from '../services/project-list-settings.service';
import { IncomingInvoiceDataInterface, IncomingInvoiceInterface } from '../interfaces/incoming-invoice.interface';
import { IncomingInvoiceModel, IncomingInvoicePrice } from '../models/incoming-invoice.model';
import { ProjectListFactory } from './project-list.factory';
import { EditBillingItemInterface } from '../../billings/modals/editBilling/interfaces/edit-billing-item-interface';
import { EditBilling } from '../../billings/modals/editBilling/models/edit-billing.model';
import { EmployeeModel } from '../../billings/modals/editBilling/models/employee.model';
import { TreeListElementStatus } from '../../common/enums/tree-list-element-status';
import { Money } from '../../../shared/troi-money/money';

@Injectable()
export class IncomingInvoiceFactory extends ProjectListFactory {
  constructor(protected projectListSettingsService: ProjectListSettingsService) {
    super(projectListSettingsService);
  }

  // public buildIncomingInvoice = (
  //   incomingInvoice: IncomingInvoiceDataInterface,
  // ): IncomingInvoiceModel => {
  //   return new IncomingInvoiceModel(incomingInvoice.date);
  // };

  public buildIncomingInvoice(incomingInvoice: EditBillingItemInterface, parentId?: string): EditBilling {
    const decimalPlaces = this.projectListSettingsService.settings.settings.decimalPlacesNumber
      ? this.projectListSettingsService.settings.settings.decimalPlacesNumber
      : 2;
    const editBillingModel = new IncomingInvoiceModel();

    editBillingModel.approvalState = incomingInvoice.approvalState;
    editBillingModel.bookingApprovalData = incomingInvoice.bookingApprovalData;
    editBillingModel.bookingGuid = incomingInvoice.bookingGuid;
    editBillingModel.id = parentId ? `${incomingInvoice.proposalId}-${parentId}` : incomingInvoice.id;
    editBillingModel.parent = parentId ? parentId : null;
    editBillingModel.isChecked = false;
    editBillingModel.date = incomingInvoice.date;
    editBillingModel.dateFormatted = incomingInvoice.date_formatted;
    editBillingModel.employee = incomingInvoice.employee
      ? new EmployeeModel(incomingInvoice.employee.id, incomingInvoice.employee.name)
      : null;
    editBillingModel.projectPath = incomingInvoice.path.replace(/&nbsp/g, ' ').replace(/&#155;/g, '>');
    editBillingModel.quantity = Number(incomingInvoice.quantity);
    editBillingModel.unit = incomingInvoice.unit;
    editBillingModel.treeStatus = TreeListElementStatus.COLLAPSED;
    editBillingModel.purchase = {
      actual: new Money(
        incomingInvoice.purchase,
        this.projectListSettingsService.settings.systemCurrency,
        decimalPlaces,
      ),
      target: new Money(
        incomingInvoice.target_purchase,
        this.projectListSettingsService.settings.systemCurrency,
        decimalPlaces,
      ),
    };
    editBillingModel.documentNumber = incomingInvoice.documentNumber;
    editBillingModel.sale = {
      actual: new Money(incomingInvoice.sale, this.projectListSettingsService.settings.systemCurrency, decimalPlaces),
      target: new Money(
        incomingInvoice.target_sale,
        this.projectListSettingsService.settings.systemCurrency,
        decimalPlaces,
      ),
    };
    editBillingModel.total =
      incomingInvoice.total !== null
        ? new Money(incomingInvoice.total, this.projectListSettingsService.settings.systemCurrency, decimalPlaces)
        : null;
    editBillingModel.margin =
      incomingInvoice.margin !== null
        ? new Money(incomingInvoice.margin, this.projectListSettingsService.settings.systemCurrency, decimalPlaces)
        : null;
    editBillingModel.internalComment = incomingInvoice.internalComment;
    editBillingModel.externalComment = incomingInvoice.externalComment;
    editBillingModel.attachmentUrl = incomingInvoice.attachmentUrl;
    editBillingModel.dmsLink = incomingInvoice.dmsLink;
    editBillingModel.isApproved = incomingInvoice.isApproved;
    editBillingModel.isBillable = incomingInvoice.isBillable;
    editBillingModel.isSkipped = incomingInvoice.isSkipped;
    editBillingModel.isInvoiced = incomingInvoice.isInvoiced;
    editBillingModel.isPassThrough = incomingInvoice.isPassThrough;
    editBillingModel.isPaid = false;
    editBillingModel.isBilled = incomingInvoice.isBilled;
    editBillingModel.isHidden = incomingInvoice.isHidden;
    editBillingModel.proposalsCount = incomingInvoice.proposalsCount;
    editBillingModel.proposalId = incomingInvoice.proposalId ? incomingInvoice.proposalId : null;
    editBillingModel.isProposalsLoading = false;
    editBillingModel.isKsK = incomingInvoice.isKsK;

    return editBillingModel;
  }

  // public buildIncomingInvoices(incomingInvoices: IncomingInvoiceInterface) {
  //   return incomingInvoices.data.map(this.buildIncomingInvoice);
  // }
  public buildIncomingInvoices(incomingInvoices: EditBillingItemInterface[], parentId?: string): EditBilling[] {
    return incomingInvoices.map((incomingInvoice: EditBillingItemInterface) =>
      this.buildIncomingInvoice(incomingInvoice, parentId),
    );
  }
}
