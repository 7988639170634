import { Component, EventEmitter, Output, Input, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { debounce } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'troi-popup-search-project',
  templateUrl: './troi-popup-search-project.component.html',
  styleUrls: ['./troi-popup-search-project.component.scss'],
})
export class TroiPopupSearchProjectComponent implements OnInit, OnChanges {
  searchString = '';

  resultSelected = false;

  open = false;

  multiSelectValues = [];

  processedInitValues = false;

  @Output() searchStringChanged = new EventEmitter<string>();

  @Output() valueSelected = new EventEmitter<any>();

  @Input() searchResult: Array<any> = [];

  @Input() loading = false;

  @Input() showSpinner = true;

  @Input() valueFieldName: string;

  @Input() labelFieldName1 = '';

  @Input() labelFieldName2 = '';

  @Input() placeholder = 'Please choose an option';

  @Input() icon = '';

  @Input() multiSelect = false;

  @Input() initialSearch = '';

  @Input() initialValues: Array<any> = [];

  @Input() transparentBackground = false;

  @Input() disabled = false;

  @Input() hideCloseIcon = false;

  constructor(private translate: TranslateService) {
    this.fetchData = debounce(this.fetchData, 500);
    this.onBlur = debounce(this.onBlur, 250);
    this.searchResult = [];
  }

  ngOnInit() {
    if (null !== this.initialSearch) {
      this.searchString = this.initialSearch;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchResult && this.searchResult.length > 0) {
      const newItem = {
        id: -1,
        name: this.translate.instant('Timerecording.found_select_one', {
          totalItems: this.searchResult.length,
        }),
      };
      this.searchResult = [newItem, ...this.searchResult];
    }

    if (changes.initialSearch) {
      if (null !== changes.initialSearch.currentValue) {
        this.searchString = changes.initialSearch.currentValue;
      }
    }

    if (false === this.processedInitValues && 0 < this.initialValues.length) {
      for (const val of this.initialValues) {
        this.setValue(val);
      }

      this.processedInitValues = true;
    }
  }

  fetchData() {
    if (this.showSpinner) this.loading = true;
    this.resultSelected = false;
    this.open = true;
    this.searchStringChanged.emit(this.searchString);
  }

  setValue(value: any) {
    if (!this.multiSelect) {
      this.searchString = value[this.labelFieldName1];
      if (this.labelFieldName2) {
        this.searchString += ' ' + value[this.labelFieldName2];
      }

      this.disabled = true;
      this.valueSelected.emit(value);
    } else {
      this.multiSelectValues.push(value);
      this.searchString = '';

      this.valueSelected.emit(this.multiSelectValues);
    }
    this.resultSelected = true;
    this.open = false;
  }

  remove(i: any) {
    this.multiSelectValues.splice(i, 1);
    this.valueSelected.emit(this.multiSelectValues);
  }

  reset() {
    this.disabled = false;
    this.searchString = '';
    this.resultSelected = false;
    this.valueSelected.emit();
  }

  toggle() {
    this.open = !this.open;
  }

  onBlur() {
    this.open = false;
  }
}
