<div *ngIf="!loaded" class="loading-spinnner-container">
  <troi-loading-spinner class="loading-spinner"></troi-loading-spinner>
</div>

<div *ngIf="loaded" class="consumption-popover">
  <div class="consumption-popover__analysis">
    <troi-progress-circle
      [hexColorCode]="sumIsNegative ? '#d9534f' : '#82ad26'"
      [consumptionPopoverAlignment]="true"
      [size]="60"
      [isNegative]="sumIsNegative"
      [solvedItems]="sumOfBookedAssignments"
      [totalItems]="totalItems"
    >
      <troi-icon icon="icon-calc" size="22px"></troi-icon>
      <span>{{
        'Tasks.labels.createTaskModal.consumptionPopover.actualAndTarget' | translate
      }}</span>
    </troi-progress-circle>
    <div class="consumption-popover__analysis--calculation">
      <div class="consumption-popover__analysis--variance-analysis">
        <div
          [ngStyle]="{ color: sumIsNegative ? '' : '#82ad26' }"
          class="consumption-popover__analysis--variance"
        >
          <span>{{
            'Tasks.labels.createTaskModal.consumptionPopover.actualValue' | translate
          }}</span>
          <span>{{ sumOfBookedAssignmentsRounded }}h </span>
        </div>
        <div class="consumption-popover__analysis--variance">
          <span>{{
            'Tasks.labels.createTaskModal.consumptionPopover.targetValue' | translate
          }}</span>
          <span>{{ getTotalItems() }}h</span>
        </div>
      </div>
      <div
        [ngStyle]="{ color: sumIsNegative ? '#d9534f' : '' }"
        class="consumption-popover__analysis--rest"
      >
        <span>{{ 'Tasks.labels.createTaskModal.consumptionPopover.rest' | translate }} </span>
        <span>{{ actualAndTargetDifference }}h</span>
      </div>
    </div>
  </div>

  <div class="consumption-popover__tasks">
    <div class="consumption-popover__tasks--subtitle">
      <div class="consumption-popover__tasks--subproject">
        <span class="w-50 consumption-popover__tasks--grey">{{
          'Tasks.labels.createTaskModal.consumptionPopover.subproject' | translate
        }}</span
        ><span class="color-black">{{
          task.calculationPosition.subproject.name || 'no subproject'
        }}</span>
      </div>
      <div class="consumption-popover__tasks--calc-pos">
        <span class="w-50 consumption-popover__tasks--grey">{{
          'Tasks.labels.createTaskModal.consumptionPopover.calculationPosition' | translate
        }}</span
        ><span class="color-black"
          >K-{{ task.calculationPosition?.id }} {{ task.calculationPosition?.serviceHeadline }}
        </span>
      </div>
    </div>
    <div class="consumption-popover__tasks--task-list">
      <div class="consumption-popover__tasks--task-list--task">
        <span>{{
          'Tasks.labels.createTaskModal.consumptionPopover.totalCalculated' | translate
        }}</span>
        <span class="color-black">{{ totalItems }}h</span>
      </div>
      <div class="consumption-popover__tasks--task-list--task color-dark-main">
        <span>{{
          'Tasks.labels.createTaskModal.consumptionPopover.totalAssigned' | translate
        }}</span>
        <span>{{ formatString(calculationPositionData.sum) }}h</span>
      </div>
      <div
        class="consumption-popover__tasks--task-list--task"
        *ngFor="let entry of calculationPositionData.entries"
      >
        <div class="consumption-popover__tasks--task-list--title">
          <span class="color-main">A {{ entry.projecttask }}</span>
          <span>{{ entry.taskTitle }}</span>
        </div>

        <span>{{ entry.hoursBudgetString }}h</span>
      </div>
      <div class="consumption-popover__tasks--task-list--task">
        <span>{{ 'Tasks.labels.createTaskModal.consumptionPopover.notAssigned' | translate }}</span>
        <span class="color-black">{{ notAssignedHours }}h</span>
      </div>
    </div>
  </div>
</div>
