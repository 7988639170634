<div
  (click)="switch()"
  class="switch"
  [ngClass]="{
    'switch--checked': _value,
    'switch--small': small,
    'switch--disabled': disabled
  }"
>
  <input
    type="checkbox"
    class="switch__input"
    [name]="name"
    [(ngModel)]="_value"
    [attr.checked]="_value"
    (focus)="scrollToView($event)"
    [attr.aria-label]="label"
  />
  <span class="switch__label"></span>
  <span class="switch__handle"></span>
</div>
