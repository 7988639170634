import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TroiDropdownListModel } from '../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { LanguagesService } from '../../../../core/services/languages.service';
import { BookingSettingsService } from './booking-settings.service';
import { Money } from '../../../../shared/troi-money/money';
import { ModalService } from '../../../../shared/troi-modals/modal.service';
import { TroiUserConfirmationComponent } from '../../../../shared/troi-user-confirmation/troi-user-confirmation.component';
import { DomService } from '../../../../shared/troi-modals/dom.service';
import { UserConfirmationSubscriber } from '../../../../shared/troi-user-confirmation/user-confirmation.subscriber';

@Injectable()
export class ProjectAssignmentDataService {
  public splitAmountChanged = new Subject();

  constructor(
    public languagesService: LanguagesService,
    public bookingSettingsService: BookingSettingsService,
    private domService: DomService,
    private confirmationSubscriber: UserConfirmationSubscriber,
  ) {
    this.bookingSettingsService.setSystemCurrencyIfNeeded();
  }

  generateDropdownOption(data): Array<TroiDropdownListModel> {
    return !data || !data.name
      ? []
      : [
          {
            label: !data.name.en ? data.name : this.languagesService.getLanguageValue(data.name),
            value: data.id,
            active: true,
          },
        ];
  }

  getIdForDropdownValue(value) {
    if (value) {
      return value.id || value;
    }
    return null;
  }

  initMoneyValue(value: string): Money {
    const formattedValue = value.replace(',', '.');
    return new Money(
      formattedValue !== '' ? formattedValue : '0',
      this.bookingSettingsService.systemCurrency,
      this.bookingSettingsService.settings.settings.decimalPlacesNumber,
    );
  }

  openConfirmationPopup(
    title = 'Booking.labels.resetProjectAssignmentData',
    executeBtnTitle = 'Booking.labels.reset',
    cancelBtnTitle = 'Common.labels.cancel',
    description = 'Common.labels.doYouReallyWantsToRemoveAllData',
  ) {
    const secondModalService = new ModalService(this.domService);
    const translations = {
      title,
      executeBtnTitle,
      cancelBtnTitle,
      description,
    };
    secondModalService.init(TroiUserConfirmationComponent, { translations }, {}, '400px', '500px', true);

    return this.confirmationSubscriber.action.pipe(first());
  }
}
