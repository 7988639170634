<div class="calc-pos-modal">
  <div class="calc-pos-modal__content">
    <div class="calc-pos-modal__info-col">
      <div class="calc-pos-modal__info-col--content">
        <div class="calc-pos-modal__info-col--icon-container">
          <troi-icon class="import-calc-pos__content--icon" icon="icon-calc"></troi-icon>
          <troi-icon
            class="import-calc-pos__content--icon"
            icon="icon-arrow-head-right"
          ></troi-icon>
          <troi-icon class="import-calc-pos__content--icon" icon="icon-kanban-board"></troi-icon>
        </div>

        <span class="calc-pos-modal__info-col--headline">{{
          'Tasks.labels.importCP.modal.infoCol.headline' | translate
        }}</span>
        <div class="calc-pos-modal__info-col--text">
          <span>
            {{ 'Tasks.labels.importCP.modal.infoCol.importCp' | translate }}
          </span>
          <span>{{ 'Tasks.labels.importCP.modal.infoCol.editTasks' | translate }}</span>
        </div>
      </div>
    </div>

    <div class="calc-pos-modal__cp-list-col">
      <troi-loading-spinner *ngIf="!loaded"></troi-loading-spinner>
      <div *ngIf="loaded" class="calc-pos-modal__cp-list-col--content">
        <div class="calc-pos-modal__cp-list-col--headline">
          <span>{{ 'Tasks.labels.importCP.modal.subtaskCol.headline' | translate }}</span>
          <span
            [ngClass]="{
              'calc-pos-modal__cp-list-col--deactivate--disabled':
                importingCPs || !cpsToImport.length
            }"
            (click)="deleteAllCP()"
            class="calc-pos-modal__cp-list-col--deactivate"
            >{{ 'Tasks.labels.importCP.modal.subtaskCol.deactivate' | translate }}</span
          >
        </div>

        <div *ngIf="!importingCPs && subprojects.length">
          <troi-import-calc-pos-subproject
            *ngFor="let subproject of subprojects"
            [subproject]="subproject"
            [cpsToImport]="cpsToImport"
            [occupiedCPs]="occupiedCPs"
            (checkboxChanged)="checkboxChange($event)"
            (removeCalcPos)="removeCalcPos($event)"
          >
          </troi-import-calc-pos-subproject>
        </div>
        <span *ngIf="!importingCPs && !subprojects.length">{{
          'Tasks.labels.importCP.modal.subtaskCol.noSubProjectsFound' | translate
        }}</span>
        <div class="import-container" *ngIf="importingCPs">
          <div class="import-container__headline">
            <span>{{ 'Tasks.labels.importCP.modal.subtaskCol.taskAreCreated' | translate }}</span>
            <span
              >{{ tasksCreated.length }}
              {{ 'Tasks.labels.importCP.modal.subtaskCol.of' | translate }}
              {{ cpsToImport.length }}
              {{ 'Tasks.labels.importCP.modal.subtaskCol.tasks' | translate }}</span
            >
          </div>
          <troi-progress-bar
            class="calc-pos-modal__cp-list-col--progress-bar"
            [progressValue]="progressValue"
            [showProgressValue]="true"
            [height]="'1.5rem'"
            [borderRadius]="'12px'"
          ></troi-progress-bar>
          <span (click)="cancelImport()" class="import-container__cancel">{{
            'Common.labels.cancel' | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="calc-pos-modal__footer">
    <div class="calc-pos-modal__footer__btn-group">
      <troi-btn id="cancel-btn" (click)="onModalClose()">
        <span>{{ 'Common.labels.cancel' | translate }}</span>
      </troi-btn>
      <troi-btn id="save-btn" (click)="onSave()" [disabled]="!cpsToImport.length">
        <span>{{ 'Common.labels.import' | translate }}</span>
      </troi-btn>
    </div>
  </div>
</div>
