import { Money } from '../../../shared/troi-money/money';
import { Currency } from '../../../shared/troi-money/currency';
import { EmployeeModel } from '../../billings/modals/editBilling/models/employee.model';
import { TreeListElementStatus } from '../../common/enums/tree-list-element-status';
import { PriceModel } from '../../billings/modals/editBilling/models/price.model';

export class IncomingInvoicePrice {
  public constructor(public currency: Currency, public price: Money) {}
}

export class IncomingInvoiceModel {
  id: string;
  parent?: string;
  isChecked: boolean;
  date: number;
  dateFormatted: string;
  employee: EmployeeModel;
  projectPath: string;
  quantity: number;
  unit: string;
  treeStatus: TreeListElementStatus;
  purchase: PriceModel;
  sale: PriceModel;
  total: Money;
  margin: Money;
  internalComment: string;
  externalComment: string;
  attachmentUrl: string;
  dmsLink: string;
  isApproved: boolean;
  isBillable: boolean;
  isSkipped: boolean;
  isInvoiced: boolean;
  isPassThrough: boolean;
  isPaid: boolean;
  isBilled: boolean;
  isHidden: boolean;
  isKsK: boolean;
  proposalsCount: number;
  proposalId?: string;
  isProposalsLoading: boolean;
  approvalState: string;
  documentNumber: string;
  bookingApprovalData: {
    actionErrorMessage: null | string;
    actions: string[];
    active: boolean;
    approvalState: string;
    summary: string;
  };
  bookingGuid: string;
  constructor() {}
}
