import { Injectable } from '@angular/core';
import { BaseSettingsDropdownInterface } from '../../interfaces/settings/dropdowns/base-settings-dropdown.interface';
import { BaseSettingsDropdownModel } from '../../models/settings/dropdowns/base-settings-dropdown.model';

@Injectable()
export class SettingsFactory {
  public createSettingsDropdown(projectStatus: BaseSettingsDropdownInterface): BaseSettingsDropdownModel {
    return new BaseSettingsDropdownModel(projectStatus.id, projectStatus.name);
  }
}
