import { Component, OnInit } from '@angular/core';
import { LanguagesService } from '../../../../core/services/languages.service';
import { UserService } from '../../../../core/user/user.service';

const editAvatarPageUrl = '/site/index.php?page=desktop_blackboard&action=edit_profile';

@Component({
  selector: 'menu-user',
  templateUrl: './menu-user.component.html',
  styleUrls: ['./menu-user.component.scss'],
})
export class MenuUserComponent implements OnInit {
  constructor(public languagesService: LanguagesService, public userService: UserService) {}

  private isInitiated = false;

  ngOnInit() {
    this.userService.fetchUser();
  }

  get user() {
    return this.userService.getUser();
  }

  get initials(): string {
    return this.user.name[0]?.toUpperCase() + this.user.surname[0]?.toUpperCase();
  }

  get avatar(): string {
    return this.user.avatarUrl;
  }

  get userTooltipMessage(): string {
    return `${this.user.name} ${this.user.surname}`;
  }

  getStyles() {
    return this.user.avatarUrl ? { 'background-image': 'url(' + this.avatar + ')' } : {};
  }

  onClickAvatar() {
    const aTag = document.createElement('a');
    const classList = ['lightbox', 'cboxElement'];
    aTag.setAttribute('href', editAvatarPageUrl);
    aTag.classList.add(...classList);
    window.top.document.querySelector('body').appendChild(aTag);
    if (!this.isInitiated) {
      // @ts-ignore
      window.top.jQuery.colorbox();
      this.isInitiated = true;
    }
    aTag.click();
    aTag.remove();
  }
}
