import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

export interface TroiNumberConfigInterface {
  digitsInfo?: string;
  decimalMark?: string;
  thousandsMark?: string;
  multipleNumbersSeparator?: string;
}

@Pipe({
  name: 'troiNumber',
})
export class NumberPipe implements PipeTransform {
  private config = {
    digitsInfo: '1.1-2',
    decimalMark: '.',
    thousandsMark: ',',
    multipleNumbersSeparator: ' / ',
  };

  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number | string | number[] | string[], config?: TroiNumberConfigInterface): string {
    this.config = {
      ...this.config,
      ...config,
    };

    if (Array.isArray(value)) {
      const numbers = [...value].map((val: string | number) => this.transform(val, config));
      return numbers.join(this.config.multipleNumbersSeparator);
    } else {
      const stringValues = this.decimalPipe.transform(value, this.config.digitsInfo)?.split('.');

      if (!stringValues) {
        return '';
      }

      if (stringValues.length < 1) {
        return stringValues.join('.');
      }
      stringValues[0] = stringValues?.[0]?.replace(/,/g, this.config.thousandsMark);

      if (stringValues?.length !== 2) {
        return stringValues?.[0] || '';
      }

      return stringValues?.join(this.config.decimalMark) || '';
    }
  }
}
