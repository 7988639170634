import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { AccountInterface } from '../interfaces/account.interface';
import { Money } from '../../../../../shared/troi-money/money';
import { AccountingSettingsService } from '../../../common/services/accounting-settings.service';
import { OpeningBalanceModel } from '../models/opening-balance.model';
import { ModelsFactory } from '../../../common/factories/models.factory';
import { OpeningBalanceInterface } from '../interfaces/opening-balance.interface';

@Injectable()
export class AddEditService {
  public form: UntypedFormGroup;

  public openingBalances: OpeningBalanceModel[] = [];

  constructor(private accountingSettingsService: AccountingSettingsService, private modelsFactory: ModelsFactory) {}

  initForm(clientId: number) {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      group: new UntypedFormControl(null, Validators.required),
      number: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      counterAccount: new UntypedFormControl(null),
      summaryAccount: new UntypedFormControl(null),
      tax: new UntypedFormControl(null),
      openingBalanceYear: new UntypedFormControl(new Date().getFullYear()),
      cashAccount: new UntypedFormControl(false),
      bankAccount: new UntypedFormControl(false),
      automaticAccount: new UntypedFormControl(false),
      depositAccount: new UntypedFormControl(false),
      datevLock: new UntypedFormControl(false),
      active: new UntypedFormControl(true),
      client: new UntypedFormControl(clientId),
    });

    this.openingBalances.push(this.createNewOpeningBalance(this.form.get('openingBalanceYear').value));
  }

  patchValues(account: AccountInterface) {
    this.form.patchValue({
      id: account.id,
      group: account.group,
      number: account.number,
      name: account.name,
      counterAccount: account.counterAccount,
      summaryAccount: account.summaryAccount,
      tax: account.tax,
      cashAccount: account.cashAccount,
      bankAccount: account.bankAccount,
      automaticAccount: account.automaticAccount,
      depositAccount: account.depositAccount,
      datevLock: account.datevLock,
      active: account.active,
    });

    this.openingBalances = this.modelsFactory.buildOpeningBalanceModels(account.openingBalances);
  }

  updateOpeningBalanceValue(value) {
    let found = false;
    const data = [];
    _.forEach(this.openingBalances, (element: OpeningBalanceModel) => {
      if (element.year === this.form.get('openingBalanceYear').value) {
        found = true;
        data.push(this.createNewOpeningBalance(element.year, value));
      } else {
        data.push(element);
      }
    });

    if (!found) {
      data.push(this.createNewOpeningBalance(this.form.get('openingBalanceYear').value, value));
    }

    this.openingBalances = data;
  }

  getOpeningBalanceModelSelectedForYear(): OpeningBalanceModel {
    const value = _.find(
      this.openingBalances,
      (data: OpeningBalanceModel) => data.year === this.form.get('openingBalanceYear').value,
    );

    if (!value) {
      const newOpeningBalance = this.createNewOpeningBalance(this.form.get('openingBalanceYear').value);
      this.openingBalances.push(newOpeningBalance);

      return newOpeningBalance;
    }

    return value;
  }

  createNewOpeningBalance(year: number, value = '0.00000000'): OpeningBalanceModel {
    const currency = this.accountingSettingsService.systemCurrency
      ? this.accountingSettingsService.systemCurrency
      : this.accountingSettingsService.buildCurrency(
          this.accountingSettingsService.settings.dropdowns.systemCurrency,
          this.accountingSettingsService.settings.client,
        );
    return new OpeningBalanceModel(
      year,
      new Money(value, currency, this.accountingSettingsService.settings.settings.decimalPlacesNumber),
    );
  }

  prepareOpeningBalancesPayload(): OpeningBalanceInterface[] {
    const data: OpeningBalanceInterface[] = [];
    _.forEach(this.openingBalances, (element: OpeningBalanceModel) => {
      data.push({
        year: element.year,
        value: element.value.value,
      });
    });

    return data;
  }
}
