<form [formGroup]="reminderTaskForm" class="reminder-task">
  <troi-modal-header
    class="reminder-task__header"
    (modalWantClose)="close()"
    [askBeforeClose]="true"
    [noCloseButton]="false"
  >
    <span class="reminder-task__headline">{{ translations.title | translate }}</span>
  </troi-modal-header>

  <troi-modal-content class="reminder-task__content">
    <troi-modal-row [withLabel]="false">
      <troi-dropdown-select
        row-value
        [fieldInvalid]="false"
        [validationEnabled]="true"
        [search]="false"
        formControlName="selectReminder"
        size="11px"
        [placeholder]="placeholder | translate"
        [options]="options"
        (selected)="selectReminderDate($event)"
      ></troi-dropdown-select>
    </troi-modal-row>
    <div *ngIf="showDatepicker" class="reminder-task__date-picker">
      <troi-icon icon="icon-calendar" class="tnd-icon-calendar"></troi-icon>
      <tnd-datepicker
        [showIcon]="false"
        [fieldInvalid]="false"
        [validationEnabled]="false"
        [singleDatePicker]="true"
        [rangeFrom]="fromDate"
        [emitIfEmpty]="false"
        [disabled]="false"
        [hideRanges]="true"
        (startDateChanged)="setIndividualDate($event)"
      ></tnd-datepicker>
    </div>
    <div></div>
  </troi-modal-content>
  <troi-modal-footer class="reminder-task__footer">
    <troi-btn
      *ngIf="reminder"
      class="footer__button footer__button--delete"
      [red]="true"
      (click)="deleteReminder()"
    >
      {{ 'Common.labels.delete' | translate }}
    </troi-btn>
    <div class="reminder-task__footer--action-buttons">
      <troi-btn class="footer__button" [outline]="true" (click)="close()">
        {{ translations.cancelBtnTitle | translate }}
      </troi-btn>
      <troi-btn class="footer__button" [outline]="false" (click)="execute()">
        {{ translations.executeBtnTitle | translate }}
      </troi-btn>
    </div>
  </troi-modal-footer>
</form>
