import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTimeFormat]',
})
export class TimeFormatDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    const input = this.el.nativeElement.value;
    const regex = /^([01]?[0-9]|2[0-3])(:[0-5][0-9])?$/;

    if (!regex.test(input)) {
      this.el.nativeElement.value = this.getValidFormattedValue(input);
    }
  }

  private getValidFormattedValue(value: string): string {
    const numericAndColonOnly = value.replace(/[^\d:]/g, '');

    if (numericAndColonOnly.length >= 3 && numericAndColonOnly.indexOf(':') === -1) {
      const hours = numericAndColonOnly.slice(0, 2);
      const minutes = numericAndColonOnly.slice(2);
      return `${hours}:${minutes}`;
    }

    return numericAndColonOnly;
  }
}
