import {
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  SimpleChanges,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { MenuInterface } from '../../interface/menu.interface';
import { LanguagesService } from '../../../../core/services/languages.service';
import { TranslationMode } from '../../../../core/enums/translationMode';

@Component({
  selector: 'menu-layer',
  templateUrl: './menu-layer.component.html',
  styleUrls: ['./menu-layer.component.scss'],
})
export class MenuLayerComponent implements OnChanges {
  public isMenuOpen = false;
  public menuWidth = 'auto';
  public selectedItem: MenuInterface | undefined;
  public resize;
  public layerOpen = false;
  public container: HTMLElement;
  public _isOpen = false;
  public _firstOpen = true;
  @Input() level = 1;
  @Input() isOpen = false;
  @Input() menu: MenuInterface[];
  @Input() title: string;
  @Input() footer: string;
  @Input() details: string;
  @Input() searchMode = false;
  @Input() searchText = '';
  @Input() loading = false;
  @Output() dragStarted = new EventEmitter<boolean>();
  @Output() transitionEnded = new EventEmitter<boolean>();
  @HostListener('window:resize') onResize() {
    clearTimeout(this.resize);
    this.resize = setTimeout(() => {
      this.calculateMenuWidth();
    }, 200);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public languagesService: LanguagesService,
    private elementRef: ElementRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ((this.searchText?.length === 0 && changes.menu) || changes.isOpen) {
      if (changes.isOpen?.currentValue) {
        this._isOpen = true;
      }
      this.selectedItem = undefined;
      this.calculateMenuWidth();
    }
  }

  onMenuTransitionEnd(event) {
    const menuIsOpen = event.target?.clientWidth !== 0;
    if (event.propertyName === 'width') {
      if (menuIsOpen) {
        this.layerOpen = menuIsOpen;
      }
      this.transitionEnded.emit(menuIsOpen);
    }
  }

  calculateMenuWidth() {
    requestAnimationFrame(() => {
      this.container = this.elementRef.nativeElement.querySelector('.menu-layer__container');
      if (!this.container) {
        return;
      }
      if (this._firstOpen) {
        this._firstOpen = false;
        this.container.addEventListener('transitionend', (event) => {
          this.onMenuTransitionEnd(event);
        });
      }

      if (this.isOpen) {
        this.layerOpen = false;
        this.container.style.opacity = '0';
        this.menuWidth = 'auto';
        requestAnimationFrame(() => {
          const width = this.container.clientWidth < 560 && this.level === 1 ? 560 : this.container.clientWidth;
          this.menuWidth = '0';

          requestAnimationFrame(() => {
            this.menuWidth = `${width}px`;
            this.container.style.opacity = '1';
          });
        });
      } else {
        this.layerOpen = false;
        this.container.style.width = '0px';
      }
    });
  }

  public get isSecondLevelOpen() {
    return this.selectedItem && Object.keys(this.selectedItem).length > 0;
  }
  public isSelectedItem(item) {
    return this.selectedItem?.id === item.id;
  }

  public getLanguageValue(string) {
    if (!string) {
      return '';
    }
    return this.languagesService.getLanguageValue(string, TranslationMode.NAVIGATION);
  }
  public selectItem(item) {
    if (this.selectedItem?.id === item.id) {
      this.selectedItem = undefined;
      return;
    }
    this.selectedItem = item;
  }

  public onDragStarted(event) {
    this.dragStarted.emit(event);
  }
}
