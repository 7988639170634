<div
  class="filter-chips"
  [ngClass]="{ 'filter-chips--unfolded': areFilterChipsUnfolded }"
  #mainContainer
  [ngStyle]="{ width: mainContainerWidth }"
>
  <div class="filter-chips__chips" #chipsContainer>
    <div
      *ngFor="let chip of chips"
      class="chips__item"
      [ngClass]="{ 'chips__item--disabled': chip.disabled }"
    >
      <div class="chips__item__value">
        {{ chip.label | translate }} :
        <strong>
          <span *ngIf="!chip.valueIsTranslatable">{{ chip.value | translate }}</span>
          <span *ngIf="chip.valueIsTranslatable">{{ chip.value }}</span>
        </strong>
      </div>
      <div *ngIf="!chip.disabled" class="chips__item__close" (click)="onFilterChipsClosed(chip)">
        <troi-icon class="chips__item__close__icon" icon="icon-close"></troi-icon>
      </div>
    </div>
  </div>

  <div class="filter-chips__unfold">
    <troi-icon
      *ngIf="areChipsContainerExtendable"
      class="unfold__icon"
      icon="icon-arrow-head-right"
      [bolder]="true"
      [large]="true"
      (click)="toggleFoldState()"
    ></troi-icon>
  </div>
</div>
