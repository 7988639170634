import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'troi-radio-icons',
  templateUrl: './troi-radio-icons.component.html',
  styleUrls: ['./troi-radio-icons.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TroiRadioIconsComponent),
      multi: true,
    },
  ],
})
export class TroiRadioIconsComponent implements ControlValueAccessor {
  @Input() public iconName: string;
  @Input() public actionName: string;
  @Input() public groupName: string;
  @Input() public tooltip: string;
  @Input() public className: string;
  @Input() public radioModel: string;
  @Input() public required: boolean;
  @Input() public disabled: string;

  propagateChange = (_: any) => {};
  onTouchedCallback = () => {};

  constructor() {}

  writeValue(value: any) {}
  registerOnChange(func: any) {
    this.propagateChange = func;
  }
  registerOnTouched(func: any) {
    this.onTouchedCallback = func;
  }
}
