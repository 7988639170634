<div class="filters sideBarOpen">
  <div class="filters__title" [ngClass]="newDesign ? 'filters__title--new-design' : ''">
    <troi-icon *ngIf="newDesign" icon="icon-filter" size="25px"></troi-icon>
    <p>{{ 'Booking.labels.filter' | translate }}</p>
  </div>
  <troi-modal-content
    class="filters__content"
    [ngClass]="newDesign ? 'filters__content--new-design' : ''"
  >
    <div class="popover-content sideBarOpen">
      <tnd-search-select
        (searchStringChanged)="onClientSearchStringChanged($event)"
        [searchResult]="resultClients"
        placeholder="{{ 'Desktop.Widgets.Projecttimes.SearchCalcPosition.Client' | translate }}"
        labelFieldName1="name"
        valueFieldName="id"
        [loading]="!loadedClients"
        [initialValues]="initialClient"
        icon="icon-persons-with-enter"
        (valueSelected)="onClientSelected($event)"
      >
      </tnd-search-select>

      <tnd-search-select
        (searchStringChanged)="onCustomerSearchStringChanged($event)"
        [searchResult]="resultCustomers"
        placeholder="{{ 'Desktop.Widgets.Projecttimes.SearchCalcPosition.Customer' | translate }}"
        labelFieldName1="name"
        valueFieldName="id"
        [loading]="!loadedCustomers"
        [initialValues]="initialCustomers"
        icon="icon-person-with-list"
        (valueSelected)="onCustomerSelected($event)"
      >
      </tnd-search-select>

      <tnd-search-select
        (searchStringChanged)="onProjectSearchStringChanged($event)"
        [searchResult]="resultProjects"
        placeholder="{{ 'Desktop.Widgets.Projecttimes.SearchCalcPosition.Project' | translate }}"
        labelFieldName1="name"
        valueFieldName="id"
        [loading]="!loadedProjects"
        [initialValues]="initialProject"
        icon="icon-folder"
        (valueSelected)="onProjectSelected($event)"
      >
      </tnd-search-select>
    </div>
  </troi-modal-content>
  <troi-modal-footer>
    <troi-btn class="filterBtn" (click)="emitFiltersReset()" [outline]="true">{{
      'Booking.labels.resetFilters' | translate
    }}</troi-btn>
    <troi-btn class="filterBtn" (click)="emitFiltersApplied()">{{
      'Booking.labels.applyFilters' | translate
    }}</troi-btn>
  </troi-modal-footer>
</div>
