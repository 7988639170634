<div class="troi-uploading" [ngClass]="actualProgress()">
  <span class="troi-uploading__label">
    <span
      class="troi-uploading__label__icon"
      [ngClass]="{
        'icon-tick': !number && progress === 100 && status === FileStatusEnum.UPLOADED,
        'icon-close': !number && progress === 100 && status === FileStatusEnum.CANCELLED
      }"
      >{{ number }}
    </span>
  </span>
  <div class="circle">
    <div class="left-side half-circle"></div>
    <div class="right-side half-circle"></div>
  </div>
</div>
