import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TaskModel } from '../../../../../models/task.model';
import { CommentInterface } from '../../../../../interfaces/comment.interface';
import { TasksHelperService } from '../../../../../services/helper.service';
import { UserService } from '../../../../../../../core/user/user.service';
import { QuillEditorComponent } from 'ngx-quill';
import { Subscription } from 'rxjs';
import { CommentsService } from '../../../../../network/comments.service';
import { AssigneeInterface } from '../../../../../interfaces/assignee.interface';
import { TasksService } from '../../../../../network/tasks.service';
import { AttachmentResponseInterface, CommentResponseInterface } from '../../../../../interfaces/responses.interface';
import { AttachmentInterface } from '../../../../../interfaces/attachment.interface';
import { AttachmentType } from '../../../../../enum/attachment-type';
import { AttachmentService } from '../../network/attachment.service';
import { StorageNotificationService } from '../../../../../../../core/notifications/storageNotification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'troi-task-modal-comments',
  templateUrl: './comments-content.component.html',
  styleUrls: ['./comments-content.component.scss'],
})
export class CommentsContentComponent implements OnInit, OnDestroy {
  @ViewChild('editor') editor: QuillEditorComponent;
  @Input() task: TaskModel;
  @Input() currentAssignee: AssigneeInterface;

  @Output() commentsChanged = new EventEmitter<boolean>();
  @Output() disableMenu = new EventEmitter<boolean>();

  private subscriptions: Subscription = new Subscription();
  public comments: CommentInterface[] = [];
  public comment: string;
  public uploadedAttachments = [] as AttachmentInterface[];
  public answerComment: CommentInterface;
  public showTextfield = false;

  constructor(
    public helperService: TasksHelperService,
    private commentService: CommentsService,
    private tasksService: TasksService,
    private attachmentService: AttachmentService,
    private notificationService: StorageNotificationService,
    private translationService: TranslateService,
  ) {}

  ngOnInit() {
    this.getComments();
  }

  public convertURL(comment: string) {
    this.comment = this.helperService.addHTTPSToURL(comment);
  }

  private getComments() {
    this.subscriptions.add(
      this.commentService.getComments(this.task.id).subscribe((res: CommentResponseInterface) => {
        this.comments = res.data as CommentInterface[];
        this.sortCommentsByDate();
        this.commentsChanged.emit(false);
      }),
    );
  }

  private sortCommentsByDate() {
    this.comments.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
    this.comments.forEach((comment) => {
      if (comment.answers)
        comment.answers.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
    });
  }

  public onCommentClick() {
    if (this.comment && this.comment.length > 0) {
      if (this.answerComment) {
        this.createCommentAnswer();
      } else {
        this.disableMenu.emit(true);
        this.subscriptions.add(
          this.commentService.createComment(this.comment, this.task).subscribe((res: CommentResponseInterface) => {
            const comment = res.data as CommentInterface;
            this.comments.push(comment);
            if (this.uploadedAttachments.length > 0) this.uploadAttachments(comment.id);
            this.commentsChanged.emit(false);
            this.disableMenu.emit(false);
            this.getComments();
            this.sortCommentsByDate();
            this.uploadedAttachments = [];
          }),
        );
      }
      this.showTextfield = false;
      this.comment = '';
    } else {
      this.notificationService.showError(
        this.translationService.instant('Tasks.labels.notifications.comments.noComment'),
      );
    }
  }

  private uploadAttachments(commentId: string) {
    this.uploadedAttachments.forEach((attachment) => {
      attachment.parentId = commentId;
      this.subscriptions.add(
        this.attachmentService.uploadAttachment(attachment).subscribe((res: AttachmentResponseInterface) => {
          this.notificationService.showSuccess(
            this.translationService.instant('Tasks.labels.notifications.attachment.upload'),
          );
        }),
      );
    });
  }

  private createCommentAnswer() {
    this.subscriptions.add(
      this.commentService
        .createCommentAnswer(this.comment, this.answerComment)
        .subscribe((res: CommentResponseInterface) => {
          if (!this.answerComment.answers) this.answerComment.answers = [];
          this.answerComment.answers.push(res.data as CommentInterface);
          this.sortCommentsByDate();
          this.answerComment = null;
        }),
    );
  }

  public toggleTextfield() {
    this.showTextfield = !this.showTextfield;
    if (this.showTextfield) {
      this.quillEditorFocus();
    } else if (!this.showTextfield) {
      this.answerComment = null;
      this.comment = null;
      this.uploadedAttachments = [];
    }
  }

  // sets focus to textarea and marks the text
  public onReplyClick(comment: CommentInterface) {
    this.showTextfield = true;
    this.quillEditorFocus();
    this.answerComment = comment;
  }

  private quillEditorFocus() {
    setTimeout(() => {
      const quillEditor = this.editor.quillEditor;
      quillEditor.focus();
      quillEditor.setSelection(0, quillEditor.getLength());
    });
  }

  public setLastReadCommentsDate() {
    if (this.currentAssignee) {
      this.currentAssignee.lastReadComments = new Date();
      this.subscriptions.add(this.tasksService.updateAssignee(this.task.id, this.currentAssignee).subscribe());
    }
  }

  public onFileUpload(files: File[], dropzone): void {
    files.forEach((file) => {
      const newAttachment: AttachmentInterface = {
        parentId: (this.uploadedAttachments.length + 1).toString(),
        attachmentType: AttachmentType.COMMENT,
        filename: file.name,
        file,
      };

      this.uploadedAttachments.push(newAttachment);
      dropzone.reset();
    });
  }

  public onFileDelete(attachment: AttachmentInterface) {
    this.uploadedAttachments = this.uploadedAttachments.filter(
      (x: AttachmentInterface) => x.parentId !== attachment.parentId,
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.setLastReadCommentsDate();
    this.commentsChanged.emit(true);
  }
}
