import { Component, Input } from '@angular/core';

@Component({
  selector: 'troi-filter',
  templateUrl: './troi-filter.component.html',
  styleUrls: ['./troi-filter.component.scss'],
})
export class TroiFilterComponent {
  @Input() public disabled = false;
}
