export class UserModel {
  constructor(
    private _id: number,
    private _name: string,
    private _surname: string,
    private _username: string,
    private _avatarUrl: string | null,
    private _isLogged: boolean,
    private _lang: string,
  ) {}

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  get surname(): string {
    return this._surname;
  }

  set surname(value: string) {
    this._surname = value;
  }

  get avatarUrl(): string {
    return this._avatarUrl;
  }

  set avatarUrl(value: string) {
    this._avatarUrl = value;
  }

  get isLogged(): boolean {
    return this._isLogged;
  }

  set isLogged(value: boolean) {
    this._isLogged = value;
  }

  get lang() {
    return this._lang;
  }

  set lang(value: string) {
    this._lang = value;
  }
}
