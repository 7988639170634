import { Injectable } from '@angular/core';
import { Routes } from '../enum/routes';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ConsumptionBookedResponseInterface,
  ConsumptionPlanResponseInterface,
} from '../interfaces/responses.interface';

@Injectable()
export class ConsumptionPopoverService {
  constructor(private http: HttpClient) {}
  private headers: HttpHeaders;

  public getAllBookings(calcPosId: number): Observable<ConsumptionBookedResponseInterface> {
    return this.http.get<ConsumptionBookedResponseInterface>(Routes.TIMERECORD + `/booked?cpId=${calcPosId}`, {
      headers: this.headers,
    });
  }

  public getAllPlans(calcPosId: number): Observable<ConsumptionPlanResponseInterface> {
    return this.http.get<ConsumptionPlanResponseInterface>(Routes.TIMERECORD_PLANNED + `?cpId=${calcPosId}`, {
      headers: this.headers,
    });
  }
}
