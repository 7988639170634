import {
  Component,
  ViewChild,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  AfterViewInit,
} from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';

const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);

@Component({
  selector: 'troi-timepicker',
  templateUrl: './troi-timepicker.component.html',
  styleUrls: ['./troi-timepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TroiTimepickerComponent),
      multi: true,
    },
  ],
})
export class TroiTimepickerComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('input', { static: false, read: ElementRef }) inputElement: ElementRef;
  @ViewChild(NgbTimepicker, { static: true, read: ElementRef }) timepickerContainer: ElementRef;
  @ViewChild(NgbTimepicker, { static: true }) timepicker: NgbTimepicker;
  @Input() public spinners = false;
  @Input() public asString = false;
  @Input() public showSeconds = false;
  @Input() public disabled = false;
  @Output() public valueChange = new EventEmitter();

  onChange: any = () => {};
  onTouched: any = () => {};

  private _value: NgbTimeStruct | string | null;

  get value() {
    return this._value;
  }

  set value(val) {
    if (val !== this._value) {
      this._value = val;
      const modelValue = this.toModel(this._value);
      if (modelValue !== null) {
        this.onChange(modelValue);
      }
      this.onTouched();
    }
  }

  writeValue(value: any) {
    if (value) {
      this.value = this.fromModel(value);
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  fromModel(value: string | NgbTimeStruct | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    if (typeof value === 'object' && !this.asString) {
      return value;
    }
    if (typeof value === 'string') {
      const split = value.split(':');
      return {
        hour: parseInt(split[0], 10),
        minute: parseInt(split[1], 10),
        second: parseInt(split[2], 10),
      };
    }
    return null;
  }
  toModel(time: NgbTimeStruct | string | null): NgbTimeStruct | string | null {
    if (typeof this.value === 'object' && !this.asString) {
      return time;
    }
    if (typeof time === 'string') {
      return time;
    }
    let timeToReturn = time != null ? `${pad(time.hour)}:${pad(time.minute)}` : null;
    if (this.showSeconds && time !== null) {
      timeToReturn += `:${pad(time.second)}`;
    }
    return timeToReturn;
  }

  ngAfterViewInit() {
    this.focusOnTimepickerInput();
  }

  focusOnTimepickerInput() {
    setTimeout(() => {
      const hourInput = document.querySelector('.ngb-tp-hour input') as HTMLInputElement;
      if (hourInput) {
        hourInput.focus();
      }
    }, 0);
  }

  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const value = input.value.replace(/\D/g, '');
    if (value.length === 2) {
      const fieldset = this.findParentFieldset(input);
      if (fieldset) {
        const minuteInput = fieldset.querySelector('.ngb-tp-minute input') as HTMLInputElement;
        if (minuteInput) {
          minuteInput.focus();
          minuteInput.select();
        }
      }
    }
  }

  private findParentFieldset(input: HTMLInputElement): HTMLFieldSetElement | null {
    let parent = input.parentElement;
    while (parent && parent.tagName !== 'FIELDSET') {
      parent = parent.parentElement;
    }
    return parent as HTMLFieldSetElement;
  }
}
