<form [formGroup]="exportListForm" class="export-list">
  <troi-modal-header [askBeforeClose]="true" (modalWantClose)="close()">
    <span>{{ 'ProjectList.labels.modals.exportList.title' | translate }}</span>
  </troi-modal-header>
  <troi-modal-content
    class="export-list__content"
    [ngClass]="{
      'export-list__content--completed':
        exportListService.exportState.status === exportStatus.COMPLETED
    }"
  >
    <span
      class="content__description"
      [ngClass]="{
        'content__description--error': exportListService.exportState.status === exportStatus.ERROR
      }"
    >
      {{ description | translate }}
      <a
        *ngIf="
          exportListService.exportState.status === exportStatus.COMPLETED &&
          exportListService.exportState.directLink
        "
        [attr.href]="exportListService.directLink"
        target="_blank"
        download
      >
        {{ 'ProjectList.labels.modals.exportList.directLink' | translate }}.</a
      >
    </span>
    <div class="content__progress progress" *ngIf="isExporting">
      <div
        class="progress__bar progress-bar"
        role="progressbar"
        [ngStyle]="{ width: progress }"
        aria-valuemin="0"
        aria-valuetext="100"
        aria-valuemax="100"
      >
        {{ progress }}
      </div>
    </div>
    <troi-modal-row
      [withLabel]="false"
      *ngIf="
        exportListService.exportState.status === exportStatus.UNKNOWN ||
        exportListService.exportState.status === exportStatus.ERROR
      "
    >
      <troi-dropdown-select
        row-value
        formControlName="exportType"
        validationEnabled="true"
        [fieldInvalid]="exportListForm.controls.exportType.errors && exportListService.submitted"
        [validationEnabled]="true"
        [disable]="exportListService.pending"
        validationMessage="ProjectList.labels.modals.exportList.exportType.required"
        size="11px"
        placeholder="{{
          'ProjectList.labels.modals.exportList.exportType.placeholder' | translate
        }}"
        [options]="exportTypeOptions"
      ></troi-dropdown-select>
    </troi-modal-row>
  </troi-modal-content>
  <troi-modal-footer
    class="export-list__footer"
    *ngIf="exportListService.exportState.status !== exportStatus.COMPLETED"
  >
    <div class="footer__error" *ngIf="exportListService.submitted && exportListForm.invalid">
      {{ 'ProjectList.labels.modals.fillAllRequiredFields' | translate }}
    </div>
    <troi-btn
      *ngIf="exportListService.exportState.status !== exportStatus.COMPLETED"
      class="footer__button"
      (click)="close()"
      [outline]="true"
      [submit]="false"
      >{{ 'Common.labels.cancel' | translate }}</troi-btn
    >
    <troi-btn
      *ngIf="
        exportListService.exportState.status === exportStatus.UNKNOWN ||
        exportListService.exportState.status === exportStatus.ERROR
      "
      class="footer__button footer__button--save"
      [disabled]="exportListService.pending"
      [submit]="true"
      (click)="export()"
      >{{ 'Common.labels.export' | translate }}</troi-btn
    >
  </troi-modal-footer>
</form>
