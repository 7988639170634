export enum TaskActionsEnum {
  RECORD_WORKING_TIME = 'recordWorkingTime',
  START_STOPWATCH = 'startStopwatch',
  REMINDER = 'reminder',
  EDIT = 'editTask',
  COPY = 'copyTask',
  MOVE_TASK = 'moveTask',
  DELETE_TASK = 'deleteTask',
  DELETE_SUBTASK = 'deleteSubtask',
}
