import { ChangeDetectorRef, Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MenuNetwork } from '../../network/menu.network';
import { MenuInterface, MenuResponseInterface, MenuCoreInterface } from '../../interface/menu.interface';
import { LanguagesService } from '../../../../core/services/languages.service';
import { MenuSettingsService } from '../../services/menu-settings.service';
import { TranslationMode } from '../../../../core/enums/translationMode';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'menu-container',
  templateUrl: './menu-container.component.html',
  styleUrls: ['./menu-container.component.scss'],
})
export class MenuContainerComponent implements OnInit, AfterViewInit {
  @ViewChild('menuLayerComponent', { static: false }) menuLayerComponentRef: any;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private menuNetwork: MenuNetwork,
    private languagesService: LanguagesService,
    private menuSettingsService: MenuSettingsService,
  ) {}

  public isMenuOpen = false;
  public menuWidth = 'auto';
  public version: string;
  public searchText: string;
  public activeQuickLink: MenuInterface;
  public activeMenu: MenuInterface;
  public foundItems: MenuInterface[];
  public menu = [];
  public quickLinks = [];
  public appMenu;
  public focused = false;
  public isDragStarted = false;
  public itemToRemove = false;
  public backdropClicked = false;
  public loading = false;
  public chatIsVisible = false;

  public currentUrl = new URL(window.top.location.toString());

  public coreMenu: MenuCoreInterface[] = [
    {
      iconName: 'icon-troi-logo',
      size: '30px',
    },
  ];

  ngOnInit(): void {
    this.fetchMenu();
    this.fetchSettings();
    this.version = environment.version;
  }

  ngAfterViewInit(): void {
    this.appMenu = window.top.document.querySelector('#appMenu');
    this.changeDetectorRef.detectChanges();
    const iframe = window.top.document.getElementById('iframeContainer');
    iframe?.addEventListener('load', () => {
      this.fetchSettings();
    });
  }

  fetchSettings() {
    this.menuSettingsService.downloadSettings(null).subscribe(() => {
      this.chatIsVisible = this.menuSettingsService.settings.chat.isVisible;
    });
  }

  public fetchMenu() {
    this.loading = true;
    this.menuNetwork.fetchMenu().subscribe((response: MenuResponseInterface) => {
      this.loading = false;
      this.menu = response.menu;
      this.quickLinks = response.shortcuts;
      this.activeQuickLink = this.findCurrentItem(this.quickLinks);
      this.activeMenu = this.findCurrentItem(this.menu);
      this.isDragStarted = false;
      this.fillEmptyIcons(this.menu);
    });
  }

  onSearchTextChange(text) {
    this.searchText = text;
    if (this.isSearchDisplayed) {
      this.foundItems = this.findItemWithText(text, this.menu);
      return;
    }
    this.foundItems = [];
  }

  onMenuToggle(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  onMouseOver(): void {
    if (!this.appMenu || this.focused) {
      return;
    }
    this.appMenu.style.width = '100%';
    this.focused = true;
  }
  onMouseOut(): void {
    if (!this.appMenu || !this.focused || this.isMenuOpen) {
      return;
    }
    this.appMenu.style.width = '100px';
    this.focused = false;
    this.isMenuOpen = false;
  }

  onMenuClick() {
    this.backdropClicked = false;
    this.onMenuToggle();
  }

  onClickBackdrop() {
    this.backdropClicked = true;
    this.onMenuToggle();
  }

  onTransitionEnded(event) {
    if (!event && this.backdropClicked) {
      this.onMouseOut();
    }
  }

  get isSearchDisplayed() {
    return this.searchText?.length > 1;
  }

  get menuToDisplay() {
    if (this.isSearchDisplayed) {
      return this.foundItems;
    }
    return this.menu;
  }

  isActive(node): boolean {
    return node.isActive;
  }

  findCurrentItem(array) {
    for (const node of array) {
      if (this.isActive(node)) return node;
      if (node.subMenu) {
        const child = this.findCurrentItem(node.subMenu);
        if (child) return child;
      }
    }
  }

  fillEmptyIcons(array) {
    for (const node of array) {
      if (!node.iconName) {
        node.iconName = 'troi-menu-no-icon-placeholder';
      }
      if (node.subMenu) {
        this.fillEmptyIcons(node.subMenu);
      }
    }
  }

  findItemWithText(text, array, parentNode?, grandNode?) {
    const _text = text.toLowerCase();
    let itemsWithText = [];
    for (const node of array) {
      if (
        this.languagesService.getLanguageValue(node.label, TranslationMode.NAVIGATION).toLowerCase().includes(_text) ||
        node.aliases?.toLowerCase().includes(_text) ||
        parentNode?.aliases?.toLowerCase().includes(_text) ||
        grandNode?.aliases?.toLowerCase().includes(_text)
      ) {
        itemsWithText.push(node);
      }
      if (node.subMenu) {
        const childWithText = this.findItemWithText(_text, node.subMenu, node, parentNode);
        itemsWithText = [...itemsWithText, ...childWithText];
      }
    }
    return itemsWithText;
  }

  public hasSlotIcon(index) {
    return Boolean(this.quickLinks[index]?.iconName);
  }

  prepareQuickLinks(uuid, index) {
    let currentQlIds = this.quickLinks.map((s) => s.id);
    const existingIndex = currentQlIds.indexOf(uuid);
    const _index = existingIndex !== -1 && existingIndex < index ? index - 1 : index;
    if (existingIndex !== -1) {
      currentQlIds = currentQlIds.filter((qlUuid) => qlUuid !== uuid);
    }

    if (_index > currentQlIds.length - 1) {
      currentQlIds.push(uuid);
    }
    if (_index <= currentQlIds.length - 1) {
      currentQlIds[_index] = uuid;
    }
    if (currentQlIds.length > 6) {
      currentQlIds.length = 6;
    }
    return currentQlIds;
  }

  updateQuickLinks(quickLinks) {
    this.menuNetwork.updateQuickLinks(quickLinks).subscribe(() => {
      this.fetchMenu();
    });
  }

  onDragStarted(event, isQuickLink?) {
    setTimeout(() => {
      this.isDragStarted = event.state;
      if (!this.isDragStarted && isQuickLink && this.itemToRemove) {
        this.removeQuickLink(event.item?.id);
      }
    }, 0);
  }

  removeQuickLink(id) {
    const newQl = this.quickLinks.map((qLink) => qLink.id).filter((qLinkId) => qLinkId !== id);
    this.updateQuickLinks({ menuIds: newQl });
  }

  onDragEnter(event) {
    event.target.classList.add('active');
    this.itemToRemove = false;
  }

  onDragOver(event) {
    event.preventDefault();
    return false;
  }

  onDragLeave(event) {
    event.target.classList.remove('active');
    this.itemToRemove = true;
  }

  onDrop(event, i) {
    event.target.classList.remove('active');
    const droppedItem = JSON.parse(event.dataTransfer.getData('application/json'));
    const newQuickLinks = this.prepareQuickLinks(droppedItem.id, i);
    this.updateQuickLinks({ menuIds: newQuickLinks });
  }

  handleEnterPressed() {
    if (this.foundItems?.length === 1) {
      const foundItem = this.menuLayerComponentRef.elementRef.nativeElement.querySelector(
        `a[href="${this.foundItems[0].link}"]`,
      );
      foundItem?.click();
    }
  }
}
