import { Component, Input } from '@angular/core';

@Component({
  selector: 'troi-popover',
  templateUrl: './troi-popover.component.html',
  styleUrls: ['./troi-popover.component.scss'],
})
export class TroiPopoverComponent {
  @Input() content: string;
  @Input() popoverHeadline = '';
  @Input() subtitle = false;
  @Input() popoverClass = '';
  @Input() placement = 'bottom top';
  @Input() autoClose = 'outside';
  @Input() subtitleColor = '#4fadf9';
  @Input() width = '';
  @Input() maxWidth = '';

  constructor() {}
}
