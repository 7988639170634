<a
  *ngIf="isAnchor"
  [draggable]="true"
  (dragstart)="onDragStart($event)"
  (dragend)="onDragEnd($event)"
  href="{{ item.link }}"
  target="{{ menuService.target(item) }}"
  class="menu-item {{ 'menu-item--level-' + level }}"
  (click)="checkAnyUnsavedItem()"
  [ngClass]="{
    'menu-item--drag-started': dragStarted,
    'menu-item--active': active,
    'menu-item--deprecated': item.isDeprecated
  }"
>
  <div class="menu-item__icon">
    <troi-icon [icon]="item.iconName" [size]="'32px'"></troi-icon>
    <div class="menu-item__deprecated-label" *ngIf="item.isDeprecated">
      {{ 'Menu.deprecated' | translate }}
    </div>
  </div>
  <div class="menu-item__deprecated-sign" *ngIf="item.isDeprecated">
    <troi-icon [icon]="'icon-circle-crossed'" [size]="'15px'"></troi-icon>
  </div>
  <div class="menu-item__label">
    <span
      class="menu-item__label-text"
      [innerHTML]="menuService.getLanguageValue(item.label)"
    ></span>
  </div>
</a>
<button
  *ngIf="!isAnchor"
  (click)="selectItem()"
  class="menu-item {{ 'menu-item--level-' + level }}"
  [ngClass]="{ 'menu-item--active': active, 'menu-item--deprecated': item.isDeprecated }"
>
  <div class="menu-item__icon">
    <div class="menu-item__icon--regular">
      <troi-icon [icon]="item.iconName" [size]="'32px'"></troi-icon>
    </div>
    <div class="menu-item__icon--hover">
      <troi-icon
        class="menu-item__small-icon"
        [icon]="iconName"
        *ngFor="let iconName of subMenuIcons"
        [size]="'20px'"
      ></troi-icon>
    </div>

    <div class="menu-item__deprecated-label" *ngIf="item.isDeprecated">
      {{ 'Menu.deprecated' | translate }}
    </div>
  </div>
  <div class="menu-item__deprecated-sign" *ngIf="item.isDeprecated">
    <troi-icon [icon]="'icon-circle-crossed'" [size]="'15px'"></troi-icon>
  </div>
  <div class="menu-item__label">
    <span
      class="menu-item__label-text"
      [innerHTML]="menuService.getLanguageValue(item.label)"
    ></span>
  </div>
</button>
