<div
  class="troi-range"
  [ngClass]="{
    'troi-range--disabled': disabled
  }"
>
  <span class="troi-range__label troi-range__label--first"
    >{{ 'Booking.labels.from' | translate }}:</span
  >
  <troi-input-field
    row-value
    (change)="changed($event.target.value, 0)"
    [value]="rangeFrom"
    [enabled]="!disabled"
  ></troi-input-field>
  <span *ngIf="yearSign()" class="troi-range__placeholder">- {{ yearSign() }}</span>
  <span class="troi-range__label">{{ 'Booking.labels.to' | translate }}:</span>
  <troi-input-field
    row-value
    (change)="changed($event.target.value, 1)"
    [value]="rangeTo"
    [enabled]="!disabled"
  ></troi-input-field>
  <span *ngIf="yearSign()" class="troi-range__placeholder">- {{ yearSign() }}</span>
</div>
