import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'troi-input-with-mask',
  templateUrl: './troi-input-with-mask.component.html',
  styleUrls: ['./troi-input-with-mask.component.scss'],
})
export class TroiInputWithMaskComponent implements OnInit {
  _value;

  @Input() public value: any;

  @Input() public enabled = true;

  @Input() public fieldInvalid = false;

  @Input() public validationEnabled = false;

  @Input() public placeholder = '';

  @Input() public mask = null;

  @Input() public decimalMarker = null;

  @Input() public separatorLimit = 100000;

  @Input() public type = 'text';

  @Input() error = '';

  @Output() valueChanged = new EventEmitter();

  ngOnInit() {
    this._value = this.value;
  }
}
