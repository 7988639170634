<div
  class="original-value-tooltip"
  [ngClass]="{
    'original-value-tooltip__left': alignLeft(),
    'original-value-tooltip__right': alignRight(),
    'original-value-tooltip__top': alignTop(),
    'original-value-tooltip__bottom': alignBottom()
  }"
>
  <div class="original-value-tooltip__label">
    {{ 'PriceList.labels.default_value' | translate }}
  </div>
  <div class="original-value-tooltip__value underline">
    {{ value }}
  </div>
</div>
