import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { FiltersInterface } from '../../../../../shared/troi-data-listing-filters/filters.interface';
import { CommonFiltersFormService } from '../../../../common/services/common-filters-form.service';

@Injectable()
export class FiltersFormService extends CommonFiltersFormService {
  prepareSearchRequestParams(filters: FiltersInterface) {
    const data = {
      group: _.isUndefined(filters.dropdownSecond) || _.isNull(filters.dropdownSecond) ? '' : filters.dropdownSecond,
    };

    return {
      ...data,
      ...super.prepareSearchRequestParams(filters),
    };
  }
}
