import { Injectable, OnDestroy } from '@angular/core';
import { ReminderInterface } from '../interfaces/reminder.interface';
import { Observable, Subscription } from 'rxjs';
import { ReminderResponseInterface } from '../interfaces/responses.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModuleInterceptor } from '../../../core/enums/module-interceptor';
import { environment } from '../../../../environments/environment';
import { Routes } from '../enum/routes';
import { PersonInterface } from '../../../shared/troi-person/interfaces/person.interface';
import { UserModel } from '../../../core/user/user.model';

@Injectable()
export class ReminderService implements OnDestroy {
  // in this case, subscriptions have to be stored here - Reminder-Modal gets destroyed before the last api call gets triggered!
  private subscriptions: Subscription = new Subscription();
  public reminders: ReminderInterface[] = [];

  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');
  }

  public getUserReminder(employeeId: string): Observable<ReminderResponseInterface> {
    return this.http.get<ReminderResponseInterface>(environment.url + Routes.REMINDER + `/${employeeId}`, {
      headers: this.headers,
    });
  }

  public getTaskReminder(taskId: string): ReminderInterface[] {
    return this.reminders.filter((r: ReminderInterface) => r.taskId === taskId);
  }

  public setReminder(user: UserModel, taskId: string, reminderDate: Date): void {
    const employee: PersonInterface = {
      id: user.id,
      firstName: user.name,
      lastName: user.surname,
      image: '',
    };
    const newReminder: ReminderInterface = {
      employee,
      taskId,
      reminderDate,
    };
    this.subscriptions.add(
      this.http
        .post<ReminderResponseInterface>(environment.url + Routes.REMINDER, newReminder, {
          headers: this.headers,
        })
        .subscribe((res: ReminderResponseInterface) => {
          this.reminders.push(res.data as ReminderInterface);
        }),
    );
  }

  public updateReminder(reminder: ReminderInterface): void {
    this.subscriptions.add(
      this.http
        .put<ReminderResponseInterface>(environment.url + Routes.REMINDER + `/${reminder.id}`, reminder, {
          headers: this.headers,
        })
        .subscribe((res: ReminderResponseInterface) => {
          this.reminders[this.reminders.findIndex((x) => x.id === reminder.id)] = res.data as ReminderInterface;
        }),
    );
  }

  public deleteReminder(id: number): void {
    this.subscriptions.add(
      this.http
        .delete<ReminderResponseInterface>(environment.url + Routes.REMINDER + `/${id}`, {
          headers: this.headers,
        })
        .subscribe(() => {
          this.reminders = this.reminders.filter((x) => x.id !== id);
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
