import { ApprovalsActionsEnum } from '../enums/approvals-enums';

enum ApprovalStatusIcon {
  PENDING = 'icon-circle-interrupted-line',
  DECLINED = 'icon-circle-crossed',
  APPROVED = 'icon-tick',
  CANCELLED = 'icon-close',
}
export class ApprovalsService {
  constructor() {}
  getApprovalStatusIcon(status: string): string {
    return status ? ApprovalStatusIcon[status.toUpperCase()] : '';
  }
  getApprovalStatusClass(status: string): string {
    return status ? `approval-status--${status.toLowerCase()}` : '';
  }
  canEdit(approvalStatus): boolean {
    if (!approvalStatus?.actions) {
      return false;
    }
    return approvalStatus.actions.includes(ApprovalsActionsEnum.EDIT);
  }
  canStart(approvalStatus): boolean {
    if (!approvalStatus?.actions) {
      return false;
    }
    return approvalStatus.actions.includes(ApprovalsActionsEnum.START);
  }
  canCancel(approvalStatus): boolean {
    if (!approvalStatus?.actions) {
      return false;
    }
    return approvalStatus.actions.includes(ApprovalsActionsEnum.CANCEL);
  }
  canHistory(approvalStatus): boolean {
    if (!approvalStatus?.actions) {
      return false;
    }
    return approvalStatus.actions.includes(ApprovalsActionsEnum.HISTORY);
  }
  canDelegate(approvalStatus): boolean {
    if (!approvalStatus?.detailedActions) {
      return false;
    }
    return approvalStatus.detailedActions.includes(ApprovalsActionsEnum.DELEGATE);
  }
  canApprove(approvalStatus): boolean {
    if (!approvalStatus?.detailedActions) {
      return false;
    }
    return approvalStatus.detailedActions.includes(ApprovalsActionsEnum.ACCEPT);
  }
  canDecline(approvalStatus): boolean {
    if (!approvalStatus?.detailedActions) {
      return false;
    }
    return approvalStatus.detailedActions.includes(ApprovalsActionsEnum.DECLINE);
  }
  isCommentForAllActionsEnabled(approvalStatus): boolean {
    if (!approvalStatus?.settings) {
      return false;
    }
    return approvalStatus.settings.enabledCommentForAllActions;
  }
}
