<div class="selected-assignee-dropdown__options">
  <div *ngIf="loading" class="loading-spinner">
    <troi-loading-spinner></troi-loading-spinner>
  </div>

  <div *ngIf="!loading">
    <div
      class="selected-assignee-dropdown__option"
      *ngFor="let assignee of filterAssignees()"
      (click)="assignEmployee(assignee)"
    >
      <div class="selected-assignee-dropdown__assignee-container">
        <div
          class="initials-container"
          [ngClass]="{ 'initials-container--no-avatar': !assignee.user.image }"
          [ngStyle]="{ 'background-image': 'url(' + getAvatarSrc(assignee) + ')' }"
        >
          <div class="initials-container__initials" *ngIf="!assignee.user.image">
            {{ getInitials(assignee) }}
          </div>
        </div>

        {{ assignee.user.firstName }}
        {{ assignee.user.lastName }}
      </div>

      <div
        *ngIf="taskHasDate && showUtilization"
        [ngStyle]="{ color: getUtilizationColor(assignee.utilization) }"
      >
        ⌀ {{ assignee.utilization | number : '1.2' }}%
      </div>
    </div>

    <div *ngFor="let team of filterTeams()">
      <div
        (click)="assignTeam(team)"
        class="selected-assignee-dropdown__option"
        *ngIf="checkIfTeamIsAssigned(team.employees)"
      >
        <div class="selected-assignee-dropdown__option--team">
          <troi-person [multiplePersons]="team.employees" [small]="true" [multiPersons]="true">
          </troi-person>
          <span
            [ngStyle]="{ maxWidth: taskHasDate && showUtilization ? '9rem' : 'auto' }"
            [ngbPopover]="team.description"
            placement="bottom"
            [openDelay]="600"
            #assignmentPopover="ngbPopover"
            popoverClass="timeentries-tooltip-popover"
            triggers="mouseenter:mouseleave"
            class="selected-assignee-dropdown__option--team--description"
            (click)="helperService.getDatepickerPosition($event)"
            >{{ team.description }}</span
          >
        </div>

        <span *ngIf="taskHasDate && showUtilization"
          >⌀ {{ team.utilization | number : '1.2' }}%</span
        >
      </div>
    </div>
  </div>
</div>
