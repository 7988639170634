import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, map } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { ModuleInterceptor } from '../../../../../../core/enums/module-interceptor';
import { Routes } from '../../../../enum/routes';
import { AttachmentType } from '../../../../enum/attachment-type';
import { AttachmentResponseInterface } from '../../../../interfaces/responses.interface';
import { AttachmentInterface } from '../../../../interfaces/attachment.interface';
import * as saveAs from 'file-saver';

@Injectable()
export class AttachmentService {
  private headers: HttpHeaders;

  public constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');
  }

  public getAttachments(parentId: string, type: AttachmentType): Observable<AttachmentResponseInterface> {
    return this.http.get<AttachmentResponseInterface>(
      environment.url + Routes.ATTACHMENTS + `/getByParent/${parentId}?attachmentType=${type.toString()}`,
      {
        headers: this.headers,
      },
    );
  }

  public uploadAttachment(newAttachment: AttachmentInterface): Observable<AttachmentResponseInterface> {
    const httpOptions: any = {
      headers: this.headers,
    };
    // convertion is necessary for upload
    const formData = new FormData();
    formData.append('file', newAttachment.file);
    formData.append('parentId', newAttachment.parentId);
    formData.append('attachmentType', newAttachment.attachmentType.toString());

    return this.http.post<AttachmentResponseInterface>(environment.url + Routes.ATTACHMENTS, formData, {
      headers: this.headers,
    });
  }

  // ToDo: improve type security and remove any-types
  public downloadAttachment(attachment: AttachmentInterface, type: AttachmentType): Observable<any> {
    const httpOptions: any = {
      headers: this.headers,
      responseType: 'blob',
    };
    return this.http
      .get<Blob>(
        environment.url + Routes.ATTACHMENTS + `/${attachment.id}?attachmentType=${type.toString()}`,
        httpOptions,
      )
      .pipe(
        map((result: any) => {
          saveAs(result, attachment.filename);
        }),
      );
  }

  public deleteAttachment(id: string, type: AttachmentType): Observable<AttachmentResponseInterface> {
    return this.http.delete<AttachmentResponseInterface>(
      environment.url + Routes.ATTACHMENTS + `/${id}?attachmentType=${type.toString()}`,
    );
  }

  public getAttachmentThumbnail(id: string, type: AttachmentType): Observable<Blob> {
    return this.http.get(environment.url + Routes.ATTACHMENTS + `/${id}/thumbnail?attachmentType=${type}`, {
      responseType: 'blob',
    });
  }
}
