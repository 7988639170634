import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Subscription, take } from 'rxjs';
import { UserService } from '../../../../../../../../../core/user/user.service';
import { PersonInterface } from '../../../../../../../../../shared/troi-person/interfaces/person.interface';
import { ForecastViewEnum } from '../../../../../../../enum/forecast-view';
import { AssigneeInterface } from '../../../../../../../interfaces/assignee.interface';
import { TasksResponseInterface } from '../../../../../../../interfaces/responses.interface';
import { SubtaskInterface } from '../../../../../../../interfaces/subtask.interface';
import { TeamOptionInterface } from '../../../../../../../interfaces/teamOption.interface';
import { TaskModel } from '../../../../../../../models/task.model';
import { SubtaskService } from '../../../../../../../network/subtask.service';
import { TasksService } from '../../../../../../../network/tasks.service';
import { TasksHelperService } from '../../../../../../../services/helper.service';
import { SelectAssigneeDropdownComponent } from '../select-assignee-dropdown/select-assignee-dropdown.component';

@Component({
  selector: 'troi-task-modal-select-assignee',
  templateUrl: './select-assignee.component.html',
  styleUrls: ['./select-assignee.component.scss'],
})
export class SelectAssigneeComponent implements OnInit, OnDestroy {
  @ViewChild('assignmentPopover') assignmentPopover: NgbPopover;
  @ViewChild('actionRequiredPopover') actionRequiredPopover: NgbPopover;
  @ViewChild('selectAssigneeDrowdown', { static: true }) selectAssigneeDrowdown: ElementRef;
  @ViewChild('dropdownComp') dropdownComp: SelectAssigneeDropdownComponent;
  @Output() assignedEmployeesChanged = new EventEmitter<TaskModel | SubtaskInterface>();
  @Input() task: TaskModel | SubtaskInterface;
  @Input() updateTaskDirectly = true;
  @Input() isSubtask = false;

  public assigneeSearch = '';
  public showDropdown = false;
  private subscriptions: Subscription = new Subscription();

  public showHighlightBorder = false;

  public selectedDateView = ForecastViewEnum.MONTH;
  public selectedUtilization = true;
  public projectTeamId = 0;
  private projectId: number;
  constructor(
    private tasksService: TasksService,
    private subtaskService: SubtaskService,
    private helperService: TasksHelperService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.helperService.projectId.subscribe((id) => {
      this.projectId = id !== null ? +id : null;
    });
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.selectAssigneeDrowdown.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }

  public assignMe(): void {
    let allAssignees: AssigneeInterface[] = [];
    this.subscriptions.add(
      this.tasksService
        .getAssigneeOptions(
          this.projectId ?? this.task.calculationPosition?.project.id,
          this.projectId ?? this.task.calculationPosition?.customer.mandant.id,
          this.getTimestamp('start'),
          this.getTimestamp('end'),
        )
        .subscribe((res) => {
          if (res.data)
            allAssignees = res.data?.map((emp: PersonInterface) => {
              return {
                employeeId: emp.id.toString(),
                user: emp,
                projectTask: +this.task.id,
                assignments: [],
                minutesTotal: 0,
                utilization: emp.utilization,
              } as AssigneeInterface;
            });

          const user = this.userService.getUser();
          const userId = user.id.toString();

          const currentUser = allAssignees.find((assignee) => {
            return assignee.employeeId === userId;
          });

          if (currentUser) this.assignEmployee(currentUser);
        }),
    );
  }

  private getTimestamp(type: string): number {
    if (type === 'start' && this.task.startDate) return new Date(this.task.startDate).getTime();
    if (type === 'end' && this.task.endDate) return new Date(this.task.endDate).getTime();

    return undefined;
  }

  public toggleOptions(): void {
    this.assigneeSearch = '';
    this.showDropdown = !this.showDropdown;
  }

  public assignTeam(team: TeamOptionInterface) {
    const totalEmployees = team.employees.length;
    let addedAssignees = 0;
    team.employees.forEach((employee) => {
      const assignee: AssigneeInterface = {
        id: employee.id.toString(),
        employeeId: employee.id.toString(),
        minutesTotal: 0,
        user: employee,
        projectTask: +this.task.id,
        assignments: [],
        utilization: employee.utilization,
        lastReadComments: null,
      };

      if (!this.task.assignees?.find((a) => a.user.id.toString() === assignee.employeeId))
        if (this.isSubtask) this.assignEmployee(assignee);
        else {
          // updates task when last assignee of team ist pushed
          this.task.assignees.push(assignee);
          addedAssignees++;
          if (addedAssignees === totalEmployees) {
            this.subscriptions.add(
              this.tasksService.updateTask(this.task).subscribe((res: TasksResponseInterface) => {
                res.data[0].assignees.forEach((a: AssigneeInterface) => a.user.id === +a.employeeId);
              }),
            );
          }
        }
    });
  }

  public async assignEmployee(assignee: AssigneeInterface): Promise<void> {
    if (!this.task.assignees || this.task.assignees.find((a) => a.user.id.toString() === assignee.employeeId)) {
      this.showDropdown = false;
      this.assignmentPopover.open();
      return;
    }

    // updating the task is necessary here, because assignee ids are required when user wants to assign working hours
    this.task.assignees.push(assignee);
    // Updates either whole task or add assignee to subtask
    if (this.isSubtask && this.updateTaskDirectly) {
      const res = await firstValueFrom(this.subtaskService.addSubtaskAssignee(this.task.id, assignee).pipe(take(1)));
      assignee.id = res.data[0].id;
    } else if (this.updateTaskDirectly) {
      const res = await firstValueFrom(this.tasksService.updateTask(this.task).pipe(take(1)));
      assignee.id = res.data[0].assignees.find((a: AssigneeInterface) => a.user.id === +assignee.employeeId).id;
    }

    this.assigneeSearch = '';
    this.showDropdown = false;
    this.assignedEmployeesChanged.emit(this.task);
  }

  public toggleHighlightBorder(): void {
    this.showHighlightBorder = !this.showHighlightBorder;
  }

  public isDisabled(): boolean {
    return this.task.created === undefined;
  }

  public noTimerangeSet(): boolean {
    return !this.task.startDate || !this.task.endDate;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
