import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TimeentryService } from '../../network/timeentry.service';
import { Subscription } from 'rxjs';
import {
  TimeEntryInterface,
  TimeEntryOverviewInterface,
  TimeEntrySettingsInterface,
  TimeEntrySettingsResponseInterface,
} from '../../../../../interfaces/timeEntry.interface';
import { TaskModel } from '../../../../../models/task.model';
import { SubtaskInterface } from '../../../../../interfaces/subtask.interface';
import {
  EmployeeResponseInterface,
  TimeEntryHelperResponseInterface,
  TimeEntryResponseInterface,
} from '../../../../../interfaces/responses.interface';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TasksHelperService } from '../../../../../services/helper.service';
import { AssigneeInterface } from '../../../../../interfaces/assignee.interface';
import { PersonInterface } from '../../../../../../../shared/troi-person/interfaces/person.interface';
import { UserService } from '../../../../../../../core/user/user.service';
import { UserModel } from '../../../../../../../core/user/user.model';
import { TasksSettingsService } from '../../../../../services/tasks-settings.service';
import { TimeEntryEnum } from '../../../../../enum/time-entry';
import * as moment from 'moment';
import { StorageService } from '../../../../../../../core/storage/storage.service';
import { StorageKeys } from '../../../../../../../core/storage/storage.keys';
import { TranslateService } from '@ngx-translate/core';
import { StorageNotificationService } from '../../../../../../../core/notifications/storageNotification.service';

@Component({
  selector: 'troi-task-modal-timeentries-content',
  templateUrl: './timeentries-content.component.html',
  styleUrls: ['./timeentries-content.component.scss'],
})
export class TimeentriesContentComponent implements OnInit, OnDestroy {
  @ViewChild('internalComment') internalComment: NgbPopover;
  @ViewChild('externalComment') externalComment: NgbPopover;
  @ViewChild('addTimePopover') addTimePopover: NgbPopover;
  @ViewChild('timePicker') timePicker: ElementRef;
  @Input() task: TaskModel;
  @Input() subtask: SubtaskInterface;
  @Input() singleComment = false;
  @Input() disabled = false;
  @Input() timeEntries: TimeEntryOverviewInterface[] = [];
  @Input() timeEntriesSum = 0;
  @Input() isScrollable = true;

  @Output() timeEntriesUpdated = new EventEmitter();

  public timeEntriesForm: FormGroup;
  public entryDate: Date = new Date();
  public dateNow = Date.now();
  private subscriptions: Subscription = new Subscription();
  public showDropdown = false;
  public selectedAssignee: AssigneeInterface;
  public assignee: PersonInterface;
  private currentUser: UserModel;

  public timeEntrySettings: TimeEntrySettingsInterface;

  constructor(
    public timeEntryService: TimeentryService,
    public helperService: TasksHelperService,
    private userService: UserService,
    private settingsService: TasksSettingsService,
    private storageService: StorageService,
    private notificationService: StorageNotificationService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.timeEntriesForm = new FormGroup({
      entryDate: new FormControl(),
      time: new FormControl('', Validators.required),
      notBillable: new FormControl(false),
      internalComment: new FormControl(),
      externalComment: new FormControl(),
    });
    this.getUser();
    this.getSettings();
  }

  private getSettings() {
    const settings = this.storageService.getItem(StorageKeys.TASKS_SETTINGS).settings.timerecord;

    this.timeEntrySettings = {
      backwardProjecttime: this.getSpecificSettings(settings, TimeEntryEnum.PROJECTTIME) ?? null,
      internalCommentsVisible: this.getSpecificSettings(settings, TimeEntryEnum.INTERNAL_COMMENTS_VISIBLE) ?? true,
      externalCommentsRequired: this.subtask
        ? false
        : this.getSpecificSettings(settings, TimeEntryEnum.EXTERNAL_COMMENTS) ?? false,
      internalCommentsRequired: this.getSpecificSettings(settings, TimeEntryEnum.INTERNAL_COMMENTS) ?? false,
      timeRecordForOthers: settings ? settings.employee.timeRecordForOthers : true,
    } as TimeEntrySettingsInterface;
  }

  private getSpecificSettings(settings: TimeEntrySettingsResponseInterface, settingType: TimeEntryEnum) {
    return settings.settings.find((setting) => setting.settingName === settingType)?.value;
  }

  public getMinDate() {
    const dateNow = moment();
    const minDate = dateNow.subtract(this.timeEntrySettings.backwardProjecttime, 'days');
    return minDate;
  }

  private getUser() {
    this.currentUser = this.userService.getUser();
    const userId = this.currentUser.id.toString();
    this.selectedAssignee = {
      employeeId: userId,
      user: this.convertUserToPerson(this.currentUser),
      projectTask: +this.task.id,
      assignments: [],
      minutesTotal: 0,
      utilization: 0,
    } as AssigneeInterface;
  }

  public subtasksHaveTimeEntries(): boolean {
    return this.timeEntries.some((entry) => {
      return entry.subtask !== 0;
    });
  }

  public convertUserToPerson(user: UserModel) {
    return {
      firstName: user.name,
      lastName: user.surname,
      image: user.avatarUrl,
    } as PersonInterface;
  }

  public dateChanged(dates): void {
    this.entryDate = new Date(dates.date[0]);
  }

  public getSubtaskTimeentries(subtaskId: string) {
    return this.timeEntries.filter((entry) => entry.subtask === +subtaskId);
  }

  public onClickNotBillable(event: boolean): void {
    this.timeEntriesForm.controls.notBillable.setValue(event);
  }

  public saveEntry() {
    const newTimeEntry: TimeEntryInterface = {
      calculationPosition: this.task.calculationPosition.id.toString(),
      date: `${this.entryDate.getFullYear()}-${this.entryDate.getMonth() + 1}-${this.entryDate.getDate()}`,
      workTime: this.timeEntriesForm.value.time,
      internalComment: this.timeEntriesForm.value.internalComment,
      externalComment: this.timeEntriesForm.value.externalComment,
      billable: !this.timeEntriesForm.value.notBillable,
      projectTask: +this.task.id,
      projectSubTask: this.subtask ? +this.subtask.id : 0,
      employeeId: +this.selectedAssignee.employeeId,
    };

    if (
      !this.subtask &&
      this.timeEntrySettings.externalCommentsRequired &&
      !newTimeEntry.externalComment &&
      this.timeEntrySettings.internalCommentsRequired &&
      !newTimeEntry.internalComment
    ) {
      this.externalComment.open();
      this.internalComment.open();
      return;
    } else if (!this.subtask && this.timeEntrySettings.externalCommentsRequired && !newTimeEntry.externalComment) {
      this.externalComment.open();
      return;
    } else if (this.timeEntrySettings.internalCommentsRequired && !newTimeEntry.internalComment) {
      this.internalComment.open();
      return;
    }

    if (this.entryDate && this.timeEntriesForm.value.time && this.timeEntriesForm.value.time !== '00:00') {
      this.subscriptions.add(
        this.timeEntryService.createTimeentry(newTimeEntry).subscribe((res: TimeEntryResponseInterface) => {
          if (res.success) {
            // timeentry array needs to be updated (but bookings endpoint is only returning success message)
            this.subscriptions.add(
              this.timeEntryService
                .getTimeentries(this.subtask ? this.subtask.id : this.task.id, this.subtask ? true : false)
                .subscribe((fetchedEntries: TimeEntryHelperResponseInterface) => {
                  this.timeEntriesSum = +fetchedEntries.data.sum;
                  if (this.task) this.task.hoursSpent = +fetchedEntries.data.sum;

                  this.helperService.setTimeEntrySum(this.timeEntriesSum);
                  this.timeEntriesUpdated.emit();
                  this.timeEntries = this.helperService.formatTimeEntries(fetchedEntries.data.entries);
                }),
            );
          } else {
            this.notificationService.showError(res.errorMessage || this.translate.instant('Common.error.general'));
          }
        }),
      );
      this.timeEntriesForm.reset();
    } else this.addTimePopover.open();
  }

  public assignEmployee(assignee: AssigneeInterface) {
    this.selectedAssignee = assignee;
    this.selectedAssignee.employeeId = assignee.user.id.toString();
    this.showDropdown = false;
  }

  public toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  public convertAssigneeToPerson(assignee: AssigneeInterface) {
    return {
      firstName: assignee.user.firstName,
      lastName: assignee.user.lastName,
      image: assignee.user.image,
    } as PersonInterface;
  }

  public showPicker() {
    if (!this.disabled) {
      this.timePicker.nativeElement.open();
    }
  }

  public onEntryUpdated(entry: TimeEntryOverviewInterface, subtaskId?: string) {
    this.timeEntries = this.timeEntries.map((x: TimeEntryOverviewInterface) => {
      if (x.id === entry.id) return entry;
      else return x;
    });
    this.timeEntriesUpdated.emit();
  }

  public onEntryDeleted(entry: TimeEntryOverviewInterface, subtaskId?: string) {
    this.timeEntries = this.timeEntries.filter((x: TimeEntryOverviewInterface) => x.id !== entry.id);
    this.timeEntriesUpdated.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
