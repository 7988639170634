import { Injectable } from '@angular/core';
import { TroiFilterSetsNetworkService } from '../network/troi-filter-sets.network';
import { BackendResponseInterface, ResponseStatusInterface } from '../../../core/interfaces/backend.response.interface';
import { Subject } from 'rxjs';
import { FilterSetModel } from '../models/filter-set.model';
import { FilterSetTypeEnum } from '../enums/filter-set-type.enum';
import { FiltersInterface } from '../../troi-data-listing-filters/filters.interface';
import { FilterSetLoadedInterface } from '../interfaces/filter-set-loaded.interface';

@Injectable()
export class TroiFilterSetsService {
  public areFiltersEdited = false;
  public filterSetSelected = new Subject<FilterSetModel>();
  public filterSetsLoaded = new Subject<FilterSetLoadedInterface>();

  constructor(protected filterSetsNetworkService: TroiFilterSetsNetworkService) {}

  public selectedFilterSetId: string | number = 'predefined-favourite';
  public isEdited = false;
  public filterSets: FilterSetModel[] = [];
  public get predefinedFilterSets(): FilterSetModel[] {
    return [
      {
        id: 'predefined-favourite',
        name: 'FilterSets.predefined.default',
        value: '',
        type: FilterSetTypeEnum.FAVOURITES,
        isDefault: true,
        filters: new FiltersInterface(),
      },
    ];
  }

  public set predefinedFilterSets(sets: FilterSetModel[]) {
    this.predefinedFilterSets = [...sets];
  }

  public get selectedFilterSet(): FilterSetModel {
    return this.filterSets.find((filterSet: FilterSetModel) => filterSet.id === this.selectedFilterSetId);
  }

  private setDefaultFilterSet(filterSet: FilterSetModel): void {
    this.filterSets = this.filterSets.map((fSet: FilterSetModel) => {
      return {
        ...fSet,
        isDefault: fSet.id === filterSet.id,
      };
    });
  }

  private setAndSelectDefaultFilterSet(filterSet: FilterSetModel, initialLoad = false): void {
    this.selectedFilterSetId = filterSet.id;
    this.setDefaultFilterSet(filterSet);
    this.filterSetsLoaded.next({ filterSet, initialLoad });
  }

  private isFilterSetDefault = (set: FilterSetModel) => set.isDefault;

  private getDefaultFilterSet(filterSets: FilterSetModel[]): FilterSetModel {
    return (
      filterSets.find(this.isFilterSetDefault) ||
      this.predefinedFilterSets.find((set: FilterSetModel) => set.isDefault === true)
    );
  }

  public loadFilterSets(): void {
    this.isEdited = false;
    this.filterSetsNetworkService.fetchFilterSets().subscribe((res: BackendResponseInterface<FilterSetModel[]>) => {
      this.filterSets = [...this.predefinedFilterSets, ...res.data];
      const defaultSet = this.getDefaultFilterSet(res.data);
      this.setAndSelectDefaultFilterSet(defaultSet, true);
    });
  }

  public addFilterSet(filterSet: FilterSetModel): void {
    this.filterSets = [...this.filterSets, filterSet];

    if (filterSet.isDefault) {
      this.setDefaultFilterSet(filterSet);
    }
  }

  public updateFilterSetValue(filterSet: FilterSetModel): void {
    this.filterSets = [
      ...this.filterSets.map((set: FilterSetModel) => {
        return filterSet.id === set.id ? filterSet : set;
      }),
    ];
  }

  public updateFilterSet(filterSet: FilterSetModel, setAsDefault = false): void {
    if (setAsDefault) {
      filterSet.isDefault = true;
      this.setDefaultFilterSet(filterSet);
    }

    this.filterSetsNetworkService.updateFilterSet(filterSet).subscribe();
  }

  public deleteFilterSet(filterSet: FilterSetModel): void {
    this.filterSetsNetworkService.deleteFilterSet(filterSet).subscribe(() => {
      const filterSetsAfterRemove = this.filterSets.filter((fSet: FilterSetModel) => fSet.id !== filterSet.id);

      const defaultPredefinedFilterSet = this.getDefaultFilterSet(filterSetsAfterRemove);

      if (this.selectedFilterSet.id === filterSet.id) {
        this.setAndSelectDefaultFilterSet(defaultPredefinedFilterSet);
      } else if (filterSet.isDefault) {
        this.setDefaultFilterSet(defaultPredefinedFilterSet);
      }

      this.filterSets = [...this.filterSets.filter((fSet: FilterSetModel) => fSet.id !== filterSet.id)];
    });
  }
}
