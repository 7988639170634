import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TaskActionInterface } from '../interfaces/task-action.interface';
import { SubtaskInterface } from '../interfaces/subtask.interface';
import { TimeEntryOverviewInterface } from '../interfaces/timeEntry.interface';
import { TroiDropdownListModel } from '../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { TranslateService } from '@ngx-translate/core';
import { TaskActionsEnum } from '../enum/task-actions';
import { TaskModel } from '../models/task.model';
import { UserService } from '../../../core/user/user.service';
import { AssigneeInterface } from '../interfaces/assignee.interface';
import { TimerangeView } from '../modules/timelineview/enum/view';
import { BookingsInterface } from '../interfaces/consumption.interface';

@Injectable()
export class TasksHelperService {
  public taskActionSub = new Subject<TaskActionInterface>();
  private _assignHoursSub = new BehaviorSubject<boolean>(false);
  private _timeEntrySum = new BehaviorSubject<number>(0);
  private _selectedSubtask: BehaviorSubject<SubtaskInterface> = new BehaviorSubject<SubtaskInterface>(null);
  private _deleteSubtask: BehaviorSubject<SubtaskInterface> = new BehaviorSubject<SubtaskInterface>(null);
  private _assignedEmployeesChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _taskHoursChanged: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private _timelineTimerangeChanged: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([0, 0]);
  private _isProjectContext: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isTimelineContext: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isResourcesContext: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _emptyBoardCreated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _taskChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _selectedUtilization: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _selectedDateView: BehaviorSubject<TimerangeView> = new BehaviorSubject<TimerangeView>(TimerangeView.DAY);
  private _timeRangeView: BehaviorSubject<TimerangeView> = new BehaviorSubject<TimerangeView>(TimerangeView.WEEK);

  private _projectId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _timeEntriesChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public selectedSubtask = this._selectedSubtask.asObservable();
  public deleteSubtask = this._deleteSubtask.asObservable();
  public assignHoursSub = this._assignHoursSub.asObservable();
  public timeEntrySumSub = this._timeEntrySum.asObservable();
  public assignedEmployeesChanged = this._assignedEmployeesChanged.asObservable();
  public taskHoursChangedSub = this._taskHoursChanged.asObservable();
  public timelineTimerangeChanged = this._timelineTimerangeChanged.asObservable();
  public isProjectContext = this._isProjectContext.asObservable();
  public isResourcesContext = this._isResourcesContext.asObservable();
  public isTimelineContext = this._isTimelineContext.asObservable();
  public selectedUtilization = this._selectedUtilization.asObservable();
  public selectedDateView = this._selectedDateView.asObservable();
  public emptyBoardCreated = this._emptyBoardCreated.asObservable();
  public taskChanged = this._taskChanged.asObservable();
  public projectId = this._projectId.asObservable();
  public timeEntriesChanged = this._timeEntriesChanged.asObservable();
  public timeRangeView = this._timeRangeView.asObservable();
  public onUnassignEmployee = new Subject<string>();

  private datepickerTopValue = 0;

  public getMoreActionsOptions(task?: TaskModel): TroiDropdownListModel[] {
    return [
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.reminder'),
        value: TaskActionsEnum.REMINDER,
        disabled: !task?.startDate,
        icon: 'icon-number-calendar',
      },
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.edit'),
        value: TaskActionsEnum.EDIT,
        disabled: false,
        icon: 'icon-can-edit',
      },
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.copy'),
        value: TaskActionsEnum.COPY,
        disabled: false,
        icon: 'icon-files',
      },
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.move'),
        value: TaskActionsEnum.MOVE_TASK,
        disabled: false,
        icon: 'icon-export',
      },
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.delete'),
        value: TaskActionsEnum.DELETE_TASK,
        disabled: !task?.hoursSpent ? false : true,
        icon: 'icon-trash-can',
      },
    ];
  }

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['code-block'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],

        ['link'],
      ],
    },
  };

  constructor(private translationService: TranslateService, private userService: UserService) {}

  public setSubtask(value: SubtaskInterface): void {
    this._selectedSubtask.next(value);
  }

  public setSubtaskToDelete(value: SubtaskInterface): void {
    this._deleteSubtask.next(value);
  }

  public setAssignedHoursState(state: boolean): void {
    this._assignHoursSub.next(state);
  }

  public setTimeEntrySum(sum: number): void {
    this._timeEntrySum.next(sum);
  }

  public onAssignedEmployeeChange(state: boolean): void {
    this._assignedEmployeesChanged.next(state);
  }

  public setTaskHoursChanged(hours: number): void {
    this._taskHoursChanged.next(hours);
  }

  public setTimelineRangeChanged(date: number[]): void {
    this._timelineTimerangeChanged.next(date);
  }

  public setProjectContext(isProjectContext: boolean): void {
    this._isProjectContext.next(isProjectContext);
  }

  public setTimelineContext(isTimelineContext: boolean): void {
    this._isTimelineContext.next(isTimelineContext);
  }

  public setResourcesContext(isResourcesContext: boolean): void {
    this._isResourcesContext.next(isResourcesContext);
  }

  public setSelectedUtilization(selectedUtilization: boolean): void {
    this._selectedUtilization.next(selectedUtilization);
  }

  public setSelectedDateView(selectedDateView: TimerangeView): void {
    this._selectedDateView.next(selectedDateView);
  }

  public setEmptyBoardCreated(boardCreated: boolean): void {
    this._emptyBoardCreated.next(boardCreated);
  }

  public setProjectId(projectId: string): void {
    this._projectId.next(projectId);
  }

  public onTimeEntriesChanged(changed: boolean): void {
    this._timeEntriesChanged.next(changed);
  }

  public setTaskChanged(changed: boolean) {
    this._taskChanged.next(changed);
  }

  public timeRangeViewChanged(view: TimerangeView): void {
    this._timeRangeView.next(view);
  }

  // gets exact position of input and adjusts its datepicker position based on that
  public getDatepickerPosition(event: MouseEvent, top?: boolean) {
    const elementPos = (event.target as HTMLElement).getBoundingClientRect();

    this.datepickerTopValue = top ? elementPos.top - 320 : elementPos.top + 20;

    document.documentElement.style.setProperty('--datepicker-top-value', `${this.datepickerTopValue}px`);
  }

  public formatTimeEntries(entries: TimeEntryOverviewInterface[]): TimeEntryOverviewInterface[] {
    entries.map((t: TimeEntryOverviewInterface) => {
      t.billingDate = new Date(t.billingDate);
      // now rounding the quantity to 2 decimals instead of "xx:xx" format
      t.quantity = String(Math.round(+t.quantity * 100) / 100);
    });

    return entries;
  }

  public addHTTPSToURL(editorContent: string): string {
    // Replace \r\n with <br> for newline conversion
    const formattedContent = editorContent.replace(/\r\n/g, '<br>');

    // Create a temporary element to manipulate the HTML content
    const tempElement = document.createElement('div');
    tempElement.innerHTML = formattedContent;

    // Process anchor tags for HTTP/HTTPS prefix
    const anchorTags = tempElement.querySelectorAll('a');
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/i;

    anchorTags.forEach((anchorTag: HTMLAnchorElement) => {
      const href = anchorTag.getAttribute('href');
      if (href && urlPattern.test(href)) {
        const hasHTTP = /^https?:\/\//i.test(href);
        if (!hasHTTP) anchorTag.setAttribute('href', 'https://' + href);
      }
    });

    // Return the processed content
    return tempElement.innerHTML;
  }

  public isUnitTypeTime(unitType: string): boolean {
    return unitType === 'T';
  }

  public formatQuantity(quantity: string | number, rate: number = 1, unitType: string) {
    const quantityToNumber = parseFloat(String(quantity));
    const formattedQuantity = (quantityToNumber * rate).toFixed(2);
    if (!this.isUnitTypeTime(unitType)) {
      return '0.00';
    }
    return formattedQuantity;
  }

  public isBookingEnabled(item: TaskModel | SubtaskInterface): boolean {
    // Time-booking is only enabled if:
    // - Current user is assigned to task or subtask.
    // - Calculation position exists.
    // - No blocking for billing or booking.
    const currentUser = this.userService.getUser();

    if (
      !item.calculationPosition?.id ||
      item.calculationPosition?.project.projectStatus.blockedForBilling ||
      ('project' in item && item.project?.projectStatus?.blockedForBooking)
    ) {
      return false;
    }

    const isUserAssigned = (assignees: AssigneeInterface[]): boolean => {
      return assignees ? assignees.some((assignee) => assignee.user.id === currentUser.id) : false;
    };

    if (isUserAssigned(item.assignees)) {
      return true;
    }

    /* Check if item is a task and has subtasks, then check the subtasks' assignees */
    if ('subtasks' in item && item.subtasks) {
      return (
        item.subtasks.some((subtask) => {
          return isUserAssigned(subtask.assignees);
        }) ?? false
      );
    }

    return false;
  }

  public formatTime(time?: number | string): string {
    const value = !time ? '0' : String(time);
    return parseFloat(value).toFixed(2);
  }

  public calculateSumOfAssignments(booked: BookingsInterface[]) {
    return booked.reduce((acc: number, booking) => acc + parseFloat(booking.hours), 0);
  }
}
