import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, HostListener, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'troi-icon-button',
  templateUrl: './troi-icon-button.component.html',
  styleUrls: ['./troi-icon-button.component.scss'],
})
export class TroiIconButtonComponent {
  @Input() icon: string;

  @HostBinding('class.disabled')
  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  @Input()
  public get skipStopPropagation(): boolean {
    return this._skipStopPropagation;
  }
  public set skipStopPropagation(value: boolean | string) {
    this._skipStopPropagation = coerceBooleanProperty(value);
  }
  private _skipStopPropagation = false;

  @HostBinding('class.ripple') ripple = false;

  @ViewChild('rippleElement') rippleElement: ElementRef<HTMLElement>;

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.skipStopPropagation) {
      event.stopPropagation();
    }
    const rect = this._elementRef.nativeElement.getBoundingClientRect();
    const x = event.clientX - rect.left - this.rippleElement.nativeElement.clientWidth / 2;
    const y = event.clientY - rect.top - this.rippleElement.nativeElement.clientHeight / 2;
    this.rippleElement.nativeElement.style.left = x + 'px';
    this.rippleElement.nativeElement.style.top = y + 'px';
    this.ripple = true;
    setTimeout(() => (this.ripple = false), 350);
  }

  constructor(private _elementRef: ElementRef<HTMLElement>) {}
}
