import { ChangeDetectorRef, Component, Input, forwardRef } from '@angular/core';
import { AbstractControl, AbstractControlDirective, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ErrorTranslationService } from '../../core/language/error.translation.service';

@Component({
  selector: 'troi-input',
  templateUrl: './troi-input.component.html',
  styleUrls: ['./troi-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TroiInputComponent),
      multi: true,
    },
  ],
})
export class TroiInputComponent implements ControlValueAccessor {
  @Input() public label: string;

  @Input() public placeholder: string;

  @Input() public type = 'text';

  @Input() public size: string;

  @Input() public hideError = false;

  @Input() public readOnly: false;

  @Input('control') public control: AbstractControlDirective | AbstractControl;

  private innerValue = '';

  private changed = [];

  private touched = [];

  public isValid = true;
  public errorMessages: string[] = [];

  constructor(private errorTranslationService: ErrorTranslationService, private cdRef: ChangeDetectorRef) {}

  get value(): string {
    return this.innerValue;
  }

  set value(value: string) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.changed.forEach((f) => f(value));
      this.updateIsValid();
    }
  }

  private updateIsValid() {
    this.isValid = this.control && (this.control.valid || this.control.pristine);
    this.cdRef.detectChanges();

    if (!this.isValid) {
      this.updateErrorMessages();
    }
  }

  public showError() {
    return !this.hideError;
  }

  private updateErrorMessages() {
    this.errorMessages = this.errorTranslationService.getErrorMessages(this.control.errors);
    this.cdRef.detectChanges();
  }

  writeValue(value: string) {
    this.innerValue = value;
  }

  registerOnChange(fn: (value: any) => void) {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void) {
    this.touched.push(fn);
  }

  touch() {
    this.touched.forEach((f) => f());
  }
}
