import { Component, Input, AfterViewInit, Injector } from '@angular/core';
import { BreadcrumbInterface } from './breadcrumb.interface';
import { BookingSettingsService } from '../../modules/bookings/common/services/booking-settings.service';
import { differenceInDays } from 'date-fns';

const TROI_SALES_EMAIL = 'sales@troi.de';

const LOGO_PATH = '/config/images/company_logo.png';
const PLACEHOLDER_PATH = '/site/resources5/images/company_placeholder.png';

@Component({
  selector: 'troi-breadcrumbs',
  templateUrl: './troi-breadcrumbs.component.html',
  styleUrls: ['./troi-breadcrumbs.component.scss'],
})
export class TroiBreadcrumbsComponent implements AfterViewInit {
  @Input() breadcrumbs: BreadcrumbInterface[] = [];
  url = window.location.origin;
  placeholder = false;
  loaded = false;
  public bookingSettingsService: BookingSettingsService;
  public demoSettings: Record<string, string | number | boolean>;

  constructor(protected injector: Injector) {
    this.bookingSettingsService = injector.get(BookingSettingsService);
  }
  ngAfterViewInit(): void {
    this.checkImage(this.logoUrl);
    this.bookingSettingsService.prepareSettings(undefined, true).subscribe((result) => {
      this.demoSettings = result.demo;
      if (this.demoSettings?.expirationDate) {
        this.demoSettings.daysTillExpiration = differenceInDays(
          new Date(this.demoSettings.expirationDate as string),
          new Date(),
        );
      }
      if (this.demoSettings?.isDemo) {
        this.demoSettings.email = TROI_SALES_EMAIL;
      }
    });
  }

  get logoUrl() {
    return this.url + LOGO_PATH;
  }

  get placeholderUrl() {
    return this.url + PLACEHOLDER_PATH;
  }

  setPlaceholder = () => {
    this.placeholder = true;
    this.setLoaded();
  };

  setLoaded = () => {
    this.loaded = true;
  };

  checkImage(imageSrc) {
    const img = new Image();
    img.onerror = this.setPlaceholder;
    img.onload = this.setLoaded;
    img.src = imageSrc;
  }
}
