import { Injectable } from '@angular/core';
import { NotificationInterface } from './notification.interface';
import { TroiNotification, TroiNotificationType } from './notification';
import { StorageKeys } from '../storage/storage.keys';
import { StorageListener } from '../storage/storage.listener';
import { MultipleMessageInterface } from '../interfaces/multipleMessage.interface';

@Injectable()
export class StorageNotificationService implements NotificationInterface {
  constructor(private storageListener: StorageListener) {}

  public showError(singleMessage: string, multipleMessages: MultipleMessageInterface[] = []) {
    const notification = new TroiNotification(TroiNotificationType.ERROR, singleMessage, multipleMessages);
    this.storageListener.store(StorageKeys.NOTIFICATION, notification);
  }

  public showWarning(singleMessage: string, multipleMessages: MultipleMessageInterface[] = []) {
    const notification = new TroiNotification(TroiNotificationType.WARNING, singleMessage, multipleMessages);
    this.storageListener.store(StorageKeys.NOTIFICATION, notification);
  }

  public showSuccess(singleMessage: string, multipleMessages: MultipleMessageInterface[] = []) {
    const notification = new TroiNotification(TroiNotificationType.SUCCESS, singleMessage, multipleMessages);
    this.storageListener.store(StorageKeys.NOTIFICATION, notification);
  }

  public showInfo(singleMessage: string, multipleMessages: MultipleMessageInterface[] = []) {
    const notification = new TroiNotification(TroiNotificationType.INFO, singleMessage, multipleMessages);
    this.storageListener.store(StorageKeys.NOTIFICATION, notification);
  }
}
