import { BaseModalDirective } from '../../../troi-base-modal/baseModal.component';
import { Component, Input } from '@angular/core';
import { ModalService } from '../../../troi-modals/modal.service';

@Component({
  selector: 'delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss'],
})
export class DeleteConfirmationComponent extends BaseModalDirective {
  @Input() executeCallback: () => void;

  constructor(public modalService: ModalService) {
    super(modalService, false, true);
  }

  public execute(): void {
    if (this.executeCallback) {
      this.executeCallback();
    }

    this.close();
  }
}
