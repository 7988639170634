<div
  class="tag-selection"
  ngbPopover="{{ 'Tasks.labels.createTaskModal.saveTaskFirst' | translate }}"
  placement="bottom"
  #saveTaskPopover="ngbPopover"
  triggers="manual"
>
  <div
    class="tag-selection__select"
    [ngClass]="{ 'tag-selection__select--focus': showHighlightBorder }"
  >
    <div class="tag-selection__select__search-input">
      <troi-icon class="tag-icon" [icon]="'icon-tag'"></troi-icon>
      <input
        type="text"
        placeholder="{{ 'Tasks.tags.placeholder' | translate }}"
        [(ngModel)]="tagSearch"
        (focus)="toggleHighlightBorder()"
        (blur)="toggleHighlightBorder()"
      />
      <div *ngIf="!showColorPalette" class="tag-dropdown">
        <troi-icon
          [medium]="true"
          icon="icon-bold-arrow-down"
          (click)="toggleOptions()"
        ></troi-icon>
      </div>
      <div *ngIf="showColorPalette" class="tag-colorpalette">
        <troi-color-palette
          (selectedColor)="onCreateTag($event)"
          (closeCreation)="onCloseColorPalette()"
        ></troi-color-palette>
      </div>
    </div>
    <div
      *ngIf="showDropdown || tagSearch"
      class="tag-selection__select__options"
      [class.open]="showDropdown"
    >
      <div
        class="tag-selection__select__option"
        *ngFor="let tag of filterTags()"
        (click)="onTagSelected(tag)"
      >
        <div
          class="tag-selection__select__option__inner-style"
          [ngStyle]="{ color: tag.colorcode }"
        >
          {{ tag.title }}
        </div>
      </div>
      <div
        *ngIf="!tagAlreadyExists()"
        class="tag-selection__select__option"
        (click)="onShowColorPalette()"
      >
        <div class="tag-selection__select__option__inner-style">
          {{ 'Tasks.tags.newTag' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="tag-display">
  <troi-tag-chip
    class="tag-display__tag-option"
    *ngFor="let tag of assignedTags"
    [curTag]="tag"
    (tagDeleted)="onTagDeleted($event)"
  ></troi-tag-chip>
</div>
