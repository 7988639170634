<div class="viewswitch">
  <div *ngIf="isResourceView" (click)="onChangeView(TimerangeView.DAY)" class="viewswitch--view">
    <troi-icon  icon="icon-day-calendar" [ngClass]="{'active': view === TimerangeView.DAY}"></troi-icon>
  </div>
  <div (click)="onChangeView(TimerangeView.WEEK)" class="viewswitch--view">
    <troi-icon  icon="icon-week-calendar" [ngClass]="{'active': view === TimerangeView.WEEK}"></troi-icon>
  </div>
  <div (click)="onChangeView(TimerangeView.MONTH)" class="viewswitch--view">
    <troi-icon icon="icon-calendar" [ngClass]="{'active': view === TimerangeView.MONTH}"></troi-icon>
  </div>
</div>
