import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { HeaderNotificationModule } from '../header-notification/headerNotification.module';
import { SharedModule } from '../../shared/shared.module';
import { ApprovalProcessComponent } from './components/approval-process/approval-process.component';
import { ApprovalHistoryComponent } from './components/approval-history/approval-history.component';
import { ApprovalStatusBarComponent } from './components/approval-status-bar/approval-status-bar.component';
import { ApprovalStatusIconComponent } from './components/approval-status-icon/approval-status-icon.component';
import { ApprovalActionsComponent } from './components/approval-actions/approval-actions.component';
import { CancelApprovalComponent } from './modals/cancel-approval/cancel-approval.component';
import { DeclineApprovalComponent } from './modals/decline-approval/decline-approval.component';
import { ApproveApprovalComponent } from './modals/approve-approval/approve-approval.component';
import { ApprovalsService } from './services/approvals.service';
import { ApprovalsNetwork } from './networks/approvals.network';

const appRoutes: Routes = [];

const components = [
  ApprovalProcessComponent,
  ApprovalHistoryComponent,
  ApprovalStatusBarComponent,
  ApprovalStatusIconComponent,
  ApprovalActionsComponent,
  CancelApprovalComponent,
  DeclineApprovalComponent,
  ApproveApprovalComponent,
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes), HeaderNotificationModule, SharedModule, NgbModule],
  declarations: [...components],
  providers: [ApprovalsService, ApprovalsNetwork],
  bootstrap: [],
  exports: [...components],
})
export class ApprovalsModule {}
