import { TroiNotification, TroiNotificationType } from '../../../../core/notifications/notification';
import { NgEventBus } from 'ng-event-bus';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class ErrorHandlerService {
  public constructor(private eventBus: NgEventBus) {}

  public error(error: any) {
    if (403 === error.status) {
      return this.handle(TroiNotificationType.ERROR, 'Desktop.Error.NotLoggedIn');
    }

    this.handle(TroiNotificationType.ERROR, error.message);
  }

  private handle(type: number, message: string) {
    this.eventBus.cast('app:notification:display', new TroiNotification(type, message));
  }
}
