import { ProjectPropertiesModel } from './project-properties.model';
import { TreeListItemModel } from '../../../../../shared/troi-tree-list/models/tree-list-item.model';
import { ProjectTypeEnum } from '../enums/project-type.enum';

export class ProjectModel extends TreeListItemModel<ProjectTypeEnum> {
  constructor(
    public _id: string,
    public _order: number,
    public properties: ProjectPropertiesModel,
    public customer: string | number,
  ) {
    super(_id, properties.name, _order);
    this._type = ProjectTypeEnum.ITEM;
    this._nodes = [];
  }

  isFolder = (): boolean => {
    return false;
  };

  isCustomer = (): boolean => {
    return false;
  };
}
