import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SubtaskInterface } from '../../../../../../../interfaces/subtask.interface';
import { TasksHelperService } from '../../../../../../../services/helper.service';
import { TaskActionsEnum } from '../../../../../../../enum/task-actions';
import { TranslateService } from '@ngx-translate/core';
import { TroiDropdownListModel } from '../../../../../../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { ModalService } from '../../../../../../../../../shared/troi-modals/modal.service';
import { TroiUserConfirmationComponent } from '../../../../../../../../../shared/troi-user-confirmation/troi-user-confirmation.component';
import { Subscription, first } from 'rxjs';
import { UserConfirmationEventEnum } from '../../../../../../../../../shared/troi-user-confirmation/user-confirmation-event.enum';
import { UserConfirmationSubscriber } from '../../../../../../../../../shared/troi-user-confirmation/user-confirmation.subscriber';
import { DomService } from '../../../../../../../../../shared/troi-modals/dom.service';
import { TasksService } from '../../../../../../../network/tasks.service';
import { PersonInterface } from '../../../../../../../../../shared/troi-person/interfaces/person.interface';
import { AssigneeInterface } from '../../../../../../../interfaces/assignee.interface';
import { SubtaskService } from '../../../../../../../network/subtask.service';
import { TaskModel } from '../../../../../../../models/task.model';
import { TroiPersonComponent } from '../../../../../../../../../shared/troi-person/troi-person.component';
import { TimeentryService } from '../../../../network/timeentry.service';
import { TimeEntryHelperResponseInterface } from '../../../../../../../interfaces/responses.interface';
import { TimeEntryOverviewInterface } from '../../../../../../../interfaces/timeEntry.interface';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { StatusInterface } from '../../../../../../../interfaces/status.interface';
import { StopwatchService } from '../../../../../../../../desk/widgets/stopwatch/stopwatch.service';

@Component({
  selector: 'troi-task-subtask',
  templateUrl: './subtask.component.html',
  styleUrls: ['./subtask.component.scss'],
})
export class SubtaskComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(TroiPersonComponent, { static: false }) troiPerson: TroiPersonComponent;
  @ViewChild('deletePopover') deletePopover: NgbPopover;

  @Input() task: TaskModel;
  @Input() subtask: SubtaskInterface;
  @Output() showEditSubtaskContent = new EventEmitter<boolean>();

  public showIcons = false;
  public taskDone = false;
  public dropdownOpen = false;
  private subscriptions: Subscription = new Subscription();

  public timeEntries: TimeEntryOverviewInterface[] = [];

  public allAssignees: PersonInterface[] | string;
  public get dropdownOptions(): TroiDropdownListModel[] {
    return [
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.delete'),
        value: TaskActionsEnum.DELETE_SUBTASK,
        icon: 'icon-number-calendar',
      },
    ];
  }

  constructor(
    private helperService: TasksHelperService,
    private translationService: TranslateService,
    public taskService: TasksService,
    public modalService: ModalService,
    private subtaskService: SubtaskService,
    private domService: DomService,
    private confirmationSubscriber: UserConfirmationSubscriber,
    private timeEntryService: TimeentryService,
    private stopwatchService: StopwatchService,
  ) {}

  ngOnInit() {
    this.getAllAssignees(false);
    this.getTimeEntries();
    this.getTimeEntriesChanges();
  }

  ngAfterViewInit() {
    this.subscriptions.add(
      this.helperService.assignedEmployeesChanged.subscribe(() => {
        this.getAllAssignees(true);
      }),
    );
  }

  private getTimeEntriesChanges() {
    this.subscriptions.add(
      this.helperService.timeEntriesChanged.subscribe(() => {
        this.getTimeEntries();
      }),
    );
  }

  private getTimeEntries() {
    this.subscriptions.add(
      this.timeEntryService
        .getTimeentries(this.subtask.id, true)
        .subscribe((fetchedEntries: TimeEntryHelperResponseInterface) => {
          this.timeEntries = fetchedEntries.data.entries;
        }),
    );
  }

  public toggleIcons(): void {
    this.showIcons = !this.showIcons;
  }

  public checkboxChange(taskDone: boolean): void {
    if (taskDone) {
      this.subtask.status = this.getDeletedStatus();
      this.task.subtaskFinished++;
      this.taskService.updateTask(this.task);

      this.subscriptions.add(this.subtaskService.updateSubtask(this.subtask, this.task.id).subscribe());
    } else {
      if (this.task.status.statusGroup.id === 5) {
        const modalService = new ModalService(this.domService);
        const translations = {
          title: 'Tasks.labels.setTaskUndonModal.title',
          executeBtnTitle: 'Common.labels.continue',
          cancelBtnTitle: 'Common.labels.cancel',
          description: 'Tasks.labels.setTaskUndonModal.description',
        };

        modalService.init(TroiUserConfirmationComponent, { translations }, {}, '400px', '500px', true);

        this.confirmationSubscriber.action.pipe(first()).subscribe((result) => {
          if (result === UserConfirmationEventEnum.EXECUTE) {
            this.task.status = this.taskService.statusOptions[0];
            this.subtask.status = this.taskService.statusOptions[0];

            this.task.subtaskFinished--;
            this.taskService.updateTask(this.task);

            this.subscriptions.add(this.subtaskService.updateSubtask(this.subtask, this.task.id).subscribe());
          }
        });
      } else {
        this.subtask.status = this.task.status;
        this.task.subtaskFinished--;
        this.taskService.updateTask(this.task);

        this.subscriptions.add(this.subtaskService.updateSubtask(this.subtask, this.task.id).subscribe());
      }
    }
  }

  public onEditSubtaskClick(): void {
    if (this.subtask.status.id !== this.getDeletedStatus().id) {
      this.showEditSubtaskContent.emit(true);
      this.helperService.setSubtask(this.subtask);
    }
  }

  public getDeletedStatus(): StatusInterface {
    return this.taskService.statusOptions.find((status) => status.statusGroup.id === 5);
  }

  public getSubtasksEnddate(): string {
    const date = new Date(this.subtask.endDate);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    return `${day} ${month}`;
  }

  public getAllAssignees(reloadPerson: boolean): void {
    if (this.subtask.assignees && this.subtask.assignees.length > 0) {
      this.allAssignees = this.subtask.assignees.map((assignee: AssigneeInterface) => {
        return {
          firstName: assignee.user.firstName,
          lastName: assignee.user.lastName,
          image: assignee.user.image,
        };
      }) as PersonInterface[];
    } else {
      this.allAssignees = 'Tasks.labels.unassigned';
    }
    if (reloadPerson) setTimeout(() => this.troiPerson.assignMultiplePersons());
  }

  public onActionSelect(action): void {
    if (this.timeEntries.length) this.deletePopover.open();
    else if (action.value === TaskActionsEnum.DELETE_SUBTASK) {
      const modalService = new ModalService(this.domService);
      const translations = {
        title: 'Tasks.labels.deleteTaskModal.title',
        executeBtnTitle: 'Common.labels.continue',
        cancelBtnTitle: 'Common.labels.cancel',
        description: 'Tasks.labels.deleteTaskModal.description',
      };

      modalService.init(TroiUserConfirmationComponent, { translations }, {}, '400px', '500px', true);

      this.confirmationSubscriber.action.pipe(first()).subscribe((result) => {
        if (result === UserConfirmationEventEnum.EXECUTE) {
          this.subscriptions.add(
            this.subtaskService.deleteSubtask(this.subtask.id).subscribe((res) => {
              this.task.subtaskAll--;
              this.taskService.updateTask(this.task);
              this.helperService.setSubtaskToDelete(this.subtask);
            }),
          );
        }
      });
    }
  }

  public onDropdownActionOpeningState(event: boolean) {
    this.dropdownOpen = event;
  }

  public onStopwatchClick(subtask: SubtaskInterface): void {
    this.stopwatchService
      .createAndStart(this.stopwatchService.preparePayloadFromTask(subtask, this.task.id))
      .subscribe();
  }

  public isBookingEnabled(subtask: SubtaskInterface): boolean {
    return this.helperService.isBookingEnabled(subtask);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
