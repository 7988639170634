import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {
  private watchWindowClosedInterval: ReturnType<typeof setTimeout>;

  private static clearActionCompletedStatus(): void {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('external_window_action_completed', 'false');
    const path = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${searchParams.toString()}`;
    const pathObject = { path };
    window.history.pushState(pathObject, '', path);
  }

  public openWindow(url: string, width: number, height: number, windowActionCompletedCallback?: () => void): void {
    WindowService.clearActionCompletedStatus();

    const screenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const screenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    const screenWidth = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const screenHeight = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;
    const systemZoom = screenWidth / window.screen.availWidth;
    const left = (screenWidth - width) / 2 / systemZoom + screenLeft;
    const top = (screenHeight - height) / 2 / systemZoom + screenTop;
    const newWindow = window.open(
      url,
      '_blank',
      `
      scrollbars=auto,
      resizable=yes,
      width=${width / systemZoom},
      height=${height / systemZoom},
      top=${top},
      left=${left}
      `,
    );

    const watchWindowClosed = (actionCompletedCallback: () => void) => {
      if (newWindow && newWindow.closed) {
        clearInterval(this.watchWindowClosedInterval);

        const urlSearchParams = new URLSearchParams(window.location.search);
        if (
          urlSearchParams.has('external_window_action_completed') &&
          urlSearchParams.get('external_window_action_completed') === 'true' &&
          actionCompletedCallback
        ) {
          actionCompletedCallback();
        }
      }
    };

    if (windowActionCompletedCallback) {
      this.watchWindowClosedInterval = setInterval(watchWindowClosed.bind(this, windowActionCompletedCallback), 500);
    }

    if (window.focus) {
      newWindow?.focus();
    }
  }
}
