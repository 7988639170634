import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Routes } from '../enums/routes';
import { IUserResponse } from './IUser';

@Injectable()
export class UserNetwork {
  public constructor(public http: HttpClient) {}

  public fetchCurrentUser(): Observable<IUserResponse> {
    return this.http.get<IUserResponse>(environment.url + Routes.EMPLOYEE);
  }
}
