import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import {
  BackendResponseInterface,
  ResponseStatusInterface,
} from '../../../../../core/interfaces/backend.response.interface';
import { environment } from '../../../../../../environments/environment';
import { Routes } from '../enums/routes';
import { AccountInterface } from '../interfaces/account.interface';
import { AccountModel } from '../models/account.model';
import { OpeningBalanceInterface } from '../interfaces/opening-balance.interface';
import { ModuleInterceptor } from '../../../../../core/enums/module-interceptor';

@Injectable()
export class AccountsNetwork {
  public headers;

  public constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.ACCOUNTING, '1');
  }

  getList(params): Observable<BackendResponseInterface<AccountInterface[]>> {
    return this.http.get<BackendResponseInterface<AccountInterface[]>>(environment.url + Routes.ACCOUNT_LIST, {
      params,
      headers: this.headers,
    });
  }

  getAccount(accountId: string): Observable<BackendResponseInterface<AccountInterface>> {
    return this.http.get<BackendResponseInterface<AccountInterface>>(
      `${environment.url + Routes.ACCOUNT}/${accountId}`,
      { headers: this.headers },
    );
  }

  create(
    formGroup: UntypedFormGroup,
    openingBalances: OpeningBalanceInterface[],
  ): Observable<BackendResponseInterface<AccountInterface>> {
    return this.http.post<BackendResponseInterface<AccountInterface>>(
      environment.url + Routes.ACCOUNT,
      this.prepareAccountPayload(formGroup, openingBalances, true),
      { headers: this.headers },
    );
  }

  delete(id: string): Observable<BackendResponseInterface<ResponseStatusInterface>> {
    return this.http.delete<BackendResponseInterface<ResponseStatusInterface>>(
      `${environment.url + Routes.ACCOUNT}/${id}`,
      { headers: this.headers },
    );
  }

  edit(
    formGroup: UntypedFormGroup,
    openingBalances: OpeningBalanceInterface[],
  ): Observable<BackendResponseInterface<AccountInterface>> {
    return this.http.put<BackendResponseInterface<AccountInterface>>(
      `${environment.url + Routes.ACCOUNT}/${formGroup.value.id}`,
      this.prepareAccountPayload(formGroup, openingBalances, false),
      { headers: this.headers },
    );
  }

  inlineEdit(account: AccountModel): Observable<BackendResponseInterface<AccountInterface>> {
    return this.http.patch<BackendResponseInterface<AccountInterface>>(
      `${environment.url + Routes.ACCOUNT}/${account.id}`,
      {
        active: account.active,
      },
      { headers: this.headers },
    );
  }

  private prepareAccountPayload(
    formGroup: UntypedFormGroup,
    openingBalances: OpeningBalanceInterface[],
    withClient = false,
  ) {
    const data = {
      number: formGroup.value.number,
      name: formGroup.value.name,
      group: formGroup.value.group,
      tax: formGroup.value.tax,
      openingBalances,
      counterAccount: formGroup.value.counterAccount,
      summaryAccount: !_.isUndefined(formGroup.value.summaryAccount) ? formGroup.value.summaryAccount : null,
      cashAccount: formGroup.value.cashAccount,
      bankAccount: formGroup.value.bankAccount,
      automaticAccount: formGroup.value.automaticAccount,
      depositAccount: formGroup.value.depositAccount,
      datevLock: formGroup.value.datevLock,
      active: formGroup.value.active,
    };

    return withClient ? { ...data, ...{ client: formGroup.value.client } } : data;
  }
}
