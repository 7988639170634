<a class="menu-quick-link"
   *ngIf="item || skeleton"
   href="{{ item?.link }}"
   [target]="menuService.target(item)"
   [ngClass]="{'menu-quick-link--active': isActive, 'menu-quick-link--skeleton': skeleton }"
   [draggable]="true"
   (dragstart)="onDragStart($event)"
   (dragend)="onDragEnd($event)"
   (click)="checkAnyUnsavedItem()"
>
    <div class="menu-quick-link__hover">
        <div class="menu-quick-link__label" [innerHTML]="menuService.getLanguageValue(item?.label)"></div>
    </div>
    <div class="menu-quick-link__icon">
        <troi-icon [icon]="item?.iconName" [size]="'24px'"></troi-icon>
    </div>
</a>
