import { Injectable } from '@angular/core';
import { UploadedFilesNetwork } from '../networks/uploaded-files.network';

@Injectable()
export class UploadedFilesService {
  constructor(private network: UploadedFilesNetwork) {}

  loadUnusedFiles(client: number, year: number, search: string, page: number, pageSize: number) {
    return this.network.getList({
      searchPhrase: search,
      client,
      year,
      currentPage: page,
      pageSize,
      sortDir: 'asc',
      sortBy: 'name',
      used: 'not-used',
      sizeFrom: '0',
      sizeTo: '',
      uploadedDateFrom: '',
      uploadedDateTo: '',
      showCompleteGroups: 'true',
    });
  }
}
