import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { TroiNotification, TroiNotificationType } from '../../core/notifications/notification';
import { StorageKeys } from '../../core/storage/storage.keys';
import { StorageListener } from '../../core/storage/storage.listener';
import { NgEventBus } from 'ng-event-bus';

@Component({
  selector: 'header-notification',
  templateUrl: './headerNotification.component.html',
  styleUrls: ['./headerNotification.component.scss'],
})
export class HeaderNotificationComponent {
  @Input('asToast') public asToast = true;

  public showNotification = false;

  public notification: TroiNotification;

  public constructor(
    private storageListener: StorageListener,
    private eventBus: NgEventBus,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    storageListener.storageChanges.subscribe((notificationData: any) => {
      if (notificationData.key === StorageKeys.NOTIFICATION) {
        this.displayNotification(notificationData.value);
        this.storageListener.clear('notification');
      }
    });

    this.eventBus.on('app:notification:display').subscribe((meta) => {
      if (!(meta.data instanceof TroiNotification)) {
        return;
      }

      this.displayNotification(meta.data);
    });
  }

  public isError() {
    return this.notification.type === TroiNotificationType.ERROR;
  }

  public isWarning() {
    return this.notification.type === TroiNotificationType.WARNING;
  }

  public isInfo() {
    return this.notification.type === TroiNotificationType.INFO;
  }

  public isSuccess() {
    return this.notification.type === TroiNotificationType.SUCCESS;
  }

  public closeNotification() {
    this.showNotification = false;
    this.changeDetectorRef.detectChanges();
  }

  private displayNotification(notification: TroiNotification) {
    this.notification = notification;
    this.showNotification = true;
    this.changeDetectorRef.detectChanges();

    setTimeout(() => {
      this.closeNotification();
    }, 10000);
  }
}
