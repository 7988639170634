<div class="comment-section">
  <span class="comment-section__subtitle">{{
    'Tasks.labels.createTaskModal.comments.subtitle' | translate
  }}</span>
  <div *ngIf="!showTextfield" (click)="toggleTextfield()" class="comment-section__add-comment">
    <troi-icon [icon]="'icon-empty-bubble'" [size]="'16px'"></troi-icon>
    <span>{{ 'Tasks.labels.createTaskModal.comments.leaveComment' | translate }}</span>
  </div>

  <div *ngIf="showTextfield" class="comment-section__header">
    <div class="comment-section__attachment-container">
      <span
        *ngIf="!answerComment"
        class="comment-section__attachment-container__add-attachment"
        (click)="dropzone.showFileSelector()"
        >+ {{ 'Tasks.labels.attachment.add' | translate }}</span
      >
    </div>

    <div class="attachment-dropdown-zone">
      <ngx-dropzone
        [accept]="'image/png,image/jpeg,image/jpg, application/pdf'"
        [maxFileSize]="20000000"
        (filesAdded)="onFileUpload($event, dropzone)"
        #dropzone
      ></ngx-dropzone>
    </div>

    <quill-editor
      #editor
      class="comment-section__header--editor"
      [(ngModel)]="comment"
      (ngModelChange)="convertURL($event)"
      placeholder="{{ 'Tasks.labels.createTaskModal.comments.addComment' | translate }}"
      [modules]="helperService.quillConfig"
    ></quill-editor>

    <div class="comment-section__footer">
      <div *ngIf="uploadedAttachments.length > 0" class="file-container">
        <div title="{{ attachment.filename }}" *ngFor="let attachment of uploadedAttachments">
          <troi-attachment
            [attachment]="attachment"
            [attachmentType]="'taskcomment'"
            [isCreateComment]="true"
            (deleteFile)="onFileDelete($event)"
          ></troi-attachment>
        </div>
      </div>
      <div class="comment-section__header--btn-grp">
        <troi-btn class="cancel-btn" (click)="toggleTextfield()">
          <span>{{ 'Common.labels.cancel' | translate }}</span>
        </troi-btn>
        <troi-btn class="save-btn" (click)="onCommentClick()">
          <span *ngIf="answerComment">{{
            'Tasks.labels.createTaskModal.comments.answer' | translate
          }}</span>
          <span *ngIf="!answerComment">{{
            'Tasks.labels.createTaskModal.comments.comment' | translate
          }}</span>
        </troi-btn>
      </div>
    </div>
  </div>

  <div class="comment-section__comments">
    <div class="comment-section__comments--scrollable">
      <div
        class="comment-section__comments--comment-container"
        *ngFor="let comment of comments; let i = index"
      >
        <div class="comment-section__comments--comment-container--main">
          <troi-task-modal-comment
            [comment]="comment"
            [task]="task"
            (reply)="onReplyClick($event)"
            (commentsChanged)="getComments()"
          ></troi-task-modal-comment>
        </div>
        <div
          *ngIf="comment.answers && comment.answers.length > 0"
          class="comment-section__comments--comment-container--answer-container"
        >
          <div
            class="comment-section__comments--comment-container--answer-container--answer"
            *ngFor="let answer of comment.answers"
          >
            <troi-task-modal-comment
              (commentsChanged)="getComments()"
              [isAnswer]="true"
              [task]="task"
              [comment]="answer"
            ></troi-task-modal-comment>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
