export enum Routes {
  BASE = '/components/timerecording',
  TIMERECORDING = '/components/timerecording/worktimesummary',
  WORKINGTIMELOG = '/components/timerecording/workingtime/',
  PROJECTTIMELOG = '/components/timerecording/projecttime/',
  PROJECTTIMELOGADD = '/components/desktop/project-timerecording/log',
  PROJECTTIMELOGEDITORDELETE = '/components/desktop/project-timerecording/log/',
  FAVUNFAV = '/components/desktop/project-timerecording/',
  MYPROJECT = '/components/timerecording/project-timerecording/calculation-position/search',
  GETSETTINGS = '/components/desktop/settings',
  MYPROJECTCPID = '/components/timerecording/project-timerecording/calculation-position/fetch',
  TIMESHEETSEXPORT = '/components/timerecording/export/timesheets',
  WORKINGTIMEEXPORT = '/components/timerecording/export/workingtime',
  PROJECTTIMEEXPORT = '/components/timerecording/export/projecttime',
  DESKTOPPROJECTTIMELOG = '/components/desktop/project-timerecording/log',
  RELOG = '/components/timerecording/project-timerecording/calculation-position/relog',
  SPREAD = '/components/timerecording/project-timerecording/calculation-position/spread',
  ASSIGNEDANDUNASSIGNED = '/components/timerecording/projectassignee/',
  DECLINEDHOURS = '/components/timerecording/project-timerecording/declined-position/fetch',
  BOILERPLATES = '/components/timerecording/boiler_plates',
}
