import { TreeListElementStatus } from '../../common/enums/tree-list-element-status';

export class InvoiceAssignNumberModel {
  constructor(
    public id: number | string,
    public name: string,
    public assignmentNumber: string,
    public parent: number | string,
    public treeStatus: TreeListElementStatus,
  ) {}
}
