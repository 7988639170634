<div
  class="tnd-datepicker"
  [ngClass]="{
    'tnd-datepicker--disabled': disabled,
    'default-alignment': defaultAlignment,
    'create-task-alignment': createTaskAlignment
  }"
>
  <troi-icon
    *ngIf="showIcon"
    icon="icon-list-points"
    class="tnd-icon-calendar"
    [size]="fontSize?.toString() + 'px'"
  ></troi-icon>
  <input
    type="text"
    class="tnd-datepicker__input"
    (click)="setPosition()"
    [placeholder]="placeholder"
    troi-daterangepicker
    [(ngModel)]="selected"
    [showCustomRangeLabel]="false"
    (click)="onPickerShown()"
    [alwaysShowCalendars]="true"
    [locale]="settings"
    [ranges]="[]"
    [autoApply]="singleDatePicker"
    [linkedCalendars]="true"
    [align]="'right'"
    [singleDatePicker]="singleDatePicker"
    (ngModelChange)="dateChanged($event, true)"
    (startDateChanged)="dateChanged($event, true, true)"
    [showClearButton]="showClearButton"
    [showCancel]="showCancelButton"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [ngClass]="{ 'tnd-datepicker--invalid': (fieldInvalid && validationEnabled) || !validDate }"
    [style.font-size.px]="fontSize"
    [style.font-family]="fontFamily"
    [style.padding-left]="paddingLeft"
  />
</div>
<div class="tnd-datepicker__error" *ngIf="validationEnabled || !validDate">
  <span *ngIf="(fieldInvalid && validDate) || showError" title="{{ requiredFieldErrorMessage | translate }}">{{
    requiredFieldErrorMessage | translate
  }}</span>
  <span *ngIf="!validDate" title="{{ 'Common.error.incorrectDate' | translate }}">{{
    'Common.error.incorrectDate' | translate
  }}</span>
</div>
