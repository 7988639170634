<div class="LogTable">
  <!-- <div class="error" *ngIf="showError">{{ showErrorMessage }}</div> -->
  <troi-loading-spinner *ngIf="loading"></troi-loading-spinner>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">{{ 'Timerecording.WorkingTime' | translate }}</th>
        <th scope="col">{{ 'Timerecording.Start' | translate }}</th>
        <th scope="col">{{ 'Timerecording.End' | translate }}</th>
        <th scope="col">{{ 'Timerecording.Totals' | translate }}</th>
      </tr>
    </thead>

    <tbody *ngIf="tableData && tableData.length > 0">
      <tr *ngFor="let item of tableData; let i = index; last as isLast">
        <td class="d-flex justify-content-between align-items-center">
          <span *ngIf="item.name === 'Check In'">{{ 'Timerecording.CheckIn' | translate }}</span>
          <span *ngIf="item.name === 'Break'">— {{ 'Timerecording.Break' | translate }}</span>

          <div class="d-flex" *ngIf="isEditable">
            <ng-container *ngIf="item.checkIn !== '00:00' && item.checkOut !== '00:00'">
              <troi-icon-button
                class="actions__icon"
                icon="icon-plus"
                title=" {{ 'Timerecording.add_break' | translate }}"
                (click)="addData('Break', i + 1, true)"
              ></troi-icon-button>
            </ng-container>
            <troi-icon-button
              class="actions__icon"
              icon="icon-trash-can"
              title=" {{ 'Timerecording.delete' | translate }}"
              (click)="deleteData(i, item)"
              *ngIf="showDeleteIcon || i > 1"
            ></troi-icon-button>
          </div>
        </td>
        <td [ngClass]="'input-' + i">
          <span *ngIf="!item.showCheckInInput" (click)="showInputField(item, 'checkIn')">{{
            item.checkIn || '00:00'
          }}</span>
          <troi-timepicker
            *ngIf="item.showCheckInInput && isEditable"
            [(ngModel)]="item.checkIn"
            (keyup.enter)="onEnterUp($event, item, 'checkIn')"
            [asString]="true"
          ></troi-timepicker>
        </td>

        <td [ngClass]="'input-' + i">
          <span *ngIf="!item.showCheckOutInput" (click)="showInputField(item, 'checkOut')">{{
            item.checkOut || '00:00'
          }}</span>
          <troi-timepicker
            *ngIf="item.showCheckOutInput && isEditable"
            [(ngModel)]="item.checkOut"
            (keyup.enter)="onEnterUp($event, item, 'checkOut')"
            [asString]="true"
          ></troi-timepicker>
        </td>

        <td [class.breal-red]="item.name === 'Break'">
          <span><troi-icon icon="icon-clock-open"></troi-icon> </span>
          <span class="mx-2">{{ timeService.setFormat(timeService.formatTime(item.total)) }}</span>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="3"></td>
        <td class="d-flex justify-content-end">
          <troi-icon-button
            icon="icon-save"
            title=" {{ 'Booking.labels.save' | translate }}"
            (click)="saveData()"
            *ngIf="isEditable"
          ></troi-icon-button>
        </td>
      </tr>
      <tr *ngIf="showAbsence && summaryData?.workingTime?.absenceQuantity > 0">
        <td colspan="3">{{ 'Timerecording.Absences' | translate }}</td>
        <td>{{ summaryData.workingTime.absenceQuantity }} h</td>
      </tr>
      <tr>
        <td colspan="3">
          <span>{{ 'Timerecording.Networkingtime' | translate }} </span>
          <br />
          <div class="mt-2" [style.width]="'280px'" *ngIf="isEditable">
            <troi-btn class="list-top-bar__button" (click)="addData('Check In', this.tableData.length, true)">
              <troi-icon icon="icon-plus" size="14px" [bolder]="true"></troi-icon>
              <span class="mx-2">{{ 'Timerecording.Add Check-In' | translate }}</span>
            </troi-btn>
          </div>
        </td>

        <td class="totalWorkingTime">
          <span><troi-icon icon="icon-clock-open"></troi-icon> </span>
          <span class="mx-2">{{ timeService.setFormat(timeService.formatTime(totalWorkingTime)) }}</span>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
