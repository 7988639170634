export enum Routes {
  LIST_VIEW = '/components/taskplanning/listview',
  SETTINGS = '/components/taskplanning/settings',
  TASKS = '/components/taskplanning/projecttask',
  SUBTASKS = '/components/taskplanning/subtask',
  STATUS = '/components/taskplanning/status',
  EMPLOYEES = '/components/taskplanning/helper/employee',
  PROJECTS = '/components/utils/projectsForUser',
  EXPORT = '/components/taskplanning/projecttask/export',
  FILTER_SETS = '/components/taskplanning/filterset',
  PHRASES = '/components/taskplanning/helper/texts',
  TAGS = '/components/taskplanning/tag',
  TAGS_DROPDOWN = '/components/taskplanning/helper/tagDropdown',
  ATTACHMENTS = '/components/taskplanning/attachment',
  REMINDER = '/components/taskplanning/projecttask/reminder',
  CALCPOS = '/components/taskplanning/helper/calculationPosition',
  CLIENT = '/components/desktop/search/client',
  CUSTOMER = '/components/desktop/search/customer',
  PROJECT = '/components/desktop/search/project',
  ASSIGNEES = '/components/taskplanning/projecttask/assignee/utilization',
  SUBPROJECTS = '/components/booking/subprojects',
  ASSIGNMENT = '/components/taskplanning/assignment',
  TIMERECORD = '/components/taskplanning/helper/timerecordCalcPos',
  TIMERECORD_PLANNED = '/components/taskplanning/helper/hoursBudgetByCalcPos',
  TIMEENTRY = '/components/desktop/project-timerecording/log',
  TIMEENTRY_HELPER = '/components/taskplanning/helper/timerecordEntries',
  TIMEENTRY_HELPER_SUBTASK = '/components/taskplanning/helper/timerecordEntries/subtask',
  COMMENTS = '/components/taskplanning/taskcomment',
  COMMENTS_ANSWER = '/components/taskplanning/taskcommentanswer',
  COMMENTS_LIKE = '/components/taskplanning/taskcommentlike',
  COMMENTS_ANSWER_LIKE = '/components/taskplanning/taskcommentanswerlike',
  PROJECT_UNIT = '/components/taskplanning/helper/projectUnitDetail',
  PROJECT_INFO = '/components/taskplanning/helper/project/single',
  FORECAST = '/components/taskplanning/forecast',
  RESOURCES = '/components/taskplanning/resources',
  TEAM_UNITS = '/components/taskplanning/projecttask/assignee/utilization/projectUnit',
  TASKS_SUBPROJECTS = 'components/taskplanning/helper/subproject',
  TASKS_SUBPROJECTS_WITH_CP = '/components/taskplanning/helper/subprojectWithCp',
  TASKS_EMAIL_NOTIFICATION = '/components/taskplanning/mail/newassignee',
  TASKS_SUBTASKS_STATUS_BATCH = '/components/taskplanning/subtask/status/batch',
  FIND_TASK_BY_ID = '/components/taskplanning/projecttask/getOne',
}
