import { Injectable } from '@angular/core';
import { AsyncSubject, Subject } from 'rxjs';
import { UserConfirmationEventEnum } from './user-confirmation-event.enum';

export interface UserConfirmationWithDataEvent {
  event: UserConfirmationEventEnum;
  data: any;
}

@Injectable()
export class UserConfirmationSubscriber {
  public action = new Subject<UserConfirmationEventEnum>();

  public actionWithData = new Subject<UserConfirmationWithDataEvent>();
}
