import { Component, Input } from '@angular/core';

@Component({
  selector: 'troi-checkmark',
  templateUrl: './troi-checkmark.component.html',
  styleUrls: ['./troi-checkmark.component.scss'],
})
export class TroiCheckmarkComponent {
  @Input() state = false;
}
