import { Component, Input } from '@angular/core';
import { RowInterface } from '../interfaces/row.interface';

@Component({
  selector: 'troi-table-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
})
export class TroiTableRowComponent {
  @Input() row: RowInterface;
}
