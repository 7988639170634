<div
  class="troi-input-field"
  [ngClass]="{
    'troi-input-field--disabled': !enabled
  }"
>
  <input
    [ngClass]="{ 'troi-input-field__input--invalid': fieldInvalid }"
    class="troi-input-field__input"
    [type]="type"
    [placeholder]="placeholder"
    [mask]="mask"
    [decimalMarker]="decimalMarker"
    [separatorLimit]="separatorLimit"
    thousandSeparator="."
    [(ngModel)]="_value"
    (ngModelChange)="valueChanged.emit(_value)"
    [disabled]="!enabled"
  />
  <div class="troi-input-field__error" *ngIf="validationEnabled">
    <small *ngIf="fieldInvalid">{{ error }}</small>
  </div>
</div>
