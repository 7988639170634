import { Injectable } from '@angular/core';
import { BookingFormNetwork } from '../networks/booking-form.network';
import { LanguagesService } from '../../../../core/services/languages.service';
import { SearchProjectService } from './search-project.service';

@Injectable()
export class SearchProjectByCustomerService extends SearchProjectService {
  constructor(public network: BookingFormNetwork, public languagesService: LanguagesService) {
    super(network, languagesService);
  }

  loadProjects(concat = false) {
    if (!concat) {
      this.projects = [];
      this.extraLoadedSubprojects = [];
    }
    this.isLoading = true;
    this.network
      .fetchProjectByCustomerList(
        this.search,
        this.client,
        this.page,
        this.pageSize,
        this.languagesService.getLanguage(),
        this.subPageSize,
      )
      .subscribe((result) => {
        this.isLoading = false;
        this.projects = concat ? [...this.projects, ...result.items] : result.items;
        this.canLoadMore = this.page === 1 || result.items.length === this.pageSize;
      });
  }
}
