import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { LanguagesService } from '../../../../core/services/languages.service';
import { PaymentMethodNetwork } from '../../../../modules/accounts/accounting-administration/payment-methods/networks/payment-method.network';
import { TroiDropdownListModel } from '../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';

@Injectable()
export class PaymentMethodsService {
  public methods = [
    {
      id: 1,
      translationKey: 'Common.labels.cash',
    },
    {
      id: 2,
      translationKey: 'Common.labels.debitCard',
    },
    {
      id: 3,
      translationKey: 'Common.labels.creditCard',
    },
    {
      id: 4,
      translationKey: 'Common.labels.directDebit',
    },
    {
      id: 5,
      translationKey: 'Common.labels.transfer',
    },
  ];

  constructor(
    private translateService: TranslateService,
    private pmn: PaymentMethodNetwork,
    private languageService: LanguagesService,
  ) {}

  buildPaymentMethods(client: number): Array<TroiDropdownListModel> {
    const paymentMethods: Array<TroiDropdownListModel> = [];
    new Promise(() => {
      this.pmn.getList(client, true).subscribe((result) => {
        _.forEach(result, ({ name, id, active, translations, deletedAt }) => {
          if (_.isNull(deletedAt)) {
            let label = name;

            if (translations) {
              label = translations[this.languageService.getDataLanguage()];
            }

            paymentMethods.push({
              label,
              value: id,
              active,
            });
          }
        });

        paymentMethods.unshift({
          label: this.translateService.instant('Booking.labels.pleaseSelect'),
          value: '',
          active: true,
        });
      });
    });
    return paymentMethods;
  }
}
