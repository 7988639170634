import { LocalStorageStatesService } from '../../../../../../shared/troi-ls-states/local-storage-states.service';
import { StorageService } from '../../../../../../core/storage/storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class FolderUnfoldedStateService extends LocalStorageStatesService {
  constructor(public storageService: StorageService) {
    super(storageService);
    this.statesLSKey = 'unfoldedProjects';
    this._savedIds = this.fetchSavedIdsFromLS();
  }
}
