import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DatepickerPositionService {
  setPosition({ position, top, left }: { position: string; top: string; left: string }) {
    document.documentElement.style.setProperty('--position', position);
    document.documentElement.style.setProperty('--top', top);
    document.documentElement.style.setProperty('--left', left);
  }
}
