<div class="not-allowed-assignee-modal">
  <troi-modal-header [noCloseButton]="true">
    <span> {{ 'Tasks.labels.notAllowedAssigneeModal.title' | translate }}</span>
  </troi-modal-header>
  <troi-modal-content style="overflow-y: inherit">
    <p class="not-allowed-assignee-modal__message">
      {{ 'Tasks.labels.notAllowedAssigneeModal.info' | translate }}
    </p>

    <ul class="not-allowed-assignee-modal__assignee-container">
      <li *ngFor="let assignee of assignees">
        {{ assignee.user.firstName }} {{ assignee.user.lastName }}
      </li>
    </ul>
  </troi-modal-content>
  <troi-modal-footer>
    <troi-btn class="not-allowed-assignee-modal__button" [outline]="true" (click)="close()">
      {{ 'Common.labels.cancel' | translate }}
    </troi-btn>
    <troi-btn class="not-allowed-assignee-modal__button" (click)="execute()">
      {{ 'Common.labels.remove' | translate }}
    </troi-btn>
  </troi-modal-footer>
</div>
